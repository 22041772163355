import { Box, Grid, IconButton, TextField, useTheme } from "@mui/material";
import { TypographyBold } from "../../components/TypographyBold";
import ClearIcon from "@mui/icons-material/Clear";
import { StatusSelect } from "./StatusSelect";

export const ItemComponent = ({
  title,
  value,
  set,
  remove,
  check,
  completed,
  disabled,
  canTick,
  mobile,
}: {
  title: string;
  value: string;
  set: (event: any) => void;
  remove: () => void;
  check: (event: any) => void;
  completed: boolean | undefined;
  disabled: boolean | undefined;
  canTick: boolean | undefined;
  mobile: boolean;
}) => {
  const theme = useTheme();

  return (
    <>
      {title === "Goal" ? (
        <TypographyBold sx={{ p: theme.spacing(0, 2, 1, 2) }}>{title}:</TypographyBold>
      ) : (
        <Grid container>
          {!mobile && (
            <Box
              sx={{
                position: "relative",
                left: "-35px",
                top: "5px",
                borderLeft: "1px solid rgba(0, 0, 0, .25)",
                borderBottom: "1px solid rgba(0, 0, 0, .25)",
                borderBottomLeftRadius: theme.spacing(2),
                height: theme.spacing(3),
                width: theme.spacing(5),
              }}
            />
          )}
          <TypographyBold
            sx={{
              left: mobile ? "5px" : "-35px",
              position: "relative",
              p: theme.spacing(2, 2, 1, 1),
            }}
          >
            {title}:
          </TypographyBold>
        </Grid>
      )}
      <Grid container direction="row-reverse" alignItems="center">
        {!disabled && (
          <IconButton color="error" aria-label="delete" component="span" onClick={remove}>
            <ClearIcon />
          </IconButton>
        )}
        <StatusSelect handleChange={check} completed={completed} canTick={canTick} mobile={mobile} />
        <Grid item xs>
          <TextField
            sx={{
              [`& fieldset`]: {
                borderRadius: "10px",
              },
            }}
            size="small"
            disabled={disabled}
            placeholder={`Enter your ${title.toLowerCase()}`}
            onChange={set}
            value={value}
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};
