import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

export const Review360HelpDialog = ({ dialogOpen, closeDialog }: { dialogOpen: boolean; closeDialog: () => void }) => (
  <Dialog open={dialogOpen} onClose={closeDialog} aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-slide-title">Review Help</DialogTitle>
    <DialogContent>
      <Typography gutterBottom>
        This survey includes 3 categories (Character, Faith and Service), each with various learning outcomes.
      </Typography>
      <Typography gutterBottom>Please answer all the questions to be best of your abilities.</Typography>
      <Typography>
        There is a written section for each category (Start, Stop, Continue) if you are reviewing someone else. Please
        fill it in based on the ratings you gave the reviewee.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={closeDialog} color="primary">
        Got it!
      </Button>
    </DialogActions>
  </Dialog>
);
