import { Link as ReactRouterLink } from "react-router-dom";
import { Card, CardActions, CardContent, CardHeader, Grid, Link, Paper, Typography } from "@mui/material";
import { Path } from "../../../helpers/Path";
import { useTheme } from "@mui/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { TypographyBold } from "../../../components/TypographyBold";
import { CalendarComponent, CalendarInterface } from "../../../components/CalendarComponent";
import { Legend } from "../../../components/Legend";

export const CalendarCard = ({
  calendarComponent,
  setState,
}: {
  calendarComponent: CalendarInterface;
  setState: (calendarComponent: CalendarInterface) => void;
}) => {
  const theme = useTheme();
  return (
    <Paper elevation={8}>
      <Card>
        <CardHeader
          sx={{
            padding: theme.spacing(4, 5, 4, 5),
          }}
          title={<TypographyBold variant="h5">Calendar</TypographyBold>}
        />
        <CardContent
          sx={{
            minHeight: 550,
            padding: theme.spacing(0, 3, 1, 3),
          }}
        >
          <Legend />
          <CalendarComponent
            state={calendarComponent}
            setState={setState}
            defaultCurrentViewName={"Month"}
            startDayHour={9}
            endDayHour={22}
            height={"auto"}
            showToolbar={false}
            editEvent={false}
          />
        </CardContent>
        <CardActions
          sx={{
            padding: theme.spacing(1, 2, 2, 1),
          }}
        >
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Link component={ReactRouterLink} underline="hover" to={Path.Calendar}>
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="body2">Go to calendar</Typography>
                <ArrowForwardIcon />
              </Grid>
            </Link>
          </Grid>
        </CardActions>
      </Card>
    </Paper>
  );
};
