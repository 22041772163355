import { useContext, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Form, Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { ModuleContent } from "../../models/ModuleContent";
import { ModuleModel } from "../../models/ModuleModel";
import { ModuleSAQ } from "../../models/ModuleSAQ";
import * as yup from "yup";
import { deleteModule, getAllModules, getModule, saveModule } from "../../services/modules";
import { MessageState } from "../../models/MessageState";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { InitModuleStats } from "../../models/ModuleStats";
import { RolesContext } from "../../components/PrivateRoute";
import { ModuleMCQ } from "../../models/ModuleMCQ";
import { SAQ } from "./SAQ";
import { moduleMonths } from "../../helpers/FormatDate";
import { maxFileSize } from "../../services/users";
import { storage } from "../../services/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { ModuleListModel } from "../../models/ModuleListModel";

const grid = 8;

interface EditModuleInterface {
  loading: boolean;
  alert: MessageState;
  module?: ModuleModel;
  modules: ModuleListModel[];
}

const editModuleFormValidationSchema = yup.object().shape({
  title: yup.string().required("Enter module title (e.g. Introduction to SOW)"),
});

const reorderContent = (list: ModuleContent[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const reorderSAQ = (list: ModuleSAQ[] | undefined, startIndex: number, endIndex: number) => {
  if (list !== undefined) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  } else {
    return undefined;
  }
};

const reorderQuestions = (list: ModuleMCQ[] | undefined, startIndex: number, endIndex: number) => {
  if (list !== undefined) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  } else {
    return undefined;
  }
};

const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  // change background colour if dragging
  background: isDragging ? "lightgrey" : "white",
  padding: grid * 1,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "lightgrey" : "white",
});

export const EditModule = () => {
  const roles = useContext(RolesContext);
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const field = {
    mt: theme.spacing(2),
    mb: theme.spacing(2),
  };
  const [state, setState] = useState<EditModuleInterface>({
    loading: true,
    modules: [],
    alert: {
      showMessage: false,
      message: "",
      severity: "success",
    },
  });

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    const moduleFetch = getModule(id);
    const getAllModulesFetch = getAllModules();

    Promise.all([moduleFetch, getAllModulesFetch]).then(([module, modules]) =>
      setState({
        ...state,
        module,
        modules: modules.flat(),
        loading: false,
      })
    );

    // eslint-disable-next-line
  }, [id]);

  const { loading, alert, module, modules } = state;

  const history = useHistory();

  const variant = [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "caption",
    "button",
    "overline",
  ];

  return loading ? (
    <Loading />
  ) : (
    <>
      <Snackbar
        open={alert.showMessage}
        onClose={() =>
          setState({
            ...state,
            alert: { ...alert, showMessage: false },
          })
        }
        autoHideDuration={6000}
      >
        <Alert elevation={6} variant="filled" severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Container maxWidth="md">
        <Typography variant="h5">{module ? "Edit module" : "New module"}</Typography>
        <Formik
          initialValues={
            module
              ? module
              : ({
                  id: "",
                  title: "",
                  month: "September",
                  order: -1,
                  availableRoles: [],
                  contents: [],
                  questions: [],
                  totalSteps: 0,
                  moduleStats: InitModuleStats,
                } as ModuleModel)
          }
          onSubmit={(values, actions) => {
            const prevId = values.id;
            deleteModule(values.id).then(() =>
              saveModule(values).then((module) => {
                setState({
                  ...state,
                  alert: {
                    showMessage: true,
                    message: "Module saved",
                    severity: "success",
                  },
                  module,
                });
                if (prevId === "" || prevId === undefined) {
                  history.push(`/edit-module/${module.id}`);
                }
                actions.setSubmitting(false);
              })
            );
          }}
          validationSchema={editModuleFormValidationSchema}
        >
          {(props) => {
            const { values, errors, touched, handleBlur, handleChange, setFieldValue, isSubmitting } = props;

            const increaseSteps = () => {
              setFieldValue(
                "contents",
                values.contents.concat({ title: "", content: [], id: values.totalSteps.toString() })
              );
              setFieldValue("totalSteps", values.totalSteps + 1);
            };

            const decreaseSteps = () => {
              setFieldValue("totalSteps", values.totalSteps - 1);
              setFieldValue("contents", values.contents.slice(0, -1));
            };

            const increaseContent = (i: number) =>
              setFieldValue(
                `contents[${i}].content`,
                values.contents[i].content.concat({
                  id: i.toString(),
                  text: "",
                  variant: "body1",
                  italic: false,
                  underline: false,
                  bold: false,
                })
              );

            const addVideo = (i: number) => setFieldValue(`contents[${i}].youtubeLink`, "");

            const deleteVideo = (i: number) => setFieldValue(`contents[${i}].youtubeLink`, undefined);

            const decreaseContent = (i: number) =>
              setFieldValue(`contents[${i}].content`, values.contents[i].content.slice(0, -1));

            const increaseSAQQuestion = (i: number) =>
              setFieldValue(
                `contents[${i}].saq`,
                values.contents[i].saq
                  ? values.contents[i].saq?.concat({
                      id: values.contents[i].saq ? (values.contents[i].saq as ModuleSAQ[]).length.toString() : "0",
                      question: "",
                      sampleAnswers: [],
                    })
                  : [
                      {
                        id: values.contents[i].saq ? (values.contents[i].saq as ModuleSAQ[]).length.toString() : "0",
                        question: "",
                        sampleAnswers: [],
                      },
                    ]
              );

            const decreaseSAQQuestion = (i: number) =>
              setFieldValue(`contents[${i}].saq`, values.contents[i].saq?.slice(0, -1));

            const increaseSAQSampleAnswer = (i: number, j: number) =>
              setFieldValue(
                `contents[${i}].saq[${j}].sampleAnswers`,
                (values.contents[i].saq?.[j]?.sampleAnswers as string[]).concat("")
              );

            const decreaseSAQSampleAnswer = (i: number, j: number) =>
              setFieldValue(
                `contents[${i}].saq[${j}].sampleAnswers`,
                (values.contents[i].saq?.[j]?.sampleAnswers as string[]).slice(0, -1)
              );

            const addSAQ = (i: number) =>
              setFieldValue(`contents[${i}].saq`, [{ id: "0", question: "", sampleAnswers: [] }]);

            const deleteSAQ = (i: number) => setFieldValue(`contents[${i}].saq`, undefined);

            const addMCQ = () =>
              setFieldValue("questions", [
                {
                  id: "0",
                  question: "",
                  choices: [{ id: "0", text: "" }],
                  answer: "1",
                  explanation: "",
                },
              ]);

            const deleteMCQ = () => setFieldValue("questions", []);

            const increaseMCQQuestion = () =>
              setFieldValue(
                "questions",
                values.questions
                  ? values.questions.concat({
                      id: values.questions.length.toString(),
                      question: "",
                      choices: [{ id: "0", text: "" }],
                    })
                  : [
                      {
                        id: "0",
                        question: "",
                        choices: [{ id: "0", text: "" }],
                      },
                    ]
              );

            const decreaseQuestion = () => setFieldValue("questions", values.questions?.slice(0, -1));

            const increaseChoice = (i: number) =>
              values.questions &&
              setFieldValue(
                `questions[${i}].choices`,
                values.questions[i].choices.concat({
                  id: values.questions[i].choices.length.toString(),
                  text: "",
                })
              );

            const decreaseChoice = (i: number) =>
              values.questions && setFieldValue(`questions[${i}].choices`, values.questions[i].choices.slice(0, -1));

            const onUploadChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
              if (e.target === null || e.target.files === null) {
                return;
              }
              const file = e.target.files[0];
              if (file.size > maxFileSize) {
                setState({
                  ...state,
                  alert: {
                    showMessage: true,
                    message: "Image exceeds file size limit (5MB)",
                    severity: "error",
                  },
                });
                return;
              }
              const fileURL = `modules/${module?.id}/${index}.${file.name.substring(file.name.lastIndexOf(".") + 1)}`;
              const storageRef = ref(storage, fileURL);
              uploadBytes(storageRef, file).then(() =>
                getDownloadURL(storageRef).then((url) => setFieldValue(`contents[${index}].imageURL`, url))
              );
            };

            return (
              <Form>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="title"
                  label="Module Title"
                  name="title"
                  value={values.title}
                  error={errors.title && touched.title ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="standard"
                />
                <InputLabel id="month-label" required sx={field}>
                  Select Month
                </InputLabel>
                <Select
                  label="Month"
                  required
                  fullWidth
                  labelId="month-label"
                  id="month"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="month"
                  value={values.month}
                  error={errors.month && touched.month ? true : false}
                  variant="standard"
                >
                  <MenuItem value={"September"}>
                    <em>None</em>
                  </MenuItem>
                  {moduleMonths.map((v, i) => (
                    <MenuItem key={i} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
                <InputLabel id="order-label" required sx={field}>
                  Select Order
                </InputLabel>
                <Select
                  label="Order"
                  disabled={values.month === "September"}
                  required
                  fullWidth
                  labelId="order-label"
                  id="order"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="order"
                  value={values.order}
                  error={errors.order && touched.order ? true : false}
                  variant="standard"
                >
                  <MenuItem value={-1}>
                    <em>None</em>
                  </MenuItem>
                  {[1, 2].map((v, i) => {
                    let findModule = modules.filter((m) => m.month === values.month && m.order === v);

                    return (
                      <MenuItem disabled={findModule.length > 0} key={i} value={v}>
                        {v} {findModule.length > 0 && " - " + findModule.at(0)?.title}
                      </MenuItem>
                    );
                  })}
                </Select>
                <InputLabel id="availableRoles-label" required sx={field}>
                  Select Roles that can access this module
                </InputLabel>
                <Select
                  label="Roles"
                  required
                  fullWidth
                  multiple
                  labelId="availableRoles-label"
                  id="availableRoles"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="availableRoles"
                  value={values.availableRoles}
                  error={errors.availableRoles && touched.availableRoles ? true : false}
                  variant="standard"
                >
                  {roles.map((v, i) => (
                    <MenuItem key={i} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
                {values.contents.map((contents, i) => (
                  <Paper
                    key={i}
                    sx={{
                      padding: theme.spacing(2, 2, 2, 2),
                      margin: theme.spacing(1, 0, 1, 0),
                    }}
                  >
                    <Typography variant="h4">Step #{i + 1}</Typography>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id={`contents[${i}].title`}
                      label="Step Title"
                      name={`contents[${i}].title`}
                      value={contents.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="standard"
                    />
                    <DragDropContext
                      onDragEnd={(result: DropResult) => {
                        // dropped outside the list
                        if (!result.destination) {
                          return;
                        }

                        const content = reorderContent(contents.content, result.source.index, result.destination.index);

                        setFieldValue(`contents[${i}].content`, content);
                      }}
                    >
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {contents.content.map((c, j) => (
                              <Draggable key={j} draggableId={j.toString()} index={j}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                  >
                                    <Paper
                                      key={j}
                                      sx={{
                                        padding: theme.spacing(2, 2, 2, 2),
                                        margin: theme.spacing(1, 0, 1, 0),
                                      }}
                                    >
                                      <Grid container justifyContent="space-between">
                                        <Grid item xs={8}>
                                          <TextField
                                            margin="normal"
                                            required
                                            multiline
                                            id={`contents[${i}].content[${j}].text`}
                                            label="Content Text"
                                            name={`contents[${i}].content[${j}].text`}
                                            value={c.text}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            variant="standard"
                                          />
                                        </Grid>
                                        <Grid container item xs={1} alignItems="center">
                                          <InputLabel id={`contents[${i}].content[${j}].variant_label`} required>
                                            Variant
                                          </InputLabel>
                                          <Select
                                            label="Variant"
                                            required
                                            labelId={`contents[${i}].content[${j}].variant_label`}
                                            id={`contents[${i}].content[${j}].variant`}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name={`contents[${i}].content[${j}].variant`}
                                            value={c.variant}
                                            variant="standard"
                                          >
                                            {variant.map((v, i) => (
                                              <MenuItem key={i} value={v}>
                                                {v}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </Grid>
                                        <Grid container item xs={1} alignItems="center">
                                          <InputLabel id={`contents[${i}].content[${j}].italic_label`}>
                                            Italic
                                          </InputLabel>
                                          <Switch
                                            id={`contents[${i}].content[${j}].italic`}
                                            size="small"
                                            checked={c.italic ? c.italic : false}
                                            onChange={(e) =>
                                              setFieldValue(`contents[${i}].content[${j}].italic`, e.target.checked)
                                            }
                                          />
                                        </Grid>
                                        <Grid container item xs={1} alignItems="center">
                                          <InputLabel id={`contents[${i}].content[${j}].underline_label`}>
                                            Underline
                                          </InputLabel>
                                          <Switch
                                            id={`contents[${i}].content[${j}].underline`}
                                            size="small"
                                            checked={c.underline ? c.underline : false}
                                            onChange={(e) =>
                                              setFieldValue(`contents[${i}].content[${j}].underline`, e.target.checked)
                                            }
                                          />
                                        </Grid>
                                        <Grid container item xs={1} alignItems="center">
                                          <InputLabel id={`contents[${i}].content[${j}].bold_label`}>Bold</InputLabel>
                                          <Switch
                                            id={`contents[${i}].content[${j}].bold`}
                                            size="small"
                                            checked={c.bold ? c.bold : false}
                                            onChange={(e) =>
                                              setFieldValue(`contents[${i}].content[${j}].bold`, e.target.checked)
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {contents.youtubeLink !== undefined && (
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id={`contents[${i}].youtubeLink`}
                        label="Youtube Link"
                        name={`contents[${i}].youtubeLink`}
                        value={contents.youtubeLink}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="standard"
                      />
                    )}
                    <Button variant="contained" sx={field} onClick={() => increaseContent(i)}>
                      Increase content
                    </Button>
                    <Button disabled={contents.content.length === 0} sx={field} onClick={() => decreaseContent(i)}>
                      Decrease content
                    </Button>
                    <Grid container justifyContent="center" xs={12} item>
                      <Button
                        disabled={contents.youtubeLink !== undefined}
                        variant="contained"
                        sx={field}
                        onClick={() => addVideo(i)}
                      >
                        Add youtube video
                      </Button>
                    </Grid>
                    {contents.youtubeLink !== undefined && (
                      <Button sx={field} onClick={() => deleteVideo(i)}>
                        Delete youtube video
                      </Button>
                    )}
                    <Box
                      component="input"
                      accept="image/*"
                      sx={{
                        display: "none",
                      }}
                      id={"imageURL - " + i}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onUploadChange(e, i)}
                      type="file"
                    />
                    <Grid container justifyContent="center" xs={12} item>
                      {module?.id ? (
                        <Box component="label" htmlFor={"imageURL - " + i}>
                          <Button
                            variant="contained"
                            disabled={!module?.id}
                            color="primary"
                            component="span"
                            size="small"
                          >
                            {contents.imageURL ? "Change image" : "Upload image"}
                          </Button>
                        </Box>
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          Please Save module first to upload images
                        </Typography>
                      )}
                    </Grid>
                    <Typography variant="body2">Preview</Typography>
                    <Typography variant="h3">{contents.title}</Typography>
                    {contents.content.map((c, j) => (
                      <Typography
                        sx={{
                          mb: theme.spacing(1),
                          textDecoration: c.underline ? "underline" : null,
                          fontStyle: c.italic ? "italic" : null,
                          fontWeight: c.bold ? "bold" : null,
                        }}
                        key={j}
                        variant={c.variant}
                      >
                        {c.text}
                      </Typography>
                    ))}
                    {contents.imageURL && (
                      <Grid xs={12} container item justifyContent="center">
                        <Box
                          component="img"
                          alt={"Step image #" + i + 1}
                          src={contents.imageURL}
                          sx={{
                            mb: theme.spacing(2),
                            objectFit: "cover",
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                        />
                      </Grid>
                    )}
                    {contents.saq ? (
                      <>
                        <SAQ
                          i={i}
                          updateSAQ={(q) => {}}
                          saq={contents.saq as ModuleSAQ[]}
                          answers={contents.saq
                            .map((_, i) => ({ [i.toString()]: "User answer " + (i + 1) }))
                            .reduce((acc, obj) => {
                              return { ...acc, ...obj };
                            }, {})}
                          completed={true}
                        />
                        <Grid container justifyContent="flex-end">
                          <Button variant="outlined" color="error" onClick={() => deleteSAQ(i)} sx={field}>
                            Delete Short Answer Questions
                          </Button>
                        </Grid>
                        <Typography>Short Answer Questions</Typography>
                        <Typography variant="body2">SAQs will appear under the content</Typography>
                        <DragDropContext
                          onDragEnd={(result: DropResult) => {
                            // dropped outside the list
                            if (!result.destination) {
                              return;
                            }

                            const saq = reorderSAQ(contents.saq, result.source.index, result.destination.index);

                            setFieldValue(`contents[${i}].saq`, saq);
                          }}
                        >
                          <Droppable droppableId="droppableSAQ">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                <Grid container direction="column">
                                  {contents.saq?.map((q, j) => (
                                    <Grid key={j} item>
                                      <Draggable draggableId={j.toString()} index={j}>
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                          >
                                            <Paper
                                              sx={{
                                                padding: theme.spacing(2, 2, 2, 2),
                                                margin: theme.spacing(1, 0, 1, 0),
                                              }}
                                            >
                                              <TextField
                                                margin="normal"
                                                required
                                                id={`contents[${i}].saq[${j}].question`}
                                                label="Question"
                                                name={`contents[${i}].saq[${j}].question`}
                                                value={q.question}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                fullWidth
                                                multiline
                                                variant="standard"
                                              />
                                              <Button
                                                size="small"
                                                variant="contained"
                                                sx={field}
                                                onClick={() => increaseSAQSampleAnswer(i, j)}
                                              >
                                                Increase Sample Answer
                                              </Button>
                                              <Button
                                                size="small"
                                                disabled={q.sampleAnswers.length === 0}
                                                sx={field}
                                                onClick={() => decreaseSAQSampleAnswer(i, j)}
                                              >
                                                Decrease Sample Answer
                                              </Button>
                                              {q.sampleAnswers.map((sampleAnswer, k) => (
                                                <TextField
                                                  key={k}
                                                  margin="normal"
                                                  required
                                                  id={`contents[${i}].saq[${j}].sampleAnswers[${k}]`}
                                                  label={`Sample answer ${k + 1}`}
                                                  name={`contents[${i}].saq[${j}].sampleAnswers[${k}]`}
                                                  value={sampleAnswer}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  fullWidth
                                                  multiline
                                                  variant="standard"
                                                />
                                              ))}
                                            </Paper>
                                          </div>
                                        )}
                                      </Draggable>
                                    </Grid>
                                  ))}
                                </Grid>
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                        <Button variant="contained" sx={field} onClick={() => increaseSAQQuestion(i)}>
                          Increase SAQ
                        </Button>
                        <Button disabled={contents.saq?.length < 2} sx={field} onClick={() => decreaseSAQQuestion(i)}>
                          Decrease SAQ
                        </Button>
                      </>
                    ) : (
                      <Button variant="contained" fullWidth onClick={() => addSAQ(i)} sx={field}>
                        Add Short Answer Questions
                      </Button>
                    )}
                  </Paper>
                ))}
                <Button variant="contained" onClick={increaseSteps} sx={field}>
                  Add step
                </Button>
                <Button disabled={values.contents.length === 0} onClick={decreaseSteps} sx={field}>
                  Decrease step
                </Button>
                {values.questions && values.questions.length !== 0 ? (
                  <>
                    <Grid container justifyContent="flex-end">
                      <Button variant="outlined" color="error" onClick={() => deleteMCQ()} sx={field}>
                        Delete Multiple Choice Questions
                      </Button>
                    </Grid>
                    <Typography>Multiple Choice Questions</Typography>
                    <DragDropContext
                      onDragEnd={(result: DropResult) => {
                        // dropped outside the list
                        if (!result.destination) {
                          return;
                        }

                        const questions = reorderQuestions(
                          values.questions,
                          result.source.index,
                          result.destination.index
                        );

                        setFieldValue("questions", questions);
                      }}
                    >
                      <Droppable droppableId="droppablequestions">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            <Grid container direction="column">
                              {values.questions.map((q, j) => (
                                <Grid key={j} item>
                                  <Draggable draggableId={j.toString()} index={j}>
                                    {(provided, snapshot) => (
                                      <div
                                        key={j}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                      >
                                        <Paper
                                          sx={{
                                            padding: theme.spacing(2, 2, 2, 2),
                                            margin: theme.spacing(1, 0, 1, 0),
                                          }}
                                        >
                                          <TextField
                                            margin="normal"
                                            required
                                            id={`questions[${j}].question`}
                                            label="MCQ"
                                            name={`questions[${j}].question`}
                                            value={q.question}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            multiline
                                            variant="standard"
                                          />
                                          <InputLabel id={`questions[${j}].multiple_answers_label`}>
                                            Multiple answers?
                                          </InputLabel>
                                          <Switch
                                            id={`questions[${j}].answers`}
                                            size="small"
                                            checked={q.answers !== undefined ? true : false}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setFieldValue(`questions[${j}].answers`, []);
                                                setFieldValue(`questions[${j}].answer`, undefined);
                                              } else {
                                                setFieldValue(`questions[${j}].answers`, undefined);
                                                setFieldValue(`questions[${j}].answer`, "1");
                                              }
                                            }}
                                          />
                                          {q.answers === undefined ? (
                                            <RadioGroup
                                              aria-labelledby="demo-controlled-radio-buttons-group"
                                              name="controlled-radio-buttons-group"
                                              value={q.answer}
                                              onChange={(e) => setFieldValue(`questions[${j}].answer`, e.target.value)}
                                            >
                                              {q.choices.map((c, k) => (
                                                <Grid container>
                                                  <Grid item xs={11}>
                                                    <TextField
                                                      key={k}
                                                      margin="normal"
                                                      required
                                                      id={`questions[${j}].choices[${k}].text`}
                                                      label={`Choice ${k + 1}`}
                                                      name={`questions[${j}].choices[${k}].text`}
                                                      value={c.text}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      multiline
                                                      fullWidth
                                                      variant="outlined"
                                                    />
                                                  </Grid>
                                                  <Grid
                                                    container
                                                    item
                                                    xs={1}
                                                    justifyContent="flex-end"
                                                    alignItems="center"
                                                  >
                                                    <FormControlLabel
                                                      value={(k + 1).toString()}
                                                      control={<Radio />}
                                                      label=""
                                                    />
                                                  </Grid>
                                                </Grid>
                                              ))}
                                            </RadioGroup>
                                          ) : (
                                            <FormGroup>
                                              {q.choices.map((c, k) => (
                                                <Grid container>
                                                  <Grid item xs={11}>
                                                    <TextField
                                                      key={k}
                                                      margin="normal"
                                                      required
                                                      id={`questions[${j}].choices[${k}].text`}
                                                      label={`Choice ${k + 1}`}
                                                      name={`questions[${j}].choices[${k}].text`}
                                                      value={c.text}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      fullWidth
                                                      multiline
                                                      variant="outlined"
                                                    />
                                                  </Grid>
                                                  <Grid
                                                    container
                                                    item
                                                    xs={1}
                                                    justifyContent="flex-end"
                                                    alignItems="center"
                                                  >
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          checked={q.answers?.includes((k + 1).toString())}
                                                          onChange={(e) => {
                                                            if (q.answers !== undefined) {
                                                              if (e.target.checked) {
                                                                const index = q.answers.findIndex(
                                                                  (s) => Number(s) > k + 1
                                                                );

                                                                if (index === -1) {
                                                                  q.answers.push((k + 1).toString());
                                                                } else {
                                                                  q.answers.splice(index, 0, (k + 1).toString());
                                                                }
                                                                setFieldValue(`questions[${j}].answers`, q.answers);
                                                              } else {
                                                                const index = q.answers.findIndex(
                                                                  (s) => Number(s) === k + 1
                                                                );

                                                                if (index !== -1) {
                                                                  q.answers.splice(index, 1);
                                                                }
                                                              }
                                                            }
                                                          }}
                                                        />
                                                      }
                                                      label=""
                                                    />
                                                  </Grid>
                                                </Grid>
                                              ))}
                                            </FormGroup>
                                          )}
                                          <Button variant="contained" sx={field} onClick={() => increaseChoice(j)}>
                                            Add Choice
                                          </Button>
                                          <Button
                                            disabled={q.choices.length < 2}
                                            sx={field}
                                            onClick={() => decreaseChoice(j)}
                                          >
                                            Decrease Choice
                                          </Button>
                                          <TextField
                                            margin="normal"
                                            required
                                            id={`questions[${j}].explanation`}
                                            label="Explanation"
                                            name={`questions[${j}].explanation`}
                                            value={q.explanation}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            multiline
                                            variant="standard"
                                          />
                                        </Paper>
                                      </div>
                                    )}
                                  </Draggable>
                                </Grid>
                              ))}
                            </Grid>
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <Button variant="contained" sx={field} onClick={() => increaseMCQQuestion()}>
                      Increase MCQ
                    </Button>
                    <Button disabled={values.questions.length < 2} sx={field} onClick={() => decreaseQuestion()}>
                      Decrease MCQ
                    </Button>
                  </>
                ) : (
                  <Button variant="contained" fullWidth onClick={() => addMCQ()} sx={field}>
                    Add Multiple Choice Questions
                  </Button>
                )}
                <Box
                  sx={{
                    position: "fixed",
                    bottom: theme.spacing(3),
                    right: theme.spacing(3),
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress color="primary" />
                  ) : (
                    <Button size="large" variant="contained" type="submit">
                      Save module
                    </Button>
                  )}
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </>
  );
};
