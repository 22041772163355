import {
  Typography,
  TextField,
  useTheme,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  FormLabel,
  Radio,
  Button,
  Grid,
  InputAdornment,
} from "@mui/material";
import { EventFormModel, RegistrationSubFormModel } from "../../../models/EventFormModel";
import { useContext, useState } from "react";
import { uploadBytes, getDownloadURL, ref } from "firebase/storage";
import { UserContext } from "../../../components/PrivateRoute";
import { storage } from "../../../services/firebase";
import { maxFileSize } from "../../../services/users";
import { InitMessageState, MessageState } from "../../../models/MessageState";
import { MessageSnackbar } from "../../../components/MessageSnackbar";

export const SubFormRegistration = ({
  canEdit,
  subForm,
  setSubFormProperty,
  eventForm,
}: {
  canEdit: boolean;
  subForm: RegistrationSubFormModel;
  setSubFormProperty: (property: keyof RegistrationSubFormModel, value: any) => void;
  eventForm: EventFormModel;
}) => {
  const [message, setMessage] = useState<MessageState>(InitMessageState);

  const theme = useTheme();

  const formControlSx = {
    my: 2,
    display: "flex",
  };

  const user = useContext(UserContext);

  const onUploadChange = (e: React.ChangeEvent<HTMLInputElement>, imageType: keyof RegistrationSubFormModel) => {
    if (user === undefined || e.target === null || e.target.files === null) {
      return;
    }
    const file = e.target.files[0];
    if (file.size > maxFileSize) {
      setMessage({
        showMessage: true,
        message: "Image exceeds file size limit (5MB)",
        severity: "error",
      });
      return;
    }
    const fileURL = `registrationSubForm/${eventForm.id}/${imageType}`;
    const storageRef = ref(storage, fileURL);
    uploadBytes(storageRef, file).then(() =>
      getDownloadURL(storageRef).then((url) => setSubFormProperty(imageType, url))
    );
  };

  return (
    <>
      <MessageSnackbar messageState={message} setMessageState={setMessage} />
      <FormControl sx={formControlSx}>
        <Typography variant="h5" sx={{ mb: theme.spacing(1) }}>
          Dedicated Page Customisation
        </Typography>
        <Box marginLeft={3}>
          <Typography color="textSecondary" variant="body1" sx={{ mb: theme.spacing(1) }}>
            Select the options you want to include
          </Typography>
          <Box marginLeft={3}>
            <FormGroup>
              <FormControl>
                <FormLabel>Would you like to have a dedicated page on the SOW website?</FormLabel>
                <RadioGroup
                  aria-label="dedicatedPageOption"
                  value={subForm.dedicatedPageOption ? "Yes" : "No"}
                  onChange={(e) => setSubFormProperty("dedicatedPageOption", e.target.value === "Yes")}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled={!canEdit} />
                  <FormControlLabel value="No" control={<Radio />} label="No" disabled={!canEdit} />
                </RadioGroup>
              </FormControl>
              {subForm.dedicatedPageOption && (
                <>
                  <Box marginLeft={3}>
                    <Typography color="textSecondary" variant="body1" sx={{ mb: theme.spacing(1) }}>
                      Select one or more options
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Banner (Image)"
                        checked={subForm.bannerOption}
                        onChange={() => setSubFormProperty("bannerOption", !subForm.bannerOption)}
                        disabled={!canEdit}
                      />
                      {subForm.bannerOption && (
                        <>
                          <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                            Upload your banner
                          </Typography>
                          <Box
                            component="input"
                            accept="image/*"
                            sx={{
                              display: "none",
                            }}
                            id="banner"
                            type="file"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onUploadChange(e, "bannerURL")}
                          />
                          {subForm.bannerURL && (
                            <>
                              <Grid xs={12} container item justifyContent="center">
                                <Box
                                  component="img"
                                  alt="Profile"
                                  src={subForm.bannerURL}
                                  sx={{
                                    mb: theme.spacing(2),
                                    height: theme.spacing(20),
                                  }}
                                />
                              </Grid>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                component="a"
                                target="_blank"
                                href={subForm.bannerURL}
                                download="event-thumbnail.jpg"
                              >
                                Download Banner
                              </Button>
                            </>
                          )}
                          {canEdit && (
                            <Box component="label" htmlFor="banner" marginLeft={3}>
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                size="small"
                                disabled={!canEdit}
                              >
                                Upload Banner
                              </Button>
                            </Box>
                          )}
                        </>
                      )}
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Header"
                        checked={subForm.headerOption}
                        onChange={() => setSubFormProperty("headerOption", !subForm.headerOption)}
                        disabled={!canEdit}
                      />
                      {subForm.headerOption && (
                        <>
                          <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                            What is the header for the page?
                          </Typography>
                          <TextField
                            size="small"
                            disabled={!canEdit}
                            label="Header"
                            onChange={(e) => setSubFormProperty("header", e.target.value)}
                            value={subForm.header ? subForm.header : ""}
                            variant="outlined"
                            fullWidth
                            sx={{ mb: theme.spacing(1) }}
                          />
                        </>
                      )}
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Dedicated Page Content Text"
                        checked={subForm.blurbOption}
                        onChange={() => setSubFormProperty("blurbOption", !subForm.blurbOption)}
                        disabled={!canEdit}
                      />
                      {subForm.blurbOption && (
                        <>
                          <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                            What text/content do you want in your dedicated page?
                          </Typography>
                          <TextField
                            size="small"
                            disabled={!canEdit}
                            label="Page Content Text"
                            onChange={(e) => setSubFormProperty("blurb", e.target.value)}
                            value={subForm.blurb ? subForm.blurb : ""}
                            multiline
                            minRows={4}
                            maxRows={6}
                            variant="outlined"
                            fullWidth
                            sx={{ mb: theme.spacing(1) }}
                          />
                        </>
                      )}
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Other Content"
                        checked={subForm.otherContentOption}
                        onChange={() => setSubFormProperty("otherContentOption", !subForm.otherContentOption)}
                        disabled={!canEdit}
                      />
                      {subForm.otherContentOption && (
                        <>
                          <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                            What other content do you want for the page?
                          </Typography>
                          <TextField
                            size="small"
                            disabled={!canEdit}
                            label="Other Content"
                            onChange={(e) => setSubFormProperty("otherContent", e.target.value)}
                            value={subForm.otherContent ? subForm.otherContent : ""}
                            multiline
                            minRows={4}
                            maxRows={6}
                            variant="outlined"
                            fullWidth
                            sx={{ mb: theme.spacing(1) }}
                          />
                        </>
                      )}
                    </FormGroup>
                  </Box>
                </>
              )}
            </FormGroup>
          </Box>
        </Box>
      </FormControl>

      <FormControl sx={formControlSx}>
        <Typography variant="h5" sx={{ mb: theme.spacing(1) }}>
          Registration Customisation
        </Typography>
        <Box marginLeft={3}>
          <Typography color="textSecondary" variant="body1" sx={{ mb: theme.spacing(1) }}>
            Select the options you want to include
          </Typography>
          <Box marginLeft={3}>
            <FormGroup>
              <FormControl>
                <FormLabel>Would you like to have an online product for your event?</FormLabel>
                <RadioGroup
                  aria-label="onlineProductOption"
                  value={subForm.onlineProductOption ? "Yes" : "No"}
                  onChange={(e) => setSubFormProperty("onlineProductOption", e.target.value === "Yes")}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled={!canEdit} />
                  <FormControlLabel value="No" control={<Radio />} label="No" disabled={!canEdit} />
                </RadioGroup>
              </FormControl>
              {subForm.onlineProductOption && (
                <>
                  <Box marginLeft={3}>
                    <Typography color="textSecondary" variant="body1" sx={{ mb: theme.spacing(1) }}>
                      What will the name of the product be?
                    </Typography>
                    <TextField
                      size="small"
                      disabled={!canEdit}
                      label="Name of Product"
                      onChange={(e) => setSubFormProperty("productName", e.target.value)}
                      value={subForm.productName ? subForm.productName : ""}
                      variant="outlined"
                      fullWidth
                      sx={{ mb: theme.spacing(1) }}
                    />
                    <Typography color="textSecondary" variant="body1" sx={{ mb: theme.spacing(1) }}>
                      How much will the price of a single product be?
                    </Typography>
                    <TextField
                      size="small"
                      disabled={!canEdit}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      label="Price"
                      onChange={(e) => setSubFormProperty("price", Number(e.target.value))}
                      value={subForm.price ? subForm.price : ""}
                      variant="outlined"
                      type="number"
                      fullWidth
                      sx={{ mb: theme.spacing(1) }}
                    />
                    <Typography color="textSecondary" variant="body1" sx={{ mb: theme.spacing(1) }}>
                      What will your description for the product be?
                    </Typography>
                    <TextField
                      size="small"
                      disabled={!canEdit}
                      label="Description"
                      onChange={(e) => setSubFormProperty("productDescription", e.target.value)}
                      value={subForm.productDescription ? subForm.productDescription : ""}
                      multiline
                      minRows={4}
                      maxRows={6}
                      variant="outlined"
                      fullWidth
                      sx={{ mb: theme.spacing(1) }}
                    />
                    <Box marginBottom={1}>
                      <Typography color="textSecondary" variant="body1" sx={{ mb: theme.spacing(1) }}>
                        Upload a square thumbnail for your product
                      </Typography>
                      <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                        This product will be featured in the "upcoming events" or "sow.org.au/registration" section of
                        the website
                      </Typography>
                      <Box
                        component="input"
                        accept="image/*"
                        sx={{
                          display: "none",
                        }}
                        id="thumbnail"
                        type="file"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onUploadChange(e, "thumbnailURL")}
                      />
                      {subForm.thumbnailURL && (
                        <>
                          <Grid xs={12} container item justifyContent="center">
                            <Box
                              component="img"
                              alt="Profile"
                              src={subForm.thumbnailURL}
                              sx={{
                                mb: theme.spacing(2),
                                height: theme.spacing(20),
                              }}
                            />
                          </Grid>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            component="a"
                            target="_blank"
                            href={subForm.thumbnailURL}
                            download="event-thumbnail.jpg"
                          >
                            Download Thumbnail
                          </Button>
                        </>
                      )}
                      {canEdit && (
                        <Box
                          sx={{ justifyContent: "space-between" }}
                          component="label"
                          htmlFor="thumbnail"
                          marginLeft={3}
                        >
                          <Button variant="contained" color="primary" component="span" size="small" disabled={!canEdit}>
                            Upload Thumbnail
                          </Button>
                        </Box>
                      )}
                    </Box>
                    <Typography color="textSecondary" variant="body1" sx={{ mb: theme.spacing(1) }}>
                      Select all information you want captured in your registration form as the product is being
                      purchased:
                    </Typography>
                    <Box marginLeft={3}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Full Name"
                          checked={subForm.fullName}
                          onChange={() => setSubFormProperty("fullName", !subForm.fullName)}
                          disabled={!canEdit}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Email Address"
                          checked={subForm.email}
                          onChange={() => setSubFormProperty("email", !subForm.email)}
                          disabled={!canEdit}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Date of Birth"
                          checked={subForm.dob}
                          onChange={() => setSubFormProperty("dob", !subForm.dob)}
                          disabled={!canEdit}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Phone Number"
                          checked={subForm.phoneNumber}
                          onChange={() => setSubFormProperty("phoneNumber", !subForm.phoneNumber)}
                          disabled={!canEdit}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Emergency Contact (Name, Relationship, Phone Number)"
                          checked={subForm.emergencyContact}
                          onChange={() => setSubFormProperty("emergencyContact", !subForm.emergencyContact)}
                          disabled={!canEdit}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="University/Institution"
                          checked={subForm.uni}
                          onChange={() => setSubFormProperty("uni", !subForm.uni)}
                          disabled={!canEdit}
                        />
                        {subForm.uni && (
                          <>
                            <Box marginLeft={3}>
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="University Year"
                                  checked={subForm.uniYear}
                                  onChange={() => setSubFormProperty("uniYear", !subForm.uniYear)}
                                  disabled={!canEdit}
                                />
                                <FormControlLabel
                                  control={<Checkbox />}
                                  label="Degree/Major"
                                  checked={subForm.degree}
                                  onChange={() => setSubFormProperty("degree", !subForm.degree)}
                                  disabled={!canEdit}
                                />
                              </FormGroup>
                            </Box>
                          </>
                        )}
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Attending a Church"
                          checked={subForm.church}
                          onChange={() => setSubFormProperty("church", !subForm.church)}
                          disabled={!canEdit}
                        />
                        {subForm.church && (
                          <>
                            <Box marginLeft={3}>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Church Name"
                                checked={subForm.churchName}
                                onChange={() => setSubFormProperty("churchName", !subForm.churchName)}
                                disabled={!canEdit}
                              />
                            </Box>
                          </>
                        )}
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Do you have any dietary requirements?"
                          checked={subForm.diet}
                          onChange={() => setSubFormProperty("diet", !subForm.diet)}
                          disabled={!canEdit}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="How did you hear about us?"
                          checked={subForm.source}
                          onChange={() => setSubFormProperty("source", !subForm.source)}
                          disabled={!canEdit}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Other"
                          checked={subForm.otherRequestsOption}
                          onChange={() => setSubFormProperty("otherRequestsOption", !subForm.otherRequestsOption)}
                          disabled={!canEdit}
                        />
                        {subForm.otherRequestsOption && (
                          <>
                            <Typography color="textSecondary" variant="body1" sx={{ mb: theme.spacing(1) }}>
                              What other questions would you like in your registration form.
                            </Typography>
                            <TextField
                              size="small"
                              disabled={!canEdit}
                              label="List all other questions you would like in your form"
                              onChange={(e) => setSubFormProperty("otherRequests", e.target.value)}
                              value={subForm.otherRequests ? subForm.otherRequests : ""}
                              multiline
                              minRows={4}
                              maxRows={6}
                              variant="outlined"
                              fullWidth
                              sx={{ mb: theme.spacing(1) }}
                            />
                          </>
                        )}
                      </FormGroup>
                    </Box>
                  </Box>
                </>
              )}
            </FormGroup>
          </Box>
        </Box>
      </FormControl>
    </>
  );
};
