import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Form, FormikProps } from "formik";
import { useContext } from "react";
import { DepartmentsContext } from "../../components/PrivateRoute";
import { Announcement } from "../../models/Announcement";

export const AnnouncementsForm = ({
  props,
  handleClose,
}: {
  props: FormikProps<Announcement>;
  handleClose: () => void;
}) => {
  const { values, errors, touched, handleBlur, handleChange, isSubmitting } = props;

  const departmentInterface = useContext(DepartmentsContext);

  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%", // Fix IE 11 issue.
        mt: theme.spacing(3),
      }}
    >
      <Form>
        <DialogTitle id="form-dialog-title">Make an Announcement</DialogTitle>
        <DialogContent>
          <TextField
            sx={{
              mb: theme.spacing(2),
            }}
            id="title"
            required
            fullWidth
            placeholder="Title"
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
          />
          <TextField
            id="content"
            required
            fullWidth
            placeholder="Write your announcement here"
            multiline
            minRows={4}
            maxRows={6}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <InputLabel id="tag" required>
            Select tag
          </InputLabel>
          <Select
            label="tag"
            fullWidth
            labelId="tag-label"
            id="tag-label"
            required
            onChange={handleChange}
            onBlur={handleBlur}
            name="tag"
            value={values.tag}
            error={errors.tag && touched.tag ? true : false}
            variant="standard"
          >
            {Object.keys(departmentInterface.departments).map((t, i) => (
              <MenuItem key={i} value={t}>
                {t}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        {!isSubmitting ? (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" onClick={handleClose} variant="contained">
              Submit
            </Button>
          </DialogActions>
        ) : (
          <CircularProgress color="secondary" />
        )}
      </Form>
    </Box>
  );
};
