import { useContext } from "react";
import { Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/styles";
import { DesignRequestDeclined } from "../../models/DesignRequestDeclined";
import { DesignRequest } from "../../models/DesignRequest";
import { DesignRequestsComponent } from "./DesignRequestsComponent";
import { DesignRequestsDeclineConfirmationDialog } from "./DesignRequestsDeclineConfirmationDialog";
import { DesignRequestsInterface } from "./DesignRequests";
import { approveDesignRequest, completeDesignRequest, declineDesignRequest } from "../../services/designRequests";
import { DepartmentsContext } from "../../components/PrivateRoute";
import { HEAD_OF_DEPARTMENT } from "../../data/Roles";
import { ConfirmationModal } from "../../components/ConfirmationModal";

export const DesignRequestsList = ({
  name,
  requestsToApprove,
  requestsApproved,
  requestsCompletedOrDeclined,
  state,
  setState,
}: {
  name: string;
  requestsToApprove: DesignRequest[];
  requestsApproved: DesignRequest[];
  requestsCompletedOrDeclined: DesignRequest[];
  state: DesignRequestsInterface;
  setState: (state: DesignRequestsInterface) => void;
}) => {
  const theme = useTheme();
  const departments = useContext(DepartmentsContext);

  const { declineDialogOpen, completeDialogOpen, currRequest, staff } = state;

  const approveRequest = (requestApproved: DesignRequest) =>
    approveDesignRequest(requestApproved)
      .then((approvedRequest) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: "Successfully approved request!",
            severity: "success",
          },
          [`requestsToApprove${name}`]: requestsToApprove.filter((obj) => obj.id !== approvedRequest.id),
          [`requestsApproved${name}`]: [...requestsApproved, approvedRequest],
          currRequest: undefined,
        })
      )
      .catch((error) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: error.message,
            severity: "error",
          },
        })
      );

  const declineRequest = (requestDeclined: DesignRequestDeclined) =>
    declineDesignRequest(requestDeclined)
      .then((declinedRequest) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message:
              "Successfully declined. An email has been sent to " +
              declinedRequest.staff.firstName +
              " notifying that you have declined the request.",
            severity: "success",
          },
          declineDialogOpen: false,
          [`requestsToApprove${name}`]: requestsToApprove.filter((obj) => obj.id !== declinedRequest.id),
          [`requestsCompletedOrDeclined${name}`]: [...requestsCompletedOrDeclined, declinedRequest],
          currRequest: undefined,
        })
      )
      .catch((error) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: error.message,
            severity: "error",
          },
          declineDialogOpen: false,
        })
      );

  const complete = () =>
    completeDesignRequest(currRequest as DesignRequest)
      .then((paidRequest) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: "Completed! An email has been sent to the user",
            severity: "success",
          },
          completeDialogOpen: false,
          [`requestsApproved${name}`]: requestsApproved.filter((obj) => obj.id !== paidRequest.id),
          [`requestsCompletedOrDeclined${name}`]: [...requestsCompletedOrDeclined, paidRequest],
          currRequest: undefined,
        })
      )
      .catch((error) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: error.message,
            severity: "error",
          },
          completeDialogOpen: false,
        })
      );

  return (
    <>
      <ConfirmationModal
        title={"Complete Design Request"}
        content={"This will complete the request."}
        confirmText={"Complete"}
        cancelText={"Cancel"}
        dialogOpen={completeDialogOpen}
        closeDialog={() => setState({ ...state, completeDialogOpen: false })}
        doAction={complete}
      />
      <DesignRequestsDeclineConfirmationDialog
        currRequest={currRequest as DesignRequest}
        dialogOpen={declineDialogOpen}
        closeDialog={() => setState({ ...state, declineDialogOpen: false })}
        declineRequest={declineRequest}
      />
      <Typography
        variant="h6"
        sx={{
          mt: theme.spacing(2),
        }}
      >
        Design Requests
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={12} md={4}>
          <Typography>Design Requests to Approve</Typography>
          {requestsToApprove?.map((dr, i) => (
            <DesignRequestsComponent
              key={i}
              designRequest={dr}
              approve={() => approveRequest(dr)}
              decline={() =>
                setState({
                  ...state,
                  declineDialogOpen: true,
                  currRequest: dr,
                })
              }
              staff={staff}
              hod={staff?.department === departments.MARKETING && staff?.role === HEAD_OF_DEPARTMENT}
              handleCommentSubmit={(alertState, comment) =>
                setState({
                  ...state,
                  alert: alertState,
                  [`requestsToApprove${name}`]: requestsToApprove.map((r) => {
                    if (r.id === dr.id && comment) {
                      return {
                        ...r,
                        commentSection: [...(r.commentSection ?? []), comment],
                      };
                    }
                    return r;
                  }),
                })
              }
              handleCommentDelete={(alertState, comment_id) =>
                setState({
                  ...state,
                  alert: alertState,
                  [`requestsToApprove${name}`]: requestsToApprove.map((r) => {
                    if (r.id === dr.id) {
                      return {
                        ...r,
                        commentSection: (r.commentSection ?? []).filter((c) => c.id !== comment_id),
                      };
                    }
                    return r;
                  }),
                })
              }
            />
          ))}
        </Grid>
        <Grid item sm={12} md={4}>
          <Typography>Requests Approved</Typography>
          {requestsApproved?.map((dr, i) => {
            return (
              <DesignRequestsComponent
                key={i}
                designRequest={dr}
                complete={() =>
                  setState({
                    ...state,
                    completeDialogOpen: true,
                    currRequest: dr,
                  })
                }
                staff={staff}
                hod={staff?.department === departments.MARKETING && staff?.role === HEAD_OF_DEPARTMENT}
                handleCommentSubmit={(alertState, comment) =>
                  setState({
                    ...state,
                    alert: alertState,
                    [`requestsApproved${name}`]: requestsApproved.map((r) => {
                      if (r.id === dr.id && comment) {
                        return {
                          ...r,
                          commentSection: [...(r.commentSection ?? []), comment],
                        };
                      }
                      return r;
                    }),
                  })
                }
                handleCommentDelete={(alertState, comment_id) =>
                  setState({
                    ...state,
                    alert: alertState,
                    [`requestsApproved${name}`]: requestsApproved.map((r) => {
                      if (r.id === dr.id) {
                        return {
                          ...r,
                          commentSection: (r.commentSection ?? []).filter((c) => c.id !== comment_id),
                        };
                      }
                      return r;
                    }),
                  })
                }
              />
            );
          })}
        </Grid>
        <Grid item sm={12} md={4}>
          <Typography gutterBottom>Requests Completed or Declined</Typography>
          <Grid container>
            {requestsCompletedOrDeclined?.map((dr, i) => (
              <DesignRequestsComponent key={i} designRequest={dr} staff={staff} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
