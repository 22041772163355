import { useContext, useEffect, useState } from "react";
import { Path } from "../../helpers/Path";
import { Loading } from "../../components/Loading";
import { useParams, Link as ReactRouterLink } from "react-router-dom";
import { Staff } from "../../models/Staff";
import { Request } from "../../models/Request";
import { getYearRequests } from "../../services/requests";
import { Container, Link, Typography } from "@mui/material";
import { UserContext } from "../../components/PrivateRoute";
import { RequestComponent } from "./RequestComponent";

interface PastYearsRequestsInterface {
  loading: boolean;
  staff?: Staff;
  pastRequests: Request[];
}

export const PastYearsRequests = () => {
  const { id } = useParams<{ id: string }>();
  const staff = useContext(UserContext) as Staff;
  const [state, setState] = useState<PastYearsRequestsInterface>({
    loading: true,
    pastRequests: [],
  });

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });

    const pastRequestsFetch = getYearRequests(id);

    Promise.all([pastRequestsFetch]).then(([pastRequests]) =>
      setState({
        ...state,
        pastRequests: pastRequests,
        loading: false,
      })
    );

    // eslint-disable-next-line
  }, [id]);

  const { loading, pastRequests } = state;

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container maxWidth="md">
        <Link to={Path["Reimbursement Requests"]} component={ReactRouterLink}>
          back
        </Link>
        <Typography align="center">Finance Requests History - Year: {id}</Typography>
        {pastRequests.map((pr, i) => (
          <RequestComponent key={i} request={pr} staff={staff} />
        ))}
      </Container>
    </>
  );
};
