import { DrawerProps, SwipeableDrawer, SwipeableDrawerProps, styled, Theme, CSSObject } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { drawerWidth } from "./Navbar";

interface Mobile {
  mobile: boolean;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerNavbar = (props: DrawerProps & SwipeableDrawerProps & Mobile) => {
  const { mobile, ...withoutMobile } = props;
  const { variant, ...mobileProps } = withoutMobile;
  const { onClose, onOpen, ...desktopProps } = withoutMobile;
  return mobile ? <SwipeableDrawer sx={{ width: drawerWidth }} {...mobileProps} /> : <Drawer {...desktopProps} />;
};
