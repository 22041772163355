import { Link as ReactRouterLink } from "react-router-dom";
import { Grid, Typography, Link } from "@mui/material";
import { DesignRequestCommentData } from "../../models/DesignRequestCommentData";
import { timeSince } from "../../helpers/FormatDate";
import { Path } from "../../helpers/Path";

export const DesignRequestComment = ({
  c,
  deleteComment,
}: {
  c: DesignRequestCommentData;
  deleteComment?: (id: string) => void;
}) => (
  <Grid container direction="column">
    <Grid item container justifyContent="space-between">
      <Typography variant="body2">
        <Link display="inline" component={ReactRouterLink} underline="hover" to={`${Path.Profile}/${c.staff.id}`}>
          {c.staff.firstName}
        </Link>
        {" - "}
        {timeSince(c.submittedTime)} ago
      </Typography>
      {deleteComment && (
        <Link
          display="inline"
          component="button"
          color="error"
          onClick={() => deleteComment(c.id as string)}
          align="right"
        >
          delete
        </Link>
      )}
    </Grid>
    <Typography gutterBottom>{c.comment}</Typography>
  </Grid>
);
