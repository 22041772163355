import { User } from "./User";

export interface DepartmentInterface {
  FINANCE: string;
  BUDGET_MANAGER: string;
  REGISTRATION_REVIEWER: string;
  MARKETING: string;
  EVENTS: string;
  departments: Departments;
}

export interface Departments {
  [department: string]: IndividualDepartment;
}

export interface IndividualDepartment {
  head: User;
  division: string;
  colour: string;
}

export const InitDepartment = {
  FINANCE: "Finance",
  BUDGET_MANAGER: "",
  REGISTRATION_REVIEWER: "",
  MARKETING: "Marketing",
  EVENTS: "Events",
  departments: {},
};

export interface PastDepartments {
  [year: string]: DepartmentInterface;
}
