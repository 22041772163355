import { Button, CircularProgress, Grid, Link, Paper, TextField, Typography, useTheme } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { timeSince } from "../../helpers/FormatDate";
import { AdditionalClarificationModel, PDPModel } from "../../models/PDPModel";
import { User } from "../../models/User";
import { userIsMentee } from "../../services/personalDevelopmentPlan";
import { TypographyBold } from "../../components/TypographyBold";

export const AdditionalClarifications = ({
  pdp,
  addAdditionalClarifications,
  deleteAdditionalClarification,
  user,
}: {
  pdp: PDPModel;
  addAdditionalClarifications: (additionalClarifications: AdditionalClarificationModel) => void;
  deleteAdditionalClarification: (index: number) => void;
  user: User;
}) => {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        p: theme.spacing(4),
        mb: theme.spacing(3),
        borderRadius: "10px",
      }}
    >
      <TypographyBold variant="h6" gutterBottom>
        Additional Clarifications
      </TypographyBold>
      {userIsMentee(pdp, user) && (
        <Formik
          initialValues={{
            content: "",
            submittedTime: new Date(),
          }}
          onSubmit={(values: AdditionalClarificationModel, { resetForm }) => {
            addAdditionalClarifications(values);
            resetForm();
          }}
        >
          {(props: FormikProps<AdditionalClarificationModel>) => {
            const { handleChange, handleBlur, isSubmitting, values } = props;
            return (
              <Form>
                <Typography variant="caption">
                  If you need to add clarifications or additional information for your goals, write here:
                </Typography>
                <TextField
                  sx={{
                    m: theme.spacing(1, 0, 1, 0),
                    [`& fieldset`]: {
                      borderRadius: "10px",
                    },
                  }}
                  id="content"
                  required
                  fullWidth
                  placeholder="Write your content here"
                  multiline
                  minRows={1}
                  maxRows={4}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.content}
                />
                {!isSubmitting ? (
                  <Button sx={{ borderRadius: "10px" }} type="submit" variant="contained">
                    Add
                  </Button>
                ) : (
                  <CircularProgress color="primary" />
                )}
              </Form>
            );
          }}
        </Formik>
      )}
      <Typography variant="caption">Past clarifications:</Typography>
      {pdp.additionalClarifications.map((ac, i) => (
        <Grid container alignItems="center" key={i}>
          <Typography>
            {ac.content} - {timeSince(ac.submittedTime)} ago
            {userIsMentee(pdp, user) && (
              <Link
                component="button"
                color="error"
                sx={{ ml: theme.spacing(2) }}
                onClick={() => deleteAdditionalClarification(i)}
              >
                Remove clarification
              </Link>
            )}
          </Typography>
        </Grid>
      ))}
    </Paper>
  );
};
