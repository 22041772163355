import { Alert, Snackbar } from "@mui/material";
import { MessageState } from "../models/MessageState";

export const MessageSnackbar = ({
  messageState,
  setMessageState,
}: {
  messageState: MessageState;
  setMessageState: (messageState: MessageState) => void;
}) => (
  <Snackbar
    open={messageState.showMessage}
    onClose={() => setMessageState({ ...messageState, showMessage: false })}
    autoHideDuration={6000}
  >
    <Alert elevation={6} variant="filled" severity={messageState.severity}>
      {messageState.message}
    </Alert>
  </Snackbar>
);
