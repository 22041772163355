import Cookies from "js-cookie";
import { XeroTokens } from "../models/XeroTokens";
import { XERO_CLIENT_ID, XERO_CLIENT_SECRET } from "../helpers/Env";
import { XeroAPI } from "../data/XeroAPI";
import { db, functions } from "./firebase";
import { httpsCallable } from "firebase/functions";
import { doc, getDoc, setDoc } from "firebase/firestore";

const ACCESS_TOKEN_COOKIE = "access_token";

export interface PledgesGoals {
  alumniGoals: PledgeGoals;
  staffGoals: PledgeGoals;
}

export interface PledgeGoals {
  [date: string]: number;
}

export interface PledgeGoal {
  date: string;
  goal: number;
}

export const InitPledgeGoals = {
  alumniGoals: {},
  staffGoals: {},
};

export const getPledgeGoals = async () => (await getDoc(doc(db, "pledges", "pledges"))).data() as PledgesGoals;

export const updatePledgeGoals = async (updatedPledgeGoals: PledgesGoals) => {
  await setDoc(doc(db, "pledges", "pledges"), updatedPledgeGoals);
  return updatedPledgeGoals;
};

export const SetAuthenticated = (tokens: XeroTokens) => {
  const second = 1000;

  Cookies.set(ACCESS_TOKEN_COOKIE, tokens.token_type + " " + tokens.access_token, {
    expires: new Date(new Date().getTime() + tokens.expires_in * second),
    secure: true,
    sameSite: "none",
  });
};

export const isXeroAuthenticated = () => {
  const a = Cookies.get(ACCESS_TOKEN_COOKIE);
  return !!a;
};

export const getToken = (scopes: string): Promise<XeroTokens> =>
  new Promise((resolve, reject) => {
    httpsCallable(
      functions,
      XeroAPI.getToken
    )({
      scopes: scopes,
      Authorization: "Basic " + btoa(XERO_CLIENT_ID + ":" + XERO_CLIENT_SECRET),
    })
      .then((res) => {
        resolve(res.data as XeroTokens);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getConnections = (): Promise<any> =>
  new Promise((resolve, reject) => {
    httpsCallable(
      functions,
      XeroAPI.getConnections
    )({
      Authorization: Cookies.get(ACCESS_TOKEN_COOKIE),
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const get = (api: XeroAPI, tenant_id?: string, body?: any): Promise<any> =>
  new Promise((resolve, reject) => {
    httpsCallable(
      functions,
      "get"
    )({
      xero_api: api,
      Authorization: Cookies.get(ACCESS_TOKEN_COOKIE),
      tenant_id: tenant_id,
      body: body,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
