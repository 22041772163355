import { Helpline } from "../models/Helpline";

export const helpLinesData: Helpline[] = [
  {
    name: "Mental Health Helpline",
    phoneNumber: "1800 011 511",
  },
  {
    name: "Lifeline",
    phoneNumber: "13 11 14",
  },
  {
    name: "Kids Helpline",
    phoneNumber: "1800 55 1800",
  },
  {
    name: "Drug and Alcohol Support",
    phoneNumber: "9977 0711",
  },
  {
    name: "Domestic Violence Support",
    phoneNumber: "1800 65 64 63",
  },
  {
    name: "Child Protection Helpline",
    phoneNumber: "13 21 11",
  },
];
