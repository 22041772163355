import { Grid, Typography } from "@mui/material";
import { Path } from "../../../helpers/Path";
import { DashboardCard } from "./DashboardCard";
import { TypographyBold } from "../../../components/TypographyBold";
import { useTheme } from "@mui/styles";

export const ModulesCard = ({ nModulesToComplete }: { nModulesToComplete: number | undefined }) => {
  const theme = useTheme();
  return (
    <DashboardCard
      title="My Modules"
      content={
        nModulesToComplete === 0 ? (
          <Grid sx={{ padding: theme.spacing(5) }} container justifyContent="center" alignItems="center">
            <TypographyBold align="center" variant="h6" color="primary">
              All completed!
            </TypographyBold>
            <Typography align="center">Have a look out for more modules to complete!</Typography>
          </Grid>
        ) : (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={4}>
              <TypographyBold fontSize={120} color="primary" align="center">
                {nModulesToComplete}
              </TypographyBold>
            </Grid>
            <Grid container direction="column" item xs={5}>
              <TypographyBold variant="h5" color="primary">
                Module{nModulesToComplete !== 1 && "s"}
              </TypographyBold>
              <Typography variant="h6" color="primary">
                To Complete
              </Typography>
            </Grid>
          </Grid>
        )
      }
      link="Go to modules"
      to={Path.Modules}
    />
  );
};
