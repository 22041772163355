import { Grid } from "@mui/material";
import { DashboardCard } from "./DashboardCard";
import { useTheme } from "@mui/styles";

export const DailyVerseCard = () => {
  const theme = useTheme();
  return (
    <DashboardCard
      title="Bible Verse of the Day"
      content={
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{
            padding: theme.spacing(4, 4, 1, 4),
          }}
        >
          <iframe
            title="dailyVerse"
            height={140}
            frameBorder="no"
            src="https://www.biblegateway.com/votd/get/?format=html&version=ESV"
          >
            View Verse of the Day
          </iframe>
        </Grid>
      }
      link="Read more"
      to={"https://www.biblegateway.com/"}
      externalWebsite={true}
    />
  );
};
