import { nextStaffYear } from "../helpers/FormatDate";
import { HeadOfDivision, Staff } from "../models/Staff";
import { db } from "./firebase";
import { User } from "../models/User";
import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { HEAD_OF_DIVISION, STAFF } from "../data/Roles";
import { atLeast, studentLeader } from "../helpers/Role";
import { DepartmentInterface } from "../models/Department";
import { Divisions } from "../models/Division";

const setOrUpdateDoc = (set: boolean) => (set ? setDoc : updateDoc);

const setOrUpdateUserDoc = async (roles: string[], user: User, year: string, set: boolean) => {
  if (atLeast(roles, STAFF, user.role) && !atLeast(roles, HEAD_OF_DIVISION, user.role)) {
    await setOrUpdateDoc(set)(doc(db, "users", "users", year, user.id), {
      email: user.email,
      role: user.role,
      department: (user as Staff).department ? (user as Staff).department : "",
    });
  } else if (user.role === HEAD_OF_DIVISION) {
    await setOrUpdateDoc(set)(doc(db, "users", "users", year, user.id), {
      email: user.email,
      role: user.role,
      division: (user as HeadOfDivision).division ? (user as HeadOfDivision).division : "",
    });
  } else if (studentLeader(user.role)) {
    await setOrUpdateDoc(set)(doc(db, "users", "users", year, user.id), {
      email: user.email,
      role: user.role,
      university: user.university ? user.university : "",
    });
  } else {
    await setOrUpdateDoc(set)(doc(db, "users", "users", year, user.id), {
      email: user.email,
      role: user.role,
    });
  }
  return "Successfully added/updated user";
};

export const addNewUser = (roles: string[], user: User, users: User[], year: string): Promise<string> => {
  if (users.find((u) => u.email === user.email)) {
    return new Promise((resolve, reject) => reject("User already exists"));
  }
  return setOrUpdateUserDoc(roles, user, year, true);
};

export const addStayingUser = (roles: string[], user: User, year: string): Promise<string> => {
  return setOrUpdateUserDoc(roles, user, year, year === nextStaffYear.toString());
};

export const deleteUser = (user: User, year: string): Promise<string> =>
  new Promise((resolve, reject) => {
    deleteDoc(doc(db, "users", "users", year, user.id))
      .then(() => resolve("Successfully deleted " + user.email))
      .catch((error) => reject(error));
  });

export const updateUser = async (
  roles: string[],
  user: User,
  name_in: string,
  name: string,
  year: string
): Promise<string> => {
  if (name === "role") {
    return setOrUpdateUserDoc(roles, { ...user, role: name_in }, year, year === nextStaffYear.toString());
  }
  await updateDoc(doc(db, "users", "users", year, user.id), {
    [name]: name_in,
  });
  return "Successfully updated user";
};

export const isInDepartmentOrDivision = (uid: string, departments: DepartmentInterface, divisions: Divisions) => {
  for (const dep of Object.values(departments.departments)) {
    if (uid === dep.head.id) {
      return true;
    }
  }
  for (const div of Object.values(divisions)) {
    if (uid === div.head.id) {
      return true;
    }
  }
  return false;
};
