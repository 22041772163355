import { Link as ReactRouterLink } from "react-router-dom";
import { Typography, Grid, Paper, Link, TextField, Button, Divider, Accordion, AccordionSummary } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Staff } from "../../models/Staff";
import { DesignRequestDeclined } from "../../models/DesignRequestDeclined";
import { DesignRequestCommentData } from "../../models/DesignRequestCommentData";
import { DesignRequest } from "../../models/DesignRequest";
import { formatDateTime } from "../../helpers/FormatDate";
import { Path } from "../../helpers/Path";
import {
  designRequestApproved,
  designRequestDeclined,
  designRequestCompleted,
  designRequestStatus,
} from "../../services/designRequests";
import { MessageState } from "../../models/MessageState";
import { DesignRequestCommentSection } from "./DesignRequestCommentSection";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DesignRequestsFormAnswers } from "./DesignRequestsFormAnswers";
import { Status } from "../../helpers/Status";

export const DesignRequestsComponent = ({
  designRequest,
  approve,
  decline,
  complete,
  hod,
  staff,
  handleCommentSubmit,
  handleCommentDelete,
  cancelDesignRequest,
}: {
  designRequest: DesignRequest;
  approve?: () => void;
  decline?: () => void;
  complete?: () => void;
  hod?: boolean;
  staff: Staff;
  handleCommentSubmit?: (m: MessageState, c: DesignRequestCommentData | null) => void;
  handleCommentDelete?: (m: MessageState, c: string) => void;
  cancelDesignRequest?: () => void;
}) => {
  const theme = useTheme();

  return (
    <Accordion
      sx={{
        margin: theme.spacing(1, 1, 0, 1),
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="resources">
        <Typography>
          #{designRequest.id} by {designRequest.staff.firstName}
          {designRequestDeclined(designRequest) && " - DECLINED"}
          {designRequestCompleted(designRequest) && " - COMPLETED"}
        </Typography>
      </AccordionSummary>
      <Grid xs={12} item>
        <Paper
          elevation={3}
          sx={{
            padding: theme.spacing(2),
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6" display="inline">
                #{designRequest.id}
              </Typography>
              <Typography display="inline">
                {" "}
                by {designRequest.staff.firstName} {designRequest.staff.lastName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Department: {designRequest.department}
              </Typography>
            </Grid>
            <Grid item>
              {!designRequestDeclined(designRequest) && cancelDesignRequest && (
                <Grid
                  container
                  item
                  justifyItems="flex-end"
                  sx={{
                    mb: theme.spacing(1),
                  }}
                >
                  <Link component="button" color="error" onClick={cancelDesignRequest}>
                    CANCEL REQUEST
                  </Link>
                </Grid>
              )}
              <Grid container item justifyItems="flex-end">
                <Typography variant="caption" color="textSecondary">
                  Submitted {formatDateTime(designRequest.submittedTime)}
                </Typography>
              </Grid>
              {designRequestApproved(designRequest) && designRequest.approvedTime && (
                <Grid container item justifyItems="flex-end">
                  <Typography variant="caption" color="textSecondary">
                    Approved {formatDateTime(designRequest.approvedTime)}
                  </Typography>
                </Grid>
              )}
              {designRequestDeclined(designRequest) && designRequest.declinedTime && (
                <Grid container item justifyItems="flex-end">
                  <Typography variant="caption" color="textSecondary">
                    Declined {formatDateTime(designRequest.declinedTime)}
                  </Typography>
                </Grid>
              )}
              {designRequestCompleted(designRequest) && designRequest.completedTime && (
                <Grid container item justifyItems="flex-end">
                  <Typography variant="caption" color="textSecondary">
                    Done {formatDateTime(designRequest.completedTime)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <DesignRequestsFormAnswers designRequest={designRequest} />
          <Grid container item xs={12}>
            <Grid xs={6} item direction="column" container justifyItems="center" alignItems="center">
              {hod && approve && decline ? (
                <Grid item>
                  <Button variant="outlined" sx={Status(theme).APPROVED} onClick={approve} size="small">
                    Approve
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      color: theme.palette.error.light,
                      fontWeight: "bold",
                    }}
                    onClick={decline}
                    size="small"
                  >
                    Decline
                  </Button>
                </Grid>
              ) : (
                <>
                  <Typography variant="body2" color="textSecondary">
                    Approval Status
                  </Typography>
                  <Typography sx={Status(theme)[designRequestStatus(designRequest)]}>
                    {designRequestStatus(designRequest)}
                  </Typography>
                </>
              )}
            </Grid>
            {designRequestApproved(designRequest) && (
              <Grid xs={6} item direction="column" container justifyItems="center" alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  Status
                </Typography>
                {hod && !designRequestCompleted(designRequest) && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      sx={{
                        color: theme.palette.success.light,
                        fontWeight: "bold",
                      }}
                      onClick={complete}
                    >
                      COMPLETE
                    </Button>
                  </Grid>
                )}
                {designRequestCompleted(designRequest) && (
                  <Typography sx={Status(theme).APPROVED}>COMPLETED</Typography>
                )}
                {!hod && !designRequestCompleted(designRequest) && (
                  <Typography
                    sx={{
                      color: theme.palette.warning.light,
                      fontWeight: "bold",
                    }}
                  >
                    PENDING
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
          {(designRequest as DesignRequestDeclined).reason && (
            <Grid
              container
              item
              xs={12}
              sx={{
                mt: theme.spacing(2),
              }}
            >
              <TextField
                disabled
                fullWidth
                label="Declined Reason"
                sx={{ "&:disabled": { color: theme.palette.common.black } }}
                value={(designRequest as DesignRequestDeclined).reason}
                variant="outlined"
              />
            </Grid>
          )}
          <Divider
            sx={{
              mt: theme.spacing(1),
            }}
          />
          <Grid container direction="column" justifyItems="center" alignItems="center">
            {designRequest.hod && (
              <Link
                sx={Status(theme)[designRequest.approvedByHOD]}
                component={ReactRouterLink}
                underline="hover"
                to={`${Path.Profile}/${designRequest.hod.id}`}
              >
                HOD ({designRequest.hod.firstName}): {designRequest.approvedByHOD}
              </Link>
            )}
          </Grid>
          <DesignRequestCommentSection
            designRequest={designRequest}
            staff={staff}
            handleSubmit={handleCommentSubmit}
            handleDelete={
              !designRequestDeclined(designRequest) && !designRequest.completed ? handleCommentDelete : undefined
            }
          />
        </Paper>
      </Grid>
    </Accordion>
  );
};
