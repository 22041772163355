import { currentStaffYear } from "../helpers/FormatDate";
import { ReviewsData, YearAndTerm } from "../models/Review360Model";

export const START = "Start";
const STOP = "Stop";
const CONTINUE = "Continue";

export const writtenComments = [START, STOP, CONTINUE];

export const reviewStartYear = 2023;

export const InitYearAndTerm: YearAndTerm = {
  year: currentStaffYear,
  term: "T0",
};

export const InitReviewsData: ReviewsData = {
  currentYearAndTerm: InitYearAndTerm,
  startYearAndTerm: InitYearAndTerm,
  questionCount: 0,
  questionCountSelf: 0,
  reviewsInfo: {},
};
