import { Button, Grid, FormControl, Select, MenuItem, Typography } from "@mui/material";
import { ProfileState, EditState } from "./Profile";

interface PropertySelectComponentInterface {
  label: string;
  name: string;
  handleChange: (event: any) => void;
  value: string | undefined;
  list: string[];
  state: ProfileState;
  setState: (state: ProfileState) => void;
  save: (name: string, value: string | undefined, label: string) => Promise<string>;
}

export const PropertySelectComponent = ({
  label,
  name,
  handleChange,
  value,
  list,
  state,
  setState,
  save,
}: PropertySelectComponentInterface) => {
  const { editState } = state;

  if (!value) {
    value = "";
  }

  return (
    <Grid container item xs={12} alignItems="center" justifyContent="space-between">
      <Grid xs={7} item>
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
        <FormControl fullWidth>
          <Select
            disabled={!editState[name as keyof EditState]}
            labelId={`${name}-label`}
            id={name}
            onChange={handleChange}
            name={name}
            value={value}
            variant="standard"
          >
            {list.map((v, i) => (
              <MenuItem key={i} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {editState[name as keyof EditState] ? (
        <Grid item>
          <Button
            onClick={() =>
              setState({
                ...state,
                editState: { ...editState, [name]: false },
              })
            }
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              save(name, value, label)
                .then((response: string) =>
                  setState({
                    ...state,
                    editState: {
                      ...editState,
                      currentYear: name === "yearJoined" ? Number(value) : editState.currentYear,
                      [name]: false,
                    },
                    messageState: {
                      showMessage: true,
                      message: response,
                      severity: "success",
                    },
                  })
                )
                .catch((error) =>
                  setState({
                    ...state,
                    messageState: {
                      showMessage: true,
                      message: error.message,
                      severity: "error",
                    },
                  })
                );
            }}
          >
            Save
          </Button>
        </Grid>
      ) : (
        <Grid item>
          <Button
            onClick={() =>
              setState({
                ...state,
                editState: { ...editState, [name]: true },
              })
            }
            disabled={name === "yearJoined" && editState.serviceHistory}
          >
            Edit
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
