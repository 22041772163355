import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useState } from "react";

export const Review360HRConfirmationDialog = ({
  heading,
  content,
  action,
  dialogOpen,
  closeDialog,
  doAction,
  calculatingResults,
}: {
  heading: string;
  content: string;
  action: string;
  dialogOpen: boolean;
  closeDialog: () => void;
  doAction: () => void;
  calculatingResults?: boolean;
}) => {
  const [actionClicked, setActionClicked] = useState<boolean>(false);
  return (
    <Dialog open={dialogOpen} onClose={closeDialog} aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-slide-title">{heading}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        {actionClicked && calculatingResults ? (
          <Grid container justifyContent="center" sx={{ width: "102px" }}>
            <CircularProgress size={30} />
          </Grid>
        ) : (
          <Button
            onClick={() => {
              setActionClicked(true);
              doAction();
            }}
            variant="contained"
            color="primary"
          >
            {action}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
