export interface ModuleStats {
  id?: string;
  complete: boolean;
  completedTime?: Date;
  currentStep: number;
  answers: { [contentId: string]: { [saqId: string]: string } };
  answersForQuestions: { [questionId: string]: string | string[] };
}

export const InitModuleStats = {
  complete: false,
  currentStep: 0,
  answers: {},
  answersForQuestions: {},
};
