import { Grid, Typography, useTheme } from "@mui/material";
import { Status } from "../../helpers/Status";
import { Staff } from "../../models/Staff";
import { Request, RequestsCategories } from "../../models/Request";
import { RequestComponent } from "./RequestComponent";
import { Reimbursement } from "../../models/Reimbursement";

export const RequestsList = ({
  title,
  requests,
  staff,
  cancelRequest,
  approve,
  decline,
  submitReimbursement,
  pay,
}: {
  title: string;
  requests: RequestsCategories;
  staff: Staff;
  cancelRequest?: (request: Request) => void;
  approve?: (request: Request) => void;
  decline?: (request: Request) => void;
  submitReimbursement?: (request: Request) => void;
  pay?: (request: Request) => void;
}) => {
  const theme = useTheme();
  return (
    <>
      <Typography
        variant="h5"
        sx={{
          mt: theme.spacing(2),
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6} xl={3}>
          <Typography sx={Status(theme).PENDING}>Waiting for approval</Typography>
          <Grid container>
            {requests.pending.map((request, i) => (
              <RequestComponent
                key={i}
                request={request}
                staff={staff}
                pending={true}
                cancelRequest={cancelRequest ? () => cancelRequest(request) : undefined}
                approve={approve ? () => approve(request) : undefined}
                decline={decline ? () => decline(request) : undefined}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item sm={12} md={6} xl={3}>
          <Typography sx={Status(theme).PENDING}>Waiting for receipt/invoice</Typography>
          <Grid container>
            {requests.approved
              .filter((r) => !(r as Reimbursement).totalReceiptAmount)
              .map((request, i) => (
                <RequestComponent
                  key={i}
                  request={request}
                  staff={staff}
                  cancelRequest={cancelRequest ? () => cancelRequest(request) : undefined}
                  submitReimbursement={submitReimbursement ? () => submitReimbursement(request) : undefined}
                  pay={() => (pay ? pay(request) : undefined)}
                />
              ))}
          </Grid>
        </Grid>
        <Grid item sm={12} md={6} xl={3}>
          <Typography sx={Status(theme).PENDING}>Waiting for payment</Typography>
          <Grid container>
            {requests.approved
              .filter((r) => (r as Reimbursement).totalReceiptAmount)
              .map((request, i) => (
                <RequestComponent
                  key={i}
                  request={request}
                  staff={staff}
                  cancelRequest={cancelRequest ? () => cancelRequest(request) : undefined}
                  submitReimbursement={submitReimbursement ? () => submitReimbursement(request) : undefined}
                  pay={() => (pay ? pay(request) : undefined)}
                />
              ))}
          </Grid>
        </Grid>
        <Grid item sm={12} md={6} xl={3}>
          <Typography sx={Status(theme).APPROVED} display="inline">
            Paid
          </Typography>
          <Typography display="inline"> / </Typography>
          <Typography sx={Status(theme).DECLINED} display="inline">
            Declined
          </Typography>
          {requests.completed.map((request, i) => (
            <RequestComponent key={i} request={request} staff={staff} />
          ))}
        </Grid>
      </Grid>
    </>
  );
};
