import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Loading } from "../../../components/Loading";
import { MessageSnackbar } from "../../../components/MessageSnackbar";
import { InitReviewsData } from "../../../data/Review360Data";
import { InitMessageState, MessageState } from "../../../models/MessageState";
import { ReviewHR, ReviewQuestions, ReviewsData, ReviewStatus } from "../../../models/Review360Model";
import { User } from "../../../models/User";
import {
  addReviewee,
  calculateResults,
  deleteReviewee,
  getReviewsHR,
  skipReviews,
  commenceReviews,
  updateCutOffDate,
  getReviewsData,
  getReviewQuestions,
  getReviewCompleteCounts,
  startSetUp,
  getReviewersFinalisedCounts,
} from "../../../services/review360";
import { getListOfActiveUsersByStaffYear } from "../../../services/users";
import { Review360HRReviewee } from "./Review360HRReviewee";
import { Review360HRConfirmationDialog } from "./Review360HRConfirmationDialog";
import { TypographyBold } from "../../../components/TypographyBold";
import { LegendComponent } from "../../../components/Legend";
import { ColoursCampuses } from "../../../data/colours";
import { drawerWidth, drawerWidthClosed } from "../../../components/Navbar";

export interface DialogInterface {
  open: boolean;
  heading: string;
  content: string;
  action: string;
  doAction: () => void;
}

interface Review360HRInterface {
  dialog: DialogInterface;
  reviewsHR: ReviewHR[];
  questions: ReviewQuestions;
  reviewsData: ReviewsData;
  users: User[];
  currentAddReviewee: User | null;
  alert: MessageState;
  loading: boolean;
  calculatingResults: boolean;
}

export const Review360HR = ({ navbarOpen, mobile }: { navbarOpen: boolean; mobile: boolean }) => {
  const [state, setState] = useState<Review360HRInterface>({
    dialog: {
      open: false,
      heading: "",
      content: "",
      action: "",
      doAction: () => {},
    },
    calculatingResults: false,
    users: [],
    reviewsHR: [],
    questions: {},
    reviewsData: InitReviewsData,
    currentAddReviewee: null,
    alert: InitMessageState,
    loading: true,
  });

  const theme = useTheme();

  useEffect(() => {
    getReviewsData().then((reviewsData) => {
      const getReviewsHRFetch = getReviewsHR(reviewsData.currentYearAndTerm);
      const getUsersFetch = getListOfActiveUsersByStaffYear(reviewsData.currentYearAndTerm.year);
      const getQuestionsFetch = getReviewQuestions(reviewsData.currentYearAndTerm);

      Promise.all([getReviewsHRFetch, getUsersFetch, getQuestionsFetch]).then(([reviewsHR, users, questions]) =>
        setState({
          ...state,
          reviewsData,
          users,
          questions,
          reviewsHR,
          loading: false,
        })
      );
    });

    // eslint-disable-next-line
  }, []);

  const { dialog, currentAddReviewee, reviewsHR, users, questions, reviewsData, alert, loading, calculatingResults } =
    state;

  const { currentYearAndTerm, reviewsInfo } = reviewsData;

  const now = new Date();

  const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

  const { count, countReviewees, countMentors, totalCount } = getReviewCompleteCounts(reviewsHR);
  const { reviewersFinalisedCount, reviewersFinalisedTotalCount } = getReviewersFinalisedCounts(reviewsHR);

  return loading ? (
    <Loading />
  ) : (
    <>
      <MessageSnackbar messageState={alert} setMessageState={(alert) => setState({ ...state, alert })} />
      <Container maxWidth="lg">
        <TypographyBold variant="h5">
          Year: {currentYearAndTerm.year}, Term: {currentYearAndTerm.term}
        </TypographyBold>
        <Divider sx={{ margin: theme.spacing(3, 0, 3, 0) }} />
        {reviewsData.reviewsInfo.status === ReviewStatus.IN_PROGRESS && (
          <Grid container>
            <LegendComponent colour={ColoursCampuses.USYD} text={"Not Submitted"} />
            <LegendComponent colour={ColoursCampuses.UNSW} text={"Submitted"} />
          </Grid>
        )}
        <Grid container spacing={2}>
          {reviewsHR.map((review, i) => (
            <Grid key={i} item xs={12} md={6} lg={4}>
              <Review360HRReviewee
                i={i}
                reviewsData={reviewsData}
                cannotRemove={reviewsInfo.status !== ReviewStatus.SETTING_UP ? true : false}
                fullyDisabled={false}
                currentYearAndTerm={currentYearAndTerm}
                review={review}
                reviewsHR={reviewsHR}
                setReviewsHR={(reviewsHR: ReviewHR[]) =>
                  setState({
                    ...state,
                    alert: {
                      message: "Updated reviewers",
                      severity: "success",
                      showMessage: true,
                    },
                    reviewsHR: [...reviewsHR.slice(0, i), review, ...reviewsHR.slice(i + 1)],
                  })
                }
                users={users}
                setDialog={(dialog) => setState({ ...state, dialog })}
                deleteReviewee={(reviewee) =>
                  deleteReviewee(currentYearAndTerm, reviewee, review.reviewers).then(() =>
                    setState({
                      ...state,
                      alert: {
                        message: "Deleted review",
                        severity: "success",
                        showMessage: true,
                      },
                      reviewsHR: [...reviewsHR.slice(0, i), ...reviewsHR.slice(i + 1)],
                      dialog: { ...dialog, open: false },
                    })
                  )
                }
                revieweeDeleteButton={true}
              />
            </Grid>
          ))}
        </Grid>
        <Autocomplete
          disabled={reviewsInfo.status ? true : false}
          id="add-reviewee"
          getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.role}`}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionDisabled={(option) => reviewsHR.map((review) => review.reviewee.id).includes(option.id)}
          value={currentAddReviewee}
          onChange={(event, newValue) =>
            newValue !== null
              ? setState({
                  ...state,
                  currentAddReviewee: newValue,
                })
              : null
          }
          options={users}
          sx={{
            width: 300,
          }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Reviewee" placeholder="Select Reviewee" />
          )}
        />
        <Button
          variant="contained"
          disabled={currentAddReviewee === null || (reviewsInfo.status ? true : false)}
          onClick={() =>
            addReviewee(currentYearAndTerm, currentAddReviewee as User).then(() =>
              setState({
                ...state,
                alert: {
                  message: "Added reviewee",
                  severity: "success",
                  showMessage: true,
                },
                reviewsHR: [
                  ...reviewsHR,
                  {
                    reviewee: currentAddReviewee as User,
                    reviewers: {
                      Mentor: [],
                      Peers: [],
                      Above: [],
                      Below: [],
                    },
                  },
                ],
                currentAddReviewee: null,
              })
            )
          }
        >
          Add reviewee
        </Button>
        <Divider sx={{ margin: theme.spacing(3, 0, 3, 0) }} />
        <Typography variant="h2">Questions</Typography>
        {questions &&
          Object.entries(questions).map(([category, attributes], i) => (
            <Card key={i} sx={{ marginBottom: theme.spacing(2) }}>
              <CardHeader title={<Typography variant="h3">{category}</Typography>} />
              <CardContent>
                {Object.entries(attributes).map(([attribute, questions], j) => (
                  <Box key={j}>
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        textDecoration: "underline",
                      }}
                      variant="h4"
                    >
                      {attribute}
                    </Typography>
                    {Object.entries(questions).map(([index, question], k) => (
                      <Typography key={k} variant="h5">
                        {question}
                      </Typography>
                    ))}
                  </Box>
                ))}
              </CardContent>
            </Card>
          ))}
        <Box sx={{ pb: theme.spacing(20) }} />
        <Review360HRConfirmationDialog
          heading={dialog.heading}
          content={dialog.content}
          action={dialog.action}
          dialogOpen={dialog.open}
          closeDialog={() => setState({ ...state, dialog: { ...dialog, open: false } })}
          doAction={dialog.doAction}
          calculatingResults={calculatingResults}
        />
      </Container>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        spacing={3}
        sx={{
          position: "fixed",
          padding: theme.spacing(1),
          bottom: 0,
          right: 0,
          width: mobile ? "100%" : `calc(100% - ${navbarOpen ? drawerWidth : drawerWidthClosed}px)`,
          backdropFilter: "blur(5px)",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        }}
      >
        {reviewsInfo.status === ReviewStatus.SETTING_UP && (
          <Grid item>
            <TypographyBold variant="h6">
              {reviewersFinalisedCount} / {reviewersFinalisedTotalCount} reviewees finalised their reviewers
            </TypographyBold>
          </Grid>
        )}
        {reviewsInfo.status === ReviewStatus.IN_PROGRESS && (
          <Grid item>
            <TypographyBold variant="h6">
              {count} / {totalCount} reviews completed
            </TypographyBold>
          </Grid>
        )}
        <Grid item>
          <TypographyBold variant="h6">
            Status: {!reviewsInfo.status && <>Not yet commenced</>}
            {reviewsInfo.status === ReviewStatus.SETTING_UP && <>Reviewees currently adding reviewers</>}
            {reviewsInfo.status === ReviewStatus.SKIPPED && <>Skipped</>}
            {reviewsInfo.status === ReviewStatus.COMPLETED && <>Completed</>}
            {reviewsInfo.status === ReviewStatus.IN_PROGRESS && reviewsInfo.cutOffDate ? (
              reviewsInfo.cutOffDate > now ? (
                <>In Progress</>
              ) : (
                <>
                  Results not yet calculated. All reviewees and mentors must submit reviews for results to be
                  calculated.
                </>
              )
            ) : (
              <></>
            )}
          </TypographyBold>
        </Grid>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              minDate={
                reviewsInfo.status === ReviewStatus.IN_PROGRESS &&
                reviewsInfo.cutOffDate &&
                reviewsInfo.cutOffDate < now &&
                count === totalCount
                  ? undefined
                  : tomorrow
              }
              disabled={
                reviewsInfo.status === ReviewStatus.IN_PROGRESS &&
                reviewsInfo.cutOffDate &&
                reviewsInfo.cutOffDate < now &&
                count === totalCount
              }
              label="Cut Off Date and Time"
              value={reviewsInfo.cutOffDate ? reviewsInfo.cutOffDate : null}
              onChange={(newValue: Date | null) =>
                newValue
                  ? updateCutOffDate(currentYearAndTerm, newValue, reviewsData).then((reviewsData) =>
                      setState({
                        ...state,
                        alert: {
                          message: "Updated cut-off date and time",
                          severity: "success",
                          showMessage: true,
                        },
                        reviewsData,
                      })
                    )
                  : null
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        {!reviewsInfo.status && (
          <Grid item>
            <Button
              variant="contained"
              color="warning"
              onClick={() =>
                setState({
                  ...state,
                  dialog: {
                    open: true,
                    heading: "Skip reviews?",
                    content: "This will skip the reviews for this term. Reviews will not commence for this term",
                    action: "Skip",
                    doAction: () =>
                      skipReviews(currentYearAndTerm, reviewsData).then((updated) =>
                        setState({
                          ...state,
                          alert: {
                            message: "Skipped term",
                            severity: "success",
                            showMessage: true,
                          },
                          reviewsHR: updated.reviewsHR,
                          reviewsData: updated.reviewsData,
                        })
                      ),
                  },
                })
              }
            >
              Skip this Term
            </Button>
          </Grid>
        )}
        {!reviewsInfo.status && (
          <Grid item>
            <Button
              disabled={reviewsHR.length === 0 ? true : false}
              variant="contained"
              onClick={() =>
                setState({
                  ...state,
                  dialog: {
                    open: true,
                    heading: "Start Set Up",
                    content: "Reviewees will be able to add their reviewers",
                    action: "Start",
                    doAction: () =>
                      startSetUp(currentYearAndTerm, reviewsData, reviewsHR).then((reviewsData) =>
                        setState({
                          ...state,
                          alert: {
                            message: "Started set up",
                            severity: "success",
                            showMessage: true,
                          },
                          reviewsData,
                          dialog: { ...dialog, open: false },
                        })
                      ),
                  },
                })
              }
            >
              Start Set Up
            </Button>
          </Grid>
        )}
        {reviewsInfo.status === ReviewStatus.SETTING_UP && (
          <Grid item>
            <Button
              disabled={!reviewsInfo.cutOffDate || reviewsHR.length === 0 ? true : false}
              variant="contained"
              onClick={() =>
                setState({
                  ...state,
                  dialog: {
                    open: true,
                    heading: "Commence reviews?",
                    content: "This will commence the reviews for this term.",
                    action: "Commence",
                    doAction: () => {
                      commenceReviews(reviewsData, reviewsHR).then((reviewsData) =>
                        setState({
                          ...state,
                          alert: {
                            message: "Commenced reviews",
                            severity: "success",
                            showMessage: true,
                          },
                          reviewsData,
                          dialog: { ...dialog, open: false },
                        })
                      );
                    },
                  },
                })
              }
            >
              Commence Reviews
            </Button>
          </Grid>
        )}
        {reviewsInfo.status === ReviewStatus.IN_PROGRESS && reviewsInfo.cutOffDate && (
          <Grid item>
            <Button
              disabled={reviewsInfo.cutOffDate > now || countReviewees + countMentors < reviewsHR.length * 2}
              variant="contained"
              onClick={() => {
                setState({
                  ...state,
                  calculatingResults: true,
                  dialog: {
                    open: true,
                    heading: "Calculate results?",
                    content:
                      "This action will DISCARD all incomplete reviews and calculate the results with the completed reviews only.",
                    action: "Calculate",
                    doAction: () => {
                      calculateResults(currentYearAndTerm, reviewsHR).then((updated) =>
                        setState({
                          ...state,
                          alert: {
                            message: "Calculated results",
                            severity: "success",
                            showMessage: true,
                          },
                          calculatingResults: false,
                          reviewsData: updated.reviewsData,
                        })
                      );
                    },
                  },
                });
              }}
            >
              Calculate Results
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};
