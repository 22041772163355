export const NODE_ENV = process.env.NODE_ENV;
export const DEPLOYMENT_ENVIRONMENT = process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT;
export const SOW_WEBSITE = process.env.REACT_APP_SOW_WEBSITE;
export const FIREBASE_API_KEY = process.env.REACT_APP_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
export const FIREBASE_DATABASE_URL = process.env.REACT_APP_DATABASE_URL;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_APP_ID;
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID;

export const XERO_CLIENT_ID = process.env.REACT_APP_XERO_CLIENT_ID;
export const XERO_CLIENT_SECRET = process.env.REACT_APP_XERO_CLIENT_SECRET;
