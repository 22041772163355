import { collection, deleteDoc, doc, getDoc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { InitReviewsData, writtenComments } from "../data/Review360Data";
import { formatDate } from "../helpers/FormatDate";
import { Review360EmailForm } from "../models/Review360EmailForm";
import {
  Term,
  Review,
  ReviewAnswers,
  ReviewsData,
  ReviewersHR,
  ReviewGroup,
  reviewGroups,
  ReviewHR,
  ReviewQuestions,
  ReviewStatus,
  YearAndTerm,
  ReviewResultsOfAll,
  ReviewResults,
  ReviewAverageGroup,
} from "../models/Review360Model";
import { User } from "../models/User";
import { sendReview360Email } from "./email";
import { db, functions } from "./firebase";
import { getMentees } from "./mentees";
import { getUser, getUserById } from "./users";

const REVIEWS_360 = "360reviews";
const REVIEWEES = "reviewees";

export const round2 = (num: number) => Math.round(num * 100) / 100;

const reviewCollection = (yearAndTerm: YearAndTerm, ...pathSegments: string[]) =>
  collection(db, REVIEWS_360, yearAndTerm.year.toString(), yearAndTerm.term, "info", ...pathSegments);

const reviewDoc = (yearAndTerm: YearAndTerm, ...pathSegments: string[]) =>
  doc(db, REVIEWS_360, yearAndTerm.year.toString(), yearAndTerm.term, "info", ...pathSegments);

const questionsDoc = (yearAndTerm: YearAndTerm, ...pathSegments: string[]) =>
  doc(db, REVIEWS_360, yearAndTerm.year.toString(), yearAndTerm.term, "questions", ...pathSegments);

export const getReviewsData = async (): Promise<ReviewsData> => {
  const currentYearAndTerm = await getCurrentYearAndTerm();
  return {
    currentYearAndTerm,
    startYearAndTerm: await getStartYearAndTerm(),
    questionCount: await getQuestionCount(currentYearAndTerm),
    questionCountSelf: await getQuestionCountSelf(currentYearAndTerm),
    reviewsInfo: await getReviewsInfo(currentYearAndTerm),
  };
};

export const getReviewsDataYearAndTerm = async (yearAndTerm: YearAndTerm): Promise<ReviewsData> => {
  return {
    currentYearAndTerm: yearAndTerm,
    startYearAndTerm: await getStartYearAndTerm(),
    questionCount: await getQuestionCount(yearAndTerm),
    questionCountSelf: await getQuestionCountSelf(yearAndTerm),
    reviewsInfo: await getReviewsInfo(yearAndTerm),
  };
};

export const getCurrentYearAndTerm = async (): Promise<YearAndTerm> =>
  (await getDoc(doc(db, REVIEWS_360, "currentYearAndTerm")))?.data() as YearAndTerm;

export const getStartYearAndTerm = async (): Promise<YearAndTerm> =>
  (await getDoc(doc(db, REVIEWS_360, "startYearAndTerm")))?.data() as YearAndTerm;

const getOtherReview = async (
  currentYearAndTerm: YearAndTerm,
  reviewer: User,
  reviewee: User
): Promise<Review | undefined> => {
  for (const groupName of reviewGroups) {
    const group = await getDoc(reviewDoc(currentYearAndTerm, REVIEWEES, reviewee.id, groupName, reviewer.id));
    if (group.exists()) {
      return {
        ...group.data(),
        reviewee,
        reviewer,
        reviewerGroup: groupName,
      } as Review;
    }
  }
  return undefined;
};

export const startSetUp = async (currentYearAndTerm: YearAndTerm, reviewsData: ReviewsData, reviewsHR: ReviewHR[]) => {
  await setDoc(
    reviewDoc(currentYearAndTerm),
    {
      status: ReviewStatus.SETTING_UP,
    },
    { merge: true }
  );
  sendReview360FinaliseEmail(reviewsHR, currentYearAndTerm);
  return {
    ...reviewsData,
    reviewsInfo: {
      ...reviewsData.reviewsInfo,
      status: ReviewStatus.SETTING_UP,
    },
  };
};

export const commenceReviews = async (reviewsData: ReviewsData, reviewsHR: ReviewHR[]) => {
  await updateDoc(reviewDoc(reviewsData.currentYearAndTerm), {
    status: ReviewStatus.IN_PROGRESS,
  });
  sendReview360CommencedEmail(reviewsHR, reviewsData.currentYearAndTerm, reviewsData.reviewsInfo.cutOffDate);
  return {
    ...reviewsData,
    reviewsInfo: {
      ...reviewsData.reviewsInfo,
      status: ReviewStatus.IN_PROGRESS,
    },
  };
};

export const sendReview360CommencedEmail = (
  reviewsHR: ReviewHR[],
  currentYearAndTerm: YearAndTerm,
  cutOffDate?: Date
) => {
  for (const reviewee of reviewsHR) {
    sendReview360CommencedEmailToReviewee(reviewee, currentYearAndTerm, cutOffDate);
    for (const [, reviewers] of Object.entries(reviewee.reviewers)) {
      for (const reviewer of reviewers) {
        sendReview360CommencedEmailToReviewer(reviewee, reviewer, currentYearAndTerm, cutOffDate);
      }
    }
  }
};

const sendReview360CommencedEmailToReviewee = (
  reviewHR: ReviewHR,
  currentYearAndTerm: YearAndTerm,
  cutOffDate?: Date
) => {
  const e: Review360EmailForm = {
    email: reviewHR.reviewee.email,
    title: "360 Reviews Commencement for " + reviewHR.reviewee.firstName + " " + reviewHR.reviewee.lastName,
    subject: "360 Reviews have commenced",
    message:
      "360 Reviews have commenced. Please submit your reviews and let your reviewers know that they can start reviewing you.",
    reviewee: reviewHR.reviewee,
    year: currentYearAndTerm.year,
    term: currentYearAndTerm.term,
    cutOffDate: "Cut-off date: " + formatDate(cutOffDate as Date),
  };
  sendReview360Email(e);
};

const sendReview360CommencedEmailToReviewer = (
  reviewHR: ReviewHR,
  reviewer: User,
  currentYearAndTerm: YearAndTerm,
  cutOffDate?: Date
) => {
  const e: Review360EmailForm = {
    email: reviewer.email,
    title: "360 Reviews Commencement for " + reviewHR.reviewee.firstName + " " + reviewHR.reviewee.lastName,
    subject: "360 Reviews have commenced",
    message:
      "360 Reviews have commenced for your reviewee, " +
      reviewHR.reviewee.firstName +
      " " +
      reviewHR.reviewee.lastName +
      ". Please start reviewing.",
    reviewee: reviewHR.reviewee,
    reviewer: reviewer,
    year: currentYearAndTerm.year,
    term: currentYearAndTerm.term,
    cutOffDate: "Cut-off date: " + formatDate(cutOffDate as Date),
  };
  sendReview360Email(e);
};

export const sendReview360FinaliseEmail = (reviewsHR: ReviewHR[], currentYearAndTerm: YearAndTerm) => {
  for (const reviewee of reviewsHR) {
    sendReview360FinaliseEmailToReviewee(reviewee, currentYearAndTerm);
  }
};

const sendReview360FinaliseEmailToReviewee = (reviewHR: ReviewHR, currentYearAndTerm: YearAndTerm) => {
  const e: Review360EmailForm = {
    email: reviewHR.reviewee.email,
    title:
      "Finalise Reviewers for " + reviewHR.reviewee.firstName + " " + reviewHR.reviewee.lastName + "'s 360 Reviews",
    subject: "Finalise reviewers for 360 Reviews",
    message: "Please finalise your reviewers for Reviews 360.",
    reviewee: reviewHR.reviewee,
    year: currentYearAndTerm.year,
    term: currentYearAndTerm.term,
  };
  sendReview360Email(e);
};

export const sendReview360CalculatedEmail = (reviewsHR: ReviewHR[], currentYearAndTerm: YearAndTerm) => {
  for (const reviewee of reviewsHR) {
    sendReview360CalculatedEmailToReviewee(reviewee, currentYearAndTerm);
  }
};

const sendReview360CalculatedEmailToReviewee = (reviewHR: ReviewHR, currentYearAndTerm: YearAndTerm) => {
  const e: Review360EmailForm = {
    email: reviewHR.reviewee.email,
    title: "Results for " + reviewHR.reviewee.firstName + " " + reviewHR.reviewee.lastName + " have been calculated",
    subject: "Results for 360 Reviews",
    message: "Your results have been calculated. Please review your results.",
    reviewee: reviewHR.reviewee,
    year: currentYearAndTerm.year,
    term: currentYearAndTerm.term,
  };
  sendReview360Email(e);
};

const getNextTerm = (term: Term): Term => {
  switch (term) {
    case "T0":
      return "T1";
    case "T1":
      return "T2";
    case "T2":
      return "T3";
    case "T3":
      return "T0";
  }
};

const getNextYearAndTerm = (currentYearAndTerm: YearAndTerm): YearAndTerm => ({
  year: currentYearAndTerm.term === "T3" ? currentYearAndTerm.year + 1 : currentYearAndTerm.year,
  term: getNextTerm(currentYearAndTerm.term),
});

const getPreviousTerm = (term: Term): Term => {
  switch (term) {
    case "T0":
      return "T3";
    case "T1":
      return "T0";
    case "T2":
      return "T1";
    case "T3":
      return "T2";
  }
};

export const getPreviousYearAndTerm = (currentYearAndTerm: YearAndTerm): YearAndTerm => ({
  year: currentYearAndTerm.term === "T0" ? currentYearAndTerm.year - 1 : currentYearAndTerm.year,
  term: getPreviousTerm(currentYearAndTerm.term),
});

export const skipReviews = async (currentYearAndTerm: YearAndTerm, reviewsData: ReviewsData) => {
  await setDoc(reviewDoc(currentYearAndTerm), {
    status: ReviewStatus.SKIPPED,
  });
  const nextYearAndTerm = getNextYearAndTerm(currentYearAndTerm);
  await updateDoc(doc(db, REVIEWS_360, "currentYearAndTerm"), {
    ...nextYearAndTerm,
  });

  await copyQuestionsToNextTerm(currentYearAndTerm, nextYearAndTerm);
  await copyReviewsToNextTerm(currentYearAndTerm, nextYearAndTerm);
  return {
    reviewsHR: [],
    reviewsData: {
      ...reviewsData,
      currentYearAndTerm: nextYearAndTerm,
      reviewsInfo: {
        ...reviewsData.reviewsInfo,
      },
    },
  };
};

export const getPastYearAverages = async (reviewsData: ReviewsData, user: User): Promise<ReviewAverageGroup[]> => {
  const pastYearAndTerms = await getPastYearAndTerms(reviewsData, user);
  const pastYearAverages: ReviewAverageGroup[] = [];
  for (const pastYearAndTerm of pastYearAndTerms) {
    const results = await getReviewResults(pastYearAndTerm, user);
    if (results !== undefined) {
      pastYearAverages.push(results.averagesOverall);
    }
  }
  return pastYearAverages;
};

export const getPastYearAveragesOfAll = async (reviewsData: ReviewsData, user: User): Promise<number[]> => {
  const pastYearAndTerms = await getPastYearAndTerms(reviewsData, user);
  const pastYearAveragesOfAll: number[] = [];
  for (const pastYearAndTerm of pastYearAndTerms) {
    const results = await getReviewResultsOfAll(pastYearAndTerm);
    if (results !== undefined) {
      pastYearAveragesOfAll.push(results.averagesOverallOfAll);
    }
  }
  return pastYearAveragesOfAll;
};

const copyQuestionsToNextTerm = async (currentYearAndTerm: YearAndTerm, nextYearAndTerm: YearAndTerm) => {
  const currentTermQuestions = await getReviewQuestions(currentYearAndTerm);
  await setReviewQuestions(nextYearAndTerm, currentTermQuestions);
};

const copyReviewsToNextTerm = async (currentYearAndTerm: YearAndTerm, nextYearAndTerm: YearAndTerm) => {
  const currentTermReviewsHR = await getReviewsHR(currentYearAndTerm);
  await setReviewsHR(nextYearAndTerm, currentTermReviewsHR);
};

export const calculateResults = async (currentYearAndTerm: YearAndTerm, reviewsHR: ReviewHR[]) => {
  await httpsCallable(
    functions,
    "calculateResults"
  )({
    year: currentYearAndTerm.year,
    term: currentYearAndTerm.term,
  });
  await updateDoc(reviewDoc(currentYearAndTerm), {
    status: ReviewStatus.COMPLETED,
  });
  const nextYearAndTerm = getNextYearAndTerm(currentYearAndTerm);
  await updateDoc(doc(db, REVIEWS_360, "currentYearAndTerm"), {
    ...nextYearAndTerm,
  });

  await copyQuestionsToNextTerm(currentYearAndTerm, nextYearAndTerm);
  await copyReviewsToNextTerm(currentYearAndTerm, nextYearAndTerm);
  sendReview360CalculatedEmail(reviewsHR, currentYearAndTerm);
  return {
    reviewsData: {
      ...InitReviewsData,
      currentYearAndTerm: nextYearAndTerm,
    },
  };
};

export const updateCutOffDate = async (currentYearAndTerm: YearAndTerm, newDate: Date, reviewsData: ReviewsData) => {
  await setDoc(reviewDoc(currentYearAndTerm), { cutOffDate: newDate }, { merge: true });
  return {
    ...reviewsData,
    reviewsInfo: { ...reviewsData.reviewsInfo, cutOffDate: newDate },
  };
};

export const addReviewee = async (currentYearAndTerm: YearAndTerm, reviewee: User) =>
  await setDoc(reviewDoc(currentYearAndTerm, REVIEWEES, reviewee.id), {});

export const deleteReviewee = async (currentYearAndTerm: YearAndTerm, reviewee: User, reviewers: ReviewersHR) => {
  for (const [group, groupReviewers] of Object.entries(reviewers)) {
    for (const reviewer of groupReviewers) {
      await deleteDoc(reviewDoc(currentYearAndTerm, REVIEWEES, reviewee.id, group, reviewer.id));
    }
  }
  await deleteDoc(reviewDoc(currentYearAndTerm, REVIEWEES, reviewee.id));
};

export const updateReviewer = async (
  currentYearAndTerm: YearAndTerm,
  revieweeId: string,
  group: ReviewGroup,
  reviewers: User[],
  oldReviewers: User[]
) => {
  // add
  const addedReviewers = reviewers.filter((r) => !oldReviewers.includes(r));
  for (const reviewer of addedReviewers) {
    await setDoc(reviewDoc(currentYearAndTerm, REVIEWEES, revieweeId, group, reviewer.id), {});
  }

  // delete
  const deletedReviewers = oldReviewers.filter((r) => !reviewers.includes(r));
  for (const reviewer of deletedReviewers) {
    await deleteDoc(reviewDoc(currentYearAndTerm, REVIEWEES, revieweeId, group, reviewer.id));
  }
};

export const getOtherWithEmail = (email: string): User => ({
  id: email,
  email: email,
  firstName: email,
  lastName: "",
  role: "Other",
});

const getReviewersGroupHR = async (
  currentYearAndTerm: YearAndTerm,
  revieweeId: string,
  group: string
): Promise<(User & { submitted?: boolean })[]> => {
  const groupSnapshot = await getDocs(reviewCollection(currentYearAndTerm, REVIEWEES, revieweeId, group));
  let reviewers: (User & { submitted?: boolean })[] = [];
  for (const reviewer of groupSnapshot.docs) {
    try {
      reviewers.push({
        ...(await getUserById(reviewer.id)),
        submitted: reviewer.data().submitted,
      });
    } catch (e) {
      reviewers.push({
        ...getOtherWithEmail(reviewer.id),
        submitted: reviewer.data().submitted,
      });
    }
  }
  return reviewers;
};

export const getReviewHR = async (
  currentYearAndTerm: YearAndTerm,
  revieweeId: string
): Promise<ReviewHR | undefined> => {
  const revieweeData = await getDoc(reviewDoc(currentYearAndTerm, REVIEWEES, revieweeId));
  if (!revieweeData.exists()) {
    return undefined;
  }
  const reviewee = {
    ...(await getUserById(revieweeId)),
    submitted: revieweeData.data()?.submitted,
    reviewersFinalised: revieweeData.data()?.reviewersFinalised,
  };
  let reviewers: ReviewersHR = {
    Mentor: [],
    Peers: [],
    Above: [],
    Below: [],
  };
  for (const group of reviewGroups) {
    reviewers[group] = await getReviewersGroupHR(currentYearAndTerm, revieweeId, group);
  }
  return { reviewee, reviewers };
};

const setReviewsHR = async (yearAndTerm: YearAndTerm, reviewsHR: ReviewHR[]): Promise<void> => {
  for (const review of reviewsHR) {
    await setDoc(reviewDoc(yearAndTerm, REVIEWEES, review.reviewee.id), {});
    for (const [group, reviewers] of Object.entries(review.reviewers)) {
      for (const reviewer of reviewers) {
        await setDoc(reviewDoc(yearAndTerm, REVIEWEES, review.reviewee.id, group, reviewer.id), {});
      }
    }
  }
};

export const getReviewsHR = async (currentYearAndTerm: YearAndTerm): Promise<ReviewHR[]> => {
  const reviewees = await getDocs(reviewCollection(currentYearAndTerm, REVIEWEES));

  const reviews: ReviewHR[] = [];
  for (const reviewee of reviewees.docs) {
    const review = await getReviewHR(currentYearAndTerm, reviewee.id);
    if (review !== undefined) {
      reviews.push(review);
    }
  }

  return reviews;
};

export const getReview = async (data: ReviewsData, revieweeId: string, email?: string): Promise<Review | undefined> => {
  let reviewer = undefined;
  if (!email) {
    try {
      reviewer = await getUser();
    } catch (err) {
      return undefined;
    }
  } else {
    reviewer = getOtherWithEmail(email) as User;
  }
  if (reviewer.id === revieweeId) {
    return await getMyReview(data, reviewer);
  }
  return await getReviewAsReviewer(data, reviewer, revieweeId);
};

export const getReviewsInfo = async (currentYearAndTerm: YearAndTerm) => {
  const info = await getDoc(reviewDoc(currentYearAndTerm));
  return {
    ...info.data(),
    cutOffDate: info.data()?.cutOffDate ? info.data()?.cutOffDate.toDate() : undefined,
  };
};

export const getMyReview = async (data: ReviewsData, user: User): Promise<Review | undefined> => {
  if (data.reviewsInfo.status === ReviewStatus.SKIPPED) return undefined;
  const myReview = await getDoc(reviewDoc(data.currentYearAndTerm, REVIEWEES, user.id));
  if (myReview.exists()) {
    return {
      ...myReview.data(),
      reviewee: user,
      reviewer: user,
    } as Review;
  }
  return undefined;
};

const getReviewAsReviewer = async (data: ReviewsData, reviewer: User, revieweeId: string) => {
  const reviewee = await getUserById(revieweeId);
  const review = await getOtherReview(data.currentYearAndTerm, reviewer, reviewee);
  return review;
};

export const getReviewsAsReviewer = async (data: ReviewsData, reviewer: User): Promise<Review[]> => {
  const reviewees = await getDocs(reviewCollection(data.currentYearAndTerm, REVIEWEES));

  const reviews: Review[] = [];
  for (const reviewee of reviewees.docs) {
    const review = await getReviewAsReviewer(data, reviewer, reviewee.id);
    if (review) {
      reviews.push(review);
    }
  }
  return reviews;
};

export const getReviewResultsOfAll = async (yearAndTerm: YearAndTerm) => {
  try {
    return (await getDoc(reviewDoc(yearAndTerm))).data() as ReviewResultsOfAll;
  } catch (err) {
    return undefined;
  }
};

export const getReviewResults = async (yearAndTerm: YearAndTerm, reviewee: User) => {
  try {
    return (await getDoc(reviewDoc(yearAndTerm, REVIEWEES, reviewee.id))).data() as ReviewResults;
  } catch (err) {
    return undefined;
  }
};

const countQuestionsSelf = (questions?: ReviewQuestions) =>
  questions
    ? Object.values(questions).reduce((a, c) => {
        let questionsCount = 0;
        for (const section of Object.values(c)) {
          questionsCount += Object.values(section).length;
        }
        return a + questionsCount;
      }, 0)
    : 0;

const countQuestions = (questions?: ReviewQuestions) =>
  questions
    ? Object.values(questions).reduce((a, c) => {
        let questionsCount = 0;
        for (const section of Object.values(c)) {
          questionsCount += Object.values(section).length;
        }
        return a + questionsCount + writtenComments.length;
      }, 0)
    : 0;

export const getQuestionCount = async (currentYearAndTerm: YearAndTerm): Promise<number> => {
  const questions = (await getDoc(questionsDoc(currentYearAndTerm)))?.data() as ReviewQuestions;
  return countQuestions(questions);
};

export const getQuestionCountSelf = async (currentYearAndTerm: YearAndTerm): Promise<number> => {
  const questions = (await getDoc(questionsDoc(currentYearAndTerm)))?.data() as ReviewQuestions;
  return countQuestionsSelf(questions);
};

export const setReviewQuestions = async (yearAndTerm: YearAndTerm, questions: ReviewQuestions): Promise<void> =>
  await setDoc(questionsDoc(yearAndTerm), questions);

export const getReviewQuestions = async (currentYearAndTerm: YearAndTerm): Promise<ReviewQuestions> =>
  (await getDoc(questionsDoc(currentYearAndTerm)))?.data() as ReviewQuestions;

export const getAnswersCount = (reviewAnswers?: ReviewAnswers) =>
  reviewAnswers
    ? Object.values(reviewAnswers).reduce((a, c) => {
        const { writtenComments, ...answerList } = c;
        let answersCount = 0;
        for (const section of Object.values(answerList)) {
          answersCount += Object.values(section).length;
        }
        if (writtenComments) {
          for (const comment of Object.values(writtenComments)) {
            if (comment) {
              answersCount++;
            }
          }
        }
        return a + answersCount;
      }, 0)
    : 0;

export const submitReview = async (review: Review | undefined) => {
  if (review?.answers) {
    if (review.reviewer.id === review.reviewee.id) {
      return submitMyReview(review);
    } else {
      return submitOtherReview(review);
    }
  }
};

export const finaliseReviewers = async (review: ReviewHR) => {
  await updateDoc(reviewDoc(await getCurrentYearAndTerm(), REVIEWEES, review.reviewee.id), {
    reviewersFinalised: true,
  });
  return { ...review, reviewee: { ...review.reviewee, reviewersFinalised: true } };
};

const submitOtherReview = async (review: Review) =>
  await updateDoc(
    reviewDoc(
      await getCurrentYearAndTerm(),
      REVIEWEES,
      review.reviewee.id,
      review.reviewerGroup as string,
      review.reviewer.id
    ),
    { submitted: true, submittedTime: new Date() }
  );

const submitMyReview = async (review: Review) =>
  await updateDoc(reviewDoc(await getCurrentYearAndTerm(), REVIEWEES, review.reviewee.id), {
    submitted: true,
    submittedTime: new Date(),
  });

export const updateReview = async (review: Review) => {
  if (review.answers) {
    if (review.reviewer.id === review.reviewee.id) {
      return updateMyReview(review);
    } else {
      return updateOtherReview(review);
    }
  }
};

const updateOtherReview = async (review: Review) =>
  await updateDoc(
    reviewDoc(
      await getCurrentYearAndTerm(),
      REVIEWEES,
      review.reviewee.id,
      review.reviewerGroup as string,
      review.reviewer.id
    ),
    { answers: review.answers }
  );

const updateMyReview = async (review: Review) => {
  await updateDoc(reviewDoc(await getCurrentYearAndTerm(), REVIEWEES, review.reviewee.id), {
    answers: review.answers,
  });
};

export const readyToSubmit = (answers?: ReviewAnswers, questions?: ReviewQuestions, self?: boolean): boolean =>
  getAnswersCount(answers) === (self ? countQuestionsSelf(questions) : countQuestions(questions));

// Gets the step that the user hasn't completed yet
export const getCurrentStep = (answers?: ReviewAnswers, categories?: ReviewQuestions): number => {
  let step = 0;
  if (answers !== undefined && categories !== undefined) {
    for (const [category, attributes] of Object.entries(categories).sort((a, b) => a[0].localeCompare(b[0]))) {
      if (!answers[category]) {
        return step;
      }
      const { writtenComments, ...answerAttributes } = answers[category];
      if (Object.entries(answerAttributes).length !== Object.entries(attributes).length) {
        return step;
      }
      if (writtenComments === undefined) {
        return step;
      }
      if (!writtenComments.Start || !writtenComments.Stop || !writtenComments.Continue) {
        return step;
      }
      if (Object.values(Object.values(answerAttributes)).length !== Object.values(Object.entries(attributes)).length) {
        return step;
      }
      step++;
    }
    return step - 1;
  }
  return step;
};

export const getReviewersAverage = (groupAverages: ReviewAverageGroup, numberOfReviewers: number) =>
  numberOfReviewers > 0
    ? (groupAverages.Mentor + (groupAverages.Others ? groupAverages.Others : 0) * (numberOfReviewers - 1)) /
      numberOfReviewers
    : NaN;

const yearAndTermEqual = (yaq1: YearAndTerm, yaq2: YearAndTerm) => yaq1.year === yaq2.year && yaq1.term === yaq2.term;

export const getPastYearAndTerms = async (data: ReviewsData, user: User) => {
  let pastYearAndTerms: YearAndTerm[] = [];
  if (!yearAndTermEqual(data.currentYearAndTerm, data.startYearAndTerm)) {
    let previousYearAndTerm = getPreviousYearAndTerm(data.currentYearAndTerm);
    while (true) {
      const reviewsDataForYearAndTerm = await getReviewsDataYearAndTerm(previousYearAndTerm);
      if ((await getMyReview(reviewsDataForYearAndTerm, user)) !== undefined) {
        pastYearAndTerms.push(previousYearAndTerm);
      }
      if (
        previousYearAndTerm.year === data.startYearAndTerm.year &&
        previousYearAndTerm.term === data.startYearAndTerm.term
      ) {
        break;
      }
      previousYearAndTerm = getPreviousYearAndTerm(previousYearAndTerm);
    }
  }
  return pastYearAndTerms;
};

export const getPastYearAndTermsAll = async (data: ReviewsData) => {
  let pastYearAndTerms: YearAndTerm[] = [];
  if (!yearAndTermEqual(data.currentYearAndTerm, data.startYearAndTerm)) {
    let previousYearAndTerm = getPreviousYearAndTerm(data.currentYearAndTerm);
    while (true) {
      pastYearAndTerms.push(previousYearAndTerm);
      if (
        previousYearAndTerm.year === data.startYearAndTerm.year &&
        previousYearAndTerm.term === data.startYearAndTerm.term
      ) {
        break;
      }
      previousYearAndTerm = getPreviousYearAndTerm(previousYearAndTerm);
    }
  }
  return pastYearAndTerms;
};

export const getPastYearAndTermsForMentees = async (data: ReviewsData, user: User) => {
  const mentees = await getMentees(user.id);
  const pastYearAndTermsForMentees: {
    mentee: User;
    pastYearAndTerms: YearAndTerm[];
  }[] = [];
  for (const mentee of mentees) {
    let pastYearAndTerms: YearAndTerm[] = [];
    if (!yearAndTermEqual(data.currentYearAndTerm, data.startYearAndTerm)) {
      let previousYearAndTerm = getPreviousYearAndTerm(data.currentYearAndTerm);
      while (true) {
        const reviewsDataForYearAndTerm = await getReviewsDataYearAndTerm(previousYearAndTerm);
        if ((await getMyReview(reviewsDataForYearAndTerm, mentee)) !== undefined) {
          pastYearAndTerms.push(previousYearAndTerm);
        }
        if (
          previousYearAndTerm.year === data.startYearAndTerm.year &&
          previousYearAndTerm.term === data.startYearAndTerm.term
        ) {
          break;
        }
        previousYearAndTerm = getPreviousYearAndTerm(previousYearAndTerm);
      }
    }
    pastYearAndTermsForMentees.push({ mentee, pastYearAndTerms });
  }
  return pastYearAndTermsForMentees;
};

export const getReviewCompleteCounts = (reviewsHR: ReviewHR[]) => {
  let count = 0;
  let countReviewees = 0;
  let countMentors = 0;
  let totalCount = 0;
  for (const reviewHR of reviewsHR) {
    ++totalCount;
    if (reviewHR.reviewee.submitted) {
      ++count;
      ++countReviewees;
    }
    if (reviewHR.reviewers["Mentor"] && reviewHR.reviewers["Mentor"][0] && reviewHR.reviewers["Mentor"][0].submitted) {
      ++countMentors;
    }
    for (const reviewer of Object.values(reviewHR.reviewers)) {
      totalCount += reviewer.length;
      count += reviewer.filter((r) => r.submitted).length;
    }
  }
  return { count, countReviewees, countMentors, totalCount };
};

export const getReviewersFinalisedCounts = (reviewsHR: ReviewHR[]) => {
  let reviewersFinalisedCount = 0;
  let reviewersFinalisedTotalCount = 0;
  for (const reviewHR of reviewsHR) {
    ++reviewersFinalisedTotalCount;
    if (reviewHR.reviewee.reviewersFinalised) {
      ++reviewersFinalisedCount;
    }
  }
  return { reviewersFinalisedCount, reviewersFinalisedTotalCount };
};
