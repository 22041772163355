import { User } from "./User";

export const reviewTerm = ["T0", "T1", "T2", "T3"] as const;
export type Term = typeof reviewTerm[number];

export interface YearAndTerm {
  year: number;
  term: Term;
}

export const CLEAR_STRENGTH = "Clear Strength";
export const RELATIVE_STRENGTH = "Relative Strength";
export const BLIND_SPOT = "Blind Spot";
export const DEVELOPMENT_OPPORTUNITY = "Development Opportunity";

export interface ReviewsData {
  currentYearAndTerm: YearAndTerm;
  startYearAndTerm: YearAndTerm;
  questionCount: number;
  questionCountSelf: number;
  reviewsInfo: ReviewsInfo;
}

export enum ReviewStatus {
  SETTING_UP = "Setting Up",
  IN_PROGRESS = "In Progress",
  COMPLETED = "Completed",
  SKIPPED = "Skipped",
}

export interface Review {
  reviewee: User;
  reviewer: User;
  reviewerGroup?: string;
  questionCount: number;
  answers?: ReviewAnswers;
  submitted: boolean;
  submittedTime?: Date;
}

export interface ReviewHR {
  reviewee: User & { submitted?: boolean; reviewersFinalised?: boolean };
  reviewers: ReviewersHR;
}

export interface ReviewsInfo {
  cutOffDate?: Date;
  status?: ReviewStatus;
}

// Note: mentor should only have one user
export type ReviewersHR = {
  [group in ReviewGroup]: (User & { submitted?: boolean })[];
};

export interface AttributesQuestion {
  [attribute: string]: {
    [index: number]: Question;
  };
}

export interface ReviewQuestions {
  [category: string]: AttributesQuestion;
}

export interface Attributes {
  [attribute: string]: {
    [index: number]: ReviewAnswerRange;
  };
}

type ReviewAnswersAttribute = Attributes & {
  writtenComments: WrittenComments;
};

export interface ReviewAnswers {
  [category: string]: ReviewAnswersAttribute;
}

export interface WrittenComments {
  Start?: string;
  Stop?: string;
  Continue?: string;
}

export const reviewAnswerRange = [1, 2, 3, 4, 5, 6, 7] as const;
export type ReviewAnswerRange = typeof reviewAnswerRange[number];

export type Question = string;

export const reviewGroups = ["Mentor", "Peers", "Above", "Below"] as const;
export type ReviewGroup = typeof reviewGroups[number];

export interface WrittenCommentsCategory {
  [category: string]: WrittenComments[];
}

export interface ReviewResults {
  averages: ReviewAverage;
  averagesAttribute: ReviewAverageAttribute;
  averagesCategory: ReviewAverageCategory;
  averagesOverall: ReviewAverageGroup;
  writtenComments: WrittenCommentsCategory;
  numberOfReviewers: number;
}

export interface ReviewResultsOfAll {
  averagesOfAll: AverageOfAll;
  averagesAttributeOfAll: AttributeAverageOfAll;
  averagesCategoryOfAll: CategoryAverageOfAll;
  averagesOverallOfAll: number;
  numberOfTotalReviewers: number;
}

export interface ReviewAverage {
  [category: string]: {
    [attribute: string]: {
      [index: number]: ReviewAverageGroup;
    };
  };
}

export interface ReviewAverageAttribute {
  [category: string]: {
    [attribute: string]: ReviewAverageGroup;
  };
}

export interface ReviewAverageCategory {
  [category: string]: ReviewAverageGroup;
}

export interface ReviewAverageGroup {
  Self: number;
  Mentor: number;
  Others?: number;
}

export const reviewAverageGroups = ["Self", "Mentor", "Others"];

export interface AverageOfAll {
  [category: string]: {
    [attribute: string]: {
      [index: number]: number;
    };
  };
}

export interface AttributeAverageOfAll {
  [category: string]: {
    [attribute: string]: number;
  };
}

export interface CategoryAverageOfAll {
  [category: string]: number;
}
