import { createTheme, responsiveFontSizes, Theme } from "@mui/material/styles";
import { common } from "@mui/material/colors";
import { User } from "../models/User";
import { universities, University } from "../data/University";
import { ColoursCampuses } from "../data/colours";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

export const SOWTheme = responsiveFontSizes(
  createTheme({
    shape: {
      borderRadius: 30,
    },
    palette: {
      primary: {
        main: ColoursCampuses.STAFF,
      },
      secondary: {
        main: common.white,
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
  })
);

export const innerTheme = (user: User): Theme => {
  return responsiveFontSizes(
    createTheme({
      shape: {
        borderRadius: 30,
      },
      palette: {
        primary: {
          main: Object.values(ColoursCampuses)[universities.indexOf(user.university as University) + 1],
        },
        secondary: {
          main: common.white,
        },
      },
      typography: {
        button: {
          textTransform: "none",
        },
      },
    })
  );
};
