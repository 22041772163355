import { Grid, Typography } from "@mui/material";
import { Staff } from "../../models/Staff";
import { DesignRequestCommentData } from "../../models/DesignRequestCommentData";
import { DesignRequest } from "../../models/DesignRequest";
import { DesignRequestComment } from "./DesignRequestComment";
import { DesignRequestCommentForm } from "./DesignRequestCommentForm";
import { MessageState } from "../../models/MessageState";
import { deleteComment, designRequestDeclined } from "../../services/designRequests";

export const DesignRequestCommentSection = ({
  designRequest,
  staff,
  handleSubmit,
  handleDelete,
}: {
  designRequest: DesignRequest;
  staff: Staff;
  handleSubmit?: (m: MessageState, c: DesignRequestCommentData | null) => void;
  handleDelete?: (m: MessageState, c: string) => void;
}) => (
  <Grid>
    <Typography variant="h6">Comments</Typography>
    {(designRequest.commentSection ?? []).map((c, i) => (
      <DesignRequestComment
        c={c}
        key={i}
        deleteComment={
          c.staff.id === staff?.id && handleDelete
            ? (comment_id: string) =>
                deleteComment(designRequest, comment_id).then((c_id) =>
                  handleDelete(
                    {
                      showMessage: true,
                      message: "Successfully deleted comment!",
                      severity: "success",
                    },
                    c_id
                  )
                )
            : undefined
        }
      />
    ))}
    {!designRequestDeclined(designRequest) && !designRequest.completed && handleSubmit && (
      <DesignRequestCommentForm designRequest={designRequest} staff={staff} handleSubmit={handleSubmit} />
    )}
  </Grid>
);
