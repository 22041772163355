import { User, UserID } from "./User";

export interface MenteesId {
  [mentee: UserID]: UserID;
}

export interface MenteeMentorPair {
  mentee: User;
  mentor: User;
}

export interface MentorsHR {
  assignedHRRep?: User;
  mentorsAndMentees: MenteeMentorPair[];
}

export const InitMentorsHR = {
  mentorsAndMentees: [],
};
