import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import { Card, CardActions, CardContent, CardHeader, Grid, Link, Paper, Typography } from "@mui/material";
import { Path } from "../../../helpers/Path";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "@mui/styles";
import { ReactNode } from "react";

export const DashboardCard = ({
  title,
  content,
  link,
  to,
  externalWebsite,
}: {
  title: String;
  content: ReactNode;
  link: String;
  to: Path | String;
  externalWebsite?: boolean;
}) => {
  const theme = useTheme();
  const history = useHistory();
  return (
    <Paper elevation={8}>
      <Card
        sx={{ cursor: "pointer" }}
        onClick={() => (externalWebsite ? window.open(to as string) : history.push(to as string))}
      >
        <CardHeader
          sx={{
            backgroundColor: theme.palette.primary.main,
            padding: theme.spacing(1.7, 0, 1.7, 0),
          }}
          title={
            <Typography color="secondary" align="center">
              {title}
            </Typography>
          }
        />
        <CardContent
          sx={{
            height: 180,
            padding: theme.spacing(0, 0, 0, 0),
          }}
        >
          {content}
        </CardContent>
        <CardActions
          sx={{
            padding: theme.spacing(1, 2, 2, 1),
          }}
        >
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            {externalWebsite ? (
              <Link sx={{ cursor: "pointer" }} underline="hover" onClick={() => window.open(to as string)}>
                <Grid container alignItems="center" justifyContent="center">
                  <Typography variant="body2">{link}</Typography>
                  <ArrowForwardIcon />
                </Grid>
              </Link>
            ) : (
              <Link component={ReactRouterLink} underline="hover" to={to as string}>
                <Grid container alignItems="center" justifyContent="center">
                  <Typography variant="body2">{link}</Typography>
                  <ArrowForwardIcon />
                </Grid>
              </Link>
            )}
          </Grid>
        </CardActions>
      </Card>
    </Paper>
  );
};
