import { db } from "./firebase";
import {
  purple,
  deepPurple,
  lightGreen,
  orange,
  green,
  red,
  blue,
  yellow,
  grey,
  cyan,
  pink,
  indigo,
  lightBlue,
  teal,
  lime,
  amber,
  deepOrange,
  brown,
  blueGrey,
} from "@mui/material/colors";
import { atLeast } from "../helpers/Role";
import { University } from "../data/University";
import { User } from "../models/User";
import { AppointmentModel } from "@devexpress/dx-react-scheduler";
import { ColoursCampuses } from "../data/colours";
import { getCurrentStaffYearRoles } from "./roles";
import {
  collection,
  getDocs,
  setDoc,
  doc,
  QueryDocumentSnapshot,
  DocumentData,
  updateDoc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { STAFF } from "../data/Roles";

export const getRolesResource = (role: string, roles: string[]) => {
  return rolesResource(roles).filter((r) => r.id >= roles.indexOf(role));
};

const rolesColours = [
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
];

export const rolesResource = (roles: string[]) => {
  return roles.map((r, i) => ({
    text: r,
    id: i,
    color: rolesColours[i],
  }));
};

export const universitiesResource = [
  {
    text: "All Campuses",
    id: 1,
    color: ColoursCampuses.ALL,
  },
  {
    text: University.USYD,
    id: 2,
    color: ColoursCampuses.USYD,
  },
  {
    text: University.UNSW,
    id: 3,
    color: ColoursCampuses.UNSW,
  },
  {
    text: University.MACQ,
    id: 4,
    color: ColoursCampuses.MACQ,
  },
  {
    text: University.UTS,
    id: 5,
    color: ColoursCampuses.UTS,
  },
  {
    text: University.ACU,
    id: 6,
    color: ColoursCampuses.ACU,
  },
  {
    text: STAFF,
    id: 7,
    color: ColoursCampuses.STAFF,
  },
];

export const getCalendarThisMonthWithReocurringEvents = async (user: User): Promise<AppointmentModel[]> => {
  const today = new Date();
  const roles = await getCurrentStaffYearRoles();
  const currentMonthCalendar = await getDocs(
    query(
      collection(db, "calendar"),
      where("endDate", ">=", new Date(today.getFullYear(), today.getMonth() - 1, 20)),
      where("endDate", "<=", new Date(today.getFullYear(), today.getMonth() + 1, 10))
    )
  );
  const repeatingCalendarEvents = await getDocs(
    query(collection(db, "calendar"), where("rRule", "!=", "random rRule"))
  );

  return currentMonthCalendar.docs
    .concat(repeatingCalendarEvents.docs)
    .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))
    .filter((doc) => roleFilter(user, doc, roles))
    .map((doc) => {
      return toJavascriptFormat(doc);
    }) as AppointmentModel[];
};

export const getCalendar = (user: User): Promise<AppointmentModel[]> =>
  new Promise(async (resolve, reject) => {
    const roles = await getCurrentStaffYearRoles();
    getDocs(collection(db, "calendar"))
      .then((res) => {
        resolve(
          res.docs
            .filter((doc) => roleFilter(user, doc, roles))
            .map((doc) => {
              return toJavascriptFormat(doc);
            }) as AppointmentModel[]
        );
      })
      .catch((error) => {
        reject(error);
      });
  });

const roleFilter = (user: User, doc: QueryDocumentSnapshot<DocumentData>, roles: string[]) => {
  if (doc.data().roles.length === 0) {
    return true;
  }
  for (let roleId of doc.data().roles) {
    let role = rolesResource(roles).find((x) => x.id === roleId);
    if (role === undefined) {
      return false;
    } else {
      if (atLeast(roles, role.text, user.role)) {
        return true;
      }
    }
  }
  return false;
};

export const addCalendar = (added: AppointmentModel) =>
  new Promise((resolve, reject) =>
    setDoc(doc(db, "calendar", added.id?.toString() as string), toFirestoreFormat(added))
      .then(() => resolve("Successfully added to calendar"))
      .catch((error) => reject(error))
  );

export const updateCalendar = (changed: { [propertyName: number]: AppointmentModel }) => {
  return new Promise((resolve, reject) =>
    updateDoc(doc(db, "calendar", Object.keys(changed)[0].toString()), Object.values(changed)[0])
      .then(() => resolve("Successfully changed calendar"))
      .catch((error) => reject(error))
  );
};

export const deleteFromCalendar = (deletedId: string | number) => {
  return new Promise((resolve, reject) =>
    deleteDoc(doc(db, "calendar", deletedId.toString()))
      .then(() => resolve("Successfully deleted calendar"))
      .catch((error) => reject(error))
  );
};

const toJavascriptFormat = (document: QueryDocumentSnapshot<DocumentData>) => {
  return {
    ...document.data(),
    startDate: document.data().startDate.toDate(),
    endDate: document.data().endDate.toDate(),
  };
};

const toFirestoreFormat = (appointment: AppointmentModel) => ({
  ...appointment,
  title: appointment.title === undefined ? "" : appointment.title,
  roles: appointment.roles[0] ? appointment.roles.sort() : [],
});
