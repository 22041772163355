import {
  Button,
  ButtonGroup,
  Container,
  Grid,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTheme } from "@mui/styles";
import { ReactNode, SyntheticEvent, useContext, useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { MessageSnackbar } from "../../components/MessageSnackbar";
import { RolesContext, UserContext } from "../../components/PrivateRoute";
import { TypographyBold } from "../../components/TypographyBold";
import { HEAD_OF_DEPARTMENT } from "../../data/Roles";
import { timeSince } from "../../helpers/FormatDate";
import { atLeast } from "../../helpers/Role";
import { Announcement } from "../../models/Announcement";
import { MessageState } from "../../models/MessageState";
import {
  deleteAnnouncement,
  departmentCheckValidTag,
  getFilteredAnnouncements,
  getNAnnouncements,
} from "../../services/announcements";
import { AnnouncementsPost } from "./AnnouncementsPost";
import { DepartmentsContext } from "../../components/PrivateRoute";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmationModal } from "../../components/ConfirmationModal";

interface AnnouncementsInterface {
  loading: boolean;
  alert: MessageState;
  postDialogOpen: boolean;
  announcements: Announcement[];
  selectedIndex: number;
  anchorEl: null;
  deleteAnnouncementConfirmationDialog: boolean;
  deleteAnnouncementId: string;
  deleteAnnouncementIndex: number;
}

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            backgroundColor: "white",
            p: 3,
            height: theme.spacing(50),
            borderTopRightRadius: theme.spacing(2),
            borderBottomRightRadius: theme.spacing(2),
            borderTop: 2,
            borderRight: 2,
            borderBottom: 2,
            borderColor: "rgba(0, 0, 0, .125)",
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface StyledTabProps {
  label: ReactNode;
}

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  marginRight: theme.spacing(1),
  "&.Mui-selected": {
    backgroundColor: "rgba(0, 0, 0, .125)",
    borderRadius: theme.spacing(0.75),
  },
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, .125)",
    borderRadius: theme.spacing(0.75),
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export const Announcements = () => {
  const [state, setState] = useState<AnnouncementsInterface>({
    loading: true,
    alert: {
      showMessage: false,
      message: "",
      severity: "success",
    },
    postDialogOpen: false,
    announcements: [],
    selectedIndex: -1,
    anchorEl: null,
    deleteAnnouncementConfirmationDialog: false,
    deleteAnnouncementId: "",
    deleteAnnouncementIndex: -1,
  });
  const { deleteAnnouncementConfirmationDialog, deleteAnnouncementId, deleteAnnouncementIndex } = state;
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setState({ ...state, loading: true });

    const announcementsFetch = getNAnnouncements(100);

    Promise.all([announcementsFetch]).then(([announcements]) =>
      setState({
        ...state,
        announcements: announcements,
        loading: false,
      })
    );
    // eslint-disable-next-line
  }, []);
  const { loading, alert, postDialogOpen, announcements } = state;

  const user = useContext(UserContext);
  const roles = useContext(RolesContext);
  const department = useContext(DepartmentsContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setAnchorEl(null);

    const announcementsFetch = getFilteredAnnouncements(Object.keys(department.departments)[index], 100);

    Promise.all([announcementsFetch]).then(([announcements]) =>
      setState({
        ...state,
        announcements: announcements,
        selectedIndex: index,
      })
    );
  };

  const handleResetClick = (event: React.MouseEvent<HTMLElement>) => {
    const announcementsFetch = getNAnnouncements(100);
    setAnchorEl(null);

    Promise.all([announcementsFetch]).then(([announcements]) =>
      setState({
        ...state,
        announcements: announcements,
        selectedIndex: -1,
      })
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <ConfirmationModal
        title={"Delete Announcement?"}
        content={"This will delete the announcement."}
        confirmText={"Yes"}
        cancelText={"No"}
        dialogOpen={deleteAnnouncementConfirmationDialog}
        closeDialog={() =>
          setState({ ...state, deleteAnnouncementConfirmationDialog: false, deleteAnnouncementIndex: -1 })
        }
        doAction={() => {
          announcements.splice(deleteAnnouncementIndex, 1);
          deleteAnnouncement(deleteAnnouncementId);
          setState({
            ...state,
            deleteAnnouncementConfirmationDialog: false,
            deleteAnnouncementId: "",
            deleteAnnouncementIndex: -1,
          });
        }}
      />
      {atLeast(roles, HEAD_OF_DEPARTMENT, user.role) && (
        <Button
          sx={{
            position: "fixed",
            bottom: theme.spacing(6),
            right: theme.spacing(6),
          }}
          variant="contained"
          size="large"
          onClick={() => setState({ ...state, postDialogOpen: true })}
        >
          Make Announcement
        </Button>
      )}
      <AnnouncementsPost
        dialogOpen={postDialogOpen}
        closeDialog={() => setState({ ...state, postDialogOpen: false })}
        handlePost={(alertState, announcement) =>
          setState({
            ...state,
            alert: alertState,
            announcements: announcement ? [announcement, ...announcements] : announcements,
          })
        }
      />
      <MessageSnackbar messageState={alert} setMessageState={(alert) => setState({ ...state, alert })} />
      <Container maxWidth="xl">
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            mt: theme.spacing(6),
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              md={5}
              lg={3}
              sx={{
                backgroundColor: theme.palette.primary.main,
                borderTopRightRadius: theme.spacing(2),
                borderTopLeftRadius: theme.spacing(2),
              }}
            >
              <Typography
                color="secondary"
                variant="h6"
                sx={{
                  paddingLeft: theme.spacing(4),
                  paddingTop: theme.spacing(0.5),
                  paddingBottom: theme.spacing(0.5),
                }}
              >
                {state.selectedIndex === -1
                  ? "Announcements"
                  : Object.keys(department.departments)[state.selectedIndex]}
              </Typography>
              <Grid
                sx={{
                  paddingLeft: theme.spacing(2),
                }}
              >
                <div>
                  <ButtonGroup
                    aria-label="Filters"
                    sx={{
                      bgcolor: "background.paper",
                      maxHeight: "30px",
                    }}
                    variant="contained"
                    size="small"
                  >
                    <ListItemButton
                      aria-haspopup="listbox"
                      aria-controls="lock-menu"
                      aria-label="Filter"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClickListItem}
                    >
                      <ListItemText primaryTypographyProps={{ variant: "caption" }} primary="Set filter" />
                    </ListItemButton>
                    <ListItemButton
                      aria-haspopup="listbox"
                      aria-controls="lock-menu"
                      aria-label="Filter"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleResetClick}
                    >
                      <ListItemText primaryTypographyProps={{ variant: "caption" }} primary="Reset filter" />
                    </ListItemButton>
                  </ButtonGroup>
                  <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "lock-button",
                      role: "listbox",
                    }}
                  >
                    {Object.keys(department.departments).map((t, i) => (
                      <MenuItem
                        key={t}
                        selected={i === state.selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, i)}
                      >
                        {t}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} md={7} lg={9}></Grid>
            <Grid item xs={12} md={5} lg={3}>
              <Tabs
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  },
                }}
                scrollButtons={false}
                orientation="vertical"
                sx={{
                  backgroundColor: "white",
                  padding: theme.spacing(1, 0, 1, 1),
                  borderTopWidth: theme.spacing(0.25),
                  borderTopStyle: "solid",
                  borderTopColor: theme.palette.primary.main,
                  borderRight: "2px solid rgba(0, 0, 0, .125)",
                  borderLeft: "2px solid rgba(0, 0, 0, .125)",
                  borderBottom: "2px solid rgba(0, 0, 0, .125)",
                  borderBottomLeftRadius: theme.spacing(2),
                  height: theme.spacing(50),
                }}
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
              >
                {announcements.map((a, i) => (
                  <StyledTab
                    key={i}
                    label={
                      <Grid container>
                        <Grid item justifyContent="flex-start">
                          <TypographyBold
                            variant="subtitle2"
                            align="left"
                            color={department.departments[departmentCheckValidTag(a.tag)].colour}
                          >
                            {a.tag}
                          </TypographyBold>
                          <TypographyBold align="left">{a.title}</TypographyBold>
                        </Grid>
                        <Grid item container justifyContent="space-between">
                          <Typography variant="body2">
                            {a.user?.firstName} {a.user?.lastName}
                          </Typography>
                          <Typography variant="body2">{timeSince(a.time)} ago</Typography>
                        </Grid>
                      </Grid>
                    }
                    {...a11yProps(i)}
                  />
                ))}
              </Tabs>
            </Grid>
            <Grid item xs={12} md={7} lg={9}>
              {announcements.map((a, i) => (
                <TabPanel key={i} value={value} index={i}>
                  <Grid container component={"span"} justifyContent="flex-end">
                    {a.user.id === user.id && (
                      <Button
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() =>
                          setState({
                            ...state,
                            deleteAnnouncementConfirmationDialog: true,
                            deleteAnnouncementId: a.id as any,
                            deleteAnnouncementIndex: i,
                          })
                        }
                      >
                        Delete
                      </Button>
                    )}
                  </Grid>
                  {a.content}
                </TabPanel>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
