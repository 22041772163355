import { Box, Card, CardContent, CardHeader, Grid, Typography, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import { TypographyBold } from "../../../components/TypographyBold";
import { Path } from "../../../helpers/Path";
import { Review, ReviewsData } from "../../../models/Review360Model";
import DoneIcon from "@mui/icons-material/Done";
import { LinearProgressWithLabel } from "../../modules/ModuleCard";
import { getAnswersCount } from "../../../services/review360";

export const Review360Card = ({
  reviewsData,
  review,
  email,
}: {
  reviewsData: ReviewsData;
  review: Review;
  email?: string;
}) => {
  const history = useHistory();
  const theme = useTheme();
  return (
    <Card
      sx={
        !review.submitted
          ? {
              cursor: "pointer",
              border: "1px solid white",
              "&:hover": {
                border: "1px solid black",
                cursor: "pointer",
              },
            }
          : {}
      }
      onClick={() =>
        !review.submitted && history.push(`${Path["360 Review Form"]}/${review.reviewee.id}${email ? `/${email}` : ""}`)
      }
    >
      <CardHeader
        sx={{
          padding: theme.spacing(4, 5, 4, 5),
        }}
        title={
          <TypographyBold variant="h6">
            {review.reviewee.firstName} {review.reviewee.lastName}'s Review
          </TypographyBold>
        }
      />
      <CardContent>
        <Box
          sx={{
            mt: theme.spacing(2),
          }}
        >
          {review.submitted ? (
            <Grid container alignItems="center" justifyContent="center">
              <Typography>Submitted</Typography>
              <DoneIcon />
            </Grid>
          ) : (
            <LinearProgressWithLabel
              value={
                review.answers
                  ? (getAnswersCount(review.answers) /
                      (review.reviewee.id === review.reviewer.id
                        ? reviewsData.questionCountSelf
                        : reviewsData.questionCount)) *
                    100
                  : 0
              }
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
