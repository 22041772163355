import { Grid, Typography } from "@mui/material";
import { Staff } from "../../models/Staff";
import { PromotionRequestCommentData } from "../../models/PromotionRequestCommentData";
import { PromotionRequest } from "../../models/PromotionRequest";
import { PromotionRequestComment } from "./PromotionRequestComment";
import { PromotionRequestCommentForm } from "./PromotionRequestCommentForm";
import { MessageState } from "../../models/MessageState";
import { deleteComment, promotionRequestDeclined } from "../../services/promotionRequests";

export const PromotionRequestCommentSection = ({
  promotionRequest,
  staff,
  handleSubmit,
  handleDelete,
}: {
  promotionRequest: PromotionRequest;
  staff?: Staff | undefined;
  handleSubmit?: (m: MessageState, c: PromotionRequestCommentData | null) => void;
  handleDelete?: (m: MessageState, c: string) => void;
}) => (
  <Grid>
    <Typography variant="h6">Comments</Typography>
    {(promotionRequest.commentSection ?? []).map((c, i) => (
      <PromotionRequestComment
        c={c}
        key={i}
        deleteComment={
          c.staff.id === staff?.id && handleDelete
            ? (comment_id: string) =>
                deleteComment(promotionRequest, comment_id).then((c_id) =>
                  handleDelete(
                    {
                      showMessage: true,
                      message: "Successfully deleted comment!",
                      severity: "success",
                    },
                    c_id
                  )
                )
            : undefined
        }
      />
    ))}
    {!promotionRequestDeclined(promotionRequest) && !promotionRequest.completed && handleSubmit && (
      <PromotionRequestCommentForm promotionRequest={promotionRequest} staff={staff} handleSubmit={handleSubmit} />
    )}
  </Grid>
);
