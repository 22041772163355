import { TabPanel } from "@mui/lab";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { EventTableRow } from "./EventTableRow";
import { EventFormModel, TabPanelValue } from "../../models/EventFormModel";
import { Staff } from "../../models/Staff";
import { DepartmentInterface } from "../../models/Department";

export const EventPanel = ({
  openEventFormDialog,
  tabPanelValue,
  events,
  page,
  rowsPerPage,
  navigateToStageForm,
  year,
  handleChangePage,
  handleChangeRowsPerPage,
  user,
  departments,
}: {
  openEventFormDialog: (selectedEventForm: EventFormModel) => void;
  tabPanelValue: TabPanelValue;
  events: EventFormModel[];
  page: number;
  rowsPerPage: number;
  navigateToStageForm: (id: string, formType: string, year: string) => void;
  year: string;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  user: Staff;
  departments: DepartmentInterface;
}) => {
  return (
    <TabPanel value={tabPanelValue}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 500 }}>Event</TableCell>
              <TableCell style={{ minWidth: 200 }}>
                Sub Form{tabPanelValue !== TabPanelValue.TO_APPROVE && "s"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((eventForm) => {
              return (
                <EventTableRow
                  openEventFormDialog={openEventFormDialog}
                  eventForm={eventForm}
                  navigateToStageForm={navigateToStageForm}
                  year={year}
                  key={eventForm.id}
                  user={user}
                  departments={departments}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={events.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TabPanel>
  );
};
