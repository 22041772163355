import { Box, Button, Grid, Menu, MenuItem, Paper, Typography, useTheme } from "@mui/material";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import {
  BLIND_SPOT,
  CLEAR_STRENGTH,
  DEVELOPMENT_OPPORTUNITY,
  RELATIVE_STRENGTH,
  ReviewAverageGroup,
  reviewAverageGroups,
  ReviewQuestions,
  ReviewResults,
  ReviewResultsOfAll,
} from "../../../../models/Review360Model";
import { getReviewersAverage } from "../../../../services/review360";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  registerables as registerablesJS,
  Title,
  Tooltip,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { blue, grey, red } from "@mui/material/colors";
import { ColoursDepartments } from "../../../../data/colours";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TypographyBold } from "../../../../components/TypographyBold";

export const Review360ResultsRatedItems = ({
  results,
  resultsOfAll,
  questions,
}: {
  results: ReviewResults;
  resultsOfAll: ReviewResultsOfAll;
  questions: ReviewQuestions;
}) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(Object.keys(results.averages)[0]);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, category: string) => {
    setSelectedCategory(category);
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  ChartJS.register(...registerablesJS);
  ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

  const ratedItemsOptions = {
    showTooltips: false,
    scales: {
      x: {
        min: 0,
        max: 7,
        ticks: {
          stepSize: 1,
        },
      },
    },
    indexAxis: "y" as const,
    spanGaps: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const groupColours = {
    Self: ColoursDepartments.Missions_1 as string,
    Mentor: ColoursDepartments.SOW_2 as string,
    Others: ColoursDepartments.SOW_5 as string,
  };

  const ratedItemsData = (
    averageGroups: ReviewAverageGroup,
    averagesOfAllLearningOutcomes: {
      [index: number]: number;
    }
  ) => ({
    labels: ["Self", "Mentor", "Other Reviewers", "All Others"],
    datasets: [
      {
        type: "bar" as const,
        label: "rating",
        data: reviewAverageGroups
          .map((group) => averageGroups[group as keyof ReviewAverageGroup] as number)
          .concat(Object.values(averagesOfAllLearningOutcomes)),
        backgroundColor: reviewAverageGroups
          .map((group) => groupColours[group as keyof ReviewAverageGroup])
          .concat(blue[800]),
      },
    ],
  });

  const getArea = (self: number, reviewers: number, overall: number) => {
    if (!reviewers || !overall) {
      return "N/A";
    } else if (self >= overall && reviewers >= overall) {
      return CLEAR_STRENGTH;
    } else if (self < overall && reviewers >= overall) {
      return RELATIVE_STRENGTH;
    } else if (self >= overall && reviewers < overall) {
      return BLIND_SPOT;
    } else if (self < overall && reviewers < overall) {
      return DEVELOPMENT_OPPORTUNITY;
    }
  };

  const getAreaColour = (area: string | undefined) => {
    if (area === CLEAR_STRENGTH) return blue[600];
    if (area === RELATIVE_STRENGTH) return blue[200];
    if (area === BLIND_SPOT) return red[200];
    if (area === DEVELOPMENT_OPPORTUNITY) return red[600];
    return grey[700];
  };

  const ratedItemsColumns = (category: string, attribute: string) => [
    { field: "id", headerName: "ID", flex: 1, maxWidth: 30 },
    {
      field: "learningOutcome",
      headerName: "Learning Outcome",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "ratings",
      headerName: "Ratings",
      flex: 1,
      maxWidth: 300,
      renderCell: (params: GridRenderCellParams<ReviewAverageGroup>) => (
        <Chart
          type="bar"
          options={ratedItemsOptions}
          data={ratedItemsData(params.value as ReviewAverageGroup, resultsOfAll.averagesOfAll[category][attribute])}
        />
      ),
    },
    {
      field: "area",
      headerName: "Area",
      flex: 1,
      maxWidth: 220,
      renderCell: (params: GridRenderCellParams<string>) => (
        <TypographyBold
          sx={{
            backgroundColor: getAreaColour(params.value),
          }}
        >
          {params.value}
        </TypographyBold>
      ),
    },
  ];

  return (
    <>
      <TypographyBold variant="h4">Rated Items</TypographyBold>
      <Grid container justifyContent="center">
        <Button
          size="large"
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          <Typography variant="h5">{selectedCategory}</Typography>
        </Button>
      </Grid>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {Object.keys(results.averages).map((category, index) => (
          <MenuItem
            key={category}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index, category)}
          >
            {category}
          </MenuItem>
        ))}
      </Menu>
      {Object.entries(results.averages[selectedCategory]).map(([attribute, learningOutcomes], j) => (
        <div key={j}>
          <Typography variant="h6">{attribute}</Typography>
          <Box component={Paper} sx={{ mb: theme.spacing(4) }}>
            <DataGrid
              autoHeight
              getRowHeight={() => "auto"}
              checkboxSelection={false}
              rows={Object.entries(learningOutcomes).map(([index, averageGroups], i) => ({
                id: i,
                learningOutcome: questions[selectedCategory][attribute][Number(index)],
                ratings: averageGroups,
                area: getArea(
                  averageGroups.Self,
                  getReviewersAverage(averageGroups, results.numberOfReviewers),
                  resultsOfAll.averagesOfAll[selectedCategory][attribute][Number(index)]
                ),
              }))}
              columns={ratedItemsColumns(selectedCategory, attribute)}
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </Box>
        </div>
      ))}
      <Typography variant="h5">Written Comments</Typography>
      <Paper sx={{ p: theme.spacing(2, 2, 4, 2) }}>
        <Typography sx={{ mt: theme.spacing(1) }} variant="h6">
          Start
        </Typography>
        {results.writtenComments[selectedCategory].map((comment, i) => (
          <Typography key={i}>{comment.Start}</Typography>
        ))}
        <Typography sx={{ mt: theme.spacing(1) }} variant="h6">
          Stop
        </Typography>
        {results.writtenComments[selectedCategory].map((comment, i) => (
          <Typography key={i}>{comment.Stop}</Typography>
        ))}
        <Typography sx={{ mt: theme.spacing(1) }} variant="h6">
          Continue
        </Typography>
        {results.writtenComments[selectedCategory].map((comment, i) => (
          <Typography key={i}>{comment.Continue}</Typography>
        ))}
      </Paper>
    </>
  );
};
