import { currentMonth, currentYear } from "../../helpers/FormatDate";
import { PledgesGoals } from "../../services/xero";

export const xeroStartYear = 2020;

export interface XeroInterface {
  loading: boolean;
  profitAndLossPledges?: XeroProfitAndLossHistory;
  slider: number[];
  pledgesGoals: PledgesGoals;
}

export interface RetrievalInterface {
  periods: Period;
  timeframe: XeroTimeFrame;
  fromDate?: string;
  toDate?: string;
}

export const periods = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] as const;
export type Period = typeof periods[number];

export enum XeroTimeFrame {
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  YEAR = "YEAR",
}

export interface XeroProfitAndLossReportMetaData {
  Id: string;
  ProviderName: string;
  Reports: XeroProfitAndLossReport[];
  Status: string;
}

interface XeroProfitAndLossReport {
  ReportDate: string;
  ReportID: string;
  ReportName: string;
  ReportTitles: string[];
  Rows: XeroProfitAndLossReportSection[];
}

interface XeroProfitAndLossReportSection {
  RowType: XeroRowType;
  Rows: XeroProfitAndLossReportRow[];
  Title: string;
  Cells?: XeroProfitAndLossReportCell[];
}

interface XeroProfitAndLossReportRow {
  RowType: XeroRowType;
  Cells: XeroProfitAndLossReportCell[];
}

interface XeroProfitAndLossReportCell {
  Value: string;
  Attributes: XeroProfitAndLossReportCellAttribute[];
}

interface XeroProfitAndLossReportCellAttribute {
  Id: string;
  Value: string;
}

export enum XeroRowType {
  HEADER = "Header",
  SECTION = "Section",
  ROW = "Row",
}

export interface XeroProfitAndLossHistory {
  periods: XeroProfitAndLossPledges[];
}

interface XeroProfitAndLossPledges {
  date: string;
  alumniPledge: number;
  staffPledge: number;
}

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const convertProfitAndLosses = (
  profitAndLosses: XeroProfitAndLossReportMetaData[]
): XeroProfitAndLossHistory => ({
  periods: profitAndLosses
    .reduce(
      (a, v) => ({
        periods: a.periods.concat(convertProfitAndLoss(v).periods),
      }),
      { periods: [] } as XeroProfitAndLossHistory
    )
    .periods.filter(
      (period) =>
        period.date.slice(-2) !== currentYear.toString().slice(-2) ||
        months.findIndex((month) => month === period.date.slice(0, 3)) <= currentMonth
    ),
});

const getMonthDifference = (startDate: Date, endDate: Date) =>
  endDate.getMonth() - startDate.getMonth() + 12 * (endDate.getFullYear() - startDate.getFullYear());

export const monthsSinceXeroStartYear = getMonthDifference(new Date(xeroStartYear, 0), new Date());

const convertProfitAndLoss = (profitAndLoss: XeroProfitAndLossReportMetaData): XeroProfitAndLossHistory => {
  let pledges: XeroProfitAndLossHistory = { periods: [] };
  let dates: string[] = [];
  profitAndLoss.Reports[0].Rows.find((sectionRow) => sectionRow.RowType === XeroRowType.HEADER)?.Cells?.map((c) =>
    dates.push(c.Value.substring(3))
  );
  dates.shift();

  let alumniPledges: number[] = [];
  profitAndLoss.Reports[0].Rows.find((sectionRow) => sectionRow.RowType === XeroRowType.SECTION)
    ?.Rows.find((row) => row.Cells[0].Value === "Alumni Pledge")
    ?.Cells?.map((c) => alumniPledges.push(Number(c.Value)));
  alumniPledges.shift();

  let staffPledges: number[] = [];
  profitAndLoss.Reports[0].Rows.find((sectionRow) => sectionRow.RowType === XeroRowType.SECTION)
    ?.Rows.find((row) => row.Cells[0].Value === "Staff Pledge")
    ?.Cells?.map((c) => staffPledges.push(Number(c.Value)));
  staffPledges.shift();

  dates.forEach((date, i) => {
    pledges.periods.push({
      date,
      alumniPledge: alumniPledges[i],
      staffPledge: staffPledges[i],
    });
  });
  pledges.periods = pledges.periods.reverse();
  return { periods: pledges.periods };
};
