import { Grid } from "@mui/material";
import { PromotionRequest } from "../../models/PromotionRequest";
import { PromotionRequestsInterface } from "./PromotionRequests";
import { PromotionRequestsComponent } from "./PromotionRequestsComponent";
import { promotionRequestCompleted, promotionRequestDeclined } from "../../services/promotionRequests";

export const PromotionRequestsMyList = ({
  name,
  requests,
  state,
  setState,
}: {
  name: string;
  requests: PromotionRequest[];
  state: PromotionRequestsInterface;
  setState: (state: PromotionRequestsInterface) => void;
}) => {
  const { myRequests, staff } = state;

  return (
    <Grid container>
      {requests?.map((dr, i) => {
        return promotionRequestDeclined(dr) || promotionRequestCompleted(dr) ? (
          <PromotionRequestsComponent key={i} staff={staff} promotionRequest={dr} />
        ) : (
          <PromotionRequestsComponent
            key={i}
            staff={staff}
            handleCommentSubmit={(alertState, comment) =>
              setState({
                ...state,
                alert: alertState,
                myRequests: myRequests.map((r) => {
                  if (r.id === dr.id && comment) {
                    return {
                      ...r,
                      commentSection: [...(r.commentSection ?? []), comment],
                    };
                  }
                  return r;
                }),
              })
            }
            handleCommentDelete={(alertState, comment_id) =>
              setState({
                ...state,
                alert: alertState,
                myRequests: myRequests.map((r) => {
                  if (r.id === dr.id) {
                    return {
                      ...r,
                      commentSection: (r.commentSection ?? []).filter((c) => c.id !== comment_id),
                    };
                  }
                  return r;
                }),
              })
            }
            promotionRequest={dr}
            cancelPromotionRequest={() =>
              setState({
                ...state,
                cancelRequestDialogOpen: true,
                currRequest: dr,
                myRequestsName: name,
                myRequestsRequests: requests,
              })
            }
          />
        );
      })}
    </Grid>
  );
};
