import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { TypographyBold } from "../../../components/TypographyBold";
import { ColoursCampuses } from "../../../data/colours";
import {
  ReviewGroup,
  reviewGroups,
  ReviewHR,
  ReviewsData,
  ReviewStatus,
  YearAndTerm,
} from "../../../models/Review360Model";
import { User } from "../../../models/User";
import { getOtherWithEmail, updateReviewer } from "../../../services/review360";
import { DialogInterface } from "./Review360HR";

export const Review360HRReviewee = ({
  i,
  reviewsData,
  cannotRemove,
  fullyDisabled,
  currentYearAndTerm,
  review,
  reviewsHR,
  deleteReviewee,
  setReviewsHR,
  setDialog,
  users,
  revieweeDeleteButton,
  fromUser,
}: {
  i: number;
  reviewsData: ReviewsData;
  cannotRemove?: boolean;
  fullyDisabled?: boolean;
  currentYearAndTerm: YearAndTerm;
  review: ReviewHR;
  reviewsHR: ReviewHR[];
  setReviewsHR: (reviewsHR: ReviewHR[]) => void;
  deleteReviewee?: (reviewee: User) => void;
  setDialog?: (dialog: DialogInterface) => void;
  users: User[];
  revieweeDeleteButton: boolean;
  fromUser?: boolean;
}) => {
  const isOptionDisabled = (option: User, group: ReviewGroup, review: ReviewHR): boolean => {
    const otherGroupReviewers = Object.entries(review.reviewers)
      .filter(([g, _]) => g !== group)
      .reduce((acc, [_, reviewers]) => acc.concat(reviewers), [] as User[])
      .map((u) => u.id);
    return (
      option.id === review.reviewee.id ||
      (group === "Mentor" && review.reviewers[group].length === 1) ||
      otherGroupReviewers.includes(option.id)
    );
  };
  const [addNonUser, setAddNonUser] = useState<string>("");
  const [addNonUserGroup, setAddNonUserGroup] = useState<ReviewGroup>("Below");
  const theme = useTheme();

  return (
    <Card>
      <CardHeader
        title={
          <Grid container justifyContent="space-between">
            <TypographyBold
              sx={{
                color: fromUser
                  ? "black"
                  : reviewsData.reviewsInfo.status === ReviewStatus.IN_PROGRESS
                  ? review.reviewee.submitted
                    ? ColoursCampuses.UNSW
                    : ColoursCampuses.USYD
                  : reviewsData.reviewsInfo.status === ReviewStatus.SETTING_UP
                  ? review.reviewee.reviewersFinalised
                    ? ColoursCampuses.UNSW
                    : ColoursCampuses.USYD
                  : "black",
              }}
            >
              {review.reviewee.firstName} {review.reviewee.lastName}'s Review
            </TypographyBold>
            {!cannotRemove && revieweeDeleteButton && (
              <Link
                component="button"
                color="error"
                onClick={() =>
                  setDialog
                    ? setDialog({
                        open: true,
                        heading: "Delete review?",
                        content: "This will delete the review",
                        action: "Delete",
                        doAction: () => (deleteReviewee ? deleteReviewee(review.reviewee) : null),
                      })
                    : null
                }
              >
                Delete reviewee
              </Link>
            )}
          </Grid>
        }
      />
      <CardContent>
        <Typography>Reviewers</Typography>
        {Object.entries(review.reviewers).map(([group, reviewers], j) => {
          return (
            <Autocomplete
              key={j}
              disabled={fullyDisabled}
              id={`${review.reviewee.id}-${group}`}
              multiple
              getOptionLabel={(option) =>
                option.id === option.email
                  ? `${option.id} - ${option.role}`
                  : `${option.firstName} ${option.lastName} - ${option.role}`
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionDisabled={(option) => isOptionDisabled(option, group as ReviewGroup, review)}
              renderTags={(value: readonly User[], getTagProps) =>
                value.map((option: User, index: number) => (
                  <Chip
                    variant="outlined"
                    sx={{
                      color:
                        reviewsData.reviewsInfo.status === ReviewStatus.IN_PROGRESS
                          ? review.reviewers[group as ReviewGroup].find((el) => el.id === option.id)?.submitted
                            ? ColoursCampuses.UNSW
                            : ColoursCampuses.USYD
                          : "black",
                    }}
                    label={`${option.firstName} ${option.lastName} - ${option.role}`}
                    {...getTagProps({ index })}
                    disabled={cannotRemove}
                  />
                ))
              }
              value={reviewers}
              onChange={(event, newValue: User[]) => {
                if (!cannotRemove || review.reviewers[group as ReviewGroup].length < newValue.length) {
                  review.reviewers[group as ReviewGroup] = newValue;
                  updateReviewer(
                    currentYearAndTerm,
                    review.reviewee.id,
                    group as ReviewGroup,
                    newValue,
                    reviewers
                  ).then(() => setReviewsHR([...reviewsHR.slice(0, i), review, ...reviewsHR.slice(i + 1)]));
                }
              }}
              options={users}
              sx={{
                width: 300,
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label={group} placeholder={`Select ${group}`} />
              )}
            />
          );
        })}
        <TypographyBold sx={{ mt: theme.spacing(2) }}>Add other email</TypographyBold>
        <TextField
          id="add-non-user"
          required
          fullWidth
          disabled={fullyDisabled}
          placeholder="Other email"
          onChange={(event) => setAddNonUser(event.target.value)}
          value={addNonUser}
          variant="standard"
        />
        <Select
          required
          fullWidth
          disabled={fullyDisabled}
          placeholder="Reviewer group"
          label="Select reviewer group"
          labelId="reviewerGroup-label"
          id="reviewerGroup"
          onChange={(event) => setAddNonUserGroup(event.target.value as ReviewGroup)}
          name="reviewerGroup"
          value={addNonUserGroup}
          variant="outlined"
        >
          {reviewGroups.map((rg, i) => (
            <MenuItem key={i} value={rg} disabled={rg === "Mentor"}>
              {rg}
            </MenuItem>
          ))}
        </Select>
        <Button
          variant="contained"
          disabled={
            addNonUser === "" ||
            !addNonUser.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) ||
            Object.values(review.reviewers).some((group) => group.some((u) => u.email === addNonUser)) ||
            users.some((u) => u.email === addNonUser)
          }
          onClick={() =>
            updateReviewer(
              currentYearAndTerm,
              review.reviewee.id,
              addNonUserGroup,
              [...review.reviewers[addNonUserGroup], getOtherWithEmail(addNonUser)],
              review.reviewers[addNonUserGroup]
            ).then(() => {
              review.reviewers[addNonUserGroup] = [...review.reviewers[addNonUserGroup], getOtherWithEmail(addNonUser)];
              setReviewsHR([...reviewsHR.slice(0, i), review, ...reviewsHR.slice(i + 1)]);
              setAddNonUser("");
            })
          }
        >
          Add Email
        </Button>
      </CardContent>
    </Card>
  );
};
