import { IconButton, IconButtonProps } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";
import { useTheme } from "@mui/styles";

export const NavBarIconButton = (props: IconButtonProps) => {
  const theme = useTheme();
  return (
    <IconButton
      sx={{
        color: theme.palette.secondary.main,
        "&$buttonDisabled": {
          color: theme.palette.grey[600],
        },
      }}
      {...props}
      size="large"
    />
  );
};

export const NavBarIconButtonLink = (props: IconButtonProps & LinkProps) => {
  const theme = useTheme();
  return (
    <IconButton
      component={Link}
      sx={{
        color: theme.palette.secondary.main,
        "&$buttonDisabled": {
          color: theme.palette.grey[600],
        },
      }}
      {...props}
      size="large"
    />
  );
};
