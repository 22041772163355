import {
  Box,
  Button,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FinanceSubFormEntry, FinanceSubFormModel } from "../../../models/EventFormModel";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

export const SectionTable = ({
  canEdit,
  entries,
  expenseOrRevenue,
  heading,
  section,
  subForm,
  setSubForm,
  setSubFormProperty,
  sectionTotal,
}: {
  canEdit: boolean;
  entries: FinanceSubFormEntry[];
  expenseOrRevenue: string;
  heading: string;
  section: keyof FinanceSubFormModel;
  subForm: FinanceSubFormModel;
  setSubForm: (subForm: FinanceSubFormModel) => void;
  setSubFormProperty: (property: keyof FinanceSubFormModel, value: any) => void;
  sectionTotal: keyof FinanceSubFormModel;
}) => {
  const setEntryValueCalculation = (key: keyof FinanceSubFormEntry, value: number, i: number) => {
    entries =
      key === "unitPrice"
        ? [
            ...entries.slice(0, i),
            { ...entries[i], [key]: value, total: entries[i].quantity && value ? value * entries[i].quantity : 0 },
            ...entries.slice(i + 1),
          ]
        : key === "quantity"
        ? [
            ...entries.slice(0, i),
            { ...entries[i], [key]: value, total: entries[i].unitPrice && value ? value * entries[i].unitPrice : 0 },
            ...entries.slice(i + 1),
          ]
        : key === "total"
        ? [...entries.slice(0, i), ...entries.slice(i + 1)]
        : entries;
    let secTotal = getSectionTotal(entries);
    setSubForm({
      ...subForm,
      [section]: entries,
      [sectionTotal]: secTotal,
      total:
        sectionTotal === "salesRevsTotal"
          ? subForm.total - (subForm[sectionTotal] as number) + secTotal
          : subForm.total + (subForm[sectionTotal] as number) - secTotal,
    });
  };

  const setEntryValue = (key: keyof FinanceSubFormEntry, value: any, i: number) =>
    setSubFormProperty(section, [...entries.slice(0, i), { ...entries[i], [key]: value }, ...entries.slice(i + 1)]);

  const getSectionTotal = (rows: FinanceSubFormEntry[]) => {
    let sum = 0;
    for (const entry of rows) {
      sum += entry.total;
    }
    return sum;
  };

  return (
    <>
      <Typography>{heading}</Typography>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 250 }} padding="none">
                {expenseOrRevenue} Type
              </TableCell>
              <TableCell style={{ minWidth: 82 }} align="left" padding="none">
                Unit Price
              </TableCell>
              <TableCell style={{ minWidth: 65 }} align="left" padding="none">
                Quantity
              </TableCell>
              <TableCell style={{ minWidth: 80 }} align="left">
                Total
              </TableCell>
              <TableCell style={{ minWidth: 590 }} align="left" padding="none">
                Justification for proposed amount
              </TableCell>
              <TableCell align="left" padding="none"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map((entry, i) => (
              <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" padding="none">
                  <TextField
                    size="small"
                    disabled={!canEdit}
                    onChange={(e) => setEntryValue("type", e.target.value, i)}
                    value={entry.type}
                    variant="outlined"
                    fullWidth
                  />
                </TableCell>
                <TableCell align="right" padding="none">
                  <TextField
                    size="small"
                    disabled={!canEdit}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    type="number"
                    onChange={(e) => setEntryValueCalculation("unitPrice", Number(e.target.value), i)}
                    value={entry.unitPrice}
                    variant="outlined"
                    fullWidth
                  />
                </TableCell>
                <TableCell align="right" padding="none">
                  <TextField
                    size="small"
                    disabled={!canEdit}
                    type="number"
                    onChange={(e) => {
                      setEntryValueCalculation("quantity", Number(e.target.value), i);
                    }}
                    value={entry.quantity}
                    variant="outlined"
                    fullWidth
                  />
                </TableCell>
                <TableCell align="right" padding="none">
                  <TextField
                    size="small"
                    disabled={true}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    type="number"
                    value={entry.total}
                    variant="outlined"
                    fullWidth
                  />
                </TableCell>
                <TableCell align="right" padding="none">
                  <TextField
                    size="small"
                    disabled={!canEdit}
                    onChange={(e) => setEntryValue("justification", e.target.value, i)}
                    value={entry.justification}
                    variant="outlined"
                    fullWidth
                  />
                </TableCell>
                <TableCell padding="none">
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    startIcon={<DeleteIcon />}
                    onClick={() => setEntryValueCalculation("total", 0, i)}
                    disabled={!canEdit}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell align="right" padding="none">
                Section total:
              </TableCell>
              <TableCell padding="none">
                <TextField
                  size="small"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  disabled={true}
                  value={subForm[sectionTotal]}
                  variant="outlined"
                  fullWidth
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() =>
          setSubFormProperty(
            section,
            entries.concat({
              type: "",
              unitPrice: 0,
              quantity: 0,
              total: 0,
              justification: "",
            })
          )
        }
        disabled={!canEdit}
      >
        Add {expenseOrRevenue}
      </Button>
      <Box my={4} />
    </>
  );
};
