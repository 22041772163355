import { Container } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import {
  getHeadsSize,
  getLeadersSize,
  getRetentionRates,
  getSurveyResponses,
  getSurveyResponsesByType,
  NumberOfHeadsByYear,
  NumberOfLeadersByYear,
  RetentionByGroup,
  RatingsOfLeadersByYear,
  attendanceData,
} from "../../services/metrics";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  registerables as registerablesJS,
  Title,
  Tooltip,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { blue, cyan, green, grey, orange, purple, red, yellow } from "@mui/material/colors";
import { atLeast } from "../../helpers/Role";
import { RolesContext } from "../../components/PrivateRoute";
import {
  CAMPUS_CHAPLAIN,
  DEPARTMENT_OVERSEER,
  DIRECTOR,
  EXECUTIVE,
  INTERN_CHAPLAIN,
  JUNIOR_CHAPLAIN,
  OUTSOURCE,
  SENIOR_CHAPLAIN,
  STAFF,
  STUDENT_LEADER,
} from "../../data/Roles";
import { rolesStartYear, yearsSinceStartYearUntilYear } from "../../helpers/FormatDate";

interface MetricsInterface {
  numberOfLeadersByYear: NumberOfLeadersByYear[];
  numberOfHeadsByYear: NumberOfHeadsByYear[];
  retentionRates: RetentionByGroup;
  overallSatisfactionRatings: RatingsOfLeadersByYear[];
  staffSatisfactionRatings: RatingsOfLeadersByYear[];
  studentLeadersSatisfactionRatings: RatingsOfLeadersByYear[];
  loading: boolean;
}

export const Metrics = () => {
  const roles = useContext(RolesContext);
  const [state, setState] = useState<MetricsInterface>({
    numberOfLeadersByYear: [],
    numberOfHeadsByYear: [],
    retentionRates: {
      "All Leaders": [],
      "Campus Leaders": [],
      Staff: [],
    },
    overallSatisfactionRatings: [],
    staffSatisfactionRatings: [],
    studentLeadersSatisfactionRatings: [],
    loading: true,
  });

  const {
    loading,
    numberOfLeadersByYear,
    numberOfHeadsByYear,
    retentionRates,
    overallSatisfactionRatings,
    staffSatisfactionRatings,
    studentLeadersSatisfactionRatings,
  } = state;

  useEffect(() => {
    getLeadersSize().then((numberOfLeadersByYear) =>
      getHeadsSize().then((numberOfHeadsByYear) =>
        getRetentionRates().then((retentionRates) =>
          getSurveyResponses().then((overallSatisfactionRatings) =>
            getSurveyResponsesByType("Staff").then((staffSatisfactionRatings) =>
              getSurveyResponsesByType("Student Leader").then((studentLeadersSatisfactionRatings) =>
                setState({
                  ...state,
                  numberOfLeadersByYear,
                  numberOfHeadsByYear,
                  retentionRates,
                  overallSatisfactionRatings,
                  staffSatisfactionRatings,
                  studentLeadersSatisfactionRatings,
                  loading: false,
                })
              )
            )
          )
        )
      )
    );

    // eslint-disable-next-line
  }, []);

  ChartJS.register(...registerablesJS);
  ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

  const options = (text: string) => ({
    scales: {
      y: {
        min: 0,
      },
    },
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    spanGaps: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text,
      },
    },
  });

  const dataAll = {
    labels: numberOfLeadersByYear.map((a) => a.year),
    datasets: [
      {
        type: "line" as const,
        label: "All Leaders",
        data: numberOfLeadersByYear.map((a) => Object.values(a.roles).reduce((acc, el) => acc + el, 0)),
        borderColor: grey[700],
        backgroundColor: grey[400],
      },
    ],
  };

  const dataOverallSatsifactionRatings = {
    labels: overallSatisfactionRatings.map((a) => a.year),
    datasets: [
      {
        type: "line" as const,
        label: "All Leaders",
        data: overallSatisfactionRatings.map((a) => a.satisfactionRating),
        borderColor: grey[700],
        backgroundColor: grey[400],
      },
      {
        type: "line" as const,
        label: "Staff",
        data: staffSatisfactionRatings.map((a) => a.satisfactionRating),
        borderColor: purple[700],
        backgroundColor: purple[400],
      },
      {
        type: "line" as const,
        label: "Student Leader",
        data: studentLeadersSatisfactionRatings.map((a) => a.satisfactionRating),
        borderColor: blue[700],
        backgroundColor: blue[400],
      },
    ],
  };

  const dataStaff = {
    labels: numberOfLeadersByYear.map((a) => a.year),
    datasets: [
      {
        type: "line" as const,
        label: OUTSOURCE,
        data: numberOfLeadersByYear.map((a) =>
          Object.entries(a.roles)
            .filter(([role, _]) => role === OUTSOURCE)
            .reduce((acc, [_, num]) => acc + num, 0)
        ),
        borderColor: orange[700],
        backgroundColor: orange[400],
      },
      {
        type: "line" as const,
        label: "Chaplains",
        data: numberOfLeadersByYear.map((a) =>
          Object.entries(a.roles)
            .filter(
              ([role, _]) =>
                role === CAMPUS_CHAPLAIN ||
                role === JUNIOR_CHAPLAIN ||
                role === SENIOR_CHAPLAIN ||
                role === INTERN_CHAPLAIN ||
                role === DIRECTOR ||
                role === "Interim Director"
            )
            .reduce((acc, [_, num]) => acc + num, 0)
        ),
        borderColor: cyan[700],
        backgroundColor: cyan[400],
      },
    ],
  };

  const dataDepartments = {
    labels: numberOfLeadersByYear.map((a) => a.year),
    datasets: [
      {
        type: "line" as const,
        label: "Departments",
        data: yearsSinceStartYearUntilYear(rolesStartYear - 2)
          .map(() => 0)
          .concat(numberOfHeadsByYear.map((h) => h.departments)),
        borderColor: red[700],
        backgroundColor: red[400],
      },
      {
        type: "line" as const,
        label: "Divisions",
        data: yearsSinceStartYearUntilYear(rolesStartYear - 2)
          .map(() => 0)
          .concat(numberOfHeadsByYear.map((h) => h.divisions)),
        borderColor: yellow[700],
        backgroundColor: yellow[400],
      },
    ],
  };

  const dataIndividual = {
    labels: numberOfLeadersByYear.map((a) => a.year),
    datasets: [
      {
        type: "line" as const,
        label: "Campus Leaders",
        data: numberOfLeadersByYear.map((a) =>
          Object.entries(a.roles)
            .filter(([role, _]) => !atLeast(roles, STAFF, role))
            .reduce((acc, [_, num]) => acc + num, 0)
        ),
        borderColor: orange[700],
        backgroundColor: orange[400],
      },
      {
        type: "line" as const,
        label: "Staff",
        data: numberOfLeadersByYear.map((a) =>
          Object.entries(a.roles)
            .filter(
              ([role, _]) =>
                atLeast(roles, STAFF, role) ||
                role === CAMPUS_CHAPLAIN ||
                role === DEPARTMENT_OVERSEER ||
                role === "Interim Director"
            )
            .reduce((acc, [_, num]) => acc + num, 0)
        ),
        borderColor: purple[700],
        backgroundColor: purple[400],
      },
    ],
  };

  const dataCampus = {
    labels: numberOfLeadersByYear.map((a) => a.year),
    datasets: [
      {
        type: "line" as const,
        label: STUDENT_LEADER,
        data: numberOfLeadersByYear.map((a) =>
          Object.entries(a.roles)
            .filter(([role, _]) => role === STUDENT_LEADER)
            .reduce((acc, [_, num]) => acc + num, 0)
        ),
        borderColor: blue[700],
        backgroundColor: blue[400],
      },
      {
        type: "line" as const,
        label: EXECUTIVE,
        data: numberOfLeadersByYear.map((a) =>
          Object.entries(a.roles)
            .filter(([role, _]) => atLeast(roles, EXECUTIVE, role) && !atLeast(roles, STAFF, role))
            .reduce((acc, [_, num]) => acc + num, 0)
        ),
        borderColor: red[700],
        backgroundColor: red[400],
      },
    ],
  };

  const dataMetric2 = {
    labels: attendanceData.map((yearData) => yearData.year),
    datasets: [
      {
        type: "line" as const,
        label: "Campus Leaders",
        data: attendanceData.map((yearData) => {
          const leaderDataForYear = numberOfLeadersByYear.find(
            (leaderData) => leaderData.year === yearData.year
          );
          return leaderDataForYear
            ? Object.entries(leaderDataForYear.roles)
                .filter(([role, _]) => !atLeast(roles, STAFF, role))
                .reduce((acc, [_, num]) => acc + num, 0)
            : 0;
        }),
        borderColor: purple[700],
        backgroundColor: purple[400],
      },
      ...attendanceData[0].campusAttendanceData.map((_, campusIndex) => ({
        type: "bar" as const,
        label: attendanceData[0].campusAttendanceData[campusIndex].campusName,
        data: attendanceData.map(
          (yearData) =>
            yearData.campusAttendanceData[campusIndex]?.averageAttendance || 0
        ),
        backgroundColor: [blue[600], green[600], red[600], yellow[600]][campusIndex],
        stack: 'Stack 0',
      })),
    ],
  };

  const dataPercentOutgoing = {
    labels: numberOfLeadersByYear.map((a) => a.year),
    datasets: [
      {
        type: "line" as const,
        label: "All Leaders",
        data: retentionRates["All Leaders"].map((a) => a.percentage),
        borderColor: grey[700],
        backgroundColor: grey[400],
      },
      {
        type: "line" as const,
        label: "Campus Leaders",
        data: retentionRates["Campus Leaders"].map((a) => a.percentage),
        borderColor: orange[700],
        backgroundColor: orange[400],
      },
      {
        type: "line" as const,
        label: "Staff",
        data: retentionRates["Staff"].map((a) => a.percentage),
        borderColor: purple[700],
        backgroundColor: purple[400],
      },
    ],
  };

  const dataAverageOutgoing = {
    labels: numberOfLeadersByYear.map((a) => a.year),
    datasets: [
      {
        type: "line" as const,
        label: "All Leaders",
        data: retentionRates["All Leaders"].map((a) => a.average),
        borderColor: grey[700],
        backgroundColor: grey[400],
      },
      {
        type: "line" as const,
        label: "Campus Leaders",
        data: retentionRates["Campus Leaders"].map((a) => a.average),
        borderColor: orange[700],
        backgroundColor: orange[400],
      },
      {
        type: "line" as const,
        label: "Staff",
        data: retentionRates["Staff"].map((a) => a.average),
        borderColor: purple[700],
        backgroundColor: purple[400],
      },
    ],
  };

  const numberOfLeadersOptions = options("All Leaders");
  const numberOfStaffOptions = options("Staff");
  const numberOfDepartmentsOptions = options("Departments and Divisions");
  const campusVsStaff = options("Campus | Staff");
  const leadersVsExecs = options("Exec | SL");
  const numberOfLeaders = options("M2 - Leaders Growth")
  const percentageServedTwoYears = options("Percentage of Outgoing Staff and Leaders that served at least 2 years");
  const averageYearsServed = options("Average Years Served by Outgoing Staff and Leaders");
  const satsifactionRatingsOptions = options("Satisfaction Ratings of Leaders (%)");

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container maxWidth="lg">
        <Chart type="line" options={numberOfLeadersOptions} data={dataAll} />
        <Chart type="line" options={campusVsStaff} data={dataIndividual} />
        <Chart type="line" options={leadersVsExecs} data={dataCampus} />
        <Chart type="line" options={numberOfStaffOptions} data={dataStaff} />
        <Chart type="line" options={numberOfDepartmentsOptions} data={dataDepartments} />
        <Chart type="bar"  options={numberOfLeaders} data={dataMetric2} />
        <Chart type="line" options={percentageServedTwoYears} data={dataPercentOutgoing} />
        <Chart type="line" options={averageYearsServed} data={dataAverageOutgoing} />
        <Chart type="line" options={satsifactionRatingsOptions} data={dataOverallSatsifactionRatings} />
      </Container>
    </>
  );
};
