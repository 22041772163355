import { useContext, useEffect, useState } from "react";
import { Alert, Button, Container, Grid, Link, Snackbar, Typography } from "@mui/material";
import { Link as ReactRouterLink } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { MessageState } from "../../models/MessageState";
import { Loading } from "../../components/Loading";
import { Staff } from "../../models/Staff";
import { DesignRequest } from "../../models/DesignRequest";
import { DesignRequestsSubmit } from "./DesignRequestsSubmit";
import {
  cancelDesignRequest,
  getAllDesignRequests,
  designRequestApproved,
  designRequestDeclined,
  designRequestPending,
  designRequestCompleted,
} from "../../services/designRequests";
import { DesignRequestsMyList } from "./DesignRequestsMyList";
import { DesignRequestsList } from "./DesignRequestsList";
import { DepartmentsContext, UserContext } from "../../components/PrivateRoute";
import { pastStaffYearsSince2022 } from "../../helpers/FormatDate";
import { Path } from "../../helpers/Path";
import { DATA_AND_IT } from "../../data/Departments";
import { ConfirmationModal } from "../../components/ConfirmationModal";

export interface DesignRequestsInterface {
  postDialogOpen: boolean;
  cancelRequestDialogOpen: boolean;
  reason: string;
  declineDialogOpen: boolean;
  currRequest?: DesignRequest;
  loading: boolean;
  alert: MessageState;
  myRequests: DesignRequest[];
  myRequestsApproved: DesignRequest[];
  myrequestsCompletedOrDeclined: DesignRequest[];
  requestsToApproveHOD: DesignRequest[];
  requestsApprovedHOD: DesignRequest[];
  requestsCompletedOrDeclinedHOD: DesignRequest[];
  completeDialogOpen: boolean;
  myRequestsName?: string;
  myRequestsRequests?: DesignRequest[];
  staff: Staff;
}

export const DesignRequests = () => {
  const user = useContext(UserContext);
  const departments = useContext(DepartmentsContext);
  const [state, setState] = useState<DesignRequestsInterface>({
    postDialogOpen: false,
    cancelRequestDialogOpen: false,
    reason: "",
    declineDialogOpen: false,
    loading: true,
    alert: {
      showMessage: false,
      message: "",
      severity: "success",
    },
    myRequests: [],
    myRequestsApproved: [],
    myrequestsCompletedOrDeclined: [],
    requestsToApproveHOD: [],
    requestsApprovedHOD: [],
    requestsCompletedOrDeclinedHOD: [],
    completeDialogOpen: false,
    staff: user as Staff,
  });

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });

    getAllDesignRequests(staff, departments).then((allRequests) =>
      Promise.all(allRequests).then(([myRequests, allRequests]) =>
        setState({
          ...state,
          staff: staff,
          myRequests: myRequests.filter((dr) => designRequestPending(dr)),
          myRequestsApproved: myRequests.filter((dr) => designRequestApproved(dr) && !designRequestCompleted(dr)),
          myrequestsCompletedOrDeclined: myRequests.filter(
            (dr) => designRequestCompleted(dr) || designRequestDeclined(dr)
          ),
          requestsToApproveHOD: allRequests.filter((dr) => designRequestPending(dr)),
          requestsApprovedHOD: allRequests.filter((dr) => designRequestApproved(dr) && !designRequestCompleted(dr)),
          requestsCompletedOrDeclinedHOD: allRequests.filter(
            (dr) => designRequestCompleted(dr) || designRequestDeclined(dr)
          ),
          loading: false,
        })
      )
    );
    // eslint-disable-next-line
  }, []);

  const theme = useTheme();

  const {
    postDialogOpen,
    loading,
    alert,
    myRequests,
    myRequestsApproved,
    myrequestsCompletedOrDeclined,
    staff,
    currRequest,
    requestsToApproveHOD,
    requestsApprovedHOD,
    requestsCompletedOrDeclinedHOD,
    cancelRequestDialogOpen,
    myRequestsName,
    myRequestsRequests,
  } = state;

  return loading ? (
    <Loading />
  ) : (
    <>
      <Snackbar
        open={alert.showMessage}
        onClose={() =>
          setState({
            ...state,
            alert: { ...alert, showMessage: false },
          })
        }
        autoHideDuration={6000}
      >
        <Alert elevation={6} variant="filled" severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Container maxWidth="lg">
        <DesignRequestsSubmit
          staff={staff}
          dialogOpen={postDialogOpen}
          closeDialog={() => setState({ ...state, postDialogOpen: false })}
          handlePost={(alertState, designRequest) =>
            setState({
              ...state,
              postDialogOpen: false,
              alert: alertState,
              myRequests: designRequest ? [...myRequests, designRequest] : myRequests,
            })
          }
        />
        {currRequest && (
          <ConfirmationModal
            title={"Cancel Design Request?"}
            content={"This will cancel the design request."}
            confirmText={"Yes"}
            cancelText={"No"}
            dialogOpen={cancelRequestDialogOpen}
            closeDialog={() => setState({ ...state, cancelRequestDialogOpen: false })}
            doAction={() =>
              cancelDesignRequest(currRequest as DesignRequest)
                .then((cancelledRequest) => {
                  setState({
                    ...state,
                    [`myRequests${myRequestsName}`]: myRequestsRequests?.filter(
                      (obj) => obj.id !== cancelledRequest.id
                    ),
                    alert: {
                      showMessage: true,
                      message: "Successfully cancelled Design Request!",
                      severity: "success",
                    },
                    cancelRequestDialogOpen: false,
                    currRequest: undefined,
                  });
                })
                .catch((error) =>
                  setState({
                    ...state,
                    alert: {
                      showMessage: true,
                      message: error.message,
                      severity: "error",
                    },
                    cancelRequestDialogOpen: false,
                  })
                )
            }
          />
        )}
        <Grid container spacing={2} justifyItems="space-between">
          <Grid item>
            <Typography variant="h5">Design Requests</Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => setState({ ...state, postDialogOpen: true })}>
              Make Design Request
            </Button>
          </Grid>
        </Grid>
        <Typography
          variant="h6"
          sx={{
            mt: theme.spacing(2),
          }}
        >
          Your Design Requests
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={12} md={4}>
            <Typography
              sx={{
                color: theme.palette.warning.light,
                fontWeight: "bold",
              }}
            >
              Current Design Requests
            </Typography>
            <DesignRequestsMyList name={""} state={state} setState={setState} requests={myRequests} />
          </Grid>
          <Grid item sm={12} md={4}>
            <Typography
              sx={{
                color: theme.palette.success.light,
                fontWeight: "bold",
              }}
            >
              Approved
            </Typography>
            <DesignRequestsMyList name={"Approved"} state={state} setState={setState} requests={myRequestsApproved} />
          </Grid>
          <Grid item sm={12} md={4}>
            <Typography
              sx={{
                color: theme.palette.success.light,
                fontWeight: "bold",
              }}
              display="inline"
            >
              Completed
            </Typography>
            <Typography display="inline"> / </Typography>
            <Typography
              sx={{
                color: theme.palette.error.light,
                fontWeight: "bold",
              }}
              display="inline"
            >
              Declined
            </Typography>
            <DesignRequestsMyList
              name={"CompletedOrDeclined"}
              state={state}
              setState={setState}
              requests={myrequestsCompletedOrDeclined}
            />
          </Grid>
        </Grid>
        {(staff.department === departments.MARKETING || staff.department === DATA_AND_IT) && (
          <DesignRequestsList
            name={"HOD"}
            requestsToApprove={requestsToApproveHOD}
            requestsApproved={requestsApprovedHOD}
            requestsCompletedOrDeclined={requestsCompletedOrDeclinedHOD}
            state={state}
            setState={setState}
          />
        )}
        {(staff.department === departments.MARKETING || staff.department === DATA_AND_IT) && (
          <Typography>History</Typography>
        )}
        {(staff.department === departments.MARKETING || staff.department === DATA_AND_IT) && (
          <Grid container direction="column">
            {pastStaffYearsSince2022.map((y, i) => (
              <Grid item key={i}>
                <Link to={Path["Past Year's Design Requests"] + "/" + y} component={ReactRouterLink}>
                  {y}
                </Link>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </>
  );
};
