import * as yup from "yup";
import { Dialog } from "@mui/material";
import { DesignRequestDeclined } from "../../models/DesignRequestDeclined";
import { DesignRequest } from "../../models/DesignRequest";
import { Formik, FormikProps } from "formik";
import { DesignRequestsDeclineForm } from "./DesignRequestsDeclineForm";

const DesignRequestsDeclineFormValidationSchema = yup.object().shape({
  reason: yup.string(),
});

export const DesignRequestsDeclineConfirmationDialog = ({
  currRequest,
  dialogOpen,
  closeDialog,
  declineRequest,
}: {
  currRequest: DesignRequest;
  dialogOpen: boolean;
  closeDialog: () => void;
  declineRequest: (request: DesignRequestDeclined) => void;
}) => (
  <Dialog open={dialogOpen} onClose={closeDialog} aria-describedby="alert-dialog-description">
    <Formik
      initialValues={{
        ...currRequest,
        reason: "",
      }}
      onSubmit={(values: DesignRequestDeclined) => declineRequest(values)}
      validationSchema={DesignRequestsDeclineFormValidationSchema}
    >
      {(props: FormikProps<DesignRequestDeclined>) => {
        return <DesignRequestsDeclineForm props={props} handleClose={closeDialog} />;
      }}
    </Formik>
  </Dialog>
);
