import { collection, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { currentStaffYear, nextStaffYear } from "../helpers/FormatDate";
import { DepartmentInterface, InitDepartment, PastDepartments } from "../models/Department";
import { User } from "../models/User";
import { db } from "./firebase";
import { getDirector, getUserByIdYear, updateFieldYear } from "./users";

interface DepartmentInterfaceFirestore {
  FINANCE: string;
  BUDGET_MANAGER: string;
  REGISTRATION_REVIEWER: string;
  MARKETING: string;
  EVENTS: string;
  departments: DepartmentsFirestore;
}

interface DepartmentsFirestore {
  [department: string]: {
    head: string;
    division: string;
    colour: string;
  };
}

const convertFromFirestore = async (departmentsFirestore: DepartmentInterfaceFirestore, year: string) => {
  if (!departmentsFirestore) {
    return InitDepartment;
  }
  let departments: DepartmentInterface = {
    ...departmentsFirestore,
    departments: {},
  };
  for (const [department, content] of Object.entries(departmentsFirestore.departments)) {
    if (!content.head) {
      departments.departments[department] = {
        division: "",
        head: {} as User,
        colour: "",
      };
    } else {
      departments.departments[department] = {
        ...content,
        head: await getUserByIdYear(content.head, year),
      };
    }
  }
  return { ...InitDepartment, ...departments };
};

const convertToFirestore = (departments: DepartmentInterface) => {
  let departmentsFirestore: DepartmentInterfaceFirestore = {
    ...departments,
    departments: {},
  };
  for (const [department, content] of Object.entries(departments.departments)) {
    departmentsFirestore.departments[department] = {
      ...content,
      head: content.head.id,
    };
  }
  return departmentsFirestore;
};

export const getStaffYearDepartmentsDoc = async (year: string): Promise<DepartmentInterfaceFirestore> =>
  (await getDoc(doc(db, "departments", year))).data() as DepartmentInterfaceFirestore;

export const getStaffYearDepartments = async (year: string, userYear?: string) =>
  convertFromFirestore(await getStaffYearDepartmentsDoc(year), userYear ? userYear : year);

export const getCurrentStaffYearDepartments = async (): Promise<DepartmentInterface> =>
  getStaffYearDepartments(currentStaffYear.toString());

export const getNextStaffYearDepartments = async (): Promise<DepartmentInterface> =>
  getStaffYearDepartments(nextStaffYear.toString(), currentStaffYear.toString());

export const getPastDepartments = async (): Promise<PastDepartments> => {
  const getDepartmentsCollection = await getDocs(collection(db, "departments"));

  let pastDepartments: PastDepartments = {};
  for (const doc of getDepartmentsCollection.docs) {
    if (Number(doc.id) < currentStaffYear) {
      pastDepartments[doc.id] = await convertFromFirestore(doc.data() as DepartmentInterfaceFirestore, doc.id);
    }
  }
  return pastDepartments;
};

export const updateDepartments = async (year: number, newDepartments: DepartmentInterface) => {
  for (const [dep, content] of Object.entries(newDepartments.departments)) {
    if (!dep || !content.division || !content.head) {
      throw new Error("One of the entries are empty!");
    }
  }
  for (const [dep, content] of Object.entries(newDepartments.departments)) {
    if ((await getDirector(currentStaffYear.toString())).id !== content.head.id) {
      await updateFieldYear(content.head.id, "role", "Head of Department", "Role", year.toString());
      await updateFieldYear(content.head.id, "department", dep, "Department", year.toString());
    }
  }

  return await updateDoc(doc(db, "departments", year.toString()), {
    ...convertToFirestore(newDepartments),
  });
};

export const setBudgetManagerYear = async (budgetManagerId: string, year: string) =>
  await updateDoc(doc(db, "departments", year), {
    BUDGET_MANAGER: budgetManagerId,
  });

export const setCurrentYearBudgetManager = (budgetManagerId: string) =>
  setBudgetManagerYear(budgetManagerId, currentStaffYear.toString());

export const setNextYearBudgetManager = (budgetManagerId: string) =>
  setBudgetManagerYear(budgetManagerId, nextStaffYear.toString());

export const setRegistrationReviewerYear = async (registrationReviewerId: string, year: string) =>
  await updateDoc(doc(db, "departments", year), {
    REGISTRATION_REVIEWER: registrationReviewerId,
  });

export const setCurrentYearBregistrationReviewer = (registrationReviewerId: string) =>
  setRegistrationReviewerYear(registrationReviewerId, currentStaffYear.toString());

export const setNextYearBregistrationReviewer = (registrationReviewerId: string) =>
  setRegistrationReviewerYear(registrationReviewerId, nextStaffYear.toString());
