import { Paper, Grid } from "@mui/material";
import { ViewState, EditingState, ChangeSet, ResourceInstance, AppointmentModel } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  Resources,
  MonthView,
  WeekView,
  DayView,
  Toolbar,
  DateNavigator,
  TodayButton,
  ViewSwitcher,
  Appointments,
  AllDayPanel,
  ConfirmationDialog,
  AppointmentTooltip,
  AppointmentForm,
  EditRecurrenceMenu,
  DragDropProvider,
} from "@devexpress/dx-react-scheduler-material-ui";
import { deleteFromCalendar, universitiesResource, updateCalendar, addCalendar } from "../services/calendar";
import { Loading } from "./Loading";
import { useTheme } from "@mui/styles";

export interface CalendarInterface {
  data?: AppointmentModel[];
  rolesResource?: ResourceInstance[];
  loading: boolean;
}

export const CalendarComponent = ({
  defaultCurrentViewName,
  startDayHour,
  endDayHour,
  height,
  state,
  setState,
  showToolbar,
  editEvent,
}: {
  defaultCurrentViewName: string;
  startDayHour: number;
  endDayHour: number;
  height: number | "auto";
  state: CalendarInterface;
  setState: (state: CalendarInterface) => void;
  showToolbar: boolean;
  editEvent?: boolean;
}) => {
  const commitChanges = async ({ added, changed, deleted }: ChangeSet) => {
    let data = state.data;
    if (data === undefined) {
      return;
    }
    if (added) {
      const startingAddedId = data.length > 0 ? Math.max(...data.map((d) => Number(d.id))) + 1 : 0;
      data = [
        ...data,
        {
          ...(added as AppointmentModel),
          id: startingAddedId,
          campus: added.campus ? added.campus : 1,
        },
      ];
      await addCalendar({
        ...(added as AppointmentModel),
        id: startingAddedId,
        campus: added.campus ? added.campus : 1,
      });
    }
    if (changed) {
      data = data.map((appointment) => {
        if (changed[Number(appointment.id)]) {
          if (changed[Number(appointment.id)].allDay === undefined) {
            delete changed[Number(appointment.id)].allDay;
          }
          return {
            ...appointment,
            ...changed[Number(appointment.id)],
          };
        } else {
          return appointment;
        }
      });
      await updateCalendar(changed);
    }
    if (deleted !== undefined) {
      data = data.filter((appointment) => appointment.id !== deleted);
      await deleteFromCalendar(deleted);
    }
    setState({ ...state, data: data });
  };

  const { data, rolesResource, loading } = state;

  const resources = [
    {
      fieldName: "campus",
      title: "Campus",
      instances: universitiesResource,
    },
    {
      fieldName: "roles",
      title: "For",
      instances: rolesResource !== undefined ? rolesResource : [],
      allowMultiple: true,
    },
  ];

  const theme = useTheme();

  return loading ? (
    <Grid container justifyContent="center">
      <Loading />
    </Grid>
  ) : (
    <Paper sx={{ borderRadius: theme.spacing(0.5) }}>
      <Scheduler data={data} firstDayOfWeek={1} height={height}>
        <ViewState defaultCurrentViewName={defaultCurrentViewName} />
        {editEvent && <EditingState onCommitChanges={commitChanges} />}
        {editEvent && <EditRecurrenceMenu />}
        <MonthView />
        <WeekView startDayHour={startDayHour} endDayHour={endDayHour} cellDuration={60} />
        <DayView startDayHour={startDayHour} endDayHour={endDayHour} cellDuration={60} />
        {showToolbar && <Toolbar />}
        {showToolbar && <DateNavigator />}
        {showToolbar && <TodayButton />}
        {showToolbar && <ViewSwitcher />}

        <Appointments />
        <AllDayPanel />
        {editEvent && <ConfirmationDialog ignoreCancel />}
        {editEvent && <AppointmentTooltip showOpenButton showDeleteButton />}
        {editEvent && <DragDropProvider />}
        {editEvent && <AppointmentForm />}
        <Resources data={resources} mainResourceName="campus" />
      </Scheduler>
    </Paper>
  );
};
