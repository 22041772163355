import { RequestStatus } from "../data/RequestStatus";
import { Staff } from "./Staff";

export interface Request {
  id?: string;
  userID: string;
  staff: Staff;
  department: string;
  description: string;
  amount: string;
  approvedByHOD: RequestStatus;
  hod?: Staff;
  approvedByFinance: RequestStatus;
  finance?: Staff;
  approvedByFinanceHead: RequestStatus;
  financeHead?: Staff;
  approvedByDirector?: RequestStatus;
  director?: Staff;
  submittedTime: Date;
  approvedTime?: Date;
  declinedTime?: Date;
  paidTime?: Date;
}

export interface RequestsModel {
  myRequests: RequestsCategories;
  hodRequests: RequestsCategories;
  budgetManagerRequests: RequestsCategories;
  financeHeadRequests: RequestsCategories;
  directorRequests: RequestsCategories;
}

export const InitRequestsCategories: RequestsCategories = {
  pending: [],
  approved: [],
  completed: [],
};

export const InitRequestsModel: RequestsModel = {
  myRequests: InitRequestsCategories,
  hodRequests: InitRequestsCategories,
  budgetManagerRequests: InitRequestsCategories,
  financeHeadRequests: InitRequestsCategories,
  directorRequests: InitRequestsCategories,
};

export interface RequestsCategories {
  pending: Request[];
  approved: Request[];
  completed: Request[];
}
