import { StepLabel, StepLabelProps, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { statusColours } from "../../services/eventForm";
import { SubFormStatus } from "../../models/EventFormModel";

const SubFormStatusComponent = ({
  status,
  isReviewer,
  variant,
}: {
  status?: SubFormStatus;
  isReviewer?: boolean;
  variant: Variant;
}) => (
  <Typography display="inline" variant={variant} color={status ? statusColours[status] : "gray"}>
    {status === SubFormStatus.APPROVED && "Approved"}
    {status === SubFormStatus.SUBMITTED && isReviewer && "Click to Review"}
    {status === SubFormStatus.SUBMITTED && !isReviewer && "Waiting for Review"}
    {status === SubFormStatus.REQUEST_FOR_CHANGE && isReviewer && "Waiting for Resubmission"}
    {status === SubFormStatus.REQUEST_FOR_CHANGE && !isReviewer && "Requested changes"}
    {!status && isReviewer && "Waiting for Submission"}
    {!status && !isReviewer && "Click to Submit"}
  </Typography>
);

export const EventStepLabel = (props: StepLabelProps & { status?: SubFormStatus; isReviewer: boolean }) => {
  const { status, isReviewer, ...propsWithoutArguments } = props;
  return (
    <StepLabel
      {...propsWithoutArguments}
      StepIconProps={{ style: { color: status ? statusColours[status] : "gray" } }}
      optional={<SubFormStatusComponent status={status} isReviewer={isReviewer} variant="caption" />}
      sx={{
        cursor: "pointer",
      }}
    />
  );
};
