import { TabContext, TabList } from "@mui/lab";
import { Box, Container, Link, Tab, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link as ReactRouterLink, useHistory, useParams } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { DepartmentsContext, UserContext } from "../../components/PrivateRoute";
import { Path } from "../../helpers/Path";
import { EventFormModel, TabPanelValue } from "../../models/EventFormModel";
import { Staff } from "../../models/Staff";
import { eventFormApproved, getEventFormsYear } from "../../services/eventForm";
import { EventFormDialog } from "./EventFormDialog";
import { EventPanel } from "./EventPanel";

interface PastYearsRequestsInterface {
  loading: boolean;
  completedEvents: EventFormModel[];
  inProgressEvents: EventFormModel[];
  currentTabPanel: string;
  page: number;
  rowsPerPage: number;
  staff?: Staff;
  selectedEventForm?: EventFormModel;
  eventFormDialogOpen: boolean;
}

export const PastYearsEvents = () => {
  const user = useContext(UserContext) as Staff;
  const departments = useContext(DepartmentsContext);
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<PastYearsRequestsInterface>({
    loading: true,
    completedEvents: [],
    inProgressEvents: [],
    currentTabPanel: TabPanelValue.COMPLETED,
    page: 0,
    rowsPerPage: 10,
    eventFormDialogOpen: false,
  });

  const { loading, page, inProgressEvents, completedEvents, rowsPerPage, eventFormDialogOpen, selectedEventForm } =
    state;

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setState({
      ...state,
      currentTabPanel: newValue,
    });
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setState({
      ...state,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      page: 0,
      rowsPerPage: +event.target.value,
    });
  };

  const navigateToStageForm = (id: string, formType: string, year: string) =>
    history.push(`${Path["Event Sub Form"]}/${year}/${formType}/${id}`);

  const history = useHistory();

  useEffect(() => {
    getEventFormsYear(id).then((forms) => {
      setState({
        ...state,
        completedEvents: forms.filter(eventFormApproved).sort((a, b) => (a.submittedTime < b.submittedTime ? 1 : -1)),
        inProgressEvents: forms
          .filter((form) => !eventFormApproved(form))
          .sort((a, b) => (a.submittedTime < b.submittedTime ? 1 : -1)),
        currentTabPanel: TabPanelValue.IN_PROGRESS,
        loading: false,
      });
    });
    // eslint-disable-next-line
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container maxWidth="lg">
        <Link to={Path["Event Forms"]} component={ReactRouterLink}>
          back
        </Link>
        <Typography align="center">Event Forms History - Year: {id}</Typography>
        <EventFormDialog
          eventForm={selectedEventForm}
          dialogOpen={eventFormDialogOpen}
          closeDialog={() => setState({ ...state, eventFormDialogOpen: false })}
        />
        <TabContext value={state.currentTabPanel}>
          <Box>
            <TabList onChange={handleChange} variant="fullWidth">
              <Tab
                label={TabPanelValue.IN_PROGRESS}
                value={TabPanelValue.IN_PROGRESS}
                key={TabPanelValue.IN_PROGRESS}
              />
              <Tab label={TabPanelValue.COMPLETED} value={TabPanelValue.COMPLETED} key={TabPanelValue.COMPLETED} />
            </TabList>
          </Box>
          <EventPanel
            openEventFormDialog={(selectedEventForm) =>
              setState({ ...state, selectedEventForm, eventFormDialogOpen: true })
            }
            tabPanelValue={TabPanelValue.IN_PROGRESS}
            events={inProgressEvents}
            page={page}
            rowsPerPage={rowsPerPage}
            navigateToStageForm={navigateToStageForm}
            year={id}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            user={user}
            departments={departments}
          />
          <EventPanel
            openEventFormDialog={(selectedEventForm) =>
              setState({ ...state, selectedEventForm, eventFormDialogOpen: true })
            }
            tabPanelValue={TabPanelValue.COMPLETED}
            events={completedEvents}
            page={page}
            rowsPerPage={rowsPerPage}
            navigateToStageForm={navigateToStageForm}
            year={id}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            user={user}
            departments={departments}
          />
        </TabContext>
      </Container>
    </>
  );
};
