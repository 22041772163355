import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { currentYear } from "../helpers/FormatDate";
import { User } from "../models/User";
import { db } from "./firebase";
import { getListOfUsersByIds, getUserById } from "./users";

export interface XeroBankTransactionsMetaData {
  BankTransactions: XeroBankTransaction[];
}

interface XeroBankTransaction {
  BankTransactionID: string;
  DateString: string;
  LineItems: XeroLineItem[];
  Contact: { Name: string };
}

interface XeroLineItem {
  Description: string;
  LineAmount: number;
}

export interface BankTransactionLine {
  id: string;
  date: string;
  name: string;
  description: string;
  amount: number;
}

export interface MissionsSupportInfo {
  goal: number;
  supportStartDate: Date;
}

export interface MissionsSupportTeam {
  id?: string;
  country: string;
  members: User[];
  leader?: User;
}

export const InitMissionsSupportData = {
  goal: 0,
  supportStartDate: new Date(),
};

export const InitMissionsSupportTeamData = {
  country: "",
  members: [],
};

export const getMissionsSupportTeams = async (): Promise<MissionsSupportTeam[]> => {
  const docs = (await getDocs(collection(db, "missionsSupport", currentYear.toString(), "teams"))).docs;
  let missionsSupportTeams = [];
  for (const doc of docs) {
    missionsSupportTeams.push({
      id: doc.id,
      country: doc.data().country,
      members:
        doc.data().memberIDs?.length > 0 ? await getListOfUsersByIds(doc.data().memberIDs, currentYear.toString()) : [],
      leader: doc.data().leaderID ? await getUserById(doc.data().leaderID) : undefined,
    });
  }
  return missionsSupportTeams;
};

export const getMissionsSupportInfo = async (): Promise<MissionsSupportInfo> => {
  const info = await getDoc(doc(db, "missionsSupport", currentYear.toString()));
  if (info.exists()) {
    return {
      goal: info.data().goal,
      supportStartDate: info.data().supportStartDate.toDate(),
    };
  }
  return InitMissionsSupportData;
};

export const updateMissionsSupportInfo = async (updatedMissionsSupportinfo: MissionsSupportInfo) => {
  await setDoc(doc(db, "missionsSupport", currentYear.toString()), updatedMissionsSupportinfo);
};
export const updateMissionsSupportTeams = async (updatedMissionsSupportTeams: MissionsSupportTeam[]) => {
  for (let data of updatedMissionsSupportTeams) {
    if (data.id) {
      await setDoc(doc(db, "missionsSupport", currentYear.toString(), "teams", data.id), {
        country: data.country,
        memberIDs: data.members.length > 0 ? data.members.map((m) => m.id) : undefined,
        leaderID: data.leader?.id,
      });
    } else {
      data.id = (
        await addDoc(collection(db, "missionsSupport", currentYear.toString(), "teams"), {
          country: data.country,
          memberIDs: data.members.length > 0 ? data.members.map((m) => m.id) : undefined,
          leaderID: data.leader?.id,
        })
      ).id;
    }
  }
  return updatedMissionsSupportTeams;
};

export const deleteMissionsSupportTeam = async (id: string) => {
  await deleteDoc(doc(db, "missionsSupport", currentYear.toString(), "teams", id));
};
