import { Box, Card, CardContent, Grid, LinearProgress, LinearProgressProps, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { User } from "../../models/User";
import { ModuleListModel } from "../../models/ModuleListModel";
import { useHistory } from "react-router-dom";
import { startModule } from "../../services/modules";
import { Path } from "../../helpers/Path";
import DoneIcon from "@mui/icons-material/Done";

export const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export const ModuleCard = ({ module, user, last }: { module: ModuleListModel; user: User; last?: boolean }) => {
  const theme = useTheme();
  const history = useHistory();
  return (
    <Grid item>
      <Card
        sx={{
          border: 0,
          borderRadius: 0,
          borderBottomLeftRadius: last ? 20 : 0,
          borderBottomRightRadius: last ? 20 : 0,
          "&:hover": {
            backgroundColor: "#ddd",
            cursor: "pointer",
            transition: "0.2s",
          },
        }}
        onClick={() => {
          startModule(user, module).then(() => history.push(Path.Module + "/" + module.id + "/" + user.id));
        }}
      >
        <CardContent
          sx={{
            height: theme.spacing(15.5),
          }}
        >
          <Typography align="center">{module.title}</Typography>
          <Box
            sx={{
              mt: theme.spacing(2),
            }}
          >
            {module.moduleStats ? (
              module.moduleStats.complete ? (
                <Grid container alignItems="center" justifyContent="center">
                  <Typography>Complete</Typography>
                  <DoneIcon />
                </Grid>
              ) : (
                <LinearProgressWithLabel
                  value={
                    module.moduleStats
                      ? ((module.moduleStats.currentStep + (module.moduleStats.complete ? 1 : 0)) /
                          (module.totalSteps + 1)) *
                        100
                      : 0
                  }
                />
              )
            ) : (
              <></>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};
