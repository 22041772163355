import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Button, Chip, Grid, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { User } from "../../models/User";
import { MissionsSupportTeam } from "../../services/missionsSupport";

export const EditMissionsTeam = ({
  users,
  team,
  removeTeam,
  isOptionDisabled,
  setTeam,
}: {
  users: User[];
  team: MissionsSupportTeam;
  removeTeam: () => void;
  isOptionDisabled: (option: User) => boolean;
  setTeam: (updatedTeam: MissionsSupportTeam) => void;
}) => {
  const theme = useTheme();
  return (
    <Grid container alignItems="center" justifyContent="center" spacing={1}>
      <Grid container justifyContent="center" item xs={12}>
        <TextField
          id="country"
          label="Country"
          variant="standard"
          onChange={(event) => setTeam({ ...team, country: event.target.value })}
          value={team.country}
        />
      </Grid>
      <Grid container justifyContent="center" item xs={12}>
        <Autocomplete
          id={`${team.id}-leader`}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.role}`}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionDisabled={isOptionDisabled}
          renderTags={(value: readonly User[], getTagProps) =>
            value.map((option: User, index: number) => (
              <Chip
                variant="outlined"
                label={`${option.firstName} ${option.lastName} - ${option.role}`}
                {...getTagProps({ index })}
              />
            ))
          }
          value={team.leader ? team.leader : null}
          onChange={(event, value: User | null) => {
            setTeam({ ...team, leader: value ? value : undefined });
          }}
          options={users}
          sx={{
            width: 300,
          }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Leader" placeholder="Select leader" />
          )}
        />
      </Grid>
      <Grid container justifyContent="center" item xs={12}>
        <Autocomplete
          id={`${team.id}-members`}
          multiple
          getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.role}`}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionDisabled={isOptionDisabled}
          renderTags={(value: readonly User[], getTagProps) =>
            value.map((option: User, index: number) => (
              <Chip
                variant="outlined"
                label={`${option.firstName} ${option.lastName} - ${option.role}`}
                {...getTagProps({ index })}
              />
            ))
          }
          value={team.members}
          onChange={(event, newValue: User[]) => {
            setTeam({ ...team, members: newValue });
          }}
          options={users}
          sx={{
            width: 300,
          }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label={"Members"} placeholder={`Select members`} />
          )}
        />
      </Grid>
      <Grid container justifyContent="center" item xs={12}>
        <Button variant="contained" color="error" onClick={removeTeam} sx={{ mb: theme.spacing(3) }}>
          <Grid container alignItems="center" justifyContent="center">
            <Typography color="secondary">Delete team</Typography>
            <ClearIcon fontSize="small" color="secondary" />
          </Grid>
        </Button>
      </Grid>
    </Grid>
  );
};
