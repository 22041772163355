import { Button, CircularProgress, TextField } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { Staff } from "../../models/Staff";
import { PromotionRequestCommentData } from "../../models/PromotionRequestCommentData";
import { PromotionRequest } from "../../models/PromotionRequest";
import { MessageState } from "../../models/MessageState";
import { submitComment } from "../../services/promotionRequests";

export const PromotionRequestCommentForm = ({
  promotionRequest,
  staff,
  handleSubmit,
}: {
  promotionRequest: PromotionRequest;
  staff?: Staff | undefined;
  handleSubmit: (m: MessageState, c: PromotionRequestCommentData | null) => void;
}) => (
  <Formik
    initialValues={{
      staff: staff as Staff,
      comment: "",
      submittedTime: new Date(),
    }}
    onSubmit={(values: PromotionRequestCommentData, { setFieldValue }) =>
      submitComment(promotionRequest, values)
        .then((c) => {
          handleSubmit(
            {
              showMessage: true,
              message: "Successfully submitted comment!",
              severity: "success",
            },
            c
          );
          setFieldValue("comment", "");
        })
        .catch((error) => {
          handleSubmit(
            {
              showMessage: true,
              message: error.message,
              severity: "error",
            },
            null
          );
        })
    }
  >
    {(props: FormikProps<PromotionRequestCommentData>) => {
      const { handleChange, handleBlur, isSubmitting, values } = props;
      return (
        <Form>
          <TextField
            id="comment"
            required
            fullWidth
            placeholder="Write your comment here"
            multiline
            minRows={1}
            maxRows={6}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.comment}
          />
          {!isSubmitting ? (
            <Button type="submit" variant="outlined">
              Submit
            </Button>
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Form>
      );
    }}
  </Formik>
);
