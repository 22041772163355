import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Link, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { UserContext } from "../../components/PrivateRoute";
import { EventFormModel } from "../../models/EventFormModel";
import { deleteEventForm, eventFormApproved } from "../../services/eventForm";
import { EventFormInterface } from "./EventForm";
import { EventFormInformation } from "./EventFormInformation";
import { EventPost } from "./EventPost";
import { Link as ReactRouterLink } from "react-router-dom";
import { Path } from "../../helpers/Path";
import { currentStaffYear } from "../../helpers/FormatDate";
import { Staff } from "../../models/Staff";

export const EventFormDialog = ({
  eventForm,
  dialogOpen,
  closeDialog,
  eventFormState,
  eventFormSetState,
}: {
  eventForm?: EventFormModel;
  dialogOpen: boolean;
  closeDialog: () => void;
  eventFormState?: EventFormInterface;
  eventFormSetState?: (state: EventFormInterface) => void;
}) => {
  const user = useContext(UserContext);
  const submitteeIsMe = eventForm?.submittee.id === user.id;
  const submitteeIsMyTeam = eventForm?.submittee.department === (user as Staff).department;
  var id = "";
  if (eventForm !== undefined) {
    id = eventForm.id;
  }
  const canDelete = (submitteeIsMe || submitteeIsMyTeam) && !eventFormApproved(eventForm);
  const [deleteText, setDeleteText] = useState("");

  const [openDelete, setDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = () => setDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => {
    setDeleteText("");
    setDeleteDialogOpen(false);
  };

  const handleEditDialogOpen = () => {
    eventFormSetState!({ ...eventFormState!, postDialogOpen: true, postDialogEdit: true });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={dialogOpen}
      onClose={closeDialog}
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        {canDelete && (
          <Box>
            <Box
              sx={{
                ml: "8px",
                display: "flex",
                float: "right",
                gap: 1,
              }}
            >
              <Button variant="contained" onClick={handleEditDialogOpen}>
                Edit
              </Button>
              <EventPost state={eventFormState!} setState={eventFormSetState!} />
              <Button color="error" variant="contained" onClick={handleDeleteDialogOpen}>
                Delete
              </Button>
            </Box>
            <Dialog open={openDelete} onClose={handleDeleteDialogClose}>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete the event form?
                  <br /> All Sub Forms in progress will be deleted as well.
                </DialogContentText>
                <Box marginTop={2}>
                  <TextField
                    variant="standard"
                    placeholder="delete"
                    sx={{
                      marginRight: 4,
                    }}
                    value={deleteText}
                    onChange={(e) => {
                      setDeleteText(e.target.value);
                    }}
                  ></TextField>
                  <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>Cancel</Button>
                    {deleteText === "delete" ? (
                      <Button
                        color="error"
                        variant="contained"
                        onClick={() => {
                          deleteEventForm(id).then(() => {
                            window.location.reload();
                          });
                        }}
                      >
                        Delete
                      </Button>
                    ) : (
                      <Button variant="contained" disabled>
                        Delete
                      </Button>
                    )}
                  </DialogActions>
                </Box>
              </DialogContent>
            </Dialog>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            float: "right",
          }}
        >
          <Link
            underline="hover"
            component={ReactRouterLink}
            to={`${Path["Event Form Download"]}/${currentStaffYear}/${eventForm?.id}`}
          >
            Download as PDF
          </Link>
        </Box>
        {eventForm && <EventFormInformation eventForm={eventForm} />}
      </DialogContent>
    </Dialog>
  );
};
