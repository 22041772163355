import { collection, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { currentStaffYear, nextStaffYear } from "../helpers/FormatDate";
import { Divisions, PastDivisions } from "../models/Division";
import { db } from "./firebase";
import { getDirector, getUserByIdYear, updateFieldYear } from "./users";

export const getStaffYearDivisionsDoc = async (year: string) => getDoc(doc(db, "divisions", year));

export const getStaffYearDivisions = async (year: string) =>
  await convertFromFirestore((await getStaffYearDivisionsDoc(year)).data()?.divisions as DivisionsFirestore, year);

export const getCurrentStaffYearDivisions = async (): Promise<Divisions> =>
  getStaffYearDivisions(currentStaffYear.toString());
export const getNextStaffYearDivisions = async (): Promise<Divisions> =>
  getStaffYearDivisions(nextStaffYear.toString());

interface DivisionsFirestore {
  [division: string]: { head: string };
}

const convertFromFirestore = async (divisionsFirestore: DivisionsFirestore, year: string) => {
  let divisions: Divisions = {};
  if (!divisionsFirestore) {
    return divisions;
  }
  for (const [division, content] of Object.entries(divisionsFirestore)) {
    divisions[division] = {
      head: await getUserByIdYear(content.head, year),
    };
  }
  return divisions;
};

const convertToFirestore = (divisions: Divisions) => {
  let divisionsFirestore: DivisionsFirestore = {};
  for (const [division, content] of Object.entries(divisions)) {
    divisionsFirestore[division] = {
      head: content.head.id,
    };
  }
  return divisionsFirestore;
};

export const updateDivisions = async (year: number, newDivisions: Divisions) => {
  for (const [key, content] of Object.entries(newDivisions)) {
    if (!key || !content.head) {
      throw new Error("One of the entries are empty!");
    }
  }

  for (const [div, content] of Object.entries(newDivisions)) {
    if ((await getDirector(currentStaffYear.toString())).id !== content.head.id) {
      await updateFieldYear(content.head.id, "role", "Head of Division", "Role", year.toString());
      await updateFieldYear(content.head.id, "division", div, "Division", year.toString());
    }
  }

  return await updateDoc(doc(db, "divisions", year.toString()), {
    divisions: convertToFirestore(newDivisions),
  });
};

export const getPastDivisions = async (): Promise<PastDivisions> => {
  const getDivisionsCollection = await getDocs(collection(db, "divisions"));

  let divisions: PastDivisions = {};
  for (const doc of getDivisionsCollection.docs) {
    if (Number(doc.id) < currentStaffYear) {
      divisions[doc.id] = doc.data()?.divisions as Divisions;
    }
  }
  return divisions;
};
