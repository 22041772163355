import { ResourceItem } from "./ResourceItem";

export interface Resource {
  folderName: string;
  items: ResourceItem[];
  folders?: Resource[];
}

export const InitResource: Resource = {
  folderName: "root",
  items: [],
};
