import { addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { FeedbackFormInterface } from "../models/FeedbackForm";
import { db } from "./firebase";
import { getUserById } from "./users";

export const submitFeedbackForm = (
  values: {
    type: string;
    content: string;
  },
  userID: string
) =>
  new Promise((resolve, reject) => {
    addDoc(collection(db, "feedback"), {
      ...values,
      userID: userID,
      archived: false,
      time: new Date(),
    })
      .then(() => resolve(values))
      .catch((error) => reject(error));
  });

export const getFeedbackForms = (): Promise<FeedbackFormInterface[]> =>
  new Promise((resolve, reject) => {
    getDocs(collection(db, "feedback"))
      .then((res) => {
        const p = res.docs.map(async (doc) => ({
          id: doc.id,
          archived: doc.data().archived,
          user: await getUserById(doc.data().userID),
          type: doc.data().type,
          content: doc.data().content,
          time: doc.data().time.toDate(),
        }));
        Promise.all(p).then((forms) => resolve(forms));
      })
      .catch((error) => {
        reject(error);
      });
  });

export const archiveForm = (form: FeedbackFormInterface): Promise<FeedbackFormInterface> =>
  new Promise((resolve, reject) =>
    updateDoc(doc(db, "feedback", form.id), { archived: true })
      .then(() => resolve({ ...form, archived: true }))
      .catch((error) => reject(error))
  );
