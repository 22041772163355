import { Alert, Button, Container, Grid, Snackbar, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { TypographyBold } from "../../components/TypographyBold";
import { currentStaffYear, nextStaffYear } from "../../helpers/FormatDate";
import { Path } from "../../helpers/Path";
import { InitDepartment } from "../../models/Department";
import { getNextStaffYearDepartments } from "../../services/departments";
import { getNextStaffYearDivisions } from "../../services/divisions";
import { getNextStaffYearRoles } from "../../services/roles";
import { getListOfUsers, getListOfUsersByStaffYear } from "../../services/users";
import { DepartmentsComponent } from "./DepartmentsComponent";
import { DivisionsComponent } from "./DivisionsComponent";
import { InitEditState, OrganisationStructureInterface } from "./OrganisationStructure";
import { RolesComponent } from "./RolesComponent";

export const OrganisationStructureNextYear = () => {
  const history = useHistory();

  const [state, setState] = useState<OrganisationStructureInterface>({
    year: currentStaffYear + 1,
    roles: [],
    rolesTemp: [],
    departments: InitDepartment,
    departmentsTemp: InitDepartment,
    divisions: {},
    divisionsTemp: {},
    users: [],
    alert: {
      showMessage: false,
      message: "",
      severity: "success",
    },
    editState: InitEditState,
    saving: false,
    loading: true,
  });

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });

    const nextYearRolesFetch = getNextStaffYearRoles();
    const nextYearDepartmentsFetch = getNextStaffYearDepartments();
    const nextYearDivisionsFetch = getNextStaffYearDivisions();
    const usersFetch = getListOfUsersByStaffYear(nextStaffYear);

    Promise.all([nextYearRolesFetch, nextYearDepartmentsFetch, nextYearDivisionsFetch, usersFetch])
      .then(([nextYearRoles, nextYearDepartments, nextYearDivisions, users]) =>
        setState({
          ...state,
          roles: nextYearRoles,
          departments: nextYearDepartments,
          divisions: nextYearDivisions,
          users,
          loading: false,
        })
      )
      .catch((err) => history.push(`${Path.Error}/Internal Error`));

    // eslint-disable-next-line
  }, []);

  const theme = useTheme();

  const { alert, loading } = state;

  return loading ? (
    <Loading />
  ) : (
    <Container maxWidth="xl">
      <Button variant="contained" component={ReactRouterLink} to={Path["Organisation Structure"]}>
        Organisation Structure for Current Year
      </Button>
      <Alert sx={{ mt: theme.spacing(12), mb: theme.spacing(8) }} elevation={2} variant="filled" severity="info">
        Rollover will occur at the 23rd of September every year. (New Staff Rotation)
      </Alert>
      <Snackbar
        open={alert.showMessage}
        onClose={() =>
          setState({
            ...state,
            alert: { ...alert, showMessage: false },
          })
        }
        autoHideDuration={6000}
      >
        <Alert elevation={6} variant="filled" severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Grid container justifyContent="center">
        <Grid item md={12} lg={6} container spacing={2}>
          <Grid item xs={12}>
            <TypographyBold variant="h5">Next Year's Roles</TypographyBold>
          </Grid>
          <RolesComponent state={state} setState={setState} />
        </Grid>
        <Grid item md={12} lg={6}>
          <Grid item xs={12}>
            <TypographyBold variant="h5">Next Year's Divisions</TypographyBold>
            <DivisionsComponent state={state} setState={setState} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <TypographyBold variant="h5">Next Year's Departments</TypographyBold>
          </Grid>
          <DepartmentsComponent state={state} setState={setState} />
        </Grid>
      </Grid>
    </Container>
  );
};
