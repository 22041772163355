import { useContext } from "react";
import {
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Grid,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { PromotionRequest } from "../../models/PromotionRequest";
import { PromotionRequestType } from "../../data/PromotionRequestType";
import { PromotionRequestObjective } from "../../data/PromotionRequestObjective";
import { DepartmentsContext } from "../../components/PrivateRoute";
import { STAFF } from "../../data/Roles";

export const PromotionRequestsFormAnswers = ({ promotionRequest }: { promotionRequest: PromotionRequest }) => {
  const departments = useContext(DepartmentsContext);
  const theme = useTheme();
  const questionTheme = {
    mb: theme.spacing(2),
  };

  return (
    <>
      <InputLabel
        id="department-label"
        sx={{
          mt: theme.spacing(3),
        }}
        required
      >
        1. Select Department
      </InputLabel>
      <Select
        label="Department"
        fullWidth
        labelId="department-label"
        id="department"
        name="department"
        disabled
        value={promotionRequest.department}
        variant="standard"
        required
      >
        {Object.keys(departments.departments).map((dep, i) => (
          <MenuItem key={i} value={dep}>
            {dep}
          </MenuItem>
        ))}
      </Select>
      <FormLabel component="legend" required>
        2. Details of Event/Project that you'd like to promote
      </FormLabel>
      <TextField
        fullWidth
        id="details"
        label="Please provide name of event/project, date, time, venue, key audience, goal/purpose of event/project"
        name="details"
        multiline
        disabled
        required
        value={promotionRequest.details}
        variant="standard"
        sx={questionTheme}
      />
      <FormLabel component="legend" required>
        3. Who is your target audience?
      </FormLabel>
      <RadioGroup value={promotionRequest.targetAudience} name="targetAudience" sx={questionTheme}>
        <FormControlLabel value="Students/Members" disabled control={<Radio />} label="Students/Members" />
        <FormControlLabel value="Alumni" disabled control={<Radio />} label="Alumni" />
        <FormControlLabel value={STAFF} disabled control={<Radio />} label={STAFF} />
        <FormControlLabel value="General Public" disabled control={<Radio />} label="General Public" />
        <FormControlLabel value="Other" disabled control={<Radio />} label="Other" />
        <TextField
          id="targetAudienceOtherText"
          name="targetAudienceOtherText"
          multiline
          disabled
          value={promotionRequest.targetAudienceOtherText}
          variant="standard"
          sx={questionTheme}
        />
      </RadioGroup>
      <FormLabel component="legend" required>
        4. What are your key messages that you would like to get across through promotion?
      </FormLabel>
      <TextField
        fullWidth
        id="message"
        name="message"
        multiline
        disabled
        value={promotionRequest.message}
        variant="standard"
        sx={questionTheme}
      />
      <FormControl
        component="fieldset"
        sx={{
          mt: theme.spacing(3),
        }}
        fullWidth
      >
        <FormLabel component="legend">5. What do you wish to gain through promotion?</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                id="objective.raiseAwareness"
                value={promotionRequest.objective.raiseAwareness}
                color="primary"
                disabled
                name="objective.raiseAwareness"
              />
            }
            label={PromotionRequestObjective.raiseAwareness}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="objective.invitePeople"
                value={promotionRequest.objective.invitePeople}
                color="primary"
                disabled
                name="objective.invitePeople"
              />
            }
            label={PromotionRequestObjective.invitePeople}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="objective.getPeopleInterested"
                value={promotionRequest.objective.getPeopleInterested}
                color="primary"
                disabled
                name="objective.getPeopleInterested"
              />
            }
            label={PromotionRequestObjective.getPeopleInterested}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="objective.hitRegistrations"
                value={promotionRequest.objective.hitRegistrations}
                disabled
                color="primary"
                name="objective.hitRegistrations"
              />
            }
            label={PromotionRequestObjective.hitRegistrations}
          />
          <Grid container>
            <FormControlLabel
              control={
                <Checkbox
                  id="objective.other"
                  value={promotionRequest.objective.other}
                  disabled
                  color="primary"
                  name="objective.other"
                />
              }
              label={PromotionRequestObjective.other}
            />
            <TextField
              id="objective.otherText"
              name="objective.otherText"
              multiline
              disabled
              value={promotionRequest.objective.otherText}
              variant="standard"
              sx={questionTheme}
            />
          </Grid>
        </FormGroup>
      </FormControl>
      <FormControl
        component="fieldset"
        sx={{
          mt: theme.spacing(3),
        }}
      >
        <FormLabel component="legend">6. Type of promotion</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                id="type.graphics"
                value={promotionRequest.type.graphics}
                disabled
                color="primary"
                name="type.graphics"
              />
            }
            label={PromotionRequestType.graphics}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="type.photos"
                value={promotionRequest.type.photos}
                disabled
                color="primary"
                name="type.photos"
              />
            }
            label={PromotionRequestType.photos}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="type.animatedGraphics"
                value={promotionRequest.type.animatedGraphics}
                disabled
                color="primary"
                name="type.animatedGraphics"
              />
            }
            label={PromotionRequestType.animatedGraphics}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="type.videos"
                value={promotionRequest.type.videos}
                disabled
                color="primary"
                name="type.videos"
              />
            }
            label={PromotionRequestType.videos}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="type.infographic"
                value={promotionRequest.type.instagramStories}
                disabled
                color="primary"
                name="type.infographic"
              />
            }
            label={PromotionRequestType.instagramStories}
          />
          <Grid container>
            <FormControlLabel
              control={
                <Checkbox
                  id="type.other"
                  value={promotionRequest.type.other}
                  disabled
                  color="primary"
                  name="type.other"
                />
              }
              label={PromotionRequestType.other}
            />
            <TextField
              id="type.otherText"
              name="type.otherText"
              multiline
              value={promotionRequest.type.otherText}
              disabled
              variant="standard"
              sx={questionTheme}
            />
          </Grid>
        </FormGroup>
      </FormControl>
      <FormLabel component="legend" required>
        7. Please specify your visual goals/theme for this promotion.
      </FormLabel>
      <TextField
        fullWidth
        id="theme"
        label="Is there a colour scheme, logos to include, images, footage etc. that you would like us to include?"
        name="theme"
        multiline
        value={promotionRequest.theme}
        disabled
        variant="standard"
        sx={questionTheme}
      />
      <FormLabel component="legend">8. Please provide examples of the above</FormLabel>
      <TextField
        fullWidth
        id="example"
        name="example"
        multiline
        value={promotionRequest.example}
        disabled
        variant="standard"
        sx={questionTheme}
      />
      <FormLabel component="legend" required>
        9. When would you like us to post? (If you have a promotion timeline, please provide share link)
      </FormLabel>
      <TextField
        fullWidth
        id="postWhen"
        label="Please provide specific dates (eg. Every monday of June) or else provide a specific time frame (start of July - end of August)."
        name="postWhen"
        multiline
        value={promotionRequest.postWhen}
        disabled
        required
        variant="standard"
        sx={questionTheme}
      />
      <FormLabel component="legend">
        10. Please send us any images/videos/files that you would like us to include/consider.
      </FormLabel>
      <TextField
        fullWidth
        id="filesToInclude"
        name="filesToInclude"
        multiline
        value={promotionRequest.filesToInclude}
        disabled
        variant="standard"
        sx={questionTheme}
      />
      <FormControl component="fieldset">
        <FormLabel component="legend" required>
          11. Would you like us to ask for your approval before posting?
        </FormLabel>
        <RadioGroup value={promotionRequest.askForApproval} name="askForApproval" sx={questionTheme}>
          <FormControlLabel value={true} control={<Radio disabled />} label="Yes" />
          <FormControlLabel value={false} control={<Radio disabled />} label="No" />
        </RadioGroup>
      </FormControl>
      <FormLabel component="legend">12. Do you have any other information you would like to include?</FormLabel>
      <TextField
        fullWidth
        id="extraInformation"
        name="extraInformation"
        multiline
        variant="standard"
        value={promotionRequest.extraInformation}
        disabled
        sx={questionTheme}
      />
    </>
  );
};
