import { Alert, Divider, Typography } from "@mui/material";
import { TypographyBold } from "../../../../components/TypographyBold";
import { ReviewQuestions, ReviewResults, ReviewResultsOfAll } from "../../../../models/Review360Model";
import { getReviewersAverage, round2 } from "../../../../services/review360";
import { Review360OverallComparison } from "./Review360OverallComparison";
import { Review360ResultsAreas } from "./Review360ResultsAreas";
import { Review360ResultsRatedItems } from "./Review360ResultsRatedItems";

export const Review360Results = ({
  results,
  resultsOfAll,
  questions,
}: {
  results: ReviewResults;
  resultsOfAll: ReviewResultsOfAll;
  questions: ReviewQuestions;
}) => {
  return (
    <>
      <Alert severity="info">
        The ratings are from 1 to 7, 1 being the lowest rating (Strongly Disagree) and 7 being the highest (Strongly
        Agree).
      </Alert>
      <Review360OverallComparison results={results} resultsOfAll={resultsOfAll} />
      <TypographyBold variant="h4">OVERALL AVERAGE</TypographyBold>
      <Typography variant="h5">Your ratings: {round2(results.averagesOverall.Self)}</Typography>
      <Typography variant="h5">
        Your Reviewers: {round2(getReviewersAverage(results.averagesOverall, results.numberOfReviewers))}
      </Typography>
      <Divider />
      <Review360ResultsAreas results={results} resultsOfAll={resultsOfAll} questions={questions} />
      <Divider />
      <Review360ResultsRatedItems results={results} resultsOfAll={resultsOfAll} questions={questions} />
      <Divider />
    </>
  );
};
