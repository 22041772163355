import * as yup from "yup";
import { Dialog } from "@mui/material";
import { RequestDeclined } from "../../models/RequestDeclined";
import { Request } from "../../models/Request";
import { Formik, FormikProps } from "formik";
import { RequestsDeclineForm } from "./RequestsDeclineForm";

const RequestsDeclineFormValidationSchema = yup.object().shape({
  reason: yup.string(),
});

export const RequestsDeclineConfirmationDialog = ({
  currRequest,
  dialogOpen,
  closeDialog,
  declineRequest,
}: {
  currRequest: Request;
  dialogOpen: boolean;
  closeDialog: () => void;
  declineRequest: (request: RequestDeclined) => void;
}) => (
  <Dialog open={dialogOpen} onClose={closeDialog}>
    <Formik
      initialValues={{
        ...currRequest,
        reason: "",
      }}
      onSubmit={(values: RequestDeclined) => declineRequest(values)}
      validationSchema={RequestsDeclineFormValidationSchema}
    >
      {(props: FormikProps<RequestDeclined>) => {
        return <RequestsDeclineForm props={props} handleClose={closeDialog} />;
      }}
    </Formik>
  </Dialog>
);
