import { doc, getDoc, setDoc, updateDoc, collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { CAMPUS_CHAPLAIN, DEPARTMENT_OVERSEER, STAFF } from "../data/Roles";
import { currentStaffYear, rolesStartYear, staffYearsSince, startYear } from "../helpers/FormatDate";
import { db } from "./firebase";
import { atLeast } from "../helpers/Role";
import { User } from "../models/User";
import { getStaffYearDepartments } from "./departments";
import { getStaffYearDivisions } from "./divisions";
import { getListOfUsersByStaffYear } from "./users";

export interface NumberOfLeadersByYear {
  year: number;
  roles: NumberOfLeadersByRole;
}

export interface NumberOfHeadsByYear {
  year: number;
  departments: number;
  divisions: number;
}

export interface CampusAttendanceData {
  campusName: string;
  totalAttendance: number;
  weeks: number;
  averageAttendance: number;
}

export interface YearlyData {
  year: number;
  campusAttendanceData: CampusAttendanceData[];
  overallAverage: number;
}

interface NumberOfLeadersByRole {
  [role: string]: number;
}
export interface RetentionByGroup {
  "All Leaders": RetentionRates[];
  "Campus Leaders": RetentionRates[];
  Staff: RetentionRates[];
}

export interface RetentionRates {
  percentage: number;
  average: number;
}

export interface RatingsOfLeadersByYear {
  year: number;
  satisfactionRating: number;
}

export const getSurveyResponses = async () => {
  const res = await getDocs(
    query(collection(db, "surveys", "sowMailchimp", "satisfactionScores"), orderBy("submitted_at", "asc"))
  );
  let surveyResponse = [];
  let avgResponse = [];
  for (const doc of res.docs) {
    surveyResponse.push({
      satisfaction_result: parseInt(doc.data().answer),
      type: doc.data().type,
      submitted_at: new Date(doc.data().submitted_at).getFullYear(),
    });
  }
  let years = [...new Set(surveyResponse.map((item) => item.submitted_at))];
  for (const year of years) {
    let yearlyResponse =
      (surveyResponse
        .filter(function (el) {
          return el.submitted_at === year;
        })
        .reduce((total, next) => total + next.satisfaction_result, 0) /
        surveyResponse.filter(function (el) {
          return el.submitted_at === year;
        }).length /
        5) *
      100;
    avgResponse.push({ year: year, satisfactionRating: yearlyResponse } as RatingsOfLeadersByYear);
  }
  return avgResponse;
};

export const getSurveyResponsesByType = async (type: string) => {
  const res = await getDocs(
    query(
      collection(db, "surveys", "sowMailchimp", "satisfactionScores"),
      where("type", "==", type),
      orderBy("submitted_at", "asc")
    )
  );
  let surveyResponse = [];
  let avgResponse = [];
  for (const doc of res.docs) {
    surveyResponse.push({
      satisfaction_result: parseInt(doc.data().answer),
      type: doc.data().type,
      submitted_at: new Date(doc.data().submitted_at).getFullYear(),
    });
  }
  let years = [...new Set(surveyResponse.map((item) => item.submitted_at))];
  for (const year of years) {
    let yearlyResponse =
      (surveyResponse
        .filter(function (el) {
          return el.submitted_at === year;
        })
        .reduce((total, next) => total + next.satisfaction_result, 0) /
        surveyResponse.filter(function (el) {
          return el.submitted_at === year;
        }).length /
        5) *
      100;
    avgResponse.push({ year: year, satisfactionRating: yearlyResponse } as RatingsOfLeadersByYear);
  }
  return avgResponse;
};

export const attendanceData: YearlyData[] = [
  {
    year: 2022,
    campusAttendanceData: [
      { campusName: "UTS", totalAttendance: 218, weeks: 23, averageAttendance: 9 },
      { campusName: "UNSW", totalAttendance: 507, weeks: 29, averageAttendance: 17 },
      { campusName: "USYD", totalAttendance: 535, weeks: 25, averageAttendance: 21 },
      { campusName: "MQ", totalAttendance: 0, weeks: 0, averageAttendance: 0 }
    ],
    overallAverage: 16
  },
  {
    year: 2023,
    campusAttendanceData: [
      { campusName: "UTS", totalAttendance: 323, weeks: 20, averageAttendance: 16 },
      { campusName: "UNSW", totalAttendance: 286, weeks: 18, averageAttendance: 16 },
      { campusName: "USYD", totalAttendance: 0, weeks: 0, averageAttendance: 0 },
      { campusName: "MQ", totalAttendance: 244, weeks: 10, averageAttendance: 24 }
    ],
    overallAverage: 28
  },
  {
    year: 2024,
    campusAttendanceData: [
      { campusName: "UTS", totalAttendance: 182, weeks: 12, averageAttendance: 15 },
      { campusName: "UNSW", totalAttendance: 266, weeks: 17, averageAttendance: 16 },
      { campusName: "USYD", totalAttendance: 281, weeks: 13, averageAttendance: 22 },
      { campusName: "MQ", totalAttendance: 559, weeks: 16, averageAttendance: 35 }
    ],
    overallAverage: 29
  }
];

export const getLeadersSize = async () => {
  let numberOfLeadersByYear = [];
  for (const year of staffYearsSince(startYear + 1)) {
    const users = await getListOfUsersByStaffYear(year);
    let roles: NumberOfLeadersByRole = {};
    for (const user of users) {
      if (roles[user.role]) {
        roles[user.role] += 1;
      } else {
        roles[user.role] = 1;
      }
    }
    numberOfLeadersByYear.push({ year, roles } as NumberOfLeadersByYear);
  }
  return numberOfLeadersByYear;
};

export const getHeadsSize = async () => {
  let numberOfHeadsByYear = [];
  for (const year of staffYearsSince(rolesStartYear)) {
    const departments = await getStaffYearDepartments(year.toString());
    const divisions = await getStaffYearDivisions(year.toString());
    numberOfHeadsByYear.push({
      year,
      departments: Object.keys(departments.departments).length,
      divisions: divisions ? Object.keys(divisions).length : 0,
    });
  }
  return numberOfHeadsByYear;
};

export const getRetentionRates = async () => {
  let retentionStats: RetentionByGroup = {
    "All Leaders": [],
    "Campus Leaders": [],
    Staff: [],
  };
  let groups = ["All Leaders", "Campus Leaders", "Staff"];
  for (let group of groups) {
    for (let k = 2009; k <= currentStaffYear - 1; k++) {
      const retentionData = await getDoc(doc(db, "metrics", "metrics", group, k.toString()));
      if (retentionData.exists() && (group === "All Leaders" || group === "Campus Leaders" || group === "Staff")) {
        retentionStats[group].push({
          average: retentionData.data().average,
          percentage: retentionData.data().percentage,
        });
      }
    }
  }
  return retentionStats;
};

export const getListOfOutgoingLeaders = async (staffYear: number, servingYears?: number) => {
  let outgoingUsers: User[] = [];
  const currentUsers = await getListOfUsersByStaffYear(staffYear);
  const nextYearUsers = await getListOfUsersByStaffYear(staffYear + 1);
  outgoingUsers = currentUsers.filter((user) => !nextYearUsers.find((u) => u.id === user.id));
  if (servingYears !== null && servingYears !== undefined) {
    for (let x = 1; x <= servingYears - 1; x++) {
      const xYearsBeforeUsers = await getListOfUsersByStaffYear(staffYear - x);
      outgoingUsers = xYearsBeforeUsers.filter((user) => outgoingUsers.find((u) => u.id === user.id));
    }
    const beforeServingTermUsers = await getListOfUsersByStaffYear(staffYear - servingYears);
    outgoingUsers = outgoingUsers.filter((user) => !beforeServingTermUsers.find((u) => u.id === user.id));
  }
  return outgoingUsers;
};

export const getOutgoingLeadersSize = async (servingYears?: number) => {
  let numberOfOutgoingLeadersByYear: NumberOfLeadersByYear[] = [];
  for (const year of staffYearsSince(startYear + 1)) {
    if (year > currentStaffYear - 1) break;
    let outgoingUsers: User[] = [];
    if (servingYears !== null && servingYears !== undefined) {
      outgoingUsers = await getListOfOutgoingLeaders(year, servingYears);
    } else {
      outgoingUsers = await getListOfOutgoingLeaders(year);
    }
    let roles: NumberOfLeadersByRole = {};
    for (const user of outgoingUsers) {
      if (roles[user.role]) {
        roles[user.role] += 1;
      } else {
        roles[user.role] = 1;
      }
    }
    numberOfOutgoingLeadersByYear.push({ year, roles } as NumberOfLeadersByYear);
  }
  return numberOfOutgoingLeadersByYear;
};

export const getAverageYearsServedByOutgoingLeadersByYear = async (roles: string[], isStaff: string) => {
  let numberOfOutgoingLeadersByYear: [NumberOfLeadersByYear[]] = [[]];
  for (let i = 1; i <= 11; i++) {
    numberOfOutgoingLeadersByYear[i] = await getOutgoingLeadersSize(i);
  }
  let totalNumberOfYearsServedByYear: number[] = Array(currentStaffYear - 2009).fill(0);
  let totalNumberOfStaff: number[] = Array(currentStaffYear - 2009).fill(0);
  for (let i = 1; i <= 11; i++) {
    let a: number[] = [];
    if (isStaff !== null && isStaff !== undefined) {
      if (isStaff === "Staff") {
        a = numberOfOutgoingLeadersByYear[i].map((a) =>
          Object.entries(a.roles)
            .filter(
              ([role, _]) =>
                atLeast(roles, STAFF, role) ||
                role === CAMPUS_CHAPLAIN ||
                role === DEPARTMENT_OVERSEER ||
                role === "Interim Director"
            )
            .reduce((acc, [_, num]) => acc + num, 0)
        );
      } else if (isStaff === "Campus Leaders") {
        a = numberOfOutgoingLeadersByYear[i].map((a) =>
          Object.entries(a.roles)
            .filter(([role, _]) => !atLeast(roles, STAFF, role))
            .reduce((acc, [_, num]) => acc + num, 0)
        );
      } else if (isStaff === "All Leaders") {
        a = numberOfOutgoingLeadersByYear[i].map((a) =>
          Object.entries(a.roles).reduce((acc, [_, num]) => acc + num, 0)
        );
      }
      for (let j = 0; j <= currentStaffYear - 2009 - 1; j++) {
        totalNumberOfYearsServedByYear[j] += a[j] * i;
        totalNumberOfStaff[j] += a[j];
      }
    }
  }
  let b: number[] = [];
  let c: number[] = [];
  let averageNumberOfYearsServedByYear: number[] = [];
  b = totalNumberOfYearsServedByYear;
  c = totalNumberOfStaff;
  averageNumberOfYearsServedByYear = b.map((value, index) => parseFloat((value / c[index]).toFixed(2)));
  for (let k = 2009; k <= currentStaffYear - 1; k++) {
    await updateDoc(doc(db, "metrics", "metrics", isStaff, k.toString()), {
      average: averageNumberOfYearsServedByYear[k - 2009],
    });
  }

  return averageNumberOfYearsServedByYear;
};

export const getPercentOfOutgoingLeadersByYear = async (roles: string[], isStaff: string) => {
  let numberOfOutgoingLeadersByYear: NumberOfLeadersByYear[] = [];
  let numberOfOutgoingLeadersServedOneYearByYear: NumberOfLeadersByYear[] = [];
  numberOfOutgoingLeadersByYear = await getOutgoingLeadersSize();
  numberOfOutgoingLeadersServedOneYearByYear = await getOutgoingLeadersSize(1);
  let a: number[] = [];
  let b: number[] = [];
  let c: number[] = [];
  if (isStaff !== null && isStaff !== undefined) {
    if (isStaff === "Staff") {
      a = numberOfOutgoingLeadersServedOneYearByYear.map((a) =>
        Object.entries(a.roles)
          .filter(
            ([role, _]) =>
              atLeast(roles, STAFF, role) ||
              role === CAMPUS_CHAPLAIN ||
              role === DEPARTMENT_OVERSEER ||
              role === "Interim Director"
          )
          .reduce((acc, [_, num]) => acc + num, 0)
      );
      b = numberOfOutgoingLeadersByYear.map((a) =>
        Object.entries(a.roles)
          .filter(
            ([role, _]) =>
              atLeast(roles, STAFF, role) ||
              role === CAMPUS_CHAPLAIN ||
              role === DEPARTMENT_OVERSEER ||
              role === "Interim Director"
          )
          .reduce((acc, [_, num]) => acc + num, 0)
      );
      c = a.map((value, index) => Math.round((1 - value / b[index]) * 100));
    } else if (isStaff === "Campus Leaders") {
      a = numberOfOutgoingLeadersServedOneYearByYear.map((a) =>
        Object.entries(a.roles)
          .filter(([role, _]) => !atLeast(roles, STAFF, role))
          .reduce((acc, [_, num]) => acc + num, 0)
      );
      b = numberOfOutgoingLeadersByYear.map((a) =>
        Object.entries(a.roles)
          .filter(([role, _]) => !atLeast(roles, STAFF, role))
          .reduce((acc, [_, num]) => acc + num, 0)
      );
      c = a.map((value, index) => Math.round((1 - value / b[index]) * 100));
    } else if (isStaff === "All Leaders") {
      a = numberOfOutgoingLeadersServedOneYearByYear.map((a) =>
        Object.entries(a.roles).reduce((acc, [_, num]) => acc + num, 0)
      );
      b = numberOfOutgoingLeadersByYear.map((a) => Object.entries(a.roles).reduce((acc, [_, num]) => acc + num, 0));
      c = a.map((value, index) => Math.round((1 - value / b[index]) * 100));
    }
  }
  for (let k = 2009; k <= currentStaffYear - 1; k++) {
    await setDoc(doc(db, "metrics", "metrics", isStaff, k.toString()), {
      percentage: c[k - 2009],
      average: null,
    });
  }
  return c;
};
