import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Container, Paper } from "@mui/material";
import { CalendarComponent, CalendarInterface } from "../../components/CalendarComponent";
import { getCalendar, getRolesResource } from "../../services/calendar";
import { Loading } from "../../components/Loading";
import { Legend } from "../../components/Legend";
import { RolesContext, UserContext } from "../../components/PrivateRoute";

export const Calendar = () => {
  const user = useContext(UserContext);
  const roles = useContext(RolesContext);
  const [state, setState] = useState<CalendarInterface>({
    loading: true,
  });

  useEffect(() => {
    setState({ ...state, loading: true });
    const calendarFetch = getCalendar(user);

    Promise.all([calendarFetch]).then(([calendar]) =>
      setState({
        ...state,
        data: calendar,
        rolesResource: getRolesResource(user.role, roles),
        loading: false,
      })
    );
    // eslint-disable-next-line
  }, []);

  const { loading } = state;

  return loading ? (
    <Loading />
  ) : (
    <Container maxWidth="lg">
      <Paper>
        <Legend />
        <CalendarComponent
          state={state as CalendarInterface}
          setState={setState as Dispatch<SetStateAction<CalendarInterface>>}
          defaultCurrentViewName={"Month"}
          startDayHour={9}
          endDayHour={22}
          height={"auto"}
          showToolbar={true}
          editEvent={true}
        />
      </Paper>
    </Container>
  );
};
