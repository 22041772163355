import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DesktopDateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Form, Formik } from "formik";
import { useContext } from "react";
import { UserContext } from "../../components/PrivateRoute";
import { EventAudience, EventFormSubmit } from "../../models/EventFormModel";
import { submitEventForm, updateEventForm } from "../../services/eventForm";
import { EventFormInterface } from "./EventForm";
import { Staff } from "../../models/Staff";

export const EventPost = ({
  state,
  setState,
}: {
  state: EventFormInterface;
  setState: (state: EventFormInterface) => void;
}) => {
  const user = useContext(UserContext);
  const theme = useTheme();

  const { postDialogOpen, postDialogEdit, selectedEventForm, inProgressEvents } = state;

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={postDialogOpen}
      onClose={() => setState({ ...state, postDialogOpen: false, postDialogEdit: false })}
      aria-describedby="alert-dialog-description"
    >
      <Formik
        initialValues={
          postDialogEdit
            ? {
                submittee: selectedEventForm!.submittee,
                name: selectedEventForm!.name,
                theme: selectedEventForm!.theme,
                purpose: selectedEventForm!.purpose,
                goals: selectedEventForm!.goals,
                location: selectedEventForm!.location,
                registrationGoal: selectedEventForm!.registrationGoal,
                audience: selectedEventForm!.audience,
                eventStartTime: selectedEventForm!.eventStartTime,
                eventEndTime: selectedEventForm!.eventEndTime,
                registrationOpenTime: selectedEventForm!.registrationOpenTime,
                registrationCloseTime: selectedEventForm!.registrationCloseTime,
                notes: selectedEventForm!.notes,
              }
            : {
                submittee: user as Staff,
                name: "",
                theme: "",
                purpose: "",
                goals: "",
                location: "",
                registrationGoal: 0,
                audience: EventAudience.ALL,
                eventStartTime: new Date(),
                eventEndTime: new Date(),
                registrationOpenTime: new Date(),
                registrationCloseTime: new Date(),
                notes: "",
              }
        }
        onSubmit={(values: EventFormSubmit) => {
          if (postDialogEdit) {
            updateEventForm(selectedEventForm!, values).then((formData) => {
              setState({
                ...state,
                postDialogOpen: false,
                postDialogEdit: false,
                inProgressEvents: [
                  formData,
                  ...state.inProgressEvents.filter((value) => {
                    return value.id !== formData.id;
                  }),
                ],
                eventFormDialogOpen: false,
              });
            });
          } else {
            submitEventForm(values).then((formData) => {
              setState({
                ...state,
                postDialogOpen: false,
                inProgressEvents: [formData, ...inProgressEvents],
              });
            });
          }
        }}
      >
        {(props) => {
          const { values, handleBlur, handleChange, isSubmitting, setFieldValue } = props;
          return (
            <Form>
              <DialogTitle id="form-dialog-title">Make an Event Request</DialogTitle>
              <DialogContent>
                <TextField
                  sx={{
                    mb: theme.spacing(2),
                  }}
                  id="name"
                  required
                  fullWidth
                  label="Name of the event"
                  onChange={handleChange}
                  value={values.name}
                  onBlur={handleBlur}
                  variant="standard"
                />
                <TextField
                  sx={{
                    mb: theme.spacing(1),
                  }}
                  id="purpose"
                  required
                  fullWidth
                  label="What is the purpose of your event?"
                  multiline
                  minRows={4}
                  maxRows={6}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.purpose}
                />
                <TextField
                  sx={{
                    mb: theme.spacing(1),
                  }}
                  id="goals"
                  required
                  fullWidth
                  label="What are the goals of your event?"
                  multiline
                  minRows={4}
                  maxRows={6}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.goals}
                />
                <TextField
                  sx={{
                    mb: theme.spacing(1),
                  }}
                  id="theme"
                  required
                  fullWidth
                  label="What is the theme of the event?"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="standard"
                  value={values.theme}
                />
                <TextField
                  sx={{
                    mb: theme.spacing(2),
                  }}
                  id="location"
                  required
                  fullWidth
                  label="Where is the location of the event?"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="standard"
                  value={values.location}
                />
                <TextField
                  id="registrationGoal"
                  required
                  fullWidth
                  type="number"
                  label="What is the registration goal for the event?"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="standard"
                  value={values.registrationGoal}
                />
                <Typography
                  color="textSecondary"
                  variant="body2"
                  sx={{
                    mb: theme.spacing(2),
                  }}
                >
                  Small: Less than 50, Medium: 50 - 100, Large: More than 100
                </Typography>
                <InputLabel id="audience-label" required>
                  Who is the target audience?
                </InputLabel>
                <Typography color="textSecondary" variant="body2">
                  Internal: SOW members
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  External: non-SOW members
                </Typography>
                <Select
                  required
                  fullWidth
                  labelId="audience-label"
                  id="audience"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="audience"
                  value={values.audience}
                  variant="outlined"
                  sx={{
                    mb: theme.spacing(1),
                  }}
                >
                  {Object.values(EventAudience).map((aud, i) => (
                    <MenuItem key={i} value={aud}>
                      {aud}
                    </MenuItem>
                  ))}
                </Select>
                <Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    sx={{
                      mb: theme.spacing(2),
                    }}
                  >
                    <Grid>
                      <Typography
                        sx={{
                          mt: theme.spacing(1),
                        }}
                        variant="body2"
                        color="textSecondary"
                      >
                        Event start time
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDateTimePicker
                          value={values.eventStartTime}
                          onChange={(value: Date | null) => setFieldValue("eventStartTime", value)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid>
                      <Typography
                        sx={{
                          mt: theme.spacing(1),
                        }}
                        variant="body2"
                        color="textSecondary"
                      >
                        Event end time
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDateTimePicker
                          value={values.eventEndTime}
                          minDate={values.eventStartTime}
                          onChange={(value: Date | null) => setFieldValue("eventEndTime", value)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justifyContent="space-around">
                    <Grid>
                      <Typography
                        sx={{
                          mt: theme.spacing(1),
                        }}
                        variant="body2"
                        color="textSecondary"
                      >
                        Registrations to open
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDateTimePicker
                          value={values.registrationOpenTime}
                          onChange={(value: Date | null) => setFieldValue("registrationOpenTime", value)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid>
                      <Typography
                        sx={{
                          mt: theme.spacing(1),
                        }}
                        variant="body2"
                        color="textSecondary"
                      >
                        Registrations to close
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDateTimePicker
                          value={values.registrationCloseTime}
                          minDate={values.registrationOpenTime}
                          maxDate={values.eventStartTime}
                          onChange={(value: Date | null) => setFieldValue("registrationCloseTime", value)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <TextField
                  sx={{
                    m: theme.spacing(2, 0, 2, 0),
                  }}
                  id="notes"
                  required
                  fullWidth
                  label="Any notes to add?"
                  multiline
                  minRows={4}
                  maxRows={6}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.notes}
                />
              </DialogContent>
              <DialogActions>
                {!isSubmitting ? (
                  <>
                    <Button
                      onClick={() => setState({ ...state, postDialogOpen: false, postDialogEdit: false })}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained">
                      {postDialogEdit ? "Update" : "Submit"}
                    </Button>
                  </>
                ) : (
                  <CircularProgress color="primary" />
                )}
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
