import { Button, Grid, TextField, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import { signin } from "../../services/auth";
import * as yup from "yup";

const signInFormValidationSchema = yup.object().shape({
  email: yup.string().email().required("Enter your email"),
  password: yup.string().required("Enter your password"),
});
export const SignInForm = ({ setError }: { setError: (message: string) => void }) => {
  const theme = useTheme();

  const submit = (email: string, password: string) => {
    signin(email, password).catch((error) => {
      setError(error.message);
    });
  };
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={(values) => {
        submit(values.email, values.password);
      }}
      validationSchema={signInFormValidationSchema}
    >
      {(props) => {
        const { values, errors, touched, handleBlur, handleChange } = props;
        return (
          <Form>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              helperText={errors.email && touched.email ? errors.email : ""}
              value={values.email}
              error={errors.email && touched.email ? true : false}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="standard"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              helperText={errors.password && touched.password ? errors.password : ""}
              value={values.password}
              error={errors.password && touched.password ? true : false}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="current-password"
              variant="standard"
            />
            <Grid
              container
              sx={{
                margin: theme.spacing(3, 0, 2),
              }}
            >
              <Button fullWidth size="large" variant="contained" type="submit">
                Log In
              </Button>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
