import { useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { signup } from "../../services/auth";
import { Path } from "../../helpers/Path";
import { Container, Typography, TextField, Grid, Snackbar, Link, Button } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Alert } from "@mui/material";

const signUpFormValidationSchema = yup.object().shape({
  email: yup.string().email().required("Enter valid email"),
  password: yup
    .string()
    .required("Please enter your password")
    .matches(
      /^(?=.*[A-Za-z])(?=.*[0-9!@#$%^&*()]).{7,20}\S$/,
      "Password must contain at least 8 characters, one alphabetic, one number or special case character"
    ),
  confirmPassword: yup
    .string()
    .required("Required")
    .test("password-match", "Password must match", function (value) {
      return this.parent.password === value;
    }),
});

export const SignUp = () => {
  const theme = useTheme();
  const [state, setState] = useState({ showMessage: false, error: "" });

  const handleMessageClose = () => {
    setState({ ...state, showMessage: false });
  };

  const submit = (values: { email: string; password: string; confirmPassword: string }) => {
    signup({ ...values, email: values.email.toLowerCase() }).catch((error) => {
      setState({ showMessage: true, error: error.message });
    });
  };

  const { showMessage, error } = state;

  return (
    <>
      <Container component="main" maxWidth="sm">
        <div>
          <Snackbar open={showMessage} onClose={handleMessageClose} autoHideDuration={6000}>
            <Alert elevation={6} variant="filled" severity="error">
              {error}
            </Alert>
          </Snackbar>
          <Container maxWidth="md">
            <Typography variant="h4" align="center" gutterBottom>
              Sign Up
            </Typography>
            <Typography align="center" gutterBottom>
              Create a SOW Account
            </Typography>
          </Container>
          <Formik
            initialValues={{
              email: "",
              password: "",
              confirmPassword: "",
            }}
            onSubmit={submit}
            validationSchema={signUpFormValidationSchema}
          >
            {(props) => {
              const { values, errors, touched, handleBlur, handleChange } = props;
              return (
                <Form>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    helperText={errors.email && touched.email ? errors.email : ""}
                    value={values.email}
                    error={errors.email && touched.email ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="standard"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    helperText={errors.password && touched.password ? errors.password : ""}
                    value={values.password}
                    error={errors.password && touched.password ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="current-password"
                    variant="standard"
                  />
                  <TextField
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    value={values.confirmPassword}
                    type="password"
                    helperText={
                      errors.confirmPassword && touched.confirmPassword
                        ? errors.confirmPassword
                        : "Re-enter password to confirm"
                    }
                    error={errors.confirmPassword && touched.confirmPassword ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="confirmPassword"
                    autoComplete="new-password"
                    variant="standard"
                  />
                  <Grid
                    container
                    sx={{
                      margin: theme.spacing(3, 0, 2),
                    }}
                  >
                    <Button fullWidth size="large" variant="contained" type="submit">
                      Sign Up
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
        <Grid container justifyContent="center">
          <Grid item>
            <Link
              sx={{
                textDecoration: "none",
                color: "black",
                fontSize: "large",
              }}
              underline="hover"
              component={ReactRouterLink}
              to={Path.SignIn}
            >
              Already have an account? Log in
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
