import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Button, CircularProgress, Grid, IconButton, TextField, useTheme } from "@mui/material";
import { User } from "../../models/User";
import { updateDivisions } from "../../services/divisions";
import { OrganisationStructureInterface } from "./OrganisationStructure";

export const DivisionsComponent = ({
  state,
  setState,
}: {
  state: OrganisationStructureInterface;
  setState: (state: OrganisationStructureInterface) => void;
}) => {
  const theme = useTheme();
  const getFirstName = (email?: string) =>
    email?.match("@sowaustralia.com$")
      ? email.split(".")[0].charAt(0).toUpperCase() + email.split(".")[0].slice(1)
      : "";
  const getLastName = (email?: string) =>
    email?.match("@sowaustralia.com$")
      ? email.split(".")[1].split("@")[0].charAt(0).toUpperCase() + email.split(".")[1].split("@")[0].slice(1)
      : "";

  const updateDivisionsForYear = () =>
    updateDivisions(state.year, divisions)
      .then(() =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: "Successfully saved",
            severity: "success",
          },
          editState: { ...editState, divisions: false },
          saving: false,
        })
      )
      .catch((error) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: error.message,
            severity: "error",
          },
        })
      );

  const handleDeleteDivision = (division: string) => {
    delete divisions[division];
    setState({ ...state, divisions });
  };

  const handleAddDivision = () => {
    if (!divisions[""]) {
      divisions[""] = {
        head: users[0],
      };
      setState({ ...state, divisions });
    }
  };

  const { divisions, divisionsTemp, users, editState, saving } = state;

  return (
    <>
      {editState.divisions ? (
        saving ? (
          <CircularProgress />
        ) : (
          <Grid item>
            <Button
              variant="contained"
              onClick={() =>
                setState({
                  ...state,
                  divisions: divisionsTemp,
                  editState: { ...editState, divisions: false },
                })
              }
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setState({ ...state, saving: true });
                updateDivisionsForYear();
              }}
            >
              Save
            </Button>
          </Grid>
        )
      ) : (
        <Button
          variant="contained"
          disabled={saving}
          onClick={() =>
            setState({
              ...state,
              divisionsTemp: divisions,
              editState: { ...editState, divisions: true },
            })
          }
        >
          Edit
        </Button>
      )}
      <Grid sx={{ mt: theme.spacing(1) }} container direction="column" spacing={2}>
        {Object.entries(divisions).map(([division, content], index) => {
          return (
            <Grid container spacing={2} item key={index}>
              <Grid item>
                <TextField
                  label="Division"
                  id="division"
                  disabled={!editState.divisions || saving || index !== Object.entries(divisions).length - 1}
                  sx={{
                    width: 150,
                  }}
                  required
                  placeholder="Division"
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    delete divisions[division];
                    divisions[event.target.value] = content;
                    setState({ ...state, divisions: divisions });
                  }}
                  value={division}
                  variant="filled"
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  id={"division-head"}
                  disabled={!editState.divisions || saving}
                  getOptionLabel={(option) =>
                    option.firstName
                      ? `${option.firstName} ${option.lastName} - ${option.role}`
                      : `${getFirstName(option.email)} ${getLastName(option.email)} - ${option.role}`
                  }
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={content.head}
                  onChange={(event, newValue: User | null) => {
                    if (newValue) {
                      divisions[division] = {
                        ...content,
                        head: newValue,
                      };

                      setState({
                        ...state,
                        divisions: divisions,
                      });
                    }
                  }}
                  options={users}
                  sx={{
                    width: 200,
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Head" placeholder={`Select Head`} />
                  )}
                />
              </Grid>
              <Grid item>
                <IconButton
                  disabled={!editState.divisions || saving}
                  color="primary"
                  aria-label="delete"
                  component="span"
                  onClick={() => handleDeleteDivision(division)}
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Button variant="contained" disabled={!editState.divisions || saving} onClick={() => handleAddDivision()}>
        Add Division
      </Button>
    </>
  );
};
