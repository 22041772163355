import { Container, Grid } from "@mui/material";
import { Loading } from "./Loading";
import { ModulesList } from "../pages/modules/ModulesList";
import { ModulesComponentInterface } from "../pages/modules/Modules";
import { moduleMonths } from "../helpers/FormatDate";

export const ModulesComponent = ({ state }: { state: ModulesComponentInterface }) => {
  const { modules, loading } = state;

  return loading ? (
    <Loading />
  ) : (
    <Container maxWidth="lg">
      <Grid container>
        {modules.map((list, i) => (
          <ModulesList title={moduleMonths[i]} modules={list} key={i} />
        ))}
      </Grid>
    </Container>
  );
};
