import { Box, Paper, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { TypographyBold } from "../../../../components/TypographyBold";
import {
  BLIND_SPOT,
  CLEAR_STRENGTH,
  DEVELOPMENT_OPPORTUNITY,
  RELATIVE_STRENGTH,
  ReviewAverageGroup,
  ReviewQuestions,
  ReviewResults,
  ReviewResultsOfAll,
} from "../../../../models/Review360Model";
import { getReviewersAverage, round2 } from "../../../../services/review360";

interface TableRow {
  id: number;
  category: string;
  attribute: string;
  learningOutcome: string;
  self: number;
  reviewers: number;
  overallAverage: number;
  area: string;
}

export const Review360ResultsAreas = ({
  results,
  resultsOfAll,
  questions,
}: {
  results: ReviewResults;
  resultsOfAll: ReviewResultsOfAll;
  questions: ReviewQuestions;
}) => {
  const theme = useTheme();

  const itemColumns = [
    { field: "id", headerName: "ID", flex: 1, maxWidth: 30 },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      field: "attribute",
      headerName: "Attribute",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "learningOutcome",
      headerName: "Learning Outcome",
      flex: 1,
      minWidth: 350,
    },
    { field: "self", headerName: "Me", flex: 1, maxWidth: 70 },
    {
      field: "reviewers",
      headerName: "Reviewers'",
      flex: 1,
      maxWidth: 70,
    },
    {
      field: "overallAverage",
      headerName: "Overall",
      flex: 1,
      maxWidth: 70,
    },
  ];

  const AreasTable = ({ rows }: { rows: TableRow[] }) =>
    rows.length > 0 ? (
      <Box component={Paper} sx={{ mb: theme.spacing(4) }}>
        <DataGrid
          autoHeight
          getRowHeight={() => "auto"}
          checkboxSelection={false}
          rows={rows}
          columns={itemColumns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </Box>
    ) : (
      <Typography sx={{ mb: theme.spacing(4) }} variant="body2" color="textSecondary">
        There are no items for this area.
      </Typography>
    );

  const getOverallAverage = (category: string, attribute: string, index: number) => {
    if (resultsOfAll.numberOfTotalReviewers - results.numberOfReviewers === 0) {
      return NaN;
    }
    return (
      (resultsOfAll.averagesOfAll[category][attribute][index] * resultsOfAll.numberOfTotalReviewers -
        getReviewersAverage(results.averages[category][attribute][index], results.numberOfReviewers) *
          results.numberOfReviewers) /
      (resultsOfAll.numberOfTotalReviewers - results.numberOfReviewers)
    );
  };

  const filterArea = (
    area: string,
    category: string,
    attribute: string,
    index: number,
    averageGroups: ReviewAverageGroup
  ) => {
    const self = averageGroups.Self;
    const reviewersAverage = getReviewersAverage(averageGroups, results.numberOfReviewers);
    const overallAverage = getOverallAverage(category, attribute, index);
    if (!reviewersAverage || !overallAverage) {
      return false;
    } else if (area === CLEAR_STRENGTH) {
      return self >= overallAverage && reviewersAverage >= overallAverage;
    } else if (area === RELATIVE_STRENGTH) {
      return self < overallAverage && reviewersAverage >= overallAverage;
    } else if (area === BLIND_SPOT) {
      return self >= overallAverage && reviewersAverage < overallAverage;
    } else if (area === DEVELOPMENT_OPPORTUNITY) {
      return self < overallAverage && reviewersAverage < overallAverage;
    }
    return false;
  };

  const sortAreas = (a: TableRow, b: TableRow) => {
    if (a.area === CLEAR_STRENGTH) {
      return a.self - a.overallAverage + a.reviewers - a.overallAverage <
        b.self - b.overallAverage + b.reviewers - b.overallAverage
        ? 1
        : -1;
    } else if (a.area === RELATIVE_STRENGTH) {
      return a.reviewers - a.self < b.reviewers - b.self ? 1 : -1;
    } else if (a.area === BLIND_SPOT) {
      return a.self - a.reviewers < b.self - b.reviewers ? 1 : -1;
    } else if (a.area === DEVELOPMENT_OPPORTUNITY) {
      return a.overallAverage - a.self + a.overallAverage - a.reviewers <
        b.overallAverage - b.self + b.overallAverage - b.reviewers
        ? 1
        : -1;
    }
    return 1;
  };

  const getRows = (area: string): TableRow[] => {
    return Object.entries(results.averages)
      .map(([category, attributes]) =>
        Object.entries(attributes)
          .map(([attribute, learningOutcomeAverages]) =>
            Object.entries(learningOutcomeAverages)
              .filter(([index, averageGroups]) => filterArea(area, category, attribute, Number(index), averageGroups))
              .map(([index, averageGroups]) => ({
                category,
                attribute,
                learningOutcome: questions[category][attribute][Number(index)],
                self: round2(averageGroups.Self),
                reviewers: round2(getReviewersAverage(averageGroups, results.numberOfReviewers)),
                overallAverage: round2(getOverallAverage(category, attribute, Number(index))),
                area,
              }))
          )
          .flat()
      )
      .flat()
      .map((a, i) => ({ ...a, id: i }))
      .sort(sortAreas);
  };

  return (
    <>
      <TypographyBold variant="h4">Areas</TypographyBold>
      <Typography variant="h5">Clear Strengths</Typography>
      <Typography variant="h6">Your ratings and your reviewers' ratings are both ABOVE the Overall Average.</Typography>
      <AreasTable rows={getRows(CLEAR_STRENGTH)} />
      <Typography variant="h5">Relative Strengths</Typography>
      <Typography variant="h6">
        Your ratings are BELOW the Overall Average, while the average rating from Your Reviewers is ABOVE the Overall
        Average.
      </Typography>
      <AreasTable rows={getRows(RELATIVE_STRENGTH)} />
      <Typography variant="h5">Blind Spots</Typography>
      <Typography variant="h6">
        Your ratings are ABOVE the Overall Average, while the average rating from Your Reviewers is BELOW the Overall
        Average.
      </Typography>
      <AreasTable rows={getRows(BLIND_SPOT)} />
      <Typography variant="h5">Development Opportunities</Typography>
      <Typography variant="h6">Your ratings and Your Reviewers rating are both BELOW the Overall Average.</Typography>
      <AreasTable rows={getRows(DEVELOPMENT_OPPORTUNITY)} />
    </>
  );
};
