import {
  Box,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { FinanceSubFormModel, SubFormModel } from "../../../models/EventFormModel";
import { formatDateTime } from "../../../helpers/FormatDate";
import { SectionTable } from "./SubFormFinanceTable";
import { SubFormStatusComponentDisplay } from "./SubForm";

export const SubFormFinance = ({
  canEdit,
  subForm,
  setSubForm,
  setSubFormProperty,
}: {
  canEdit: boolean;
  subForm: FinanceSubFormModel;
  setSubForm: (subForm: FinanceSubFormModel) => void;
  setSubFormProperty: (property: keyof FinanceSubFormModel, value: any) => void;
}) => {
  const { salesRevenues, directExpenses, indirectExpenses, equipmentMaterials, travelOthers, salesRevsTotal } = subForm;
  const theme = useTheme();

  const directorNeedsToApprove = subForm.total - salesRevsTotal < -5000;

  return (
    <>
      <Box>
        <Typography variant="h4">BUDGET ELEMENTS</Typography>
        <Box my={1} />
        <Typography variant="body1">
          1.{")"} <strong>Sales/Revenue</strong>: all revenue which is generated by the project - minimise your expenses
          by creating different sources of revenue!
        </Typography>
        <Typography variant="body1" sx={{ marginLeft: "40px" }}>
          a. Registration income, sponsorships/partnerships, food sales, merchandise sales, service sales, etc.
        </Typography>
        <Box my={1} />
        <Typography variant="body1">
          2.{")"} <strong>Direct Expenses</strong>: expenses which relate directly to the making of the project; the
          essentials.
        </Typography>
        <Typography variant="body1" sx={{ marginLeft: "40px" }}>
          a. Venue hire, registration fees (include all types), food/drinks, service fees, honorariums, care packages.
        </Typography>
        <Box my={1} />
        <Typography variant="body1">
          3.{")"} <strong>Indirect Expenses</strong>: expenses which relate indirectly to the project; not essential but
          still needed.
        </Typography>
        <Typography variant="body1" sx={{ marginLeft: "40px" }}>
          a. Utilities, insurance, advertising, IT subscriptions/fees, etc.
        </Typography>
        <Box my={1} />
        <Typography variant="body1">
          4.{")"} <strong>Equipment and Materials</strong>: a sub-category of direct costs.
        </Typography>
        <Typography variant="body1" sx={{ marginLeft: "40px" }}>
          a. Games equipment, decorations, first-aid kits, furniture, printing/stationery, merchandise costs, etc.
        </Typography>
        <Box my={1} />
        <Typography variant="body1">
          5.{")"} <strong>Travel and Other Expenses</strong>: travelling expenses and other unexpected fees.
        </Typography>
        <Typography variant="body1" sx={{ marginLeft: "40px" }}>
          a. Motor vehicle fuel, extra transport hire, buffers, contingency-plan expenses, snacks, etc.
        </Typography>
      </Box>
      <Box my={4} />
      <Typography variant="h4">BUDGET BREAKDOWN</Typography>
      <Box my={1} />
      <SectionTable
        canEdit={canEdit}
        entries={salesRevenues}
        expenseOrRevenue={"Revenue"}
        heading={"1) SALES/REVENUE"}
        section={"salesRevenues"}
        subForm={subForm as FinanceSubFormModel}
        setSubForm={setSubForm}
        setSubFormProperty={(property: keyof FinanceSubFormModel, value: any) =>
          setSubFormProperty(property as keyof SubFormModel, value)
        }
        sectionTotal={"salesRevsTotal"}
      />
      <SectionTable
        canEdit={canEdit}
        entries={directExpenses}
        expenseOrRevenue={"Expense"}
        heading={"2) DIRECT EXPENSES"}
        section={"directExpenses"}
        subForm={subForm as FinanceSubFormModel}
        setSubForm={setSubForm}
        setSubFormProperty={(property: keyof FinanceSubFormModel, value: any) =>
          setSubFormProperty(property as keyof SubFormModel, value)
        }
        sectionTotal={"directExpsTotal"}
      />
      <SectionTable
        canEdit={canEdit}
        entries={indirectExpenses}
        expenseOrRevenue={"Expense"}
        heading={"3) INDIRECT EXPENSES"}
        section={"indirectExpenses"}
        subForm={subForm as FinanceSubFormModel}
        setSubForm={setSubForm}
        setSubFormProperty={(property: keyof FinanceSubFormModel, value: any) =>
          setSubFormProperty(property as keyof SubFormModel, value)
        }
        sectionTotal={"indirectExpsTotal"}
      />
      <SectionTable
        canEdit={canEdit}
        entries={equipmentMaterials}
        expenseOrRevenue={"Expense"}
        heading={"4) EQUIPMENT AND MATERIALS"}
        section={"equipmentMaterials"}
        subForm={subForm as FinanceSubFormModel}
        setSubForm={setSubForm}
        setSubFormProperty={(property: keyof FinanceSubFormModel, value: any) =>
          setSubFormProperty(property as keyof SubFormModel, value)
        }
        sectionTotal={"equipMaterialsTotal"}
      />
      <SectionTable
        canEdit={canEdit}
        entries={travelOthers}
        expenseOrRevenue={"Expense"}
        heading={"5) TRAVEL AND OTHER EXPENSES"}
        section={"travelOthers"}
        subForm={subForm as FinanceSubFormModel}
        setSubForm={setSubForm}
        setSubFormProperty={(property: keyof FinanceSubFormModel, value: any) =>
          setSubFormProperty(property as keyof SubFormModel, value)
        }
        sectionTotal={"travelOthersTotal"}
      />
      <Box my={4} />
      <Typography variant="h4">BUDGET SUMMARY</Typography>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="none">1{")"} Sales/Revenue</TableCell>
              <TableCell align="left" padding="none">
                2{")"} Direct Costs
              </TableCell>
              <TableCell align="left" padding="none">
                3{")"} Indirect Costs
              </TableCell>
              <TableCell align="left">4{")"} Equipmennt and Materials</TableCell>
              <TableCell align="left" padding="none">
                5{")"} Travel and other Expenses
              </TableCell>
              <TableCell align="left" padding="none">
                Total = (1-2-3-4-5)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row" padding="none">
                <TextField
                  size="small"
                  disabled={true}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  value={subForm.salesRevsTotal}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: theme.spacing(1) }}
                />
              </TableCell>
              <TableCell align="right" padding="none">
                <TextField
                  size="small"
                  disabled={true}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  type="number"
                  value={subForm.directExpsTotal}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: theme.spacing(1) }}
                />
              </TableCell>
              <TableCell align="right" padding="none">
                <TextField
                  size="small"
                  disabled={true}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  type="number"
                  value={subForm.indirectExpsTotal}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: theme.spacing(1) }}
                />
              </TableCell>
              <TableCell align="right" padding="none">
                <TextField
                  size="small"
                  disabled={true}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  value={subForm.equipMaterialsTotal}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: theme.spacing(1) }}
                />
              </TableCell>
              <TableCell padding="none">
                <TextField
                  size="small"
                  disabled={true}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  value={subForm.travelOthersTotal}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: theme.spacing(1) }}
                />
              </TableCell>
              <TableCell padding="none">
                <TextField
                  size="small"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  disabled={true}
                  value={subForm.total}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: theme.spacing(1) }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={4} />
      {subForm.status && (
        <Grid>
          <Typography>Budget Manager Approval Status: {formatDateTime(subForm.approvedByBudgetManagerTime)}</Typography>
          <SubFormStatusComponentDisplay status={subForm.budgetManagerApprovalStatus} variant="body1" />
          {directorNeedsToApprove && (
            <>
              <Typography>Director Approval Status: {formatDateTime(subForm.approvedByDirectorTime)}</Typography>
              <SubFormStatusComponentDisplay status={subForm.directorApprovalStatus} variant="body1" />
            </>
          )}
          <Typography>Finance Head Approval Status: {formatDateTime(subForm.approvedDate)}</Typography>
          <SubFormStatusComponentDisplay status={subForm.status} variant="body1" />
        </Grid>
      )}
    </>
  );
};
