import { useContext } from "react";
import * as yup from "yup";
import { Dialog } from "@mui/material";
import { Formik, FormikProps } from "formik";
import { Staff } from "../../models/Staff";
import { DesignRequest } from "../../models/DesignRequest";
import { MessageState } from "../../models/MessageState";
import { DesignRequestsForm } from "./DesignRequestsForm";
import { RequestStatus } from "../../data/RequestStatus";
import { submitDesignRequest } from "../../services/designRequests";
import { DepartmentsContext } from "../../components/PrivateRoute";

const DesignRequestsFormValidationSchema = yup.object().shape({
  department: yup.string().required("Enter department"),
  details: yup.string().required("Enter details"),
  theme: yup.string().required("Enter theme"),
  example: yup.string(),
  message: yup.string().required("Enter message"),
  dueDate: yup.date().required("Enter due date"),
  multipleDrafts: yup.bool().required("Answer yes or no"),
  runTheDesign: yup.bool().required("Answer yes or no"),
  extraInformation: yup.string(),
  detailsOfEventOrProject: yup.string(),
});

export const DesignRequestsSubmit = ({
  staff,
  dialogOpen,
  closeDialog,
  handlePost,
}: {
  staff: Staff;
  dialogOpen: boolean;
  closeDialog: () => void;
  handlePost: (m: MessageState, r: DesignRequest | null) => void;
}) => {
  const departments = useContext(DepartmentsContext);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={dialogOpen}
      onClose={closeDialog}
      aria-describedby="alert-dialog-description"
    >
      <Formik
        initialValues={{
          submittedTime: new Date(),
          staff: staff,
          approvedByHOD: RequestStatus.PENDING,
          type: {
            event: false,
            promotion: false,
            pr: false,
            other: false,
          },
          productType: {
            brochure: false,
            flyer: false,
            booklet: false,
            facebookbanner: false,
            infographic: false,
            invitation: false,
            logo: false,
            merchandise: false,
            poster: false,
            powerpointPresentation: false,
            other: false,
          },
          department: staff.department,
          details: "",
          theme: "",
          message: "",
          dueDate: new Date(),
          multipleDrafts: false,
          runTheDesign: false,
          completed: false,
        }}
        onSubmit={(values: DesignRequest) => {
          submitDesignRequest(values, departments)
            .then((dr) =>
              handlePost(
                {
                  showMessage: true,
                  message: "Successfully submitted design request! An email has been sent to the Marketing Team.",
                  severity: "success",
                },
                dr
              )
            )
            .catch((error) => {
              handlePost(
                {
                  showMessage: true,
                  message: error.message,
                  severity: "error",
                },
                null
              );
            });
        }}
        validationSchema={DesignRequestsFormValidationSchema}
      >
        {(props: FormikProps<DesignRequest>) => {
          return <DesignRequestsForm props={props} handleClose={closeDialog} />;
        }}
      </Formik>
    </Dialog>
  );
};
