import { Typography } from "@mui/material";
import { TypographyBold } from "../../components/TypographyBold";
import { formatDateTime } from "../../helpers/FormatDate";
import { EventFormModel } from "../../models/EventFormModel";
import { Staff } from "../../models/Staff";

export const EventFormInformation = ({ eventForm }: { eventForm: EventFormModel }) => {
  return (
    <>
      <TypographyBold variant="h5" gutterBottom>
        {eventForm.name}
      </TypographyBold>
      <TypographyBold gutterBottom>
        by {eventForm.submittee.firstName} {eventForm.submittee.lastName} ({(eventForm.submittee as Staff).department})
      </TypographyBold>
      <TypographyBold variant="body2">Submitted Time</TypographyBold>
      <Typography gutterBottom>{formatDateTime(eventForm.submittedTime)}</Typography>
      <TypographyBold variant="body2">Last Updated</TypographyBold>
      <Typography gutterBottom>{formatDateTime(eventForm.updatedTime)}</Typography>
      <TypographyBold variant="body2">Event Start Time</TypographyBold>
      <Typography gutterBottom>{formatDateTime(eventForm.eventStartTime)}</Typography>
      <TypographyBold variant="body2">Event End Time</TypographyBold>
      <Typography gutterBottom>{formatDateTime(eventForm.eventEndTime)}</Typography>
      <TypographyBold variant="body2">Event Location</TypographyBold>
      <Typography gutterBottom>{eventForm.location}</Typography>
      <TypographyBold variant="body2">Event Purpose</TypographyBold>
      <Typography gutterBottom>{eventForm.purpose}</Typography>
      <TypographyBold variant="body2">Event Goals</TypographyBold>
      <Typography gutterBottom>{eventForm.goals}</Typography>
      <TypographyBold variant="body2">Event Theme</TypographyBold>
      <Typography gutterBottom>{eventForm.theme}</Typography>
      <TypographyBold variant="body2">Registration Goal</TypographyBold>
      <Typography gutterBottom>{eventForm.registrationGoal}</Typography>
      <TypographyBold variant="body2">Target Audience</TypographyBold>
      <Typography gutterBottom>{eventForm.audience}</Typography>
      <TypographyBold variant="body2">Registrations To Open</TypographyBold>
      <Typography gutterBottom>{formatDateTime(eventForm.registrationOpenTime)}</Typography>
      <TypographyBold variant="body2">Registrations To Close</TypographyBold>
      <Typography gutterBottom>{formatDateTime(eventForm.registrationCloseTime)}</Typography>
      <TypographyBold variant="body2">Notes</TypographyBold>
      <Typography gutterBottom>{eventForm.notes}</Typography>
    </>
  );
};
