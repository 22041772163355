import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export const RequestsHowDialog = ({ dialogOpen, handleClose }: { dialogOpen: boolean; handleClose: () => void }) => (
  <Dialog
    open={dialogOpen}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{"How the Process Works"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        1. Must submit a request with a description of what you want to buy (NOTE: Make sure you make a request BEFORE
        making purchases).
      </DialogContentText>
      <DialogContentText id="alert-dialog-description">
        2. Wait for approval from HOD, Finance Budget manager and Finance Head.
      </DialogContentText>
      <DialogContentText id="alert-dialog-description">
        3. An email will be sent to you when it is approved.
      </DialogContentText>
      <DialogContentText id="alert-dialog-description">4. Make Purchase (if applicable).</DialogContentText>
      <DialogContentText id="alert-dialog-description">
        5. Submit an invoice/receipt, with proof of transaction.
      </DialogContentText>
      <DialogContentText id="alert-dialog-description">
        6. The finance head will send you the reimbursement (up to your requested amount, anything amounting over will
        require discussion) and you will receive an email once it is done.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} autoFocus>
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);
