import { SOW_WEBSITE } from "../helpers/Env";
import { Path } from "../helpers/Path";
import { RequestStatus } from "../data/RequestStatus";
import { Staff } from "../models/Staff";
import { PromotionRequestDeclined } from "../models/PromotionRequestDeclined";
import { PromotionRequestCommentData } from "../models/PromotionRequestCommentData";
import { PromotionRequest } from "../models/PromotionRequest";
import { PromotionRequestEmailForm } from "../models/PromotionRequestEmailForm";
import { sendPromotionRequestEmail } from "./email";
import { db } from "./firebase";
import { approved, declined, pending, WhereFilterOp } from "./requests";
import { getHODByDepartment, getUserById, getUserByIdYear } from "./users";
import { currentStaffYear } from "../helpers/FormatDate";
import { DepartmentInterface } from "../models/Department";
import { getStaffYearDepartments } from "./departments";
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDocs,
  query,
  QuerySnapshot,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

export const promotionRequestStatus = (promotionRequest: PromotionRequest): RequestStatus => {
  if (promotionRequestApproved(promotionRequest)) {
    return RequestStatus.APPROVED;
  } else if (promotionRequestDeclined(promotionRequest)) {
    return RequestStatus.DECLINED;
  } else {
    return RequestStatus.PENDING;
  }
};

export const promotionRequestApproved = (promotionRequest: PromotionRequest): boolean =>
  approved(promotionRequest.approvedByHOD);

export const promotionRequestDeclined = (promotionRequest: PromotionRequest): boolean =>
  declined(promotionRequest.approvedByHOD);

export const promotionRequestPending = (promotionRequest: PromotionRequest): boolean =>
  pending(promotionRequest.approvedByHOD);

export const promotionRequestCompleted = (promotionRequest: PromotionRequest) => promotionRequest.completed === true;

export const getAllPromotionRequests = (
  staff: Staff,
  departments: DepartmentInterface
): Promise<Promise<PromotionRequest[]>[]> =>
  new Promise((resolve) => resolve([getMyPromotionRequests(staff), getPromotionRequests(staff, departments)]));

const getMyPromotionRequests = (staff: Staff): Promise<PromotionRequest[]> =>
  getRequestsWhere("userID", "==", staff.id);

const getPromotionRequests = (staff: Staff, departments: DepartmentInterface): Promise<PromotionRequest[]> => {
  if (staff.department === departments.MARKETING) {
    return getRequestsWhere("userID", "!=", "fakeemail@mail.com");
  } else {
    return new Promise((resolve) => {
      resolve([]);
    });
  }
};

const getCommentSection = (id: string, year: string): Promise<PromotionRequestCommentData[]> =>
  new Promise((resolve, reject) => {
    getDocs(collection(db, "promotionRequests", "promotionRequests", year, id, "comments"))
      .then((res) => {
        const p = res.docs.map(async (doc) => {
          const userFetch = await getUserById(doc.data().userID);
          return {
            comment: doc.data().comment,
            staff: userFetch,
            submittedTime: doc.data().submittedTime.toDate(),
            id: doc.id,
          } as PromotionRequestCommentData;
        });
        Promise.all(p).then((list) => resolve(list.sort((a, b) => (a.submittedTime > b.submittedTime ? 1 : -1))));
      })
      .catch((error) => {
        reject(error);
      });
  });

const getRequestsWhere = (category: string, is: WhereFilterOp, incoming_category: any): Promise<PromotionRequest[]> =>
  new Promise((resolve, reject) =>
    getDocs(
      query(
        collection(db, "promotionRequests", "promotionRequests", currentStaffYear.toString()),
        where(category, is, incoming_category)
      )
    )
      .then((res) => {
        const p = getDataFromFirestoreYear(res, currentStaffYear.toString());
        Promise.all(p).then((list) => resolve(list.sort((a, b) => (a.submittedTime > b.submittedTime ? 1 : -1))));
      })
      .catch((error) => {
        reject(error);
      })
  );

export const getYearPromotionRequests = (year: string): Promise<PromotionRequest[]> =>
  new Promise((resolve, reject) =>
    getDocs(collection(db, "promotionRequests", "promotionRequests", year))
      .then((res) => {
        const p = getDataFromFirestoreYear(res, year);
        Promise.all(p).then((list) => resolve(list.sort((a, b) => (a.submittedTime > b.submittedTime ? 1 : -1))));
      })
      .catch((error) => {
        reject(error);
      })
  );
const getDataFromFirestoreYear = (res: QuerySnapshot<DocumentData>, year: string) =>
  res.docs.map(async (doc) => {
    const departments = await getStaffYearDepartments(year);
    const hodFetch = await getHODByDepartment(departments, departments.MARKETING);
    const userFetch = await getUserByIdYear(doc.data().userID, year);
    const commentSectionFetch = await getCommentSection(doc.id, year);
    return {
      ...doc.data(),
      staff: userFetch,
      hod: hodFetch,
      submittedTime: doc.data().submittedTime.toDate(),
      approvedTime: doc.data().approvedTime ? doc.data().approvedTime.toDate() : undefined,
      declinedTime: doc.data().declinedTime ? doc.data().declinedTime.toDate() : undefined,
      completedTime: doc.data().completedTime ? doc.data().completedTime.toDate() : undefined,
      commentSection: commentSectionFetch,
      id: doc.id,
    } as PromotionRequest;
  });

const sendNeedApprovalEmail = (promotionRequest: PromotionRequest) =>
  sendPromotionRequestEmail({
    email: promotionRequest.hod?.email,
    subject:
      "The promotion request: #" +
      promotionRequest.id +
      " from " +
      promotionRequest.staff.firstName +
      " needs your approval",
    name: promotionRequest.hod?.firstName,
    message:
      "The promotion request below requires your approval. To approve, please go to the dashboard through the link below. Before approving, you can ask questions in the comment section.",
    promotionRequest: promotionRequest,
    website: SOW_WEBSITE + Path["Promotion Requests"],
  } as PromotionRequestEmailForm);

const sendEmailSent = (promotionRequest: PromotionRequest) =>
  sendPromotionRequestEmail({
    email: promotionRequest.staff.email,
    subject: "Your promotion request: #" + promotionRequest.id + " has been submitted",
    name: promotionRequest.staff.firstName,
    message:
      "The promotion request below has been submitted to the " +
      promotionRequest.hod?.department +
      " Department Head. Once approved, an email will be sent.",
    promotionRequest: promotionRequest,
    website: SOW_WEBSITE + Path["Promotion Requests"],
  } as PromotionRequestEmailForm);

export const submitPromotionRequest = (
  promotionRequest: PromotionRequest,
  departments: DepartmentInterface
): Promise<PromotionRequest> =>
  new Promise((resolve, reject) => {
    getDocs(collection(db, "promotionRequests", "promotionRequests", currentStaffYear.toString()))
      .then((res) => {
        const id_new = (res.docs.length > 0 ? Math.max(...res.docs.map((d) => Number(d.id))) + 1 : 1).toString();
        const hodFetch = getHODByDepartment(departments, departments.MARKETING);
        Promise.all([hodFetch]).then(([hod_in]) => {
          promotionRequest = {
            ...promotionRequest,
            hod: hod_in,
            id: id_new,
          };
          const { staff, hod, id, ...requestWithoutStaff } = promotionRequest;
          setDoc(doc(db, "promotionRequests", "promotionRequests", currentStaffYear.toString(), id_new), {
            ...requestWithoutStaff,
            userID: promotionRequest.staff.id,
          })
            .then(() => {
              sendNeedApprovalEmail(promotionRequest);
              sendEmailSent(promotionRequest);
              resolve(promotionRequest);
            })
            .catch((error) => {
              reject(error);
            });
        });
      })
      .catch((error) => {
        reject(error);
      });
  });

const sendCancelledEmail = (promotionRequest: PromotionRequest) =>
  sendPromotionRequestEmail({
    email: promotionRequest.hod?.email,
    subject:
      "The promotion request: #" +
      promotionRequest.id +
      " from " +
      promotionRequest.staff.firstName +
      " has been cancelled",
    name: promotionRequest.hod?.firstName,
    message:
      "The promotion request below has been cancelled by the requestee. It will no longer show up on the dashboard.",
    promotionRequest: promotionRequest,
    website: SOW_WEBSITE + Path["Promotion Requests"],
  } as PromotionRequestEmailForm);

export const cancelPromotionRequest = (promotionRequest: PromotionRequest): Promise<PromotionRequest> => {
  return new Promise(async (resolve, reject) => {
    const comments = await getDocs(
      collection(
        db,
        "promotionRequests",
        "promotionRequests",
        currentStaffYear.toString(),
        promotionRequest.id as string,
        "comments"
      )
    );

    comments.docs.map(async (d) => {
      await deleteDoc(
        doc(
          db,
          "promotionRequests",
          "promotionRequests",
          currentStaffYear.toString(),
          promotionRequest.id as string,
          "comments",
          d.id
        )
      );
    });
    deleteDoc(
      doc(db, "promotionRequests", "promotionRequests", currentStaffYear.toString(), promotionRequest.id as string)
    )
      .then(() => {
        sendCancelledEmail(promotionRequest);
        resolve(promotionRequest);
      })
      .catch((error) => reject(error));
  });
};

const sendApprovedEmail = (promotionRequest: PromotionRequest) => {
  sendPromotionRequestEmail({
    email: promotionRequest.staff.email,
    subject: "Your promotion request: #" + promotionRequest.id + " has been approved",
    name: promotionRequest.staff.firstName,
    message: "The promotion request below has been approved. Please check the dashboard for more details.",
    promotionRequest: promotionRequest,
    website: SOW_WEBSITE + Path["Promotion Requests"],
  } as PromotionRequestEmailForm);

  sendPromotionRequestEmail({
    email: promotionRequest.hod?.email,
    subject:
      "The promotion request: #" +
      promotionRequest.id +
      " from " +
      promotionRequest.staff.firstName +
      " has been approved by you",
    name: promotionRequest.hod?.firstName,
    message: "The promotion request below has been approved by you. Please check the dashboard for more details.",
    promotionRequest: promotionRequest,
    website: SOW_WEBSITE + Path["Promotion Requests"],
  } as PromotionRequestEmailForm);
};

export const approvePromotionRequest = (promotionRequest: PromotionRequest): Promise<PromotionRequest> =>
  new Promise((resolve, reject) => {
    promotionRequest = {
      ...promotionRequest,
      approvedByHOD: RequestStatus.APPROVED,
      approvedTime: new Date(),
    };
    updateDoc(
      doc(db, "promotionRequests", "promotionRequests", currentStaffYear.toString(), promotionRequest.id as string),
      {
        approvedByHOD: RequestStatus.APPROVED,
        approvedTime: new Date(),
      }
    )
      .then(() => {
        sendApprovedEmail(promotionRequest);
        resolve(promotionRequest);
      })
      .catch((error) => {
        reject(error);
      });
  });

const sendDeclinedEmail = (promotionRequest: PromotionRequest) => {
  sendPromotionRequestEmail({
    email: promotionRequest.staff.email,
    subject: "Your promotion request: #" + promotionRequest.id + " has been declined",
    name: promotionRequest.staff.firstName,
    message: "The promotion request below has been declined. Please check the dashboard for more details.",
    promotionRequest: promotionRequest,
    website: SOW_WEBSITE + Path["Promotion Requests"],
  } as PromotionRequestEmailForm);

  sendPromotionRequestEmail({
    email: promotionRequest.hod?.email,
    subject:
      "The promotion request: #" +
      promotionRequest.id +
      " from " +
      promotionRequest.staff.firstName +
      " has been declined by you",
    name: promotionRequest.hod?.firstName,
    message: "The promotion request below has been declined by you. Please check the dashboard for more details.",
    promotionRequest: promotionRequest,
    website: SOW_WEBSITE + Path["Promotion Requests"],
  } as PromotionRequestEmailForm);
};

export const declinePromotionRequest = (dr: PromotionRequestDeclined): Promise<PromotionRequestDeclined> => {
  let drRejected: PromotionRequestDeclined = {
    ...dr,
    approvedByHOD: RequestStatus.DECLINED,
    declinedTime: new Date(),
  };
  return new Promise((resolve, reject) => {
    updateDoc(doc(db, "promotionRequests", "promotionRequests", currentStaffYear.toString(), drRejected.id as string), {
      approvedByHOD: RequestStatus.DECLINED,
      declinedTime: drRejected.declinedTime,
      reason: drRejected.reason,
    })
      .then(() => {
        sendDeclinedEmail(drRejected);
        resolve(drRejected);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sendCompletedEmail = (promotionRequest: PromotionRequest) => {
  sendPromotionRequestEmail({
    email: promotionRequest.staff.email,
    subject: "Your promotion request: #" + promotionRequest.id + " has been completed",
    name: promotionRequest.staff.firstName,
    message: "The promotion request below has been completed. Please check the dashboard for more details.",
    promotionRequest: promotionRequest,
    website: SOW_WEBSITE + Path["Promotion Requests"],
  } as PromotionRequestEmailForm);

  sendPromotionRequestEmail({
    email: promotionRequest.hod?.email,
    subject:
      "The promotion request: #" +
      promotionRequest.id +
      " from " +
      promotionRequest.staff.firstName +
      " has been completed by you",
    name: promotionRequest.hod?.firstName,
    message: "The promotion request below has been completed by you. Please check the dashboard for more details.",
    promotionRequest: promotionRequest,
    website: SOW_WEBSITE + Path["Promotion Requests"],
  } as PromotionRequestEmailForm);
};

export const completePromotionRequest = (dr: PromotionRequest): Promise<PromotionRequest> => {
  dr = {
    ...dr,
    completed: true,
    completedTime: new Date(),
  };
  return new Promise((resolve, reject) =>
    updateDoc(doc(db, "promotionRequests", "promotionRequests", currentStaffYear.toString(), dr.id as string), {
      completed: dr.completed,
      completedTime: dr.completedTime,
    })
      .then(() => {
        sendCompletedEmail(dr);
        resolve(dr);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

const sendCommentEmail = (promotionRequest: PromotionRequest, comment: PromotionRequestCommentData, staff: Staff) =>
  sendPromotionRequestEmail({
    email: staff.email,
    subject: "The promotion request: #" + promotionRequest.id + " has a new comment by " + comment.staff.firstName,
    name: staff.firstName,
    message: "The promotion request below has a new comment by " + comment.staff.firstName + ": " + comment.comment,
    promotionRequest: promotionRequest,
    website: SOW_WEBSITE + Path["Promotion Requests"],
  } as PromotionRequestEmailForm);

export const submitComment = (
  promotionRequest: PromotionRequest,
  comment: PromotionRequestCommentData
): Promise<PromotionRequestCommentData> =>
  new Promise((resolve, reject) => {
    getDocs(
      collection(
        db,
        "promotionRequests",
        "promotionRequests",
        currentStaffYear.toString(),
        promotionRequest.id as string,
        "comments"
      )
    )
      .then((res) => {
        const id_new = (res.docs.length > 0 ? Math.max(...res.docs.map((d) => Number(d.id))) + 1 : 1).toString();
        comment = {
          ...comment,
          submittedTime: new Date(),
          id: id_new,
        };
        const { staff, id, ...commentWithoutStaff } = comment;
        setDoc(
          doc(
            db,
            "promotionRequests",
            "promotionRequests",
            currentStaffYear.toString(),
            promotionRequest.id as string,
            "comments",
            id_new
          ),
          {
            ...commentWithoutStaff,
            userID: staff.id,
          }
        )
          .then(() => {
            if (comment.staff.id === promotionRequest.staff.id) {
              sendCommentEmail(promotionRequest, comment, promotionRequest.hod as Staff);
            } else if (comment.staff.id === promotionRequest.hod?.id) {
              sendCommentEmail(promotionRequest, comment, promotionRequest.staff);
            }
            resolve(comment);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });

export const deleteComment = (promotionRequest: PromotionRequest, comment_id: string): Promise<string> =>
  new Promise((resolve, reject) => {
    deleteDoc(
      doc(
        db,
        "promotionRequests",
        "promotionRequests",
        currentStaffYear.toString(),
        promotionRequest.id as string,
        "comments",
        comment_id
      )
    )
      .then(() => {
        resolve(comment_id);
      })
      .catch((error) => {
        reject(error);
      });
  });
