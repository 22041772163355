import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Alert, Button, Container, FormControl, Grid, Snackbar, TextField, Typography, useTheme } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect, useRef, useState } from "react";
import { Loading } from "../../components/Loading";
import { User } from "../../models/User";
import {
  InitMissionsSupportData,
  InitMissionsSupportTeamData,
  MissionsSupportInfo,
  MissionsSupportTeam,
  deleteMissionsSupportTeam,
  getMissionsSupportInfo,
  getMissionsSupportTeams,
  updateMissionsSupportInfo,
  updateMissionsSupportTeams,
} from "../../services/missionsSupport";
import { getListOfActiveUsers } from "../../services/users";
import { EditMissionsTeam } from "./EditMissionsTeam";

interface EditMissionsDataInterface {
  loading: boolean;
  info: MissionsSupportInfo;
  teams: MissionsSupportTeam[];
  listOfAllUsers: User[];
  newMembers: string[];
}

function calculateDateString(startDate: Date) {
  var dd = String(startDate.getDate()).padStart(2, "0");
  var mm = String(startDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = startDate.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
}

export const EditMissionsData = () => {
  const [state, setState] = useState<EditMissionsDataInterface>({
    loading: true,
    info: InitMissionsSupportData,
    teams: [InitMissionsSupportTeamData],
    listOfAllUsers: [],
    newMembers: [],
  });

  const [updating, setUpdating] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const { info, teams, listOfAllUsers, loading } = state;

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    Promise.all([getMissionsSupportInfo(), getMissionsSupportTeams(), getListOfActiveUsers()]).then(
      ([info, teams, listOfAllUsers]) =>
        setState({
          ...state,
          info,
          teams,
          listOfAllUsers,
          loading: false,
        })
    );
    // eslint-disable-next-line
  }, []);

  const renderCounter = useRef(0);

  useEffect(() => {
    if (renderCounter.current > 1) {
      const delayDebounceFn = setTimeout(() => {
        updateMissionsSupportTeams(teams).then(() =>
          updateMissionsSupportInfo(info).then(() => {
            setUpdating(false);
            setShowMessage(true);
          })
        );
      }, 1000);
      setUpdating(true);
      setShowMessage(true);
      return () => clearTimeout(delayDebounceFn);
    }
    ++renderCounter.current;
  }, [teams, info]);

  const theme = useTheme();

  const isOptionDisabled = (option: User): boolean => {
    const allLeaders = teams.map((t) => t.leader?.id).filter((leaderId) => leaderId !== undefined) as string[];
    const allGoingToMissions = teams.flatMap((t) => t.members.map((m) => m.id)).concat(allLeaders);
    return allGoingToMissions.includes(option.id);
  };

  return loading ? (
    <Loading />
  ) : (
    <Container maxWidth="lg">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showMessage}
        onClose={() => setShowMessage(false)}
        autoHideDuration={3000}
      >
        <Alert severity={updating ? "warning" : "success"} elevation={6} variant="filled">
          {updating ? "Saving..." : "Saved!"}
        </Alert>
      </Snackbar>
      <FormControl fullWidth sx={{ mb: theme.spacing(2) }}>
        <TextField
          id="goal"
          label="Financial Goal"
          variant="standard"
          onChange={(e) => setState({ ...state, info: { ...info, goal: Number(e.target.value) } })}
          value={info.goal ? info.goal : ""}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography>$</Typography>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: theme.spacing(2) }}>
        <TextField
          id="date"
          label="Start Date"
          type="date"
          variant="standard"
          onChange={(e) => setState({ ...state, info: { ...info, supportStartDate: new Date(e.target.value) } })}
          value={calculateDateString(info.supportStartDate)}
          sx={{ width: 220 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      {teams.map((team, i) => (
        <EditMissionsTeam
          users={listOfAllUsers}
          key={i}
          team={team}
          removeTeam={() =>
            deleteMissionsSupportTeam(team.id as string).then(() =>
              setState({ ...state, teams: [...teams.slice(0, i), ...teams.slice(i + 1)] })
            )
          }
          isOptionDisabled={isOptionDisabled}
          setTeam={(updatedTeam) =>
            setState({ ...state, teams: [...teams.slice(0, i), updatedTeam, ...teams.slice(i + 1)] })
          }
        />
      ))}
      <Button
        sx={{ m: theme.spacing(1, 5, 1, 5) }}
        variant="text"
        onClick={() => setState({ ...state, teams: [...teams, InitMissionsSupportTeamData] })}
      >
        <Grid container alignItems="center" justifyContent="center">
          <Typography sx={{ mr: theme.spacing(1) }}>Add team</Typography>
          <AddCircleIcon fontSize="small" />
        </Grid>
      </Button>
    </Container>
  );
};
