import { Card, CardContent, Grid, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { TypographyBold } from "../../components/TypographyBold";
import { HEAD_OF_DIVISION, STAFF } from "../../data/Roles";
import { universities } from "../../data/University";
import { currentStaffYear, nextStaffYear } from "../../helpers/FormatDate";
import { atLeast } from "../../helpers/Role";
import { DepartmentInterface } from "../../models/Department";
import { Divisions } from "../../models/Division";
import { HeadOfDivision, Staff } from "../../models/Staff";
import { User } from "../../models/User";
import { addStayingUser, deleteUser, isInDepartmentOrDivision } from "../../services/organisationRearrangement";
import { SelectComponent } from "./SelectComponent";

export const YearsUser = ({
  u,
  users,
  roles,
  departments,
  divisions,
  year,
  setUsers,
  setUpdateUser,
  setErrorMessage,
}: {
  u: User;
  users: User[];
  roles: string[];
  departments: DepartmentInterface;
  divisions: Divisions;
  year: number;
  setUsers: (users: User[], message: string) => void;
  setUpdateUser: (user: User, name: string, value: string, message: string) => void;
  setErrorMessage: (error: string) => void;
}) => {
  const getNextYearUserOrCurrentYearUser = (user: User) =>
    year === currentStaffYear
      ? user
      : users.find((u) => u.id === user.id)
      ? (users.find((u) => u.id === user.id) as User)
      : user;

  const getFirstName = (email: string) =>
    email.match("@sowaustralia.com$") ? email.split(".")[0].charAt(0).toUpperCase() + email.split(".")[0].slice(1) : "";
  const getLastName = (email: string) =>
    email.match("@sowaustralia.com$")
      ? email.split(".")[1].split("@")[0].charAt(0).toUpperCase() + email.split(".")[1].split("@")[0].slice(1)
      : "";

  return (
    <Card
      sx={{
        backgroundColor: users.find((user) => user.id === u.id) ? green[50] : red[50],
      }}
    >
      <CardContent>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <TypographyBold>
              {u.firstName || getFirstName(u.id)} {u.lastName || getLastName(u.id)}
            </TypographyBold>
          </Grid>
          {year === nextStaffYear && (
            <Grid item>
              <Typography>This person is: </Typography>
            </Grid>
          )}
          {year === nextStaffYear && (
            <Grid item>
              <Select
                disabled={
                  users.find((user) => user.id === u.id) && isInDepartmentOrDivision(u.id, departments, divisions)
                }
                labelId="status-label"
                id="status"
                value={users.find((user) => user.id === u.id) ? "Staying" : "Leaving"}
                onChange={(event: SelectChangeEvent<string>) =>
                  event.target.value === "Staying"
                    ? addStayingUser(roles, u as Staff & HeadOfDivision, year.toString()).then((message) =>
                        setUsers([...users, u], message)
                      )
                    : deleteUser(u, year.toString()).then((message) =>
                        setUsers(
                          users.filter((user) => user.id !== u.id),
                          message
                        )
                      )
                }
                name="status"
                variant="standard"
              >
                <MenuItem value="Staying">Staying</MenuItem>
                <MenuItem value="Leaving">Leaving</MenuItem>
              </Select>
            </Grid>
          )}
        </Grid>
        {users.find((user) => user.id === u.id) && (
          <>
            <Grid container alignItems="center">
              <Typography>Role: </Typography>
              <SelectComponent
                roles={roles}
                list={roles}
                name={"role"}
                user={getNextYearUserOrCurrentYearUser(u)}
                disabled={isInDepartmentOrDivision(u.id, departments, divisions)}
                year={year}
                setUpdateUser={setUpdateUser}
                setErrorMessage={setErrorMessage}
              />
            </Grid>
            {!atLeast(roles, STAFF, getNextYearUserOrCurrentYearUser(u).role) && (
              <Grid container alignItems="center">
                <Typography>University: </Typography>
                <SelectComponent
                  roles={roles}
                  list={universities}
                  name={"university"}
                  user={getNextYearUserOrCurrentYearUser(u)}
                  disabled={isInDepartmentOrDivision(u.id, departments, divisions)}
                  year={year}
                  setUpdateUser={setUpdateUser}
                  setErrorMessage={setErrorMessage}
                />
              </Grid>
            )}
            {!atLeast(roles, HEAD_OF_DIVISION, getNextYearUserOrCurrentYearUser(u).role) &&
              atLeast(roles, STAFF, getNextYearUserOrCurrentYearUser(u).role) && (
                <Grid container alignItems="center">
                  <Typography>Department: </Typography>
                  <SelectComponent
                    roles={roles}
                    list={Object.keys(departments.departments)}
                    name={"department"}
                    user={getNextYearUserOrCurrentYearUser(u)}
                    disabled={isInDepartmentOrDivision(u.id, departments, divisions)}
                    year={year}
                    setUpdateUser={setUpdateUser}
                    setErrorMessage={setErrorMessage}
                  />
                </Grid>
              )}
            {getNextYearUserOrCurrentYearUser(u).role === HEAD_OF_DIVISION && (
              <Grid container alignItems="center">
                <Typography>Division: </Typography>
                <SelectComponent
                  roles={roles}
                  list={Object.keys(divisions)}
                  name={"division"}
                  user={getNextYearUserOrCurrentYearUser(u)}
                  disabled={isInDepartmentOrDivision(u.id, departments, divisions)}
                  year={year}
                  setUpdateUser={setUpdateUser}
                  setErrorMessage={setErrorMessage}
                />
              </Grid>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};
