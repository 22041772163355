import { Alert, CircularProgress, Snackbar, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { httpsCallable } from "firebase/functions";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { RolesContext, UserContext } from "../../components/PrivateRoute";
import { STAFF } from "../../data/Roles";
import { atLeast } from "../../helpers/Role";
import { MessageState } from "../../models/MessageState";
import { SearchResult } from "../../models/SearchResult";
import { Staff } from "../../models/Staff";
import { functions } from "../../services/firebase";

export const SearchBar = () => {
  const user = useContext(UserContext);
  const roles = useContext(RolesContext);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [messageState, setMessageState] = useState<MessageState>({
    showMessage: false,
    message: "",
    severity: "success",
  });
  const history = useHistory();
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      const delayDebounceFn = setTimeout(() => {
        httpsCallable(
          functions,
          "search"
        )({
          query: searchTerm,
          isStaff: atLeast(roles, STAFF, user.role),
          department: (user as Staff).department,
        })
          .then((res) => {
            setResults(res.data as SearchResult[]);
            setLoading(false);
          })
          .catch((error) => {
            setMessageState({
              showMessage: true,
              message: "There was an error with the search function. Please try again.",
              severity: "error",
            });
          });
      }, 1000);
      setLoading(true);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm, user, roles]);

  return (
    <>
      <Snackbar
        open={messageState.showMessage}
        onClose={() => setMessageState({ ...messageState, showMessage: false })}
        autoHideDuration={6000}
      >
        <Alert elevation={6} variant="filled" severity={messageState.severity}>
          {messageState.message}
        </Alert>
      </Snackbar>
      <Autocomplete
        freeSolo
        id="search-bar"
        sx={{ width: 300 }}
        open={open}
        options={results}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        filterOptions={(x) => x}
        includeInputInList
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
        loading={loading}
        renderOption={(props, option) => (
          <li {...props} onClick={() => history.push(option.to)}>
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ backgroundColor: "white", borderRadius: 20 }}
            label="Search"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
        inputValue={searchTerm}
        onInputChange={(event, newInputValue) => {
          newInputValue !== searchTerm && setSearchTerm(newInputValue);
        }}
      />
    </>
  );
};
