import { Link as ReactRouterLink } from "react-router-dom";
import { Typography, Grid, Paper, Link, TextField, Divider, Accordion, AccordionSummary } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Staff } from "../../models/Staff";
import { DesignRequestDeclined } from "../../models/DesignRequestDeclined";
import { DesignRequest } from "../../models/DesignRequest";
import { formatDateTime } from "../../helpers/FormatDate";
import { Path } from "../../helpers/Path";
import {
  designRequestApproved,
  designRequestDeclined,
  designRequestCompleted,
  designRequestStatus,
} from "../../services/designRequests";
import { DesignRequestCommentSection } from "./DesignRequestCommentSection";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DesignRequestsFormAnswers } from "./DesignRequestsFormAnswers";
import { Status } from "../../helpers/Status";

export const PastYearsDesignRequestsComponent = ({
  designRequest,
  staff,
}: {
  designRequest: DesignRequest;
  staff: Staff;
}) => {
  const theme = useTheme();

  return (
    <Accordion
      sx={{
        margin: theme.spacing(1, 1, 0, 1),
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="resources">
        <Typography>
          #{designRequest.id} by {designRequest.staff.firstName}
          {designRequestDeclined(designRequest) && " - DECLINED"}
          {designRequestCompleted(designRequest) && " - COMPLETED"}
        </Typography>
      </AccordionSummary>
      <Grid xs={12} item>
        <Paper
          elevation={3}
          sx={{
            padding: theme.spacing(2),
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6" display="inline">
                #{designRequest.id}
              </Typography>
              <Typography display="inline">
                {" "}
                by {designRequest.staff.firstName} {designRequest.staff.lastName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Department: {designRequest.department}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container item justifyItems="flex-end">
                <Typography variant="caption" color="textSecondary">
                  Submitted {formatDateTime(designRequest.submittedTime)}
                </Typography>
              </Grid>
              {designRequestApproved(designRequest) && designRequest.approvedTime && (
                <Grid container item justifyItems="flex-end">
                  <Typography variant="caption" color="textSecondary">
                    Approved {formatDateTime(designRequest.approvedTime)}
                  </Typography>
                </Grid>
              )}
              {designRequestDeclined(designRequest) && designRequest.declinedTime && (
                <Grid container item justifyItems="flex-end">
                  <Typography variant="caption" color="textSecondary">
                    Declined {formatDateTime(designRequest.declinedTime)}
                  </Typography>
                </Grid>
              )}
              {designRequestCompleted(designRequest) && designRequest.completedTime && (
                <Grid container item justifyItems="flex-end">
                  <Typography variant="caption" color="textSecondary">
                    Done {formatDateTime(designRequest.completedTime)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <DesignRequestsFormAnswers designRequest={designRequest} />
          <Grid container item xs={12}>
            <Grid xs={6} item direction="column" container justifyItems="center" alignItems="center">
              <Typography variant="body2" color="textSecondary">
                Approval Status
              </Typography>
              <Typography sx={Status(theme)[designRequestStatus(designRequest)]}>
                {designRequestStatus(designRequest)}
              </Typography>
            </Grid>
            {designRequestApproved(designRequest) && (
              <Grid xs={6} item direction="column" container justifyItems="center" alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  Status
                </Typography>
                {designRequestCompleted(designRequest) && (
                  <Typography sx={Status(theme).APPROVED}>COMPLETED</Typography>
                )}
                {!designRequestCompleted(designRequest) && <Typography sx={Status(theme).PENDING}>PENDING</Typography>}
              </Grid>
            )}
          </Grid>
          {(designRequest as DesignRequestDeclined).reason && (
            <Grid
              container
              item
              xs={12}
              sx={{
                mt: theme.spacing(2),
              }}
            >
              <TextField
                disabled
                fullWidth
                label="Declined Reason"
                sx={{
                  "&:disabled": {
                    color: theme.palette.common.black,
                  },
                }}
                value={(designRequest as DesignRequestDeclined).reason}
                variant="outlined"
              />
            </Grid>
          )}
          <Divider
            sx={{
              mt: theme.spacing(1),
            }}
          />
          <Grid container direction="column" justifyItems="center" alignItems="center">
            {designRequest.hod && (
              <Link
                sx={Status(theme)[designRequest.approvedByHOD]}
                component={ReactRouterLink}
                underline="hover"
                to={`${Path.Profile}/${designRequest.hod.id}`}
              >
                HOD ({designRequest.hod.firstName}): {designRequest.approvedByHOD}
              </Link>
            )}
          </Grid>
          <DesignRequestCommentSection designRequest={designRequest} staff={staff} />
        </Paper>
      </Grid>
    </Accordion>
  );
};
