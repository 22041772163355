import { collection, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { currentStaffYear, nextStaffYear, rolesStartYear, yearsSinceStartYearUntilYear } from "../helpers/FormatDate";
import { PastRoles } from "../helpers/Role";
import { db } from "./firebase";

export const getStaffYearRoles = async (year: string) => (await getDoc(doc(db, "roles", year))).data()?.roles;

export const getCurrentStaffYearRoles = async (): Promise<string[]> => getStaffYearRoles(currentStaffYear.toString());

export const getNextStaffYearRoles = async (): Promise<string[]> => getStaffYearRoles(nextStaffYear.toString());

export const getPastRoles = async (): Promise<PastRoles> => {
  const getRolesCollection = await getDocs(collection(db, "roles"));

  let roles: PastRoles = {};
  for (const doc of getRolesCollection.docs) {
    roles[doc.id] = doc.data()?.roles;
  }

  for (const year of yearsSinceStartYearUntilYear(rolesStartYear)) {
    if (!roles[year]) {
      roles[year] = roles[rolesStartYear - 1];
    }
  }
  return roles;
};

export const updateRoles = async (year: number, newRoles: string[]) => {
  for (const role of newRoles) {
    if (!role) {
      throw new Error("One of the entries are empty!");
    }
  }
  await updateDoc(doc(db, "roles", year.toString()), { roles: newRoles });
};
