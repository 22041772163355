import { Container, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { MessageSnackbar } from "../../components/MessageSnackbar";
import { DepartmentsContext } from "../../components/PrivateRoute";
import { currentStaffYear, nextStaffYear } from "../../helpers/FormatDate";
import { InitMessageState, MessageState } from "../../models/MessageState";
import { User } from "../../models/User";
import { getNextStaffYearDepartments, setRegistrationReviewerYear } from "../../services/departments";
import { getDepartmentStaffListYear, getUserByIdYear } from "../../services/users";
import { DATA_AND_IT } from "../../data/Departments";

interface SetRegistrationReviewerInterface {
  loading: boolean;
  ITStaff: User[];
  currentRegistrationReviewer?: User;
  nextYearRegistrationReviewer?: User;
  messageState: MessageState;
}

export const SetRegistrationReviewer = () => {
  const departments = useContext(DepartmentsContext);

  const [state, setState] = useState<SetRegistrationReviewerInterface>({
    loading: true,
    ITStaff: [],
    messageState: InitMessageState,
  });

  const { loading } = state;

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });

    getNextStaffYearDepartments().then((nextYearDepartments) => {
      const getCurrentRegistrationReviewerFetch = getUserByIdYear(
        departments.REGISTRATION_REVIEWER,
        currentStaffYear.toString()
      );
      const getNextYearRegistrationReviewerFetch = getUserByIdYear(
        nextYearDepartments.REGISTRATION_REVIEWER,
        currentStaffYear.toString()
      );
      const getITDepartmentFetch = getDepartmentStaffListYear(DATA_AND_IT, currentStaffYear.toString());

      Promise.all([
        getCurrentRegistrationReviewerFetch,
        getNextYearRegistrationReviewerFetch,
        getITDepartmentFetch,
      ]).then(([currentRegistrationReviewer, nextYearRegistrationReviewer, ITStaff]) =>
        setState({
          ...state,
          currentRegistrationReviewer,
          nextYearRegistrationReviewer,
          ITStaff,
          loading: false,
        })
      );
    });

    // eslint-disable-next-line
  }, []);

  const { ITStaff, currentRegistrationReviewer, nextYearRegistrationReviewer, messageState } = state;

  const handleCurrentRegistrationReviewerChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value) {
      setRegistrationReviewerYear(event.target.value, currentStaffYear.toString()).then(() =>
        setState({
          ...state,
          currentRegistrationReviewer: ITStaff.find((f) => f.id === event.target.value),
          messageState: {
            ...messageState,
            showMessage: true,
            message: "Successfully saved",
          },
        })
      );
    }
  };

  const handleNextYearRegistrationReviewerChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value) {
      setRegistrationReviewerYear(event.target.value, nextStaffYear.toString()).then(() =>
        setState({
          ...state,
          nextYearRegistrationReviewer: ITStaff.find((f) => f.id === event.target.value),
          messageState: {
            ...messageState,
            showMessage: true,
            message: "Successfully saved",
          },
        })
      );
    }
  };

  const theme = useTheme();

  return loading ? (
    <Loading />
  ) : (
    <>
      <MessageSnackbar
        messageState={messageState}
        setMessageState={(messageState) => setState({ ...state, messageState })}
      />
      <Container maxWidth="sm">
        {ITStaff && currentRegistrationReviewer && (
          <>
            <FormControl fullWidth sx={{ mb: theme.spacing(2) }}>
              <InputLabel id="setRegistrationReviewerLabel">Current Year Registration Reviewer</InputLabel>
              <Select
                labelId="setRegistrationReviewerLabel"
                id="setRegistrationReviewer"
                onChange={handleCurrentRegistrationReviewerChange}
                value={currentRegistrationReviewer.id ? currentRegistrationReviewer.id : ""}
                variant="standard"
              >
                {ITStaff.map((v, i) => (
                  <MenuItem key={i} value={v.id}>
                    {v.firstName} {v.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="setNextYearRegistrationReviewerLabel">Next Year Registration Reviewer</InputLabel>
              <Select
                labelId="setNextYearRegistrationReviewerLabel"
                id="setNextYearRegistrationReviewer"
                onChange={handleNextYearRegistrationReviewerChange}
                value={nextYearRegistrationReviewer?.id ? nextYearRegistrationReviewer?.id : ""}
                variant="standard"
              >
                {ITStaff.map((v, i) => (
                  <MenuItem key={i} value={v.id}>
                    {v.firstName} {v.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </Container>
    </>
  );
};
