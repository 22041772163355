import { Link as ReactRouterLink } from "react-router-dom";
import { Button, ButtonGroup, Menu, MenuItem } from "@mui/material";
import { Path } from "../../helpers/Path";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const RequestFormMenu = ({
  open,
  handleClick,
  handleClose,
}: {
  open: null | HTMLElement;
  handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleClose: () => void;
}) => {
  return (
    <>
      <div onClick={(event) => handleClick(event)}>
        <ButtonGroup size="large" variant="contained" aria-label="split button">
          <Button color="secondary">Request Forms</Button>
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </div>
      <Menu
        open={Boolean(open)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "request-form-menu-button",
        }}
        anchorEl={open}
        keepMounted
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MenuItem component={ReactRouterLink} to={Path["Reimbursement Requests"]}>
          Reimbursement
        </MenuItem>
        <MenuItem component={ReactRouterLink} to={Path["Design Requests"]}>
          Design
        </MenuItem>
        <MenuItem component={ReactRouterLink} to={Path["Promotion Requests"]}>
          Promotion
        </MenuItem>
      </Menu>
    </>
  );
};
