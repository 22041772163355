import { useContext } from "react";
import * as yup from "yup";
import { Dialog } from "@mui/material";
import { Formik, FormikProps } from "formik";
import { Staff } from "../../models/Staff";
import { Request } from "../../models/Request";
import { MessageState } from "../../models/MessageState";
import { RequestsForm } from "./RequestsForm";
import { submitRequest } from "../../services/requests";
import { RequestStatus } from "../../data/RequestStatus";
import { DepartmentsContext } from "../../components/PrivateRoute";

const RequestsFormValidationSchema = yup.object().shape({
  description: yup.string().required("Enter description"),
  department: yup.string(),
  amount: yup
    .string()
    .required("Please enter the amount")
    .matches(/^[1-9]{1}[0-9]*(\.\d{1,2})?$/, "Must be a valid amount"),
});

export const RequestsSubmit = ({
  staff,
  dialogOpen,
  closeDialog,
  handlePost,
}: {
  staff: Staff;
  dialogOpen: boolean;
  closeDialog: () => void;
  handlePost: (m: MessageState, r: Request | null) => void;
}) => {
  const departments = useContext(DepartmentsContext);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={dialogOpen}
      onClose={closeDialog}
      aria-describedby="alert-dialog-description"
    >
      <Formik
        initialValues={{
          userID: staff.id,
          submittedTime: new Date(),
          staff: staff,
          approvedByHOD: RequestStatus.PENDING,
          approvedByFinance: RequestStatus.PENDING,
          approvedByFinanceHead: RequestStatus.PENDING,
          department: staff.department,
          description: "",
          amount: "",
        }}
        onSubmit={(values: Request) =>
          submitRequest(values, departments)
            .then((r) =>
              handlePost(
                {
                  showMessage: true,
                  message: "Successfully submitted request! Emails have been sent to the HOD and Finance Team.",
                  severity: "success",
                },
                r
              )
            )
            .catch((error) => {
              handlePost(
                {
                  showMessage: true,
                  message: error.message,
                  severity: "error",
                },
                null
              );
            })
        }
        validationSchema={RequestsFormValidationSchema}
      >
        {(props: FormikProps<Request>) => {
          return <RequestsForm props={props} handleClose={closeDialog} />;
        }}
      </Formik>
    </Dialog>
  );
};
