import { SOW_WEBSITE } from "../helpers/Env";
import { currentStaffYear, currentYear } from "../helpers/FormatDate";
import { Path } from "../helpers/Path";
import { Reimbursement, ReimbursementSubmit } from "../models/Reimbursement";
import { sendFinanceReimbursementEmail } from "./email";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "./firebase";
import { doc, updateDoc } from "firebase/firestore";
import { FinanceReimbursementEmailForm } from "../models/FinanceRequestEmailForm";

const uploadFileAsPromise = (file: File, id: string | undefined, index: number): Promise<string> => {
  return new Promise(function (resolve, reject) {
    const storageRef = ref(storage, `receipts/${currentYear}/${id}/${index}/${file.name}`);

    uploadBytes(storageRef, file).then(() => resolve(getDownloadURL(storageRef)));
  });
};

export const submitReceipt = async (reimbursementSubmit: ReimbursementSubmit): Promise<Reimbursement> => {
  const receiptURLsList: string[][] = [];
  for (const recipient of reimbursementSubmit.recipients) {
    receiptURLsList.push(
      await Promise.all(recipient.files.map((file: File, i) => uploadFileAsPromise(file, reimbursementSubmit.id, i)))
    );
  }

  const reimbursement: Reimbursement = {
    ...reimbursementSubmit,
    recipients: reimbursementSubmit.recipients.map((recipient, i) => {
      const { files, ...recipientWithoutFiles } = recipient;
      return { ...recipientWithoutFiles, receiptURLs: receiptURLsList[i] };
    }),
  };
  await updateDoc(doc(db, "requests", "requests", currentStaffYear.toString(), reimbursement.id as string), {
    recipients: reimbursement.recipients,
    totalReceiptAmount: reimbursement.totalReceiptAmount,
    paid: false,
  });

  sendReceiptSubmitted(reimbursement);
  return reimbursement;
};

export const reimbursementPending = (reimbursement: Reimbursement) =>
  reimbursement.paid !== undefined && reimbursement.paid === false;

export const paid = (reimbursement: Reimbursement) => reimbursement.paid === true;

const sendReceiptSubmitted = (reimbursement: Reimbursement) =>
  sendFinanceReimbursementEmail({
    email: reimbursement.financeHead?.email,
    subject:
      "The receipt for request: #" +
      reimbursement.id +
      " of $" +
      reimbursement.amount +
      " (Receipt or Invoice: $" +
      reimbursement.totalReceiptAmount +
      ") requires reimbursement",
    name: reimbursement.financeHead?.firstName,
    message: "Please pay then close the request.",
    request: reimbursement,
    website: SOW_WEBSITE + Path["Reimbursement Requests"],
  } as FinanceReimbursementEmailForm);

const sendPaid = (reimbursement: Reimbursement) =>
  sendFinanceReimbursementEmail({
    email: reimbursement.staff.email,
    subject:
      "Your request: #" +
      reimbursement.id +
      " of $" +
      reimbursement.amount +
      " (Receipt or Invoice: $" +
      reimbursement.totalReceiptAmount +
      ") has been paid! (Paid: $" +
      reimbursement.paidAmount +
      ")",
    name: reimbursement.staff.firstName,
    message:
      "You have been paid $" +
      reimbursement.paidAmount +
      " by the Finance Head (" +
      reimbursement.financeHead?.firstName +
      ").",
    request: reimbursement,
    website: SOW_WEBSITE + Path["Reimbursement Requests"],
  } as FinanceReimbursementEmailForm);

const sendToBMOfficerIfPayIsDifferent = (reimbursement: Reimbursement) => {
  Number(reimbursement.amount) !== Number(reimbursement.paidAmount) &&
    sendFinanceReimbursementEmail({
      email: reimbursement.finance?.email,
      subject:
        "The request: #" +
        reimbursement.id +
        " of $" +
        reimbursement.amount +
        " (Receipt or Invoice: $" +
        reimbursement.totalReceiptAmount +
        ") has been paid! (Paid: $" +
        reimbursement.paidAmount +
        ")",
      name: reimbursement.finance?.firstName,
      message: "The requested amount and paid amount is different. Please update the budget accordingly.",
      request: reimbursement,
      website: SOW_WEBSITE + Path["Reimbursement Requests"],
    } as FinanceReimbursementEmailForm);
};

const sendPaidToPayer = (reimbursement: Reimbursement) => {
  sendFinanceReimbursementEmail({
    email: reimbursement.financeHead?.email,
    subject:
      "The reimbursement request: #" +
      reimbursement.id +
      " of $" +
      reimbursement.amount +
      " (Receipt or Invoice: $" +
      reimbursement.totalReceiptAmount +
      ") has been paid by you! (Paid: $" +
      reimbursement.paidAmount +
      ")",
    name: reimbursement.financeHead?.firstName,
    message:
      "You have sent the reimbursement to " +
      reimbursement.staff.firstName +
      ". An email has been sent to let them know.",
    request: reimbursement,
    website: SOW_WEBSITE + Path["Reimbursement Requests"],
  } as FinanceReimbursementEmailForm);
};

export const pay = (reimbursement: Reimbursement): Promise<Reimbursement> => {
  reimbursement = {
    ...reimbursement,
    paid: true,
    paidTime: new Date(),
  };
  return new Promise((resolve, reject) =>
    updateDoc(doc(db, "requests", "requests", currentStaffYear.toString(), reimbursement.id as string), {
      paid: reimbursement.paid,
      paidTime: reimbursement.paidTime,
      comment: reimbursement.comment,
      paidAmount: reimbursement.paidAmount,
    })
      .then(() => {
        sendPaid(reimbursement);
        sendPaidToPayer(reimbursement);
        sendToBMOfficerIfPayIsDifferent(reimbursement);
        resolve(reimbursement);
      })
      .catch((error) => {
        reject(error);
      })
  );
};
