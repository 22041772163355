import { useContext } from "react";
import {
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Grid,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { DesignRequest } from "../../models/DesignRequest";
import { DesignRequestType } from "../../data/DesignRequestType";
import { DesignRequestProductType } from "../../data/DesignRequestProductType";
import { DepartmentsContext } from "../../components/PrivateRoute";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export const DesignRequestsFormAnswers = ({ designRequest }: { designRequest: DesignRequest }) => {
  const theme = useTheme();
  const questionTheme = {
    mb: theme.spacing(2),
  };
  const departments = useContext(DepartmentsContext);

  return (
    <>
      <InputLabel
        id="department-label"
        sx={{
          mt: theme.spacing(3),
        }}
        required
      >
        1. Select Department
      </InputLabel>
      <Select
        label="Department"
        fullWidth
        required
        labelId="department-label"
        id="department"
        name="department"
        value={designRequest.department}
        disabled
        variant="standard"
      >
        {Object.keys(departments.departments).map((dep, i) => (
          <MenuItem key={i} value={dep}>
            {dep}
          </MenuItem>
        ))}
      </Select>
      <FormControl
        component="fieldset"
        sx={{
          mt: theme.spacing(3),
        }}
        fullWidth
        required
      >
        <FormLabel component="legend" required>
          2. What best describes your request?
        </FormLabel>
        <FormGroup>
          <FormControlLabel
            disabled
            control={
              <Checkbox id="type.event" defaultChecked={designRequest.type.event} color="primary" name="type.event" />
            }
            label={DesignRequestType.event}
          />
          <FormControlLabel
            disabled
            control={
              <Checkbox
                id="type.promotion"
                defaultChecked={designRequest.type.promotion}
                color="primary"
                name="type.promotion"
              />
            }
            label={DesignRequestType.promotion}
          />
          <FormControlLabel
            disabled
            control={<Checkbox id="type.pr" defaultChecked={designRequest.type.pr} color="primary" name="type.pr" />}
            label={DesignRequestType.pr}
          />
          <Grid container>
            <FormControlLabel
              disabled
              control={
                <Checkbox id="type.other" defaultChecked={designRequest.type.other} color="primary" name="type.other" />
              }
              label={DesignRequestType.other}
            />
            <TextField
              id="type.otherText"
              name="type.otherText"
              multiline={designRequest.type.other}
              value={designRequest.type.otherText}
              disabled
              variant="standard"
              sx={questionTheme}
            />
          </Grid>
        </FormGroup>
      </FormControl>
      <FormLabel component="legend" required>
        3. If tied to event/project, please provide details
      </FormLabel>
      <TextField
        required
        fullWidth
        id="details"
        label="Event/Project name, date, time, venue, target audience, theme,
            aim/purpose"
        name="details"
        multiline
        value={designRequest.details}
        disabled
        variant="standard"
        sx={questionTheme}
      />
      <FormControl
        component="fieldset"
        sx={{
          mt: theme.spacing(3),
        }}
        fullWidth
        required
      >
        <FormLabel component="legend">4. What would you like us to design?</FormLabel>
        <FormGroup>
          <FormControlLabel
            disabled
            control={
              <Checkbox
                id="productType.brochure"
                defaultChecked={designRequest.productType.brochure}
                color="primary"
                name="productType.brochure"
              />
            }
            label={DesignRequestProductType.brochure}
          />
          <FormControlLabel
            disabled
            control={
              <Checkbox
                id="productType.flyer"
                defaultChecked={designRequest.productType.flyer}
                color="primary"
                name="productType.flyer"
              />
            }
            label={DesignRequestProductType.flyer}
          />
          <FormControlLabel
            disabled
            control={
              <Checkbox
                id="productType.booklet"
                defaultChecked={designRequest.productType.booklet}
                color="primary"
                name="productType.booklet"
              />
            }
            label={DesignRequestProductType.booklet}
          />
          <FormControlLabel
            disabled
            control={
              <Checkbox
                id="productType.facebookbanner"
                defaultChecked={designRequest.productType.facebookbanner}
                color="primary"
                name="productType.facebookbanner"
              />
            }
            label={DesignRequestProductType.facebookbanner}
          />
          <FormControlLabel
            disabled
            control={
              <Checkbox
                id="productType.infographic"
                defaultChecked={designRequest.productType.infographic}
                color="primary"
                name="productType.infographic"
              />
            }
            label={DesignRequestProductType.infographic}
          />
          <FormControlLabel
            disabled
            control={
              <Checkbox
                id="productType.invitation"
                defaultChecked={designRequest.productType.invitation}
                color="primary"
                name="productType.invitation"
              />
            }
            label={DesignRequestProductType.invitation}
          />
          <FormControlLabel
            disabled
            control={
              <Checkbox
                id="productType.logo"
                defaultChecked={designRequest.productType.logo}
                color="primary"
                name="productType.logo"
              />
            }
            label={DesignRequestProductType.logo}
          />
          <FormControlLabel
            disabled
            control={
              <Checkbox
                id="productType.merchandise"
                defaultChecked={designRequest.productType.merchandise}
                color="primary"
                name="productType.merchandise"
              />
            }
            label={DesignRequestProductType.merchandise}
          />
          <FormControlLabel
            disabled
            control={
              <Checkbox
                id="productType.poster"
                defaultChecked={designRequest.productType.poster}
                color="primary"
                name="productType.poster"
              />
            }
            label={DesignRequestProductType.poster}
          />
          <FormControlLabel
            disabled
            control={
              <Checkbox
                id="productType.powerpointPresentation"
                defaultChecked={designRequest.productType.powerpointPresentation}
                color="primary"
                name="productType.powerpointPresentation"
              />
            }
            label={DesignRequestProductType.powerpointPresentation}
          />
          <Grid container>
            <FormControlLabel
              disabled
              control={
                <Checkbox
                  id="productType.other"
                  defaultChecked={designRequest.productType.other}
                  color="primary"
                  name="productType.other"
                />
              }
              label={DesignRequestProductType.other}
            />
            <TextField
              id="productType.otherText"
              name="productType.otherText"
              multiline
              disabled
              value={designRequest.productType.otherText}
              variant="standard"
              sx={questionTheme}
            />
          </Grid>
        </FormGroup>
      </FormControl>
      <FormLabel component="legend">
        5. Is there a theme/colour palette/visual preference that you would like us to work with?
      </FormLabel>
      <TextField
        required
        fullWidth
        id="theme"
        label="You may provide details via share link."
        name="theme"
        multiline
        value={designRequest.theme}
        disabled
        variant="standard"
        sx={questionTheme}
      />
      <FormLabel component="legend">6. Please provide examples of the above</FormLabel>
      <TextField
        fullWidth
        id="example"
        label="You may provide details via share link."
        name="example"
        multiline
        value={designRequest.example}
        disabled
        variant="standard"
        sx={questionTheme}
      />
      <FormLabel component="legend">7. What is the key message you would like to send out? *</FormLabel>
      <TextField
        required
        fullWidth
        id="message"
        name="message"
        multiline
        value={designRequest.message}
        disabled
        variant="standard"
        sx={questionTheme}
      />
      <Grid container direction="column" sx={questionTheme}>
        <FormLabel component="legend">8. When do you need the design by? *</FormLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            inputFormat="dd/MM/yyyy"
            value={designRequest.dueDate}
            disabled
            onChange={(value: Date | null) => {}}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <FormControl component="fieldset" required>
        <FormLabel component="legend">
          9. Would you like multiple demos/drafts provided before the final design?
        </FormLabel>
        <RadioGroup value={designRequest.multipleDrafts} name="multipleDrafts" sx={questionTheme}>
          <FormControlLabel value={true} control={<Radio />} label="Yes" disabled />
          <FormControlLabel value={false} control={<Radio />} label="No" disabled />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset" required>
        <FormLabel component="legend">10. Would you like us to run the design by you before finalising?</FormLabel>
        <RadioGroup value={designRequest.runTheDesign} name="runTheDesign" sx={questionTheme}>
          <FormControlLabel value={true} control={<Radio />} label="Yes" disabled />
          <FormControlLabel value={false} control={<Radio />} label="No" disabled />
        </RadioGroup>
      </FormControl>
      <FormLabel component="legend">11. Do you have any other information you would like to include?</FormLabel>
      <TextField
        fullWidth
        id="extraInformation"
        name="extraInformation"
        multiline
        value={designRequest.extraInformation}
        disabled
        variant="standard"
        sx={questionTheme}
      />
      <FormLabel component="legend">12. Details of Event/Project that you'd like to promote</FormLabel>
      <TextField
        fullWidth
        id="detailsOfEventOrProject"
        name="detailsOfEventOrProject"
        multiline
        value={designRequest.detailsOfEventOrProject}
        disabled
        variant="standard"
        sx={questionTheme}
      />
    </>
  );
};
