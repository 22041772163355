import { SOW_WEBSITE } from "../helpers/Env";
import { Path } from "../helpers/Path";
import { RequestStatus } from "../data/RequestStatus";
import { Staff } from "../models/Staff";
import { DesignRequestDeclined } from "../models/DesignRequestDeclined";
import { DesignRequestCommentData } from "../models/DesignRequestCommentData";
import { DesignRequest } from "../models/DesignRequest";
import { DesignRequestEmailForm } from "../models/DesignRequestEmailForm";
import { sendDesignRequestEmail } from "./email";
import { db } from "./firebase";
import { approved, declined, pending, WhereFilterOp } from "./requests";
import { getHODByDepartment, getUserById, getUserByIdYear, getUsersByDepartment } from "./users";
import { currentStaffYear } from "../helpers/FormatDate";
import { DepartmentInterface } from "../models/Department";
import { getStaffYearDepartments } from "./departments";
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDocs,
  query,
  QuerySnapshot,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

export const designRequestStatus = (designRequest: DesignRequest): RequestStatus => {
  if (designRequestApproved(designRequest)) {
    return RequestStatus.APPROVED;
  } else if (designRequestDeclined(designRequest)) {
    return RequestStatus.DECLINED;
  } else {
    return RequestStatus.PENDING;
  }
};

export const designRequestApproved = (designRequest: DesignRequest): boolean => approved(designRequest.approvedByHOD);

export const designRequestDeclined = (designRequest: DesignRequest): boolean => declined(designRequest.approvedByHOD);

export const designRequestPending = (designRequest: DesignRequest): boolean => pending(designRequest.approvedByHOD);

export const designRequestCompleted = (designRequest: DesignRequest) => designRequest.completed === true;

export const getAllDesignRequests = (
  staff: Staff,
  departments: DepartmentInterface
): Promise<Promise<DesignRequest[]>[]> =>
  new Promise((resolve) => resolve([getMyDesignRequests(staff), getDesignRequests(staff, departments)]));

const getMyDesignRequests = (staff: Staff): Promise<DesignRequest[]> => getRequestsWhere("userID", "==", staff.id);

const getDesignRequests = (staff: Staff, departments: DepartmentInterface): Promise<DesignRequest[]> => {
  if (staff.department === departments.MARKETING) {
    return getRequestsWhere("userID", "!=", "fakeemail@mail.com");
  } else {
    return new Promise((resolve) => {
      resolve([]);
    });
  }
};

const getCommentSection = (id: string, year: string): Promise<DesignRequestCommentData[]> =>
  new Promise((resolve, reject) => {
    getDocs(collection(db, "designRequests", "designRequests", year, id, "comments"))
      .then((res) => {
        const p = res.docs.map(async (doc) => {
          const userFetch = await getUserById(doc.data().userID);
          return {
            comment: doc.data().comment,
            staff: userFetch,
            submittedTime: doc.data().submittedTime.toDate(),
            id: doc.id,
          } as DesignRequestCommentData;
        });
        Promise.all(p).then((list) => resolve(list.sort((a, b) => (a.submittedTime > b.submittedTime ? 1 : -1))));
      })
      .catch((error) => {
        reject(error);
      });
  });

const getRequestsWhere = (category: string, is: WhereFilterOp, incoming_category: any): Promise<DesignRequest[]> =>
  new Promise((resolve, reject) =>
    getDocs(
      query(
        collection(db, "designRequests", "designRequests", currentStaffYear.toString()),
        where(category, is, incoming_category)
      )
    )
      .then((res) => {
        const p = getDataFromFirestoreYear(res, currentStaffYear.toString());
        Promise.all(p).then((list) => resolve(list.sort((a, b) => (a.submittedTime > b.submittedTime ? 1 : -1))));
      })
      .catch((error) => {
        reject(error);
      })
  );

export const getYearDesignRequests = (year: string): Promise<DesignRequest[]> =>
  new Promise((resolve, reject) =>
    getDocs(collection(db, "designRequests", "designRequests", year))
      .then((res) => {
        const p = getDataFromFirestoreYear(res, year);
        Promise.all(p).then((list) => resolve(list.sort((a, b) => (a.submittedTime > b.submittedTime ? 1 : -1))));
      })
      .catch((error) => {
        reject(error);
      })
  );

const getDataFromFirestoreYear = (res: QuerySnapshot<DocumentData>, year: string) =>
  res.docs.map(async (doc) => {
    const departments = await getStaffYearDepartments(year);
    const hodFetch = await getHODByDepartment(departments, departments.MARKETING);
    const userFetch = await getUserByIdYear(doc.data().userID, year);
    const commentSectionFetch = await getCommentSection(doc.id, year);
    return {
      ...doc.data(),
      staff: userFetch,
      hod: hodFetch,
      dueDate: doc.data().dueDate.toDate(),
      submittedTime: doc.data().submittedTime.toDate(),
      approvedTime: doc.data().approvedTime ? doc.data().approvedTime.toDate() : undefined,
      declinedTime: doc.data().declinedTime ? doc.data().declinedTime.toDate() : undefined,
      completedTime: doc.data().completedTime ? doc.data().completedTime.toDate() : undefined,
      commentSection: commentSectionFetch,
      id: doc.id,
    } as DesignRequest;
  });

const sendNeedReviewingEmail = (designRequest: DesignRequest, email: string) =>
  sendDesignRequestEmail({
    email: email,
    subject:
      "The design request: #" + designRequest.id + " from " + designRequest.staff.firstName + " needs your reviewing",
    message:
      "The design request below requires your reviewing. To approve, please go to the dashboard through the link below. You can ask questions in the comment section.",
    designRequest: designRequest,
    website: SOW_WEBSITE + Path["Design Requests"],
  } as DesignRequestEmailForm);

const sendEmailSent = (designRequest: DesignRequest) =>
  sendDesignRequestEmail({
    email: designRequest.staff.email,
    subject: "Your design request: #" + designRequest.id + " has been submitted",
    message:
      "The design request below has been submitted to the " +
      designRequest.hod?.department +
      " Department Head. Once approved, an email will be sent.",
    designRequest: designRequest,
    website: SOW_WEBSITE + Path["Design Requests"],
  } as DesignRequestEmailForm);

const sendNeedRegistration = (designRequest: DesignRequest) =>
  sendDesignRequestEmail({
    email: "dominic.tjong@sowaustralia.com",
    subject:
      "The design request: #" +
      designRequest.id +
      " from " +
      designRequest.staff.firstName +
      " need to have registration made",
    message: "The design request below requires you to add registration on the main website.",
    designRequest: designRequest,
    website: SOW_WEBSITE + Path["Design Requests"],
  } as DesignRequestEmailForm);

export const submitDesignRequest = (
  designRequest: DesignRequest,
  departments: DepartmentInterface
): Promise<DesignRequest> =>
  new Promise((resolve, reject) => {
    getDocs(collection(db, "designRequests", "designRequests", currentStaffYear.toString()))
      .then((res) => {
        const id_new = (res.docs.length > 0 ? Math.max(...res.docs.map((d) => Number(d.id))) + 1 : 1).toString();
        const hodFetch = getHODByDepartment(departments, departments.MARKETING);
        Promise.all([hodFetch]).then(([hod_in]) => {
          designRequest = {
            ...designRequest,
            hod: hod_in,
            id: id_new,
          };
          const { staff, hod, id, ...requestWithoutStaff } = designRequest;
          setDoc(doc(db, "designRequests", "designRequests", currentStaffYear.toString(), id_new), {
            ...requestWithoutStaff,
            userID: designRequest.staff.id,
          })
            .then(() =>
              getUsersByDepartment(departments.MARKETING).then(async (users) => {
                for (const user of users) {
                  await sendNeedReviewingEmail(designRequest, user.email);
                }
                await sendEmailSent(designRequest);
                await sendNeedRegistration(designRequest);
                resolve(designRequest);
              })
            )
            .catch((error) => {
              reject(error);
            });
        });
      })
      .catch((error) => {
        reject(error);
      });
  });

const sendCancelledEmail = (designRequest: DesignRequest) =>
  sendDesignRequestEmail({
    email: designRequest.hod?.email,
    subject:
      "The design request: #" + designRequest.id + " from " + designRequest.staff.firstName + " has been cancelled",
    name: designRequest.hod?.firstName,
    message:
      "The design request below has been cancelled by the requestee. It will no longer show up on the dashboard.",
    designRequest: designRequest,
    website: SOW_WEBSITE + Path["Design Requests"],
  } as DesignRequestEmailForm);

export const cancelDesignRequest = (designRequest: DesignRequest): Promise<DesignRequest> => {
  return new Promise(async (resolve, reject) => {
    const comments = await getDocs(
      collection(
        db,
        "designRequests",
        "designRequests",
        currentStaffYear.toString(),
        designRequest.id as string,
        "comments"
      )
    );

    comments.docs.map(async (d) => {
      await deleteDoc(
        doc(
          db,
          "designRequests",
          "designRequests",
          currentStaffYear.toString(),
          designRequest.id as string,
          "comments",
          d.id
        )
      );
    });
    deleteDoc(doc(db, "designRequests", "designRequests", currentStaffYear.toString(), designRequest.id as string))
      .then(() => {
        sendCancelledEmail(designRequest);
        resolve(designRequest);
      })
      .catch((error) => reject(error));
  });
};

const sendApprovedEmail = (designRequest: DesignRequest) => {
  sendDesignRequestEmail({
    email: designRequest.staff.email,
    subject: "Your design request: #" + designRequest.id + " has been approved",
    name: designRequest.staff.firstName,
    message: "The design request below has been approved. Please check the dashboard for more details.",
    designRequest: designRequest,
    website: SOW_WEBSITE + Path["Design Requests"],
  } as DesignRequestEmailForm);

  sendDesignRequestEmail({
    email: designRequest.hod?.email,
    subject:
      "The design request: #" +
      designRequest.id +
      " from " +
      designRequest.staff.firstName +
      " has been approved by you",
    name: designRequest.hod?.firstName,
    message: "The design request below has been approved by you. Please check the dashboard for more details.",
    designRequest: designRequest,
    website: SOW_WEBSITE + Path["Design Requests"],
  } as DesignRequestEmailForm);
};

export const approveDesignRequest = (designRequest: DesignRequest): Promise<DesignRequest> =>
  new Promise((resolve, reject) => {
    designRequest = {
      ...designRequest,
      approvedByHOD: RequestStatus.APPROVED,
      approvedTime: new Date(),
    };
    updateDoc(doc(db, "designRequests", "designRequests", currentStaffYear.toString(), designRequest.id as string), {
      approvedByHOD: RequestStatus.APPROVED,
      approvedTime: new Date(),
    })
      .then(() => {
        sendApprovedEmail(designRequest);
        resolve(designRequest);
      })
      .catch((error) => {
        reject(error);
      });
  });

const sendDeclinedEmail = (designRequest: DesignRequest) => {
  sendDesignRequestEmail({
    email: designRequest.staff.email,
    subject: "Your design request: #" + designRequest.id + " has been declined",
    name: designRequest.staff.firstName,
    message: "The design request below has been declined. Please check the dashboard for more details.",
    designRequest: designRequest,
    website: SOW_WEBSITE + Path["Design Requests"],
  } as DesignRequestEmailForm);

  sendDesignRequestEmail({
    email: designRequest.hod?.email,
    subject:
      "The design request: #" +
      designRequest.id +
      " from " +
      designRequest.staff.firstName +
      " has been declined by you",
    name: designRequest.hod?.firstName,
    message: "The design request below has been declined by you. Please check the dashboard for more details.",
    designRequest: designRequest,
    website: SOW_WEBSITE + Path["Design Requests"],
  } as DesignRequestEmailForm);
};

export const declineDesignRequest = (dr: DesignRequestDeclined): Promise<DesignRequestDeclined> => {
  let drRejected: DesignRequestDeclined = {
    ...dr,
    approvedByHOD: RequestStatus.DECLINED,
    declinedTime: new Date(),
  };
  return new Promise((resolve, reject) => {
    updateDoc(doc(db, "designRequests", "designRequests", currentStaffYear.toString(), drRejected.id as string), {
      approvedByHOD: RequestStatus.DECLINED,
      declinedTime: drRejected.declinedTime,
      reason: drRejected.reason,
    })
      .then(() => {
        sendDeclinedEmail(drRejected);
        resolve(drRejected);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sendCompletedEmail = (designRequest: DesignRequest) => {
  sendDesignRequestEmail({
    email: designRequest.staff.email,
    subject: "Your design request: #" + designRequest.id + " has been completed",
    name: designRequest.staff.firstName,
    message: "The design request below has been completed. Please check the dashboard for more details.",
    designRequest: designRequest,
    website: SOW_WEBSITE + Path["Design Requests"],
  } as DesignRequestEmailForm);

  sendDesignRequestEmail({
    email: designRequest.hod?.email,
    subject:
      "The design request: #" +
      designRequest.id +
      " from " +
      designRequest.staff.firstName +
      " has been completed by you",
    name: designRequest.hod?.firstName,
    message: "The design request below has been completed by you. Please check the dashboard for more details.",
    designRequest: designRequest,
    website: SOW_WEBSITE + Path["Design Requests"],
  } as DesignRequestEmailForm);
};

export const completeDesignRequest = (dr: DesignRequest): Promise<DesignRequest> => {
  dr = {
    ...dr,
    completed: true,
    completedTime: new Date(),
  };
  return new Promise((resolve, reject) =>
    updateDoc(doc(db, "designRequests", "designRequests", currentStaffYear.toString(), dr.id as string), {
      completed: dr.completed,
      completedTime: dr.completedTime,
    })
      .then(() => {
        sendCompletedEmail(dr);
        resolve(dr);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

const sendCommentEmail = (designRequest: DesignRequest, comment: DesignRequestCommentData, staff: Staff) =>
  sendDesignRequestEmail({
    email: staff.email,
    subject: "The design request: #" + designRequest.id + " has a new comment by " + comment.staff.firstName,
    name: staff.firstName,
    message: "The design request below has a new comment by " + comment.staff.firstName + ": " + comment.comment,
    designRequest: designRequest,
    website: SOW_WEBSITE + Path["Design Requests"],
  } as DesignRequestEmailForm);

export const submitComment = (
  designRequest: DesignRequest,
  comment: DesignRequestCommentData
): Promise<DesignRequestCommentData> =>
  new Promise((resolve, reject) => {
    getDocs(
      collection(
        db,
        "designRequests",
        "designRequests",
        currentStaffYear.toString(),
        designRequest.id as string,
        "comments"
      )
    )
      .then((res) => {
        const id_new = (res.docs.length > 0 ? Math.max(...res.docs.map((d) => Number(d.id))) + 1 : 1).toString();
        comment = {
          ...comment,
          submittedTime: new Date(),
          id: id_new,
        };
        const { staff, id, ...commentWithoutStaff } = comment;
        setDoc(
          doc(
            db,
            "designRequests",
            "designRequests",
            currentStaffYear.toString(),
            designRequest.id as string,
            "comments",
            id_new
          ),
          {
            ...commentWithoutStaff,
            userID: staff.id,
          }
        )
          .then(() => {
            if (comment.staff.id === designRequest.staff.id) {
              sendCommentEmail(designRequest, comment, designRequest.hod as Staff);
            } else if (comment.staff.id === designRequest.hod?.id) {
              sendCommentEmail(designRequest, comment, designRequest.staff);
            }
            resolve(comment);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });

export const deleteComment = (designRequest: DesignRequest, comment_id: string): Promise<string> =>
  new Promise((resolve, reject) => {
    deleteDoc(
      doc(
        db,
        "designRequests",
        "designRequests",
        currentStaffYear.toString(),
        designRequest.id as string,
        "comments",
        comment_id
      )
    )
      .then(() => {
        resolve(comment_id);
      })
      .catch((error) => {
        reject(error);
      });
  });
