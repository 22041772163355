import { Container } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { InitResource, Resource } from "../../models/Resource";
import { getResources } from "../../services/storage";
import { ResourcesCard } from "../dashboard/DashboardCards/ResourcesCard";

interface ResourcesState {
  resource: Resource;
  loading: boolean;
}

export const Resources = () => {
  const [state, setState] = useState<ResourcesState>({
    loading: true,
    resource: InitResource,
  });

  const { resource, loading } = state;

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    const resourcesFetch = getResources();

    Promise.all([resourcesFetch]).then(([resource]) => {
      setState({
        ...state,
        resource,
        loading: false,
      });
    });
    // eslint-disable-next-line
  }, []);

  const theme = useTheme();

  return loading ? (
    <Loading />
  ) : (
    <Container
      sx={{
        mt: theme.spacing(4),
      }}
      maxWidth="md"
    >
      <ResourcesCard resource={resource} noCardActions={true} />
    </Container>
  );
};
