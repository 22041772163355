import { Container, Link, Typography } from "@mui/material";
import { Path } from "../helpers/Path";
import { Link as ReactRouterLink } from "react-router-dom";

export const PageNotFound = () => {
  return (
    <Container maxWidth="lg">
      <Typography variant="h4" align="center">
        PAGE NOT FOUND 404
      </Typography>
      <Link
        sx={{
          textDecoration: "none",
          color: "black",
          fontSize: "large",
        }}
        underline="hover"
        component={ReactRouterLink}
        to={Path.Dashboard}
      >
        <Typography align="center" variant="h4">
          Go to Dashboard
        </Typography>
      </Link>
    </Container>
  );
};
