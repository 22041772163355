import { Checkbox, FormControl, MenuItem, Select } from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles, useTheme } from "@mui/styles";

const useStyles = makeStyles({
  icon: {
    fill: "white",
  },
  root: {
    color: "white",
  },
});

export const StatusSelect = ({
  handleChange,
  completed,
  canTick,
  mobile,
}: {
  handleChange: (event: any) => void;
  completed: boolean | undefined;
  canTick: boolean | undefined;
  mobile: boolean;
}) => {
  const theme = useTheme();
  const classes = useStyles();
  return canTick ? (
    mobile ? (
      <Checkbox checked={completed ? completed : false} onChange={handleChange} />
    ) : (
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          size="small"
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: grey[500],
                color: theme.palette.common.white,
                borderRadius: "10px",
              },
            },
          }}
          inputProps={{
            classes: {
              icon: classes.icon,
              root: classes.root,
            },
          }}
          sx={{
            borderRadius: "10px",
            backgroundColor: grey[600],
            color: theme.palette.common.white,
            fontWeight: "bold",
            textAlign: "center",
          }}
          variant="outlined"
          value={completed ? 1 : 0}
          onChange={handleChange}
        >
          <MenuItem
            value={1}
            sx={{
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: grey[600],
              },
            }}
          >
            Done
          </MenuItem>
          <MenuItem
            value={0}
            sx={{
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: grey[600],
              },
            }}
          >
            Not done
          </MenuItem>
        </Select>
      </FormControl>
    )
  ) : (
    <></>
  );
};
