import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Form, Formik } from "formik";
import { ModuleMCQ } from "../../models/ModuleMCQ";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { useState } from "react";

export const MCQ = ({
  questions,
  answers,
  updateAnswers,
  completed,
}: {
  questions: ModuleMCQ[];
  answers: { [questionId: string]: string | string[] };
  updateAnswers: (a: { [questionId: string]: string | string[] }) => void;
  completed: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  return (
    <Formik initialValues={answers ? answers : {}} enableReinitialize onSubmit={() => setOpen(true)}>
      {(props) => {
        const { values, setFieldValue } = props;
        return (
          <Form>
            <ConfirmationModal
              title={"Submit MCQ answers?"}
              content={"You won't be able to submit it again"}
              confirmText={"Submit"}
              cancelText={"Cancel"}
              dialogOpen={open}
              closeDialog={() => setOpen(false)}
              doAction={() => {
                updateAnswers(values);
                setOpen(false);
              }}
            />
            <Typography variant="h3" sx={{ marginTop: theme.spacing(10) }}>
              Multiple Choice Questions
            </Typography>
            <Grid container direction="column">
              {questions.map((q, i) => (
                <Box key={i} sx={{ marginTop: theme.spacing(3) }}>
                  <FormControl>
                    <FormLabel id={`${i}-mcq`}>{q.question}</FormLabel>
                    {q.answers === undefined ? (
                      <RadioGroup
                        aria-labelledby={`${i}-mcq`}
                        name="radio-buttons-group"
                        value={values[i.toString()] ?? null}
                        onChange={(e) => (!completed ? setFieldValue(i.toString(), e.target.value) : null)}
                      >
                        {q.choices.map((choice, j) => (
                          <FormControlLabel
                            disabled={completed}
                            key={j}
                            value={(j + 1).toString()}
                            control={
                              <Radio
                                sx={{
                                  "&.Mui-checked.Mui-disabled": {
                                    color: q.answer === values[i] ? "success.main" : "error.main",
                                    opacity: 1,
                                  },
                                }}
                              />
                            }
                            label={choice.text}
                          />
                        ))}
                        {completed && (
                          <>
                            {q.answer !== answers[i] && (
                              <Typography color={theme.palette.info.main}>
                                Correct answer: {q.choices[Number(q.answer) - 1]?.text}
                              </Typography>
                            )}
                            <Typography>{q.explanation}</Typography>
                          </>
                        )}
                      </RadioGroup>
                    ) : (
                      <FormGroup>
                        {q.choices.map((choice, j) => (
                          <FormControlLabel
                            disabled={completed}
                            key={j}
                            value={(j + 1).toString()}
                            checked={
                              completed
                                ? q.answers?.includes((j + 1).toString()) ||
                                  answers[i.toString()]?.includes((j + 1).toString())
                                : answers[i.toString()]?.includes((j + 1).toString())
                            }
                            control={
                              <Checkbox
                                sx={{
                                  "&.Mui-checked.Mui-disabled": {
                                    color:
                                      q.answers?.includes((j + 1).toString()) &&
                                      answers[i.toString()]?.includes((j + 1).toString())
                                        ? "success.main"
                                        : q.answers?.includes((j + 1).toString())
                                        ? "info.main"
                                        : "error.main",
                                    opacity: 1,
                                  },
                                }}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    if (answers[i.toString()] === undefined) {
                                      answers[i.toString()] = [];
                                    }
                                    const index = (answers[i.toString()] as string[]).findIndex(
                                      (s) => Number(s) > j + 1
                                    );

                                    if (index === -1) {
                                      (answers[i.toString()] as string[]).push((j + 1).toString());
                                    } else {
                                      (answers[i.toString()] as string[]).splice(index, 0, (j + 1).toString());
                                    }
                                  } else {
                                    const index = (answers[i.toString()] as string[]).findIndex(
                                      (s) => Number(s) === j + 1
                                    );

                                    if (index !== -1) {
                                      (answers[i.toString()] as string[]).splice(index, 1);
                                    }
                                  }
                                  setFieldValue(i.toString(), answers[i.toString()]);
                                }}
                              />
                            }
                            label={choice.text}
                          />
                        ))}
                        {completed && (
                          <>
                            {JSON.stringify(q.answers) !== JSON.stringify(answers[i]) && (
                              <>
                                <Typography color={theme.palette.info.main}>Correct answers: </Typography>
                                {q.answers.map((a, j) => (
                                  <Typography
                                    key={j}
                                    color={
                                      answers[i].includes(a) ? theme.palette.success.main : theme.palette.info.main
                                    }
                                  >
                                    {q.choices[Number(a) - 1].text}
                                  </Typography>
                                ))}
                              </>
                            )}
                            <Typography>{q.explanation}</Typography>
                          </>
                        )}
                      </FormGroup>
                    )}
                  </FormControl>
                </Box>
              ))}
            </Grid>
            {!completed ? (
              <Button
                disabled={Object.keys(values).length !== Object.keys(questions).length}
                fullWidth
                size="large"
                variant="contained"
                type="submit"
              >
                Submit
              </Button>
            ) : (
              <Typography>
                Final score:{" "}
                {(
                  (questions.reduce(
                    (a, c, i) =>
                      a +
                      ((
                        c.answers !== undefined
                          ? JSON.stringify(c.answers) === JSON.stringify(answers[i])
                          : c.answer === answers[i]
                      )
                        ? 1
                        : 0),
                    0
                  ) /
                    questions.length) *
                  100
                ).toFixed()}
                %
              </Typography>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
