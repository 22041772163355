export enum University {
  USYD = "University of Sydney",
  UNSW = "University of New South Wales",
  UTS = "University of Technology, Sydney",
  MACQ = "Macquarie University",
  ACU = "Australian Catholic University",
}

export enum UniversityAny {
  USYD = "University of Sydney" as any,
  UNSW = "University of New South Wales" as any,
  UTS = "University of Technology, Sydney" as any,
  MACQ = "Macquarie University" as any,
  ACU = "Australian Catholic University" as any,
}

export const universities = Object.values(University);
export const universitiesWithoutAcu = Object.values(University).slice(0, -1);
