import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { updateDepartments } from "../../services/departments";
import ClearIcon from "@mui/icons-material/Clear";
import { User } from "../../models/User";
import { OrganisationStructureInterface } from "./OrganisationStructure";
import { Loading } from "../../components/Loading";

export const DepartmentsComponent = ({
  state,
  setState,
}: {
  state: OrganisationStructureInterface;
  setState: (state: OrganisationStructureInterface) => void;
}) => {
  const getFirstName = (email?: string) =>
    email?.match("@sowaustralia.com$")
      ? email.split(".")[0].charAt(0).toUpperCase() + email.split(".")[0].slice(1)
      : "";
  const getLastName = (email?: string) =>
    email?.match("@sowaustralia.com$")
      ? email.split(".")[1].split("@")[0].charAt(0).toUpperCase() + email.split(".")[1].split("@")[0].slice(1)
      : "";
  const theme = useTheme();
  const updateDepartmentsForYear = () =>
    updateDepartments(state.year, departments)
      .then(() =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: "Successfully saved",
            severity: "success",
          },
          editState: { ...editState, departments: false },
          saving: false,
        })
      )
      .catch((error) =>
        setState({
          ...state,
          departments: JSON.parse(JSON.stringify(departmentsTemp)),
          editState: { ...editState, departments: false },
          alert: {
            showMessage: true,
            message: error.message,
            severity: "error",
          },
        })
      );

  const handleDeleteDepartment = (department: string) => {
    delete departments.departments[department];
    setState({ ...state, departments: departments });
  };

  const handleAddDepartment = () => {
    if (!departments.departments[""]) {
      departments.departments[""] = {
        head: users[0],
        division: "",
        colour: "",
      };
      setState({ ...state, departments: departments });
    }
  };

  const { departments, departmentsTemp, divisions, users, editState, saving } = state;
  return (
    <Grid item>
      {editState.departments ? (
        saving ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              variant="contained"
              onClick={() =>
                setState({
                  ...state,
                  departments: JSON.parse(JSON.stringify(departmentsTemp)),
                  editState: { ...editState, departments: false },
                })
              }
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setState({ ...state, saving: true });
                updateDepartmentsForYear();
              }}
            >
              Save
            </Button>
          </>
        )
      ) : (
        <Button
          variant="contained"
          disabled={saving}
          onClick={() =>
            setState({
              ...state,
              departmentsTemp: JSON.parse(JSON.stringify(departments)),
              editState: { ...editState, departments: true },
            })
          }
        >
          Edit
        </Button>
      )}
      <Typography variant="h6">Which department will handle/approve the Marketing and Promotion Requests?</Typography>
      <Select
        disabled={!editState.departments || saving}
        required
        labelId="marketing-label"
        id="marketing"
        onChange={(event: SelectChangeEvent<string>) => {
          departments.MARKETING = event.target.value;
          setState({
            ...state,
            departments: departments,
          });
        }}
        name="marketing"
        value={departments.MARKETING}
        variant="outlined"
      >
        {Object.keys(departments.departments).map((dep, i) => (
          <MenuItem key={i} value={dep}>
            {dep}
          </MenuItem>
        ))}
      </Select>
      <Grid sx={{ mt: theme.spacing(1) }} container direction="column" spacing={2}>
        {Object.entries(departments.departments).map(([department, content], index) => (
          <Grid spacing={2} container item key={index}>
            <Grid item>
              <TextField
                label="Department"
                id="department"
                disabled={
                  !editState.departments || saving || index !== Object.entries(departments.departments).length - 1
                }
                required
                placeholder="Department"
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  let newDepartmentsList = Object.entries(departments.departments).map(([dep, div]) =>
                    dep === department ? [event.target.value, div] : [dep, div]
                  );

                  setState({
                    ...state,
                    departments: {
                      ...departments,
                      departments: Object.fromEntries(newDepartmentsList),
                    },
                  });
                }}
                value={department}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <Select
                disabled={!editState.departments || saving}
                required
                label="Division"
                labelId="divisions-label"
                id="divisions"
                sx={{
                  width: 150,
                }}
                onChange={(event: SelectChangeEvent<string>) => {
                  departments.departments[department] = {
                    ...content,
                    division: event.target.value,
                  };

                  setState({
                    ...state,
                    departments: departments,
                  });
                }}
                name="divisions"
                value={content.division}
                variant="filled"
              >
                {Object.keys(divisions).map((div, i) => (
                  <MenuItem key={i} value={div}>
                    {div}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <TextField
                disabled={!editState.departments || saving}
                label="Colour"
                id="colour"
                required
                placeholder="Colour"
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  departments.departments[department] = {
                    ...content,
                    colour: event.target.value,
                  };

                  setState({
                    ...state,
                    departments,
                  });
                }}
                value={content.colour}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <Autocomplete
                id={"department-head"}
                disabled={!editState.departments || saving}
                getOptionLabel={(option) =>
                  option.firstName
                    ? `${option.firstName} ${option.lastName} - ${option.role}`
                    : `${getFirstName(option.email)} ${getLastName(option.email)} - ${option.role}`
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={content.head}
                onChange={(event, newValue: User | null) => {
                  if (newValue) {
                    departments.departments[department] = {
                      ...content,
                      head: newValue,
                    };

                    setState({
                      ...state,
                      departments: departments,
                    });
                  }
                }}
                options={users}
                sx={{
                  width: 200,
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label="head" placeholder={`Select Head`} />
                )}
              />
            </Grid>
            <Grid item>
              <IconButton
                disabled={!editState.departments || saving}
                color="primary"
                aria-label="delete"
                component="span"
                onClick={() => handleDeleteDepartment(department)}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Button variant="contained" disabled={!editState.departments || saving} onClick={() => handleAddDepartment()}>
        Add Department
      </Button>
    </Grid>
  );
};
