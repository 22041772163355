import { useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { sendPasswordResetEmailTo } from "../../services/auth";
import { Path } from "../../helpers/Path";
import { Container, Typography, TextField, Grid, Snackbar, Link, Button } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Alert } from "@mui/material";
import { MessageState } from "../../models/MessageState";

const forgotPasswordValidationSchema = yup.object().shape({
  email: yup.string().email().required("Enter your email"),
});

export const ForgotPassword = () => {
  const theme = useTheme();
  const [state, setState] = useState<MessageState>({
    showMessage: false,
    message: "",
    severity: "error",
  });

  const handleMessageClose = () => {
    setState({ ...state, showMessage: false });
  };

  const { showMessage, message, severity } = state;

  return (
    <>
      <Container component="main" maxWidth="sm">
        <div>
          <Snackbar open={showMessage} onClose={handleMessageClose} autoHideDuration={6000}>
            <Alert elevation={6} variant="filled" severity={severity}>
              {message}
            </Alert>
          </Snackbar>
          <Container maxWidth="md">
            <Typography variant="h4" align="center" gutterBottom>
              Forgot Password
            </Typography>
            <Typography align="center" gutterBottom>
              Enter your email below
            </Typography>
          </Container>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={(values) => {
              sendPasswordResetEmailTo(values.email)
                .then((message) => {
                  setState({
                    severity: "success",
                    message: message,
                    showMessage: true,
                  });
                })
                .catch((error) => {
                  setState({
                    severity: "error",
                    message: error.message,
                    showMessage: true,
                  });
                });
            }}
            validationSchema={forgotPasswordValidationSchema}
          >
            {(props) => {
              const { values, errors, touched, handleBlur, handleChange } = props;
              return (
                <Form>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    helperText={errors.email && touched.email ? errors.email : ""}
                    value={values.email}
                    error={errors.email && touched.email ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="standard"
                  />
                  <Grid
                    container
                    sx={{
                      margin: theme.spacing(3, 0, 2),
                    }}
                  >
                    <Button fullWidth size="large" variant="contained" type="submit">
                      Send password reset
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
        <Grid container>
          <Grid item>
            <Link
              sx={{
                textDecoration: "none",
                color: "black",
                fontSize: "large",
              }}
              underline="hover"
              component={ReactRouterLink}
              to={Path.SignIn}
            >
              Log In
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
