import { Dialog } from "@mui/material";
import { Formik, FormikProps } from "formik";
import { useContext } from "react";
import { UserContext } from "../../components/PrivateRoute";
import { Announcement } from "../../models/Announcement";
import { MessageState } from "../../models/MessageState";
import { postAnnouncement } from "../../services/announcements";
import { AnnouncementsForm } from "./AnnouncementsForm";
import { Staff } from "../../models/Staff";

export const AnnouncementsPost = ({
  dialogOpen,
  closeDialog,
  handlePost,
}: {
  dialogOpen: boolean;
  closeDialog: () => void;
  handlePost: (m: MessageState, a: Announcement | null) => void;
}) => {
  const user = useContext(UserContext);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={dialogOpen}
      onClose={closeDialog}
      aria-describedby="alert-dialog-description"
    >
      <Formik
        initialValues={{
          title: "",
          content: "",
          time: new Date(),
          user: user,
          tag: (user as Staff).department,
        }}
        onSubmit={(values: Announcement) =>
          postAnnouncement(values)
            .then((a) =>
              handlePost(
                {
                  showMessage: true,
                  message: "Successfully posted announcement!",
                  severity: "success",
                },
                a
              )
            )
            .catch((error) => {
              handlePost(
                {
                  showMessage: true,
                  message: error.message,
                  severity: "error",
                },
                null
              );
            })
        }
      >
        {(props: FormikProps<Announcement>) => {
          return <AnnouncementsForm props={props} handleClose={closeDialog} />;
        }}
      </Formik>
    </Dialog>
  );
};
