import { Button, CircularProgress, Grid, IconButton, TextField, Typography } from "@mui/material";
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import ClearIcon from "@mui/icons-material/Clear";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { updateRoles } from "../../services/roles";
import { OrganisationStructureInterface } from "./OrganisationStructure";

const reorderContent = (list: string[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  // some basic styles to make the items look a bit nicer
  // userSelect: "none",
  padding: grid * 2,
  margin: `${grid}px`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "lightgrey",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "grey" : "grey",
});

export const RolesComponent = ({
  state,
  setState,
}: {
  state: OrganisationStructureInterface;
  setState: (state: OrganisationStructureInterface) => void;
}) => {
  const updateRolesForYear = () =>
    updateRoles(state.year, roles)
      .then(() =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: "Successfully saved",
            severity: "success",
          },
          editState: { ...editState, roles: false },
          saving: false,
        })
      )
      .catch((error) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: error.message,
            severity: "error",
          },
        })
      );

  const handleDeleteRole = (index: number) => {
    roles.splice(index, 1);
    setState({ ...state, roles: roles });
  };

  const handleAddRole = () => {
    roles.push("");
    setState({ ...state, roles: roles });
  };

  const { roles, rolesTemp: nextYearRolesTemp, editState, saving } = state;

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const roles = reorderContent(state.roles, result.source.index, result.destination.index);

    setState({
      ...state,
      roles,
    });
  };

  return (
    <Grid item>
      <Typography variant="body2">Order from top to bottom</Typography>
      {editState.roles ? (
        saving ? (
          <CircularProgress />
        ) : (
          <Grid item>
            <Button
              variant="contained"
              onClick={() =>
                setState({
                  ...state,
                  roles: [...nextYearRolesTemp],
                  editState: { ...editState, roles: false },
                })
              }
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setState({ ...state, saving: true });
                updateRolesForYear();
              }}
            >
              Save
            </Button>
          </Grid>
        )
      ) : (
        <Button
          variant="contained"
          disabled={saving}
          onClick={() =>
            setState({
              ...state,
              rolesTemp: [...roles],
              editState: { ...editState, roles: true },
            })
          }
        >
          Edit
        </Button>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable isDropDisabled={!editState.roles || saving} droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {roles.map((role, index) => (
                <Draggable
                  isDragDisabled={!editState.roles || saving}
                  key={index}
                  draggableId={index.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <Grid container alignItems="center" justifyContent="stretch">
                        <IconButton
                          disabled={!editState.roles || saving}
                          color="primary"
                          aria-label="drag"
                          component="span"
                        >
                          <DragIndicatorIcon />
                        </IconButton>
                        <TextField
                          id="role"
                          disabled={!editState.roles || saving}
                          required
                          placeholder="Role"
                          onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            setState({
                              ...state,
                              roles: [...roles.slice(0, index), event.target.value, ...roles.slice(index + 1)],
                            })
                          }
                          value={role}
                          variant="standard"
                        />
                        <IconButton
                          disabled={!editState.roles || saving}
                          color="primary"
                          aria-label="delete"
                          component="span"
                          onClick={() => handleDeleteRole(index)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button variant="contained" disabled={!editState.roles || saving} onClick={() => handleAddRole()}>
        Add Role
      </Button>
    </Grid>
  );
};
