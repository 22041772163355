import { Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { FeedbackFormInterface } from "../../models/FeedbackForm";
import { archiveForm, getFeedbackForms } from "../../services/feedbackForm";
import { ViewFeedbackCard } from "./ViewFeedbackCard";

export interface ViewFeedbackInterface {
  loading: boolean;
  feedbackForms: FeedbackFormInterface[];
}

export const ViewFeedback = () => {
  const [state, setState] = useState<ViewFeedbackInterface>({
    loading: true,
    feedbackForms: [],
  });

  useEffect(() => {
    setState({ ...state, loading: true });
    const feedbackFormsFetch = getFeedbackForms();

    Promise.all([feedbackFormsFetch]).then(([feedbackForms]) => {
      setState({
        ...state,
        feedbackForms: feedbackForms,
        loading: false,
      });
    });
    // eslint-disable-next-line
  }, []);

  const { loading, feedbackForms } = state;

  const nonArchived = feedbackForms.filter((ff) => !ff.archived);
  const archived = feedbackForms.filter((ff) => ff.archived);

  return loading ? (
    <Loading />
  ) : (
    <Container maxWidth="sm">
      <Grid spacing={2} container>
        {nonArchived.map((f, i) => (
          <ViewFeedbackCard
            feedback={f}
            key={i}
            archiveForm={() =>
              archiveForm(f).then((newF) =>
                setState({
                  ...state,
                  feedbackForms: [...feedbackForms.slice(0, i), newF, ...feedbackForms.slice(i + 1)],
                })
              )
            }
          />
        ))}
        <Typography sx={{ mt: 8 }} variant="h4">
          Archived
        </Typography>
        {archived.map((f, i) => (
          <ViewFeedbackCard feedback={f} key={i} />
        ))}
      </Grid>
    </Container>
  );
};
