import { Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import { timeSince } from "../../helpers/FormatDate";
import { FeedbackFormInterface } from "../../models/FeedbackForm";

export const ViewFeedbackCard = ({
  feedback,
  archiveForm,
}: {
  feedback: FeedbackFormInterface;
  archiveForm?: () => void;
}) => (
  <Grid xs={12} item>
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Typography>
            by {feedback.user.firstName} {feedback.user.lastName}
          </Typography>
          <Typography>
            Type: {feedback.type} - {timeSince(feedback.time)} ago
          </Typography>
        </Grid>
        <Typography align="center" variant="body2">
          Feedback
        </Typography>
        <Typography align="center">{feedback.content}</Typography>
      </CardContent>
      <CardActions>
        {!feedback.archived && archiveForm && (
          <Grid container justifyContent="flex-end">
            <Button variant="contained" onClick={() => archiveForm()}>
              Archive
            </Button>
          </Grid>
        )}
      </CardActions>
    </Card>
  </Grid>
);
