import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export const ConfirmationModal = ({
  title,
  content,
  confirmText,
  cancelText,
  dialogOpen,
  closeDialog,
  doAction,
}: {
  title: string;
  content: string;
  confirmText: string;
  cancelText: string;
  dialogOpen: boolean;
  closeDialog: () => void;
  doAction: () => void;
}) => (
  <Dialog open={dialogOpen} onClose={closeDialog}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeDialog} color="primary">
        {cancelText}
      </Button>
      <Button variant="contained" onClick={doAction} color="primary">
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
);
