import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { User } from "../../models/User";
import { getMentorsHR, updateAssignedHRRep, updateMentorsHR } from "../../services/mentees";
import { getListOfUsers } from "../../services/users";
import ClearIcon from "@mui/icons-material/Clear";
import { Staff } from "../../models/Staff";
import { DATA_AND_IT, PEOPLE_AND_CULTURE, TRAINING_AND_DEVELOPMENT } from "../../data/Departments";
import { MentorsHR, InitMentorsHR } from "../../models/Mentee";
import { UserContext } from "../../components/PrivateRoute";
import { useHistory } from "react-router-dom";

interface UpdateMentorsInterface {
  loading: boolean;
  showMessage: boolean;
  mentorsHR: MentorsHR;
  users: User[];
}

export const UpdateMentors = () => {
  const [state, setState] = useState<UpdateMentorsInterface>({
    loading: false,
    showMessage: false,
    mentorsHR: InitMentorsHR,
    users: [],
  });

  const user = useContext(UserContext);
  const theme = useTheme();

  const { loading, mentorsHR, users, showMessage } = state;

  useEffect(() => {
    setState({ ...state, loading: true });
    const mentorsHRFetch = getMentorsHR();
    const getUsersFetch = getListOfUsers();

    Promise.all([mentorsHRFetch, getUsersFetch]).then(([mentorsHR, users]) => {
      setState({
        ...state,
        mentorsHR,
        users,
        loading: false,
      });
    });
    // eslint-disable-next-line
  }, []);

  const history = useHistory();

  if (
    (user as Staff).department !== TRAINING_AND_DEVELOPMENT &&
    (user as Staff).department !== PEOPLE_AND_CULTURE &&
    (user as Staff).department !== DATA_AND_IT
  ) {
    history.goBack();
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showMessage}
        onClose={() => setState({ ...state, showMessage: false })}
        autoHideDuration={3000}
      >
        <Alert severity="success" elevation={6} variant="filled">
          Saved!
        </Alert>
      </Snackbar>
      <Container maxWidth="lg">
        <Typography>Update HR Rep responsible for approving Personal Development Plans</Typography>
        <Autocomplete
          id={`update-hr-rep`}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.role}`}
          getOptionDisabled={(option) => mentorsHR.assignedHRRep?.id === option.id}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={mentorsHR.assignedHRRep ? mentorsHR.assignedHRRep : null}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              updateAssignedHRRep(newValue).then(() =>
                setState({
                  ...state,
                  showMessage: true,
                  mentorsHR: {
                    ...mentorsHR,
                    assignedHRRep: newValue,
                  },
                })
              );
            }
          }}
          options={users.filter((user) => (user as Staff).department === TRAINING_AND_DEVELOPMENT)}
          sx={{
            width: 300,
            mb: theme.spacing(4),
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Select HR Representative"
              placeholder="HR Representative"
            />
          )}
        />
        {mentorsHR.mentorsAndMentees.map((pair, i) => (
          <Box key={i}>
            <Grid spacing={2} justifyContent="center" container>
              <Grid item>
                <Autocomplete
                  id={`update-mentee-${i}`}
                  getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.role}`}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionDisabled={(option) =>
                    mentorsHR.mentorsAndMentees.map((pair) => pair.mentee.id).includes(option.id)
                  }
                  value={pair.mentee}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      const mentorsHRTemp = Object.assign({}, mentorsHR);
                      mentorsHRTemp.mentorsAndMentees[i].mentee = newValue;
                      updateMentorsHR(mentorsHRTemp).then(() =>
                        setState({
                          ...state,
                          showMessage: true,
                          mentorsHR: mentorsHRTemp,
                        })
                      );
                    }
                  }}
                  options={users}
                  sx={{
                    width: 300,
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Select Mentee" placeholder="Mentee" />
                  )}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  id={`update-mentor-${i}`}
                  getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.role}`}
                  getOptionDisabled={(option) => pair.mentee.id === option.id}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={pair.mentor}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      const mentorsHRTemp = Object.assign({}, mentorsHR);
                      mentorsHRTemp.mentorsAndMentees[i].mentor = newValue;
                      updateMentorsHR(mentorsHRTemp).then(() =>
                        setState({
                          ...state,
                          showMessage: true,
                          mentorsHR: mentorsHRTemp,
                        })
                      );
                    }
                  }}
                  options={users}
                  sx={{
                    width: 300,
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Select Mentor" placeholder="Mentor" />
                  )}
                />
              </Grid>
              <Grid item>
                <IconButton
                  color="error"
                  aria-label="delete"
                  component="span"
                  onClick={() => {
                    const mentorsHRTemp = Object.assign({}, mentorsHR);
                    mentorsHRTemp.mentorsAndMentees.splice(i, 1);
                    updateMentorsHR(mentorsHRTemp).then(() =>
                      setState({
                        ...state,
                        showMessage: true,
                        mentorsHR: mentorsHRTemp,
                      })
                    );
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider sx={{ m: theme.spacing(2, 0, 2, 0) }} />
          </Box>
        ))}
        <Button
          variant="contained"
          onClick={() => {
            const mentorsHRTemp = Object.assign({}, mentorsHR);
            const newMentee = users.find(
              (user) => !mentorsHR.mentorsAndMentees.map((pair) => pair.mentee.id).includes(user.id)
            );
            const newMentor = users.find(
              (user) =>
                !mentorsHR.mentorsAndMentees.map((pair) => pair.mentee.id).includes(user.id) &&
                user.id !== newMentee?.id
            );
            if (newMentee && newMentor) {
              mentorsHRTemp.mentorsAndMentees.push({
                mentee: newMentee,
                mentor: newMentor,
              });
            }
            updateMentorsHR(mentorsHRTemp).then(() =>
              setState({
                ...state,
                showMessage: true,
                mentorsHR: mentorsHRTemp,
              })
            );
          }}
        >
          Add mentee
        </Button>
      </Container>
    </>
  );
};
