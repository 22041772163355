import { Box, Typography } from "@mui/material";
import { Path } from "../../../helpers/Path";
import { DashboardCard } from "./DashboardCard";
import { Announcement } from "../../../models/Announcement";
import { TypographyBold } from "../../../components/TypographyBold";
import { useTheme } from "@mui/styles";
import { useContext } from "react";
import { DepartmentsContext } from "../../../components/PrivateRoute";
import { departmentCheckValidTag } from "../../../services/announcements";

export const AnnouncementCard = ({ announcement }: { announcement: Announcement }) => {
  const theme = useTheme();
  const department = useContext(DepartmentsContext);

  return (
    <DashboardCard
      title="Announcements"
      content={
        <Box
          sx={{
            padding: theme.spacing(2, 5, 1, 5),
          }}
        >
          <TypographyBold
            variant="subtitle2"
            align="left"
            sx={{ ml: "-20px" }}
            color={department.departments[departmentCheckValidTag(announcement.tag)].colour}
          >
            {announcement.tag}
          </TypographyBold>
          <TypographyBold>{announcement?.title}</TypographyBold>
          <Typography>{announcement?.content}</Typography>
        </Box>
      }
      link="Read more"
      to={Path.Announcements}
    />
  );
};
