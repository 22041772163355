import * as yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  useTheme,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";

const PDPDeclineDialogFormValidationSchema = yup.object().shape({
  reason: yup.string(),
});

export const PDPDeclineDialog = ({
  dialogOpen,
  closeDialog,
  decline,
}: {
  dialogOpen: boolean;
  closeDialog: () => void;
  decline: (reason: string) => void;
}) => {
  const theme = useTheme();
  return (
    <Dialog open={dialogOpen} onClose={closeDialog} aria-describedby="alert-dialog-description">
      <Formik
        initialValues={{
          reason: "",
        }}
        onSubmit={(values: { reason: string }) => decline(values.reason)}
        validationSchema={PDPDeclineDialogFormValidationSchema}
      >
        {(props: FormikProps<{ reason: string }>) => {
          const { values, errors, touched, handleBlur, handleChange, isSubmitting } = props;
          return (
            <Box
              sx={{
                width: "100%", // Fix IE 11 issue.
                mt: theme.spacing(3),
              }}
            >
              <Form>
                <DialogTitle id="form-dialog-title">Decline Plan</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    Please submit a reason below. The mentee will be notified.
                  </DialogContentText>
                  <TextField
                    id="reason"
                    fullWidth
                    required
                    placeholder="Reason"
                    multiline
                    minRows={4}
                    maxRows={6}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.reason && touched.reason ? errors.reason : ""}
                    value={values.reason}
                    error={errors.reason && touched.reason ? true : false}
                    variant="outlined"
                  />
                </DialogContent>
                {!isSubmitting ? (
                  <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                      Cancel
                    </Button>
                    <Button color="error" variant="contained" type="submit">
                      Decline
                    </Button>
                  </DialogActions>
                ) : (
                  <CircularProgress color="secondary" />
                )}
              </Form>
            </Box>
          );
        }}
      </Formik>
    </Dialog>
  );
};
