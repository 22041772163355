import { useContext } from "react";
import { FormikProps, Form } from "formik";
import {
  TextField,
  CircularProgress,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Grid,
  RadioGroup,
  Radio,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { PromotionRequest } from "../../models/PromotionRequest";
import { PromotionRequestType } from "../../data/PromotionRequestType";
import { PromotionRequestObjective } from "../../data/PromotionRequestObjective";
import { DepartmentsContext } from "../../components/PrivateRoute";
import { STAFF } from "../../data/Roles";

export const PromotionRequestsForm = ({
  props,
  handleClose,
}: {
  props: FormikProps<PromotionRequest>;
  handleClose: () => void;
}) => {
  const departments = useContext(DepartmentsContext);
  const theme = useTheme();
  const questionTheme = {
    mb: theme.spacing(2),
  };
  const { values, errors, touched, handleBlur, handleChange, isSubmitting, setFieldValue } = props;

  const str2bool = (value: string | null) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  const errorObjective =
    [
      values.objective.raiseAwareness,
      values.objective.invitePeople,
      values.objective.getPeopleInterested,
      values.objective.hitRegistrations,
      values.objective.other,
    ].filter((v) => v).length === 0;
  const errorType =
    [
      values.type.graphics,
      values.type.photos,
      values.type.animatedGraphics,
      values.type.videos,
      values.type.instagramStories,
      values.type.other,
    ].filter((v) => v).length === 0;
  return (
    <Box
      sx={{
        width: "100%", // Fix IE 11 issue.
        mt: theme.spacing(3),
      }}
    >
      <Form>
        <DialogTitle id="form-dialog-title">Submit a Promotion Request</DialogTitle>
        <DialogContent>
          <InputLabel
            id="department-label"
            sx={{
              mt: theme.spacing(3),
            }}
            required
          >
            1. Select Department
          </InputLabel>
          <Select
            label="Department"
            required
            fullWidth
            labelId="department-label"
            id="department"
            onChange={handleChange}
            onBlur={handleBlur}
            name="department"
            value={values.department}
            error={errors.department && touched.department ? true : false}
            variant="standard"
          >
            {Object.keys(departments.departments).map((dep, i) => (
              <MenuItem key={i} value={dep}>
                {dep}
              </MenuItem>
            ))}
          </Select>
          <FormLabel component="legend" required>
            2. Details of Event/Project that you'd like to promote
          </FormLabel>
          <TextField
            required
            fullWidth
            id="details"
            label="Please provide name of event/project, date, time, venue, key audience, goal/purpose of event/project"
            name="details"
            multiline
            helperText={errors.details && touched.details ? errors.details : ""}
            value={values.details}
            error={errors.details && touched.details ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            sx={questionTheme}
          />
          <FormLabel component="legend" required>
            3. Who is your target audience?
          </FormLabel>
          <RadioGroup value={values.targetAudience} onChange={handleChange} name="targetAudience" sx={questionTheme}>
            <FormControlLabel value="Students/Members" control={<Radio required />} label="Students/Members" />
            <FormControlLabel value="Alumni" control={<Radio required />} label="Alumni" />
            <FormControlLabel value={STAFF} control={<Radio required />} label={STAFF} />
            <FormControlLabel value="General Public" control={<Radio required />} label="General Public" />
            <FormControlLabel value="Other" control={<Radio required />} label="Other" />
            <TextField
              id="targetAudienceOtherText"
              name="targetAudienceOtherText"
              multiline
              required={values.targetAudience === "Other"}
              disabled={values.targetAudience !== "Other"}
              value={values.targetAudienceOtherText}
              error={errors.targetAudienceOtherText && touched.targetAudienceOtherText ? true : false}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="standard"
              sx={questionTheme}
            />
          </RadioGroup>
          <FormLabel component="legend" required>
            4. What are your key messages that you would like to get across through promotion?
          </FormLabel>
          <TextField
            fullWidth
            id="message"
            name="message"
            required
            multiline
            helperText={errors.message && touched.message ? errors.message : ""}
            value={values.message}
            error={errors.message && touched.message ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            sx={questionTheme}
          />
          <FormControl
            required
            error={errorObjective}
            component="fieldset"
            sx={{
              mt: theme.spacing(3),
            }}
            fullWidth
          >
            <FormLabel component="legend">5. What do you wish to gain through promotion?</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id="objective.raiseAwareness"
                    value={values.objective.raiseAwareness}
                    color="primary"
                    onChange={handleChange}
                    name="objective.raiseAwareness"
                  />
                }
                label={PromotionRequestObjective.raiseAwareness}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="objective.invitePeople"
                    value={values.objective.invitePeople}
                    color="primary"
                    onChange={handleChange}
                    name="objective.invitePeople"
                  />
                }
                label={PromotionRequestObjective.invitePeople}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="objective.getPeopleInterested"
                    value={values.objective.getPeopleInterested}
                    color="primary"
                    onChange={handleChange}
                    name="objective.getPeopleInterested"
                  />
                }
                label={PromotionRequestObjective.getPeopleInterested}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="objective.hitRegistrations"
                    value={values.objective.hitRegistrations}
                    color="primary"
                    onChange={handleChange}
                    name="objective.hitRegistrations"
                  />
                }
                label={PromotionRequestObjective.hitRegistrations}
              />
              <Grid container>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="objective.other"
                      value={values.objective.other}
                      color="primary"
                      onChange={handleChange}
                      name="objective.other"
                    />
                  }
                  label={PromotionRequestObjective.other}
                />
                <TextField
                  id="objective.otherText"
                  name="objective.otherText"
                  multiline
                  required={values.objective.other}
                  disabled={!values.objective.other}
                  value={values.objective.otherText}
                  error={errors.objective?.otherText && touched.objective?.otherText ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="standard"
                  sx={questionTheme}
                />
              </Grid>
            </FormGroup>
          </FormControl>
          <FormControl
            required
            error={errorType}
            component="fieldset"
            sx={{
              mt: theme.spacing(3),
            }}
          >
            <FormLabel component="legend">6. Type of promotion</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id="type.graphics"
                    value={values.type.graphics}
                    color="primary"
                    onChange={handleChange}
                    name="type.graphics"
                  />
                }
                label={PromotionRequestType.graphics}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="type.photos"
                    value={values.type.photos}
                    color="primary"
                    onChange={handleChange}
                    name="type.photos"
                  />
                }
                label={PromotionRequestType.photos}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="type.animatedGraphics"
                    value={values.type.animatedGraphics}
                    color="primary"
                    onChange={handleChange}
                    name="type.animatedGraphics"
                  />
                }
                label={PromotionRequestType.animatedGraphics}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="type.videos"
                    value={values.type.videos}
                    color="primary"
                    onChange={handleChange}
                    name="type.videos"
                  />
                }
                label={PromotionRequestType.videos}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="type.infographic"
                    value={values.type.instagramStories}
                    color="primary"
                    onChange={handleChange}
                    name="type.infographic"
                  />
                }
                label={PromotionRequestType.instagramStories}
              />
              <Grid container>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="type.other"
                      value={values.type.other}
                      color="primary"
                      onChange={handleChange}
                      name="type.other"
                    />
                  }
                  label={PromotionRequestType.other}
                />
                <TextField
                  id="type.otherText"
                  name="type.otherText"
                  multiline
                  required={values.type.other}
                  disabled={!values.type.other}
                  value={values.type.otherText}
                  error={errors.type?.otherText && touched.type?.otherText ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="standard"
                  sx={questionTheme}
                />
              </Grid>
            </FormGroup>
          </FormControl>
          <FormLabel component="legend" required>
            7. Please specify your visual goals/theme for this promotion.
          </FormLabel>
          <TextField
            required
            fullWidth
            id="theme"
            label="Is there a colour scheme, logos to include, images, footage etc. that you would like us to include?"
            name="theme"
            multiline
            helperText={errors.theme && touched.theme ? errors.theme : ""}
            value={values.theme}
            error={errors.theme && touched.theme ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            sx={questionTheme}
          />
          <FormLabel component="legend">8. Please provide examples of the above</FormLabel>
          <TextField
            fullWidth
            id="example"
            label="You may provide details via share link."
            name="example"
            multiline
            helperText={errors.example && touched.example ? errors.example : ""}
            value={values.example}
            error={errors.example && touched.example ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            sx={questionTheme}
          />
          <FormLabel component="legend" required>
            9. When would you like us to post? (If you have a promotion timeline, please provide share link)
          </FormLabel>
          <TextField
            fullWidth
            id="postWhen"
            label="Please provide specific dates (eg. Every monday of June) or else provide a specific time frame (start of July - end of August)."
            name="postWhen"
            multiline
            helperText={errors.postWhen && touched.postWhen ? errors.postWhen : ""}
            value={values.postWhen}
            error={errors.postWhen && touched.postWhen ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            required
            sx={questionTheme}
          />
          <FormLabel component="legend">
            10. Please send us any images/videos/files that you would like us to include/consider.
          </FormLabel>
          <TextField
            fullWidth
            label="Please provide share link."
            id="filesToInclude"
            name="filesToInclude"
            multiline
            helperText={errors.filesToInclude && touched.filesToInclude ? errors.filesToInclude : ""}
            value={values.filesToInclude}
            error={errors.filesToInclude && touched.filesToInclude ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            sx={questionTheme}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend" required>
              11. Would you like us to ask for your approval before posting?
            </FormLabel>
            <RadioGroup
              value={values.askForApproval}
              onChange={(e) => setFieldValue("askForApproval", str2bool(e.target.value))}
              name="askForApproval"
              sx={questionTheme}
            >
              <FormControlLabel value={true} control={<Radio required />} label="Yes" />
              <FormControlLabel value={false} control={<Radio required />} label="No" />
            </RadioGroup>
          </FormControl>
          <FormLabel component="legend">12. Do you have any other information you would like to include?</FormLabel>
          <TextField
            fullWidth
            id="extraInformation"
            name="extraInformation"
            multiline
            helperText={errors.extraInformation && touched.extraInformation ? errors.extraInformation : ""}
            value={values.extraInformation}
            error={errors.extraInformation && touched.extraInformation ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            sx={questionTheme}
          />
        </DialogContent>
        {!isSubmitting ? (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        ) : (
          <CircularProgress color="secondary" />
        )}
      </Form>
    </Box>
  );
};
