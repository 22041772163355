import { Button, Grid, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Form, Formik } from "formik";
import { ModuleSAQ } from "../../models/ModuleSAQ";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { useState } from "react";

export const SAQ = ({
  i,
  updateSAQ,
  saq,
  answers,
  completed,
}: {
  i: number;
  updateSAQ: (a: { [saqId: string]: string }) => void;
  saq: ModuleSAQ[];
  answers?: { [saqId: string]: string };
  completed: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  return (
    <Formik initialValues={answers ? answers : {}} enableReinitialize onSubmit={() => setOpen(true)}>
      {(props) => {
        const { values, handleBlur, handleChange } = props;
        return (
          <Form>
            <ConfirmationModal
              title={"Submit answers?"}
              content={"You won't be able to submit it again"}
              confirmText={"Submit"}
              cancelText={"Cancel"}
              dialogOpen={open}
              closeDialog={() => setOpen(false)}
              doAction={() => {
                updateSAQ(values);
                setOpen(false);
              }}
            />
            <Grid container direction="column">
              {saq.map((q, j) => (
                <div key={`saq-${i}-${q.id}`}>
                  <Typography sx={{ mt: theme.spacing(2) }}>{q.question}</Typography>
                  <TextField
                    disabled={completed}
                    margin="normal"
                    required
                    fullWidth
                    key={`saq-${i}-${q.id}`}
                    id={`saq-${i}-${q.id}`}
                    name={`${j}`}
                    value={values[j] ? values[j] : ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="standard"
                  />
                  {completed && <Typography variant="body2">Sample answers</Typography>}
                  {completed &&
                    q.sampleAnswers.map((sampleAnswer, k) => <Typography key={k}>{sampleAnswer}</Typography>)}
                </div>
              ))}
            </Grid>
            {!completed && (
              <Grid
                container
                sx={{
                  margin: theme.spacing(3, 0, 2),
                }}
              >
                <Button fullWidth size="large" variant="contained" type="submit">
                  Submit
                </Button>
              </Grid>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
