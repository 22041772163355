import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { Announcement } from "../models/Announcement";
import { db } from "./firebase";
import { getUserById } from "./users";

export const getNAnnouncements = (n: number): Promise<Announcement[]> =>
  new Promise(async (resolve, reject) => {
    const res = await getDocs(query(collection(db, "announcements"), orderBy("time", "desc"), limit(n)));
    let announcements = [];
    for (const doc of res.docs) {
      let user = await getUserById(doc.data().userID);
      announcements.push({
        id: doc.id,
        title: doc.data().title,
        tag: doc.data().tag,
        content: doc.data().content,
        user: user,
        time: doc.data().time.toDate(),
      });
    }
    resolve(announcements);
  });

export const getFilteredAnnouncements = (tag: string, n: number): Promise<Announcement[]> =>
  new Promise(async (resolve, reject) => {
    const res = await getDocs(
      query(collection(db, "announcements"), where("tag", "==", tag), orderBy("time", "desc"), limit(n))
    );
    let announcements = [];
    for (const doc of res.docs) {
      let user = await getUserById(doc.data().userID);
      announcements.push({
        id: doc.id,
        title: doc.data().title,
        tag: doc.data().tag,
        content: doc.data().content,
        user: user,
        time: doc.data().time.toDate(),
      });
    }
    resolve(announcements);
  });

export const getAnnouncements = (): Promise<Announcement[]> => getNAnnouncements(100);

export const getRecentAnnouncement = (): Promise<Announcement> =>
  new Promise((resolve, reject) => getNAnnouncements(1).then((announcements) => resolve(announcements[0])));

export const postAnnouncement = (announcement: Announcement): Promise<Announcement> =>
  new Promise((resolve, reject) => {
    const { user, ...announcementWithoutUser } = announcement;
    addDoc(collection(db, "announcements"), {
      ...announcementWithoutUser,
      time: serverTimestamp(),
      userID: user.id,
    })
      .then((docRef) => {
        announcement.id = docRef.id;
        resolve(announcement);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const deleteAnnouncement = (id: string) =>
  new Promise((resolve, reject) => {
    deleteDoc(doc(db, "announcements", id))
      .then(() => {
        resolve(id);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const departmentCheckValidTag = (tags: string) => (typeof tags === "undefined" || tags === "" ? "Alumni" : tags);
