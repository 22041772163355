import { Container, Grid, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { websitesData } from "../data/websitesData";
import { christianPsychologistsData } from "../data/christianPsychologistsData";
import { helpLinesData } from "../data/helpLinesData";
import packageJson from "../../package.json";

export const Footer = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg" sx={{ mt: theme.spacing(24) }}>
      <Grid container item xs={12} spacing={3}>
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={3}
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item>
            <Typography sx={{ mb: theme.spacing(1) }} variant="h5">
              WEBSITES
            </Typography>
          </Grid>
          {websitesData.map((website, i) => (
            <Grid sx={{ mt: theme.spacing(1) }} key={i} item>
              <Link
                sx={{ color: theme.palette.grey[600], cursor: "pointer" }}
                underline="hover"
                onClick={() => window.open(website.link)}
              >
                {website.name}
              </Link>
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={3}
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item>
            <Typography sx={{ mb: theme.spacing(1) }} variant="h5">
              CHRISTIAN PSYCHOLOGISTS
            </Typography>
          </Grid>
          {christianPsychologistsData.map((cp, i) => (
            <Grid sx={{ mt: theme.spacing(1) }} key={i} item>
              <Link
                sx={{ color: theme.palette.grey[600], cursor: "pointer" }}
                underline="hover"
                onClick={() => window.open(cp.link)}
              >
                {cp.name}
              </Link>
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={3}
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item>
            <Typography sx={{ mb: theme.spacing(1) }} variant="h5">
              HELPLINES
            </Typography>
          </Grid>
          {helpLinesData.map((hl, i) => (
            <Grid sx={{ mt: theme.spacing(1) }} key={i} item>
              <Typography>{hl.name}</Typography>
              <Link
                sx={{ color: theme.palette.grey[600], cursor: "pointer" }}
                underline="hover"
                href={`tel:${hl.phoneNumber}`}
              >
                {hl.phoneNumber}
              </Link>
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={3}
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item>
            <Typography sx={{ mb: theme.spacing(1) }} variant="h5">
              POLICIES
            </Typography>
            <Typography>N/A</Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ mb: theme.spacing(1) }} variant="h5">
              CONTACT DETAILS
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              Email: <Link href="mailto:sijin.yang@sowaustralia.com">sijin.yang@sowaustralia.com</Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ mb: theme.spacing(1) }} variant="h5">
              VERSION
            </Typography>
            <Typography>{packageJson.version}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
