import { useContext } from "react";
import { FormikProps, Form } from "formik";
import {
  TextField,
  CircularProgress,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Grid,
  RadioGroup,
  Radio,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { DesignRequest } from "../../models/DesignRequest";
import { DesignRequestType } from "../../data/DesignRequestType";
import { DesignRequestProductType } from "../../data/DesignRequestProductType";
import { DepartmentsContext } from "../../components/PrivateRoute";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export const DesignRequestsForm = ({
  props,
  handleClose,
}: {
  props: FormikProps<DesignRequest>;
  handleClose: () => void;
}) => {
  const theme = useTheme();
  const questionTheme = {
    mb: theme.spacing(2),
  };
  const { values, errors, touched, handleBlur, handleChange, isSubmitting, setFieldValue } = props;

  const departments = useContext(DepartmentsContext);

  const str2bool = (value: string | null) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  const errorType =
    [values.type.event, values.type.promotion, values.type.pr, values.type.other].filter((v) => v).length === 0;
  const errorProductType =
    [
      values.productType.brochure,
      values.productType.flyer,
      values.productType.booklet,
      values.productType.facebookbanner,
      values.productType.infographic,
      values.productType.invitation,
      values.productType.logo,
      values.productType.merchandise,
      values.productType.poster,
      values.productType.powerpointPresentation,
      values.productType.other,
    ].filter((v) => v).length === 0;
  return (
    <Box
      sx={{
        width: "100%", // Fix IE 11 issue.
        mt: theme.spacing(3),
      }}
    >
      <Form>
        <DialogTitle id="form-dialog-title">Submit a Design Request</DialogTitle>
        <DialogContent>
          <InputLabel
            id="department-label"
            sx={{
              mt: theme.spacing(3),
            }}
            required
          >
            1. Select Department
          </InputLabel>
          <Select
            label="Department"
            required
            fullWidth
            labelId="department-label"
            id="department"
            onChange={handleChange}
            onBlur={handleBlur}
            name="department"
            value={values.department}
            error={errors.department && touched.department ? true : false}
            variant="standard"
          >
            {Object.keys(departments.departments).map((dep, i) => (
              <MenuItem key={i} value={dep}>
                {dep}
              </MenuItem>
            ))}
          </Select>
          <FormControl
            required
            error={errorType}
            component="fieldset"
            sx={{
              mt: theme.spacing(3),
            }}
            fullWidth
          >
            <FormLabel component="legend" required>
              2. What best describes your request?
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id="type.event"
                    value={values.type.event}
                    color="primary"
                    onChange={handleChange}
                    name="type.event"
                  />
                }
                label={DesignRequestType.event}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="type.promotion"
                    value={values.type.promotion}
                    color="primary"
                    onChange={handleChange}
                    name="type.promotion"
                  />
                }
                label={DesignRequestType.promotion}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="type.pr"
                    value={values.type.pr}
                    color="primary"
                    onChange={handleChange}
                    name="type.pr"
                  />
                }
                label={DesignRequestType.pr}
              />
              <Grid container>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="type.other"
                      value={values.type.other}
                      color="primary"
                      onChange={handleChange}
                      name="type.other"
                    />
                  }
                  label={DesignRequestType.other}
                />
                <TextField
                  id="type.otherText"
                  name="type.otherText"
                  multiline
                  required={values.type.other}
                  disabled={!values.type.other}
                  value={values.type.otherText}
                  error={errors.type?.otherText && touched.type?.otherText ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="standard"
                  sx={questionTheme}
                />
              </Grid>
            </FormGroup>
          </FormControl>
          <FormLabel component="legend" required>
            3. If tied to event/project, please provide details
          </FormLabel>
          <TextField
            required
            fullWidth
            id="details"
            label="Event/Project name, date, time, venue, target audience, theme,
            aim/purpose"
            name="details"
            multiline
            helperText={errors.details && touched.details ? errors.details : ""}
            value={values.details}
            error={errors.details && touched.details ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            sx={questionTheme}
          />
          <FormControl
            required
            error={errorProductType}
            component="fieldset"
            sx={{
              mt: theme.spacing(3),
            }}
            fullWidth
          >
            <FormLabel component="legend">4. What would you like us to design?</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id="productType.brochure"
                    value={values.productType.brochure}
                    color="primary"
                    onChange={handleChange}
                    name="productType.brochure"
                  />
                }
                label={DesignRequestProductType.brochure}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="productType.flyer"
                    value={values.productType.flyer}
                    color="primary"
                    onChange={handleChange}
                    name="productType.flyer"
                  />
                }
                label={DesignRequestProductType.flyer}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="productType.booklet"
                    value={values.productType.booklet}
                    color="primary"
                    onChange={handleChange}
                    name="productType.booklet"
                  />
                }
                label={DesignRequestProductType.booklet}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="productType.facebookbanner"
                    value={values.productType.facebookbanner}
                    color="primary"
                    onChange={handleChange}
                    name="productType.facebookbanner"
                  />
                }
                label={DesignRequestProductType.facebookbanner}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="productType.infographic"
                    value={values.productType.infographic}
                    color="primary"
                    onChange={handleChange}
                    name="productType.infographic"
                  />
                }
                label={DesignRequestProductType.infographic}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="productType.invitation"
                    value={values.productType.invitation}
                    color="primary"
                    onChange={handleChange}
                    name="productType.invitation"
                  />
                }
                label={DesignRequestProductType.invitation}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="productType.logo"
                    value={values.productType.logo}
                    color="primary"
                    onChange={handleChange}
                    name="productType.logo"
                  />
                }
                label={DesignRequestProductType.logo}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="productType.merchandise"
                    value={values.productType.merchandise}
                    color="primary"
                    onChange={handleChange}
                    name="productType.merchandise"
                  />
                }
                label={DesignRequestProductType.merchandise}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="productType.poster"
                    value={values.productType.poster}
                    color="primary"
                    onChange={handleChange}
                    name="productType.poster"
                  />
                }
                label={DesignRequestProductType.poster}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="productType.powerpointPresentation"
                    value={values.productType.powerpointPresentation}
                    color="primary"
                    onChange={handleChange}
                    name="productType.powerpointPresentation"
                  />
                }
                label={DesignRequestProductType.powerpointPresentation}
              />
              <Grid container>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="productType.other"
                      value={values.productType.other}
                      color="primary"
                      onChange={handleChange}
                      name="productType.other"
                    />
                  }
                  label={DesignRequestProductType.other}
                />
                <TextField
                  id="productType.otherText"
                  name="productType.otherText"
                  multiline
                  required={values.productType.other}
                  disabled={!values.productType.other}
                  value={values.productType.otherText}
                  error={errors.productType?.otherText && touched.productType?.otherText ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="standard"
                  sx={questionTheme}
                />
              </Grid>
            </FormGroup>
          </FormControl>
          <FormLabel component="legend">
            5. Is there a theme/colour palette/visual preference that you would like us to work with?
          </FormLabel>
          <TextField
            required
            fullWidth
            id="theme"
            label="You may provide details via share link."
            name="theme"
            multiline
            helperText={errors.theme && touched.theme ? errors.theme : ""}
            value={values.theme}
            error={errors.theme && touched.theme ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            sx={questionTheme}
          />
          <FormLabel component="legend">6. Please provide examples of the above</FormLabel>
          <TextField
            fullWidth
            id="example"
            label="You may provide details via share link."
            name="example"
            multiline
            helperText={errors.example && touched.example ? errors.example : ""}
            value={values.example}
            error={errors.example && touched.example ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            sx={questionTheme}
          />
          <FormLabel component="legend" required>
            7. What is the key message you would like to send out? *
          </FormLabel>
          <TextField
            fullWidth
            id="message"
            name="message"
            required
            multiline
            helperText={errors.message && touched.message ? errors.message : ""}
            value={values.message}
            error={errors.message && touched.message ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            sx={questionTheme}
          />
          <Grid container direction="column" sx={questionTheme}>
            <FormLabel component="legend">8. When do you need the design by? *</FormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                value={values.dueDate}
                minDate={new Date()}
                onChange={(value: Date | null) => setFieldValue("dueDate", value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <FormControl component="fieldset" required>
            <FormLabel component="legend">
              9. Would you like multiple demos/drafts provided before the final design?
            </FormLabel>
            <RadioGroup
              value={values.multipleDrafts}
              onChange={(e) => setFieldValue("multipleDrafts", str2bool(e.target.value))}
              name="multipleDrafts"
              sx={questionTheme}
            >
              <FormControlLabel value={true} control={<Radio required />} label="Yes" />
              <FormControlLabel value={false} control={<Radio required />} label="No" />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">10. Would you like us to run the design by you before finalising?</FormLabel>
            <RadioGroup
              value={values.runTheDesign}
              onChange={(e) => setFieldValue("runTheDesign", str2bool(e.target.value))}
              name="runTheDesign"
              sx={questionTheme}
            >
              <FormControlLabel value={true} control={<Radio required />} label="Yes" />
              <FormControlLabel value={false} control={<Radio required />} label="No" />
            </RadioGroup>
          </FormControl>
          <FormLabel component="legend">11. Do you have any other information you would like to include?</FormLabel>
          <TextField
            fullWidth
            id="extraInformation"
            name="extraInformation"
            multiline
            helperText={errors.extraInformation && touched.extraInformation ? errors.extraInformation : ""}
            value={values.extraInformation}
            error={errors.extraInformation && touched.extraInformation ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            sx={questionTheme}
          />
          <FormLabel component="legend">12. Details of Event/Project that you'd like to promote</FormLabel>
          <TextField
            fullWidth
            id="detailsOfEventOrProject"
            name="detailsOfEventOrProject"
            multiline
            helperText={
              errors.detailsOfEventOrProject && touched.detailsOfEventOrProject ? errors.detailsOfEventOrProject : ""
            }
            value={values.detailsOfEventOrProject}
            error={errors.detailsOfEventOrProject && touched.detailsOfEventOrProject ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            sx={questionTheme}
          />
        </DialogContent>
        {!isSubmitting ? (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </DialogActions>
        ) : (
          <CircularProgress color="secondary" />
        )}
      </Form>
    </Box>
  );
};
