import { listAll, ref } from "firebase/storage";
import { Resource } from "../models/Resource";
import { storage } from "./firebase";

export const getResources = async (): Promise<Resource> => {
  return getResourcesFolder("resources", "resources");
};

export const getResourcesFoldersOnly = async (): Promise<Resource> => {
  const folderMeta = await listAll(ref(storage, "resources"));
  let folders = [];
  for (const folder of folderMeta.prefixes) {
    folders.push({ folderName: folder.name, items: [] });
  }
  return {
    folderName: "resources",
    items: [],
    folders,
  };
};

export const getResourcesFolder = async (folderPath: string, folderName: string): Promise<Resource> => {
  const folderMeta = await listAll(ref(storage, folderPath));

  let folders = [];
  for (const folder of folderMeta.prefixes) {
    folders.push(await getResourcesFolder(folder.fullPath, folder.name));
  }

  return {
    folderName,
    items: folderMeta.items,
    folders,
  };
};
