import { TextField, Button, Grid, Typography } from "@mui/material";
import { EditState, ProfileState } from "./Profile";

interface PropertyComponentInterface {
  label: string;
  name: string;
  handleChange: (event: any) => void;
  value: string | undefined;
  state: ProfileState;
  setState: (state: ProfileState) => void;
  save: (name: string, value: string | undefined, label: string) => Promise<string>;
}

export const PropertyComponent = ({
  label,
  name,
  handleChange,
  value,
  state,
  setState,
  save,
}: PropertyComponentInterface) => {
  const { editState } = state;

  return (
    <Grid container item xs={12} alignItems="center" justifyContent="space-between">
      <Grid item xs={7}>
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
        <TextField
          fullWidth
          name={name}
          onChange={handleChange}
          defaultValue={value}
          variant="standard"
          disabled={!editState[name as keyof EditState]}
        ></TextField>
      </Grid>
      {editState[name as keyof EditState] ? (
        <Grid item>
          <Button onClick={() => setState({ ...state, editState: { ...editState, [name]: false } })}>Cancel</Button>
          <Button
            onClick={() => {
              save(name, value, label)
                .then((response: string) =>
                  setState({
                    ...state,
                    messageState: {
                      showMessage: true,
                      message: response,
                      severity: "success",
                    },
                    editState: {
                      ...editState,
                      [name]: false,
                    },
                  })
                )
                .catch((error) =>
                  setState({
                    ...state,
                    messageState: {
                      showMessage: true,
                      message: error.message,
                      severity: "error",
                    },
                  })
                );
            }}
          >
            Save
          </Button>
        </Grid>
      ) : (
        <Grid item>
          <Button onClick={() => setState({ ...state, editState: { ...editState, [name]: true } })}>Edit</Button>
        </Grid>
      )}
    </Grid>
  );
};
