import { Theme } from "@mui/material";

export const Status = (theme: Theme) => ({
  APPROVED: {
    color: theme.palette.success.light,
    fontWeight: "bold",
  },
  DECLINED: {
    color: theme.palette.error.light,
    fontWeight: "bold",
  },
  PENDING: {
    color: theme.palette.warning.light,
    fontWeight: "bold",
  },
});
