import { Container, Link, Typography } from "@mui/material";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import { Path } from "../helpers/Path";

export const Error = () => {
  const { message } = useParams<{ message: string }>();
  return (
    <Container maxWidth="sm">
      <Typography align="center">Oops!</Typography>
      <Typography align="center">{message ? message : "Something went wrong!"}</Typography>
      <Link
        sx={{
          textDecoration: "none",
          color: "black",
          fontSize: "large",
        }}
        underline="hover"
        component={ReactRouterLink}
        to={Path.Dashboard}
      >
        <Typography align="center" variant="h4">
          Go to Dashboard
        </Typography>
      </Link>
    </Container>
  );
};
