import { Alert, Button, Container, Grid, Snackbar, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { DepartmentsContext, RolesContext } from "../../components/PrivateRoute";
import { TypographyBold } from "../../components/TypographyBold";
import { currentStaffYear } from "../../helpers/FormatDate";
import { Path } from "../../helpers/Path";
import { DepartmentInterface, InitDepartment } from "../../models/Department";
import { Divisions } from "../../models/Division";
import { MessageState } from "../../models/MessageState";
import { User } from "../../models/User";
import { getCurrentStaffYearDivisions } from "../../services/divisions";
import { getListOfUsers } from "../../services/users";
import { DepartmentsComponent } from "./DepartmentsComponent";
import { DivisionsComponent } from "./DivisionsComponent";
import { RolesComponent } from "./RolesComponent";

export const InitEditState = {
  roles: false,
  departments: false,
  divisions: false,
};

interface EditState {
  roles: boolean;
  departments: boolean;
  divisions: boolean;
}

export interface OrganisationStructureInterface {
  year: number;
  roles: string[];
  rolesTemp: string[];
  departments: DepartmentInterface;
  departmentsTemp: DepartmentInterface;
  divisions: Divisions;
  divisionsTemp: Divisions;
  users: User[];
  alert: MessageState;
  editState: EditState;
  saving: boolean;
  loading: boolean;
}

export const OrganisationStructure = () => {
  const history = useHistory();
  const roles = useContext(RolesContext);
  const departments = useContext(DepartmentsContext);

  const [state, setState] = useState<OrganisationStructureInterface>({
    year: currentStaffYear,
    roles: [],
    rolesTemp: [],
    departments: InitDepartment,
    departmentsTemp: InitDepartment,
    divisions: {},
    divisionsTemp: {},
    users: [],
    alert: {
      showMessage: false,
      message: "",
      severity: "success",
    },
    editState: InitEditState,
    saving: false,
    loading: true,
  });

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });

    const divisionsFetch = getCurrentStaffYearDivisions();
    const usersFetch = getListOfUsers();

    Promise.all([divisionsFetch, usersFetch])
      .then(([divisions, users]) =>
        setState({
          ...state,
          roles,
          departments,
          divisions,
          users,
          loading: false,
        })
      )
      .catch((err) => history.push(`${Path.Error}/Internal Error`));

    // eslint-disable-next-line
  }, []);

  const theme = useTheme();

  const { alert, loading } = state;

  return loading ? (
    <Loading />
  ) : (
    <Container maxWidth="xl">
      <Button variant="contained" component={ReactRouterLink} to={Path["Organisation Structure for Next Year"]}>
        Organisation Structure for Next Year
      </Button>
      <Alert sx={{ mt: theme.spacing(12), mb: theme.spacing(8) }} elevation={2} variant="filled" severity="info">
        You are changing active users' data. Your changes will take effect immediately.
      </Alert>
      <Snackbar
        open={alert.showMessage}
        onClose={() =>
          setState({
            ...state,
            alert: { ...alert, showMessage: false },
          })
        }
        autoHideDuration={6000}
      >
        <Alert elevation={6} variant="filled" severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Grid container justifyContent="center">
        <Grid item md={12} lg={6} xl={4} container spacing={2}>
          <Grid item xs={12}>
            <TypographyBold variant="h5">Current Year Roles</TypographyBold>
          </Grid>
          <RolesComponent state={state} setState={setState} />
        </Grid>
        <Grid item md={12} lg={6} xl={4}>
          <Grid item xs={12}>
            <TypographyBold variant="h5">Current Year Divisions</TypographyBold>
          </Grid>
          <DivisionsComponent state={state} setState={setState} />
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <TypographyBold variant="h5">Current Year Departments</TypographyBold>
          </Grid>
          <DepartmentsComponent state={state} setState={setState} />
        </Grid>
      </Grid>
    </Container>
  );
};
