export enum ColoursSowIs {
  DownToEarth = "#0F2523",
  Organic = "#283E42",
  RefreshingUnique = "#E2F1DA",
  Passionate = "#CD643C",
  Flexible = "#F5F3E3",
  FlexibleSupportive = "#DDE2D5",
  Transparent = "#C3D9D8",
  Warm = "#E5AD66",
}

export enum ColoursDepartments {
  SOW_1 = "#0F2523",
  SOW_2 = "#283E42",
  SOW_3 = "#DDE2D5",
  SOW_4 = "#E2F1DA",
  SOW_5 = "#F5F3E3",
  Alumni_1 = "#C3D9D8",
  Missions_1 = "#CD643C",
  Missions_2 = "#E5AD66",
}

export enum ColoursCampuses {
  ALL = "#CD643C",
  USYD = "#B5403D",
  UNSW = "#619445",
  UTS = "#3B5499",
  MACQ = "#F2C259",
  ACU = "#57427A",
  STAFF = "#283E42",
}
