import { Container, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Loading } from "../../../components/Loading";
import { MessageSnackbar } from "../../../components/MessageSnackbar";
import { DepartmentsContext } from "../../../components/PrivateRoute";
import { currentStaffYear, nextStaffYear } from "../../../helpers/FormatDate";
import { InitMessageState, MessageState } from "../../../models/MessageState";
import { User } from "../../../models/User";
import { getNextStaffYearDepartments, setBudgetManagerYear } from "../../../services/departments";
import { getDepartmentStaffListYear, getUserByIdYear } from "../../../services/users";

interface SetBudgetManagerInterface {
  loading: boolean;
  financeDepartmentStaff: User[];
  currentBudgetManager?: User;
  nextYearBudgetManager?: User;
  messageState: MessageState;
}

export const SetBudgetManager = () => {
  const departments = useContext(DepartmentsContext);

  const [state, setState] = useState<SetBudgetManagerInterface>({
    loading: true,
    financeDepartmentStaff: [],
    messageState: InitMessageState,
  });

  const { loading } = state;

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });

    getNextStaffYearDepartments().then((nextYearDepartments) => {
      const getCurrentBudgetManagerFetch = getUserByIdYear(departments.BUDGET_MANAGER, currentStaffYear.toString());
      const getNextYearBudgetManagerFetch = getUserByIdYear(
        nextYearDepartments.BUDGET_MANAGER,
        currentStaffYear.toString()
      );
      const getFinanceDepartmentFetch = getDepartmentStaffListYear(departments.FINANCE, currentStaffYear.toString());

      Promise.all([getCurrentBudgetManagerFetch, getNextYearBudgetManagerFetch, getFinanceDepartmentFetch]).then(
        ([currentBudgetManager, nextYearBudgetManager, financeDepartmentStaff]) =>
          setState({
            ...state,
            currentBudgetManager,
            nextYearBudgetManager,
            financeDepartmentStaff,
            loading: false,
          })
      );
    });

    // eslint-disable-next-line
  }, []);

  const { financeDepartmentStaff, currentBudgetManager, nextYearBudgetManager, messageState } = state;

  const handleCurrentBudgetManagerChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value) {
      setBudgetManagerYear(event.target.value, currentStaffYear.toString()).then(() =>
        setState({
          ...state,
          currentBudgetManager: financeDepartmentStaff.find((f) => f.id === event.target.value),
          messageState: {
            ...messageState,
            showMessage: true,
            message: "Successfully saved",
          },
        })
      );
    }
  };

  const handleNextYearBudgetManagerChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value) {
      setBudgetManagerYear(event.target.value, nextStaffYear.toString()).then(() =>
        setState({
          ...state,
          nextYearBudgetManager: financeDepartmentStaff.find((f) => f.id === event.target.value),
          messageState: {
            ...messageState,
            showMessage: true,
            message: "Successfully saved",
          },
        })
      );
    }
  };

  const theme = useTheme();

  return loading ? (
    <Loading />
  ) : (
    <>
      <MessageSnackbar
        messageState={messageState}
        setMessageState={(messageState) => setState({ ...state, messageState })}
      />
      <Container maxWidth="sm">
        {financeDepartmentStaff && currentBudgetManager && (
          <>
            <FormControl fullWidth sx={{ mb: theme.spacing(2) }}>
              <InputLabel id="setBudgetManagerLabel">Current Year Budget Manager</InputLabel>
              <Select
                labelId="setBudgetManagerLabel"
                id="setBudgetManager"
                onChange={handleCurrentBudgetManagerChange}
                value={currentBudgetManager.id ? currentBudgetManager.id : ""}
                variant="standard"
              >
                {financeDepartmentStaff.map((v, i) => (
                  <MenuItem key={i} value={v.id}>
                    {v.firstName} {v.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="setNextYearBudgetManagerLabel">Next Year Budget Manager</InputLabel>
              <Select
                labelId="setNextYearBudgetManagerLabel"
                id="setNextYearBudgetManager"
                onChange={handleNextYearBudgetManagerChange}
                value={nextYearBudgetManager?.id ? nextYearBudgetManager?.id : ""}
                variant="standard"
              >
                {financeDepartmentStaff.map((v, i) => (
                  <MenuItem key={i} value={v.id}>
                    {v.firstName} {v.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </Container>
    </>
  );
};
