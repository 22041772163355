import { useContext, useEffect, useState } from "react";
import { Path } from "../../helpers/Path";
import { Loading } from "../../components/Loading";
import { useParams, Link as ReactRouterLink, useHistory } from "react-router-dom";
import { Staff } from "../../models/Staff";
import { PastYearsPromotionRequestsComponent } from "./PastYearsPromotionRequestsComponent";
import { Container, Link, Typography } from "@mui/material";
import { DepartmentsContext, UserContext } from "../../components/PrivateRoute";
import { PromotionRequest } from "../../models/PromotionRequest";
import { getYearPromotionRequests } from "../../services/promotionRequests";

interface PastYearsRequestsInterface {
  loading: boolean;
  staff?: Staff;
  pastPromotionRequests: PromotionRequest[];
}

export const PastYearsPromotionRequests = () => {
  const user = useContext(UserContext) as Staff;
  const departments = useContext(DepartmentsContext);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [state, setState] = useState<PastYearsRequestsInterface>({
    loading: true,
    pastPromotionRequests: [],
  });

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });

    const pastPromotionRequestsFetch = getYearPromotionRequests(id);

    Promise.all([pastPromotionRequestsFetch]).then(([pastPromotionRequests]) => {
      if (user.department !== departments.MARKETING) {
        history.push(Path["Promotion Requests"]);
      } else {
        setState({
          ...state,
          pastPromotionRequests: pastPromotionRequests,
          loading: false,
        });
      }
    });

    // eslint-disable-next-line
  }, [id]);

  const { loading, pastPromotionRequests } = state;

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container maxWidth="md">
        <Link to={Path["Promotion Requests"]} component={ReactRouterLink}>
          back
        </Link>
        <Typography align="center">Promotion Requests History - Year: {id}</Typography>
        {pastPromotionRequests.map((pr, i) => (
          <PastYearsPromotionRequestsComponent key={i} promotionRequest={pr} staff={user} />
        ))}
      </Container>
    </>
  );
};
