import { Alert, Button, Container, Grid, Link, Snackbar, useTheme } from "@mui/material";
import { Link as ReactRouterLink } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { DepartmentsContext, RolesContext } from "../../components/PrivateRoute";
import { currentStaffYear } from "../../helpers/FormatDate";
import { Divisions } from "../../models/Division";
import { InitMessageState, MessageState } from "../../models/MessageState";
import { User } from "../../models/User";
import { getCurrentStaffYearDivisions } from "../../services/divisions";
import { getListOfNewUsers, getListOfUsers } from "../../services/users";
import { AddUserDialog } from "./AddUserDialog";
import { YearsUser } from "./YearsUser";
import { Path } from "../../helpers/Path";

export interface UpdateUsersInterface {
  users: User[];
  newUsers: User[];
  divisions: Divisions;
  addUserDialogOpen: boolean;
  loading: boolean;
  messageState: MessageState;
}

export const UpdateUsers = () => {
  const roles = useContext(RolesContext);
  const departments = useContext(DepartmentsContext);
  const theme = useTheme();

  const [state, setState] = useState<UpdateUsersInterface>({
    users: [],
    newUsers: [],
    divisions: {},
    loading: true,
    addUserDialogOpen: false,
    messageState: InitMessageState,
  });

  useEffect(() => {
    const usersFetch = getListOfUsers();
    const newUsersFetch = getListOfNewUsers();
    const divisionsFetch = getCurrentStaffYearDivisions();

    Promise.all([usersFetch, newUsersFetch, divisionsFetch]).then(([users, newUsers, divisions]) =>
      setState({
        ...state,
        users,
        divisions,
        newUsers,
        loading: false,
      })
    );

    // eslint-disable-next-line
  }, []);

  const { loading, users, newUsers, addUserDialogOpen, divisions, messageState } = state;

  return loading ? (
    <Loading />
  ) : (
    <>
      <AddUserDialog
        addUserDialogOpen={addUserDialogOpen}
        setAddUserDialogOpen={(open: boolean) => setState({ ...state, addUserDialogOpen: open })}
        users={users}
        setAddUser={(values: { email: string; role: string }, message: string) =>
          setState({
            ...state,
            users: [
              ...users,
              {
                ...values,
                id: values.email + "@sowaustralia.com",
                email: values.email + "@sowaustralia.com",
              },
            ],
            addUserDialogOpen: false,
            messageState: {
              severity: "success",
              showMessage: true,
              message,
            },
          })
        }
        setAddUserFail={(error: string) =>
          setState({
            ...state,
            addUserDialogOpen: false,
            messageState: {
              severity: "error",
              showMessage: true,
              message: error,
            },
          })
        }
        roles={roles}
        departments={departments}
        divisions={divisions}
        staffYear={currentStaffYear}
      />
      <Snackbar
        open={messageState.showMessage}
        onClose={() =>
          setState({
            ...state,
            messageState: { ...messageState, showMessage: false },
          })
        }
        autoHideDuration={6000}
      >
        <Alert elevation={6} variant="filled" severity={messageState.severity}>
          {messageState.message}
        </Alert>
      </Snackbar>
      <Button variant="contained" component={ReactRouterLink} to={Path["Update Users for Next Year"]}>
        Update Users for Next Year
      </Button>
      <Button
        sx={{
          position: "fixed",
          bottom: theme.spacing(8),
          right: theme.spacing(8),
          zIndex: 4,
        }}
        variant="contained"
        size="large"
        onClick={() => setState({ ...state, addUserDialogOpen: true })}
      >
        Add New User
      </Button>
      <Container maxWidth="md">
        <Alert sx={{ mt: theme.spacing(12), mb: theme.spacing(8) }} elevation={2} variant="filled" severity="info">
          You are changing active users' data. Your changes will take effect immediately. For HOD changes, please go to{" "}
          <Link
            sx={{
              textDecoration: "none",
              color: "black",
              fontSize: "large",
            }}
            underline="hover"
            component={ReactRouterLink}
            to={Path["Organisation Structure"]}
          >
            Change Organisation Structure
          </Link>
        </Alert>
        <Grid spacing={2} container>
          {users.map((u, j) => (
            <Grid item xs={6} key={j}>
              <YearsUser
                u={u}
                users={users}
                roles={roles}
                departments={departments}
                divisions={divisions}
                year={currentStaffYear}
                setUsers={(users, message) =>
                  setState({
                    ...state,
                    users: users,
                    messageState: {
                      severity: "success",
                      showMessage: true,
                      message,
                    },
                  })
                }
                setUpdateUser={(user, name, value, message) =>
                  setState({
                    ...state,
                    users:
                      users.findIndex((u) => u.id === user.id) !== -1
                        ? ([
                            ...users.slice(
                              0,
                              users.findIndex((u) => u.id === user.id)
                            ),
                            {
                              ...users[users.findIndex((u) => u.id === user.id)],
                              [name]: value,
                            },
                            ...users.slice(users.findIndex((u) => u.id === user.id) + 1),
                          ] as User[])
                        : [...users, { ...user, [name]: value }],
                    messageState: {
                      severity: "success",
                      showMessage: true,
                      message,
                    },
                  })
                }
                setErrorMessage={(error) =>
                  setState({
                    ...state,
                    messageState: {
                      severity: "error",
                      showMessage: true,
                      message: error,
                    },
                  })
                }
              />
            </Grid>
          ))}
          {newUsers.map((u, j) => (
            <Grid item xs={6} key={j}>
              <YearsUser
                u={u}
                users={newUsers}
                roles={roles}
                departments={departments}
                divisions={divisions}
                year={currentStaffYear}
                setUsers={(newUsers, message) =>
                  setState({
                    ...state,
                    newUsers: newUsers,
                    messageState: {
                      severity: "success",
                      showMessage: true,
                      message,
                    },
                  })
                }
                setUpdateUser={(user, name, value, message) =>
                  setState({
                    ...state,
                    newUsers:
                      newUsers.findIndex((u) => u.id === user.id) !== -1
                        ? ([
                            ...newUsers.slice(
                              0,
                              newUsers.findIndex((u) => u.id === user.id)
                            ),
                            {
                              ...newUsers[newUsers.findIndex((u) => u.id === user.id)],
                              [name]: value,
                            },
                            ...newUsers.slice(newUsers.findIndex((u) => u.id === user.id) + 1),
                          ] as User[])
                        : [...newUsers, { ...user, [name]: value }],
                    messageState: {
                      severity: "success",
                      showMessage: true,
                      message,
                    },
                  })
                }
                setErrorMessage={(error) =>
                  setState({
                    ...state,
                    messageState: {
                      severity: "error",
                      showMessage: true,
                      message: error,
                    },
                  })
                }
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};
