import HelpIcon from "@mui/icons-material/Help";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Link as ReactRouterLink, useHistory, useParams } from "react-router-dom";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { Loading } from "../../components/Loading";
import { UserContext } from "../../components/PrivateRoute";
import { TypographyBold } from "../../components/TypographyBold";
import { formatDateTime } from "../../helpers/FormatDate";
import { Path } from "../../helpers/Path";
import { AdditionalClarificationModel, PDPModel } from "../../models/PDPModel";
import { User } from "../../models/User";
import { getAssignedHRRep, getMentees, getMenteesAsHR } from "../../services/mentees";
import {
  HR,
  MENTOR,
  getPersonalDevelopmentPlan,
  sendPDPApprovedEmail,
  sendPDPDeclinedEmail,
  sendPDPSubmittedEmail,
  updatePersonalDevelopmentPlan,
  userIsMentee,
  userIsMentor,
} from "../../services/personalDevelopmentPlan";
import { AdditionalClarifications } from "./AdditionalClarifications";
import { Goals } from "./Goals";
import { PDPDeclineDialog } from "./PDPDeclineDialog";
import { PDPHelpDialog } from "./PDPHelpDialog";

export interface PersonalDevelopmentPlanInterface {
  loading: boolean;
  openPDPApproveDialog: boolean;
  openPDPDeclineDialog: boolean;
  openPDPHelpDialog: boolean;
  openPDPSubmitDialog: boolean;
  pdp?: PDPModel;
  mentees: User[];
  menteesAsHR: User[];
  assignedHRRep?: User;
}

export const PersonalDevelopmentPlan = ({ mobile }: { mobile: boolean }) => {
  const { id } = useParams<{ id: string }>();
  const user = useContext(UserContext);

  const history = useHistory();
  const theme = useTheme();

  const [state, setState] = useState<PersonalDevelopmentPlanInterface>({
    loading: true,
    openPDPApproveDialog: false,
    openPDPDeclineDialog: false,
    openPDPHelpDialog: false,
    openPDPSubmitDialog: false,
    mentees: [],
    menteesAsHR: [],
  });

  const [updating, setUpdating] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const {
    loading,
    pdp,
    mentees,
    openPDPApproveDialog,
    openPDPDeclineDialog,
    openPDPHelpDialog,
    openPDPSubmitDialog,
    assignedHRRep,
    menteesAsHR,
  } = state;

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });

    const personalDevelopmentPlanFetch = getPersonalDevelopmentPlan(id, user);
    const menteesFetch = getMentees(user.id);
    const assignedHRRepFetch = getAssignedHRRep();

    Promise.all([personalDevelopmentPlanFetch, menteesFetch, assignedHRRepFetch])
      .then(([pdp, mentees, assignedHRRep]) => {
        if (assignedHRRep?.id === user.id) {
          getMenteesAsHR().then((menteesAsHR) =>
            setState({
              ...state,
              pdp,
              mentees,
              menteesAsHR,
              assignedHRRep,
              loading: false,
            })
          );
        } else {
          setState({ ...state, pdp, mentees, assignedHRRep, loading: false });
        }
      })
      .catch((err) => history.push(Path.Dashboard));

    // eslint-disable-next-line
  }, [id]);

  const renderCounter = useRef(0);

  useEffect(() => {
    if (pdp) {
      if (renderCounter.current > 1) {
        const delayDebounceFn = setTimeout(() => {
          updatePersonalDevelopmentPlan(pdp).then(() => {
            setUpdating(false);
            setShowMessage(true);
          });
        }, 1000);
        setUpdating(true);
        setShowMessage(true);
        return () => clearTimeout(delayDebounceFn);
      }
      ++renderCounter.current;
    }
  }, [pdp]);

  const approvePDP = () => {
    if (pdp && userIsMentor(pdp, user) && assignedHRRep?.id === user.id) {
      const tempPDP = Object.assign({}, pdp);
      tempPDP.approvedByMentor = true;
      tempPDP.approvedByMentorTime = new Date();
      tempPDP.approvedByHR = true;
      tempPDP.approvedByHRTime = new Date();
      sendPDPApprovedEmail(tempPDP, HR);
      setState({ ...state, pdp: tempPDP, openPDPApproveDialog: false });
    } else if (pdp && userIsMentor(pdp, user)) {
      const tempPDP = Object.assign({}, pdp);
      tempPDP.approvedByMentor = true;
      tempPDP.approvedByMentorTime = new Date();
      sendPDPApprovedEmail(tempPDP, MENTOR);
      setState({ ...state, pdp: tempPDP, openPDPApproveDialog: false });
    } else if (pdp && assignedHRRep?.id === user.id) {
      const tempPDP = Object.assign({}, pdp);
      tempPDP.approvedByHR = true;
      tempPDP.approvedByHRTime = new Date();
      sendPDPApprovedEmail(tempPDP, HR);
      setState({ ...state, pdp: tempPDP, openPDPApproveDialog: false });
    }
  };

  const declinePDP = (reason: string) => {
    const tempPDP = Object.assign({}, pdp);
    const {
      submitted,
      submittedTime,
      approvedByMentor,
      approvedByMentorTime,
      approvedByHR,
      approvedByHRTime,
      ...tempPDPWithAllRemoved
    } = tempPDP;
    if (pdp?.mentor.id === user.id) {
      sendPDPDeclinedEmail(tempPDPWithAllRemoved, MENTOR, reason, assignedHRRep as User);
    } else {
      sendPDPDeclinedEmail(tempPDPWithAllRemoved, HR, reason, assignedHRRep as User);
    }
    setState({
      ...state,
      pdp: tempPDPWithAllRemoved,
      openPDPDeclineDialog: false,
    });
  };

  const submitPDP = () => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.submitted = true;
    tempPDP.submittedTime = new Date();
    sendPDPSubmittedEmail(tempPDP, assignedHRRep as User);
    setState({ ...state, pdp: tempPDP, openPDPSubmitDialog: false });
  };

  const addAdditionalClarifications = (additionalClarification: AdditionalClarificationModel) =>
    pdp &&
    setState({
      ...state,
      pdp: {
        ...pdp,
        additionalClarifications: pdp.additionalClarifications.concat({
          ...additionalClarification,
          submittedTime: new Date(),
        }),
      },
    });

  const deleteAdditionalClarification = (index: number) => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.additionalClarifications.splice(index, 1);
    setState({ ...state, pdp: tempPDP });
  };

  const pdpApproved = pdp?.approvedByHR && pdp.approvedByMentor;

  const pdpApprovedByYou =
    (pdp?.mentor.id === user.id && pdp.approvedByMentor) || (assignedHRRep?.id === user.id && pdp?.approvedByHR);

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container maxWidth="xl">
        {pdp && assignedHRRep && pdp.mentee.id !== user.id && (
          <Link
            sx={{
              textDecoration: "none",
              color: "black",
              fontSize: "large",
            }}
            underline="hover"
            component={ReactRouterLink}
            to={`${Path["Personal Development Plan"]}/${user.id}`}
          >
            Your Personal Development Plan
          </Link>
        )}
        <Box sx={{ mb: theme.spacing(4) }} />
        {assignedHRRep && mentees.length > 0 && (
          <Box>
            <TypographyBold variant="h6">Your mentees' PDPs</TypographyBold>
            <Grid container direction="column" sx={{ mb: theme.spacing(4) }}>
              {mentees
                .filter((mentee) => mentee.id !== id)
                .map((mentee, i) => (
                  <Grid item key={i}>
                    <Link
                      sx={{
                        textDecoration: "none",
                        color: "black",
                        fontSize: "large",
                      }}
                      underline="hover"
                      component={ReactRouterLink}
                      to={`${Path["Personal Development Plan"]}/${mentee.id}`}
                    >
                      {mentee.firstName} {mentee.lastName}'s Personal Development Plan
                    </Link>
                  </Grid>
                ))}
            </Grid>
          </Box>
        )}
        {assignedHRRep && menteesAsHR.length > 0 && (
          <Box>
            <TypographyBold variant="h6">All mentee's PDPs</TypographyBold>
            <Grid container direction="column" sx={{ mb: theme.spacing(4) }}>
              {menteesAsHR.map((mentee, i) => (
                <Grid item key={i}>
                  <Link
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      fontSize: "large",
                    }}
                    underline="hover"
                    component={ReactRouterLink}
                    to={`${Path["Personal Development Plan"]}/${mentee.id}`}
                  >
                    {mentee.firstName} {mentee.lastName}'s Personal Development Plan
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        {pdp && assignedHRRep && (
          <>
            <PDPHelpDialog
              dialogOpen={openPDPHelpDialog}
              closeDialog={() => setState({ ...state, openPDPHelpDialog: false })}
            />
            <ConfirmationModal
              title={"Submit Personal Development Plan?"}
              content={
                "This will submit the plan for review. Once you submit it, you cannot edit your plan until it is declined by the mentor/HR."
              }
              confirmText={"Submit"}
              cancelText={"Cancel"}
              dialogOpen={openPDPSubmitDialog}
              closeDialog={() => setState({ ...state, openPDPSubmitDialog: false })}
              doAction={submitPDP}
            />
            <ConfirmationModal
              title={"Approve Personal Development Plan?"}
              content={"Do you approve this plan? You cannot undo this action."}
              confirmText={"Approve"}
              cancelText={"Cancel"}
              dialogOpen={openPDPApproveDialog}
              closeDialog={() => setState({ ...state, openPDPApproveDialog: false })}
              doAction={approvePDP}
            />
            <PDPDeclineDialog
              dialogOpen={openPDPDeclineDialog}
              closeDialog={() => setState({ ...state, openPDPDeclineDialog: false })}
              decline={(reason) => declinePDP(reason)}
            />
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={showMessage}
              onClose={() => setShowMessage(false)}
              autoHideDuration={3000}
            >
              <Alert severity={updating ? "warning" : "success"} elevation={6} variant="filled">
                {updating ? "Saving..." : "Saved!"}
              </Alert>
            </Snackbar>
            <Grid container alignItems="center" justifyContent="space-between">
              <TypographyBold gutterBottom variant="h6">
                {pdp.mentee.firstName + " " + pdp.mentee.lastName}
              </TypographyBold>
              <IconButton color="primary" onClick={() => setState({ ...state, openPDPHelpDialog: true })}>
                <HelpIcon fontSize="large" />
              </IconButton>
            </Grid>
            {pdp.submitted && (
              <Grid container direction="row-reverse" spacing={2} sx={{ mb: theme.spacing(4) }}>
                <Grid item>
                  <Tooltip title={pdp.approvedByHRTime ? formatDateTime(pdp.approvedByHRTime) : ""} placement="top">
                    <Paper
                      sx={{
                        borderRadius: "15px",
                        p: theme.spacing(1, 2, 1, 2),
                        backgroundColor: pdp.approvedByHR ? theme.palette.success.main : theme.palette.warning.light,
                        width: "170px",
                      }}
                      elevation={2}
                    >
                      <Typography variant="caption" color={pdp.approvedByHR ? "white" : "textSecondary"}>
                        HR Approval Status
                      </Typography>
                      <TypographyBold variant="body2" color={pdp.approvedByHR ? "white" : "textPrimary"}>
                        {pdp.approvedByHR ? "Approved" : "Pending"}
                      </TypographyBold>
                    </Paper>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={pdp.approvedByMentorTime ? formatDateTime(pdp.approvedByMentorTime) : ""}
                    placement="top"
                  >
                    <Paper
                      sx={{
                        borderRadius: "15px",
                        p: theme.spacing(1, 2, 1, 2),
                        backgroundColor: pdp.approvedByMentor
                          ? theme.palette.success.main
                          : theme.palette.warning.light,
                        width: "170px",
                      }}
                      elevation={2}
                    >
                      <Typography variant="caption" color={pdp.approvedByMentor ? "white" : "textSecondary"}>
                        Mentor Approval Status
                      </Typography>
                      <TypographyBold variant="body2" color={pdp.approvedByMentor ? "white" : "textPrimary"}>
                        {pdp.approvedByMentor ? "Approved" : "Pending"}
                      </TypographyBold>
                    </Paper>
                  </Tooltip>
                </Grid>
              </Grid>
            )}
            <Goals
              pdp={pdp}
              assignedHRRep={assignedHRRep}
              updatePDP={(pdp) => setState({ ...state, pdp })}
              user={user}
              mobile={mobile}
            />
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
              sx={{ mt: theme.spacing(6) }}
            >
              <Typography variant="caption">
                {pdp.submitted && pdp.submittedTime
                  ? "Plan submitted: " + formatDateTime(pdp.submittedTime)
                  : "Plan not yet submitted"}
              </Typography>
              {userIsMentee(pdp, user) && (
                <Button
                  sx={{ borderRadius: "20px", m: theme.spacing(2, 0, 2, 0) }}
                  size="large"
                  variant="contained"
                  disabled={pdp.submitted}
                  onClick={() => setState({ ...state, openPDPSubmitDialog: true })}
                >
                  {pdpApproved ? "Approved" : pdp.submitted ? "Submitted" : "Submit for review"}
                </Button>
              )}
              {!pdpApprovedByYou && pdp.submitted && (userIsMentor(pdp, user) || assignedHRRep?.id === user.id) && (
                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                  <Grid item>
                    <Button
                      color="success"
                      variant="contained"
                      onClick={() => setState({ ...state, openPDPApproveDialog: true })}
                    >
                      Approve
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => setState({ ...state, openPDPDeclineDialog: true })}
                    >
                      Decline
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {pdp && pdpApproved && (
              <AdditionalClarifications
                pdp={pdp}
                addAdditionalClarifications={addAdditionalClarifications}
                deleteAdditionalClarification={deleteAdditionalClarification}
                user={user}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
};
