import { Alert, Container, FormControlLabel, Link, Paper, Switch, ThemeProvider, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import { createContext, createElement, useEffect, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { DATA_AND_IT, FINANCE, MARKETING } from "../data/Departments";
import { STAFF } from "../data/Roles";
import { DEPLOYMENT_ENVIRONMENT } from "../helpers/Env";
import { Path } from "../helpers/Path";
import { atLeast } from "../helpers/Role";
import { innerTheme, SOWTheme } from "../helpers/Theme";
import { DepartmentInterface, InitDepartment } from "../models/Department";
import { Staff } from "../models/Staff";
import { User } from "../models/User";
import { PageNotFound } from "../pages/PageNotFound";
import { logout } from "../services/auth";
import { getCurrentStaffYearDepartments } from "../services/departments";
import { functions } from "../services/firebase";
import { getCurrentStaffYearRoles } from "../services/roles";
import { getUser } from "../services/users";
import { Loading } from "./Loading";
import { Main, Navbar } from "./Navbar";
import { TypographyBold } from "./TypographyBold";

export const UserContext = createContext<User>({} as User);
export const RolesContext = createContext<string[]>({} as string[]);
export const DepartmentsContext = createContext<DepartmentInterface>(InitDepartment);

export const PrivateRoute = (
  { component, currentUser, path, atLeastRole, departments, ...rest }: any // eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  const history = useHistory();
  const [state, setState] = useState<{
    navbarOpen: boolean;
    user?: User;
    roles: string[];
    departmentInterface: DepartmentInterface;
    functionsEmulator: boolean;
    loading: boolean;
  }>({
    navbarOpen: true,
    loading: true,
    roles: [],
    departmentInterface: InitDepartment,
    functionsEmulator: false,
  });

  useEffect(() => {
    setState({ ...state, loading: true });

    const userFetch = getUser();
    const rolesFetch = getCurrentStaffYearRoles();
    const departmentsFetch = getCurrentStaffYearDepartments();

    if (user != null && DEPLOYMENT_ENVIRONMENT === "production") {
      httpsCallable(functions, "date")().then((date) => {
        const date1 = date.data as number;
        const date2 = new Date().getTime();

        const msBetweenDates = Math.abs(date1 - date2);

        // 👇️ convert ms to hours                  min  sec   ms
        const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

        if (hoursBetweenDates > 1) {
          history.push(`${Path.Error}/Your system date is different to the actual date`);
        }

        Promise.all([userFetch, rolesFetch, departmentsFetch])
          .then(([user, roles, departmentInterface]) => {
            setState({
              ...state,
              user,
              roles,
              departmentInterface,
              loading: false,
            });
          })
          .catch((err) =>
            err.message === "User does not exist in the database"
              ? logout().then(() => history.push(Path.SignIn))
              : err.message === "User is not found in authentication"
              ? history.push(Path.SignIn)
              : history.push(`${Path.Error}/Internal Error`)
          );
      });
    } else {
      Promise.all([userFetch, rolesFetch, departmentsFetch])
        .then(([user, roles, departmentInterface]) => {
          setState({
            ...state,
            user,
            roles,
            departmentInterface,
            loading: false,
          });
        })
        .catch((err) =>
          err.message === "User does not exist in the database"
            ? logout().then(() => history.push(Path.SignIn))
            : err.message === "User is not found in authentication"
            ? history.push(Path.SignIn)
            : history.push(`${Path.Error}/Internal Error`)
        );
    }
    // eslint-disable-next-line
  }, []);

  const { user, roles, departmentInterface, functionsEmulator, loading, navbarOpen } = state;

  useEffect(() => {
    if (functionsEmulator && DEPLOYMENT_ENVIRONMENT === "local") {
      connectFunctionsEmulator(functions, "localhost", 5001);
    }
  }, [functionsEmulator]);

  const indexOfPath = path && Object.values(Path).indexOf(("/" + path.split("/")[1]) as unknown as Path);
  const theme = useTheme();
  const mobile = !useMediaQuery(theme.breakpoints.up("sm"));

  if (departments && departments.includes(FINANCE)) departments.push(departmentInterface.FINANCE);
  if (departments && departments.includes(MARKETING)) departments.push(departmentInterface.MARKETING);

  const routeComponent = (
    props: any // eslint-disable-line @typescript-eslint/no-explicit-any
  ) => {
    return currentUser ? (
      loading ? (
        <Loading />
      ) : path &&
        (!atLeastRole ||
          (atLeast &&
            atLeast(roles, atLeastRole, user?.role) &&
            (departments && departments.length > 0
              ? (user as Staff).department === DATA_AND_IT
                ? true
                : departments.includes((user as Staff).department)
              : true))) ? (
        <UserContext.Provider value={user as User}>
          <RolesContext.Provider value={roles}>
            <DepartmentsContext.Provider value={departmentInterface}>
              <ThemeProvider theme={!atLeast(roles, STAFF, user?.role) ? innerTheme(user as User) : SOWTheme}>
                <Navbar
                  active={("/" + path.split("/")[1]) as Path}
                  open={navbarOpen}
                  setOpen={(open) => setState({ ...state, navbarOpen: open })}
                  title={Object.keys(Path)[indexOfPath]}
                  mobile={mobile}
                />
                <Main open={navbarOpen} mobile={mobile} sx={{ mb: theme.spacing(30) }}>
                  <Container maxWidth="xl">
                    {path !== Path.Dashboard && !(path as string).startsWith(Path["Organisation Chart"]) && !mobile ? (
                      <Paper
                        sx={{
                          backgroundColor: "rgb(242, 243, 245)",
                          m: theme.spacing(4, 1, 4, 1),
                          p: theme.spacing(6, 4, 6, 4),
                        }}
                      >
                        <Container maxWidth="xl">
                          {DEPLOYMENT_ENVIRONMENT === "development" && (
                            <Alert variant="filled" severity="info">
                              <TypographyBold>
                                This is the test/development environment. Please go to{" "}
                                <Link
                                  sx={{
                                    "&:hover": {
                                      cursor: "pointer",
                                    },
                                  }}
                                  underline="hover"
                                  onClick={() => {
                                    window.location.href = "https://theshedsow.web.app/";
                                  }}
                                >
                                  the main website
                                </Link>
                                . Your changes on this website will not be effective.
                              </TypographyBold>
                            </Alert>
                          )}
                          <TypographyBold
                            variant="h4"
                            sx={{
                              mb: theme.spacing(4),
                            }}
                          >
                            {Object.keys(Path)[indexOfPath]}
                          </TypographyBold>
                        </Container>
                        {createElement(component, {
                          ...props,
                          currentUser,
                          setUser: (user: User) => setState({ ...state, user: user }),
                          navbarOpen,
                          mobile,
                        })}
                      </Paper>
                    ) : (
                      <>
                        <Container maxWidth="xl">
                          {DEPLOYMENT_ENVIRONMENT === "local" && (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={functionsEmulator}
                                  disabled={functionsEmulator === true}
                                  onChange={(e) => setState({ ...state, functionsEmulator: e.target.checked })}
                                />
                              }
                              label="Connect Functions Emulator"
                            />
                          )}
                          {DEPLOYMENT_ENVIRONMENT === "development" && (
                            <Alert variant="filled" severity="info">
                              <TypographyBold>
                                This is the test/development environment. Please go to{" "}
                                <Link
                                  sx={{
                                    "&:hover": {
                                      cursor: "pointer",
                                    },
                                  }}
                                  underline="hover"
                                  onClick={() => {
                                    window.location.href = "https://theshedsow.web.app/";
                                  }}
                                >
                                  the main website
                                </Link>
                                . Your changes on this website will not be effective.
                              </TypographyBold>
                            </Alert>
                          )}
                        </Container>
                        {createElement(component, {
                          ...props,
                          currentUser,
                          setUser: (user: User) => setState({ ...state, user: user }),
                          navbarOpen,
                          mobile,
                        })}
                      </>
                    )}
                  </Container>
                </Main>
              </ThemeProvider>
            </DepartmentsContext.Provider>
          </RolesContext.Provider>
        </UserContext.Provider>
      ) : (
        <PageNotFound />
      )
    ) : (
      <Redirect to={{ pathname: Path.SignIn }} />
    );
  };
  return <Route {...rest} render={routeComponent} />;
};
