import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

export const PDPHelpDialog = ({ dialogOpen, closeDialog }: { dialogOpen: boolean; closeDialog: () => void }) => (
  <Dialog open={dialogOpen} onClose={closeDialog} aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-slide-title">What is a Personal Development Plan?</DialogTitle>
    <DialogContent>
      <Typography>
        A Personal Development Plan is a set of 2-3 goals that are set at the beginning of a serving term.
      </Typography>
      <Typography>
        A mentee will work with their mentor to set goals, capabilities and developments which will be reviewed by the
        mentor and the HR Department.
      </Typography>
      <Typography>
        Once the goals have been approved, you cannot edit your goals, but you can tick the relative items off as you
        complete them.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={closeDialog} color="primary">
        Got it!
      </Button>
    </DialogActions>
  </Dialog>
);
