import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { TypographyBold } from "../../components/TypographyBold";
import { PDPModel } from "../../models/PDPModel";
import { User } from "../../models/User";
import { userIsMentee, userIsMentor } from "../../services/personalDevelopmentPlan";
import { ItemComponent } from "./ItemComponent";

export const Goals = ({
  pdp,
  updatePDP,
  assignedHRRep,
  user,
  mobile,
}: {
  pdp: PDPModel;
  updatePDP: (pdp: PDPModel) => void;
  assignedHRRep: User;
  user: User;
  mobile: boolean;
}) => {
  const theme = useTheme();
  const addGoal = () => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.goals = tempPDP.goals.concat({
      goal: "",
      capabilities: [
        {
          capability: "",
          developments: [
            {
              development: "",
            },
          ],
        },
      ],
    });
    updatePDP(tempPDP);
  };

  const setGoal = (goalValue: string, g: number) => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.goals[g].goal = goalValue;
    updatePDP(tempPDP);
  };

  const checkGoal = (checked: boolean, g: number) => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.goals[g].completed = checked;
    updatePDP(tempPDP);
  };

  const deleteGoal = (g: number) => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.goals.splice(g, 1);
    updatePDP(tempPDP);
  };

  const addCapability = (g: number) => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.goals[g].capabilities = tempPDP.goals[g].capabilities.concat({
      capability: "",
      developments: [
        {
          development: "",
        },
      ],
    });
    updatePDP(tempPDP);
  };

  const setCapability = (capabilityValue: string, g: number, c: number) => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.goals[g].capabilities[c].capability = capabilityValue;
    updatePDP(tempPDP);
  };

  const checkCapability = (checked: boolean, g: number, c: number) => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.goals[g].capabilities[c].completed = checked;
    updatePDP(tempPDP);
  };

  const deleteCapability = (g: number, c: number) => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.goals[g].capabilities.splice(c, 1);
    updatePDP(tempPDP);
  };

  const addDevelopment = (g: number, c: number) => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.goals[g].capabilities[c].developments = tempPDP.goals[g].capabilities[c].developments.concat({
      development: "",
    });
    updatePDP(tempPDP);
  };

  const setDevelopment = (developmentValue: string, g: number, c: number, d: number) => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.goals[g].capabilities[c].developments[d].development = developmentValue;
    updatePDP(tempPDP);
  };

  const checkDevelopment = (checked: boolean, g: number, c: number, d: number) => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.goals[g].capabilities[c].developments[d].completed = checked;
    updatePDP(tempPDP);
  };

  const deleteDevelopment = (g: number, c: number, d: number) => {
    const tempPDP = Object.assign({}, pdp);
    tempPDP.goals[g].capabilities[c].developments.splice(d, 1);
    updatePDP(tempPDP);
  };

  const disabled = userIsMentor(pdp, user) || assignedHRRep.id === user.id || pdp.submitted;

  const canTick = userIsMentee(pdp, user) && pdp.approvedByMentor && pdp.approvedByHR;

  return (
    <>
      {pdp.goals.map((goal, g) => (
        <Paper
          sx={{
            p: theme.spacing(4),
            mb: theme.spacing(3),
            borderRadius: "10px",
          }}
          key={g}
        >
          <ItemComponent
            title="Goal"
            value={goal.goal}
            set={(event) => setGoal(event.target.value, g)}
            remove={() => deleteGoal(g)}
            check={(event) => checkGoal(event.target.value === 1 ? true : event.target.checked ? true : false, g)}
            completed={goal.completed}
            disabled={disabled}
            canTick={canTick}
            mobile={mobile}
          />
          {goal.capabilities?.map((capability, c) => (
            <Box key={c} sx={{ ml: theme.spacing(mobile ? 2 : 7) }}>
              <ItemComponent
                title="Capability"
                value={capability.capability}
                set={(event) => setCapability(event.target.value, g, c)}
                remove={() => deleteCapability(g, c)}
                check={(event) =>
                  checkCapability(event.target.value === 1 ? true : event.target.checked ? true : false, g, c)
                }
                completed={capability.completed}
                disabled={disabled}
                canTick={canTick}
                mobile={mobile}
              />
              {capability.developments?.map((development, d) => (
                <Box key={d} sx={{ ml: theme.spacing(mobile ? 2 : 7) }}>
                  <ItemComponent
                    title="Development"
                    value={development.development}
                    set={(event) => setDevelopment(event.target.value, g, c, d)}
                    remove={() => deleteDevelopment(g, c, d)}
                    check={(event) =>
                      checkDevelopment(event.target.value === 1 ? true : event.target.checked ? true : false, g, c, d)
                    }
                    completed={development.completed}
                    disabled={disabled}
                    canTick={canTick}
                    mobile={mobile}
                  />
                </Box>
              ))}
              {!disabled && (
                <Grid container justifyContent="flex-end">
                  <Button
                    sx={{ m: theme.spacing(1, 5, 1, 5) }}
                    variant="text"
                    disabled={capability.developments.length >= 2}
                    onClick={() => addDevelopment(g, c)}
                  >
                    <Grid container alignItems="center" justifyContent="center">
                      <Typography sx={{ mr: theme.spacing(1) }}>Add development</Typography>
                      <AddCircleIcon fontSize="small" />
                    </Grid>
                  </Button>
                </Grid>
              )}
            </Box>
          ))}
          {!disabled && (
            <Grid container justifyContent="center">
              <Button variant="text" onClick={() => addCapability(g)} disabled={goal.capabilities.length >= 3}>
                <Grid container alignItems="center" justifyContent="center">
                  <AddCircleIcon fontSize="medium" sx={{ mr: theme.spacing(1) }} />
                  <Typography>Add capability</Typography>
                </Grid>
              </Button>
            </Grid>
          )}
        </Paper>
      ))}
      {!disabled && (
        <Paper>
          <Button disabled={pdp.goals.length >= 3} variant="text" onClick={() => addGoal()} fullWidth>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ p: theme.spacing(12) }}
            >
              <TypographyBold>Add a Goal</TypographyBold>
              <AddCircleIcon fontSize="large" />
            </Grid>
          </Button>
        </Paper>
      )}
    </>
  );
};
