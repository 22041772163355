import { useContext, useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { getAllModules } from "../../services/modules";
import { ModulesComponent } from "../../components/ModulesComponent";
import { UserContext } from "../../components/PrivateRoute";
import { ModuleListModel } from "../../models/ModuleListModel";
import { useHistory } from "react-router-dom";
import { Staff } from "../../models/Staff";
import { DATA_AND_IT, TRAINING_AND_DEVELOPMENT } from "../../data/Departments";

export interface ModulesComponentInterface {
  modules: ModuleListModel[][];
  loading: boolean;
}

export const AllModules = () => {
  const user = useContext(UserContext);
  const history = useHistory();
  const [state, setState] = useState<ModulesComponentInterface>({
    loading: true,
    modules: [],
  });

  if ((user as Staff).department !== TRAINING_AND_DEVELOPMENT && (user as Staff).department !== DATA_AND_IT) {
    history.goBack();
  }

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    const modulesFetch = getAllModules();

    Promise.all([modulesFetch]).then(([modules]) => {
      setState({
        ...state,
        modules,
        loading: false,
      });
    });
    // eslint-disable-next-line
  }, []);

  const { loading } = state;

  return loading ? <Loading /> : <ModulesComponent state={state} />;
};
