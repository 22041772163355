import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { red } from "@mui/material/colors";
import { DIRECTOR, HEAD_OF_DEPARTMENT, HEAD_OF_DIVISION } from "../../data/Roles";
import { User } from "../../models/User";
import { updateUser } from "../../services/organisationRearrangement";

export const SelectComponent = ({
  roles,
  list,
  name,
  user,
  disabled,
  year,
  setUpdateUser,
  setErrorMessage,
}: {
  roles: string[];
  list: string[];
  name: string;
  user: User;
  disabled: boolean;
  year: number;
  setUpdateUser: (user: User, name: string, value: string, message: string) => void;
  setErrorMessage: (error: string) => void;
}) => {
  return (
    <Select
      disabled={disabled}
      labelId={`${name}-label`}
      id={name}
      sx={
        user[name as keyof User] && list.includes(user[name as keyof User] as string)
          ? {}
          : { color: red[900], backgroundColor: red[100] }
      }
      value={
        user[name as keyof User] && list.includes(user[name as keyof User] as string)
          ? user[name as keyof User]
          : "Unassigned"
      }
      onChange={(event: SelectChangeEvent<string>) =>
        event.target.value !== "Unassigned"
          ? updateUser(roles, user, event.target.value, name, year.toString())
              .then((message) => setUpdateUser(user, name, event.target.value, message))
              .catch((error) => setErrorMessage(error))
          : setErrorMessage("Cannot set " + name + " as Unassigned")
      }
      name={name}
      variant="standard"
    >
      <MenuItem value={"Unassigned"}>Unassigned</MenuItem>
      {list.map((v, i) => (
        <MenuItem disabled={v === HEAD_OF_DEPARTMENT || v === HEAD_OF_DIVISION || v === DIRECTOR} key={i} value={v}>
          {v}
        </MenuItem>
      ))}
    </Select>
  );
};
