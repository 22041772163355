import { useEffect, useState } from "react";
import { Container, Grid, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { ModuleListModel } from "../../models/ModuleListModel";
import { Link as ReactRouterLink } from "react-router-dom";
import { Path } from "../../helpers/Path";
import { getAllModules, ModuleMonthsThemes, moduleMonthsThemes } from "../../services/modules";
import { Loading } from "../../components/Loading";
import { moduleMonths } from "../../helpers/FormatDate";

export const EditModules = () => {
  const [state, setState] = useState<{
    modules: ModuleListModel[];
    loading: boolean;
  }>({
    loading: true,
    modules: [],
  });

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    const modulesFetch = getAllModules();

    Promise.all([modulesFetch]).then(([modules]) => {
      setState({
        ...state,
        modules: modules.flat(),
        loading: false,
      });
    });
    // eslint-disable-next-line
  }, []);

  const { modules, loading } = state;

  let unassignedModules = modules.filter((m) => m.order === -1 || m.month === "September");

  const theme = useTheme();
  return !loading ? (
    <Container maxWidth="lg">
      <Grid
        container
        direction="column"
        sx={{
          mb: theme.spacing(2),
        }}
      >
        {moduleMonths.map((month, i) => {
          let currentMonthModules = modules.filter((m) => m.month === month);
          let modules1 = currentMonthModules.filter((m) => m.order === 1);
          let modules2 = currentMonthModules.filter((m) => m.order === 2);
          return (
            <Grid item key={i}>
              <Typography variant="h4">
                {month} - {moduleMonthsThemes[month as keyof ModuleMonthsThemes]}
              </Typography>
              <Typography variant="h5">Week #1</Typography>
              {modules1.map((m, j) => (
                <Grid item key={j}>
                  <Link to={Path["Edit Module"] + "/" + m.id} component={ReactRouterLink}>
                    {m.title}
                  </Link>
                </Grid>
              ))}
              <Typography variant="h5">Week #2</Typography>
              {modules2.map((m, j) => (
                <Grid item key={j}>
                  <Link to={Path["Edit Module"] + "/" + m.id} component={ReactRouterLink}>
                    {m.title}
                  </Link>
                </Grid>
              ))}
            </Grid>
          );
        })}
        <Typography variant="h5">Unassigned modules</Typography>
        {unassignedModules.map((m, j) => (
          <Grid item key={j}>
            <Link to={Path["Edit Module"] + "/" + m.id} component={ReactRouterLink}>
              {m.title}
            </Link>
          </Grid>
        ))}
        <Link
          sx={{ mt: theme.spacing(2) }}
          variant="h4"
          to={Path["Edit Module"] + "/new-module"}
          component={ReactRouterLink}
        >
          Create New Module
        </Link>
      </Grid>
    </Container>
  ) : (
    <Loading />
  );
};
