import { useContext } from "react";
import { FormikProps, Form } from "formik";
import {
  TextField,
  CircularProgress,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Request } from "../../models/Request";
import { DepartmentsContext } from "../../components/PrivateRoute";

export const RequestsForm = ({ props, handleClose }: { props: FormikProps<Request>; handleClose: () => void }) => {
  const departments = useContext(DepartmentsContext);
  const theme = useTheme();
  const { values, errors, touched, handleBlur, handleChange, isSubmitting } = props;
  return (
    <Box
      sx={{
        width: "100%", // Fix IE 11 issue.
        mt: theme.spacing(3),
      }}
    >
      <Form>
        <DialogTitle id="form-dialog-title">Submit a Request</DialogTitle>
        <DialogContent>
          <InputLabel
            id="department-label"
            sx={{
              mt: theme.spacing(3),
            }}
            required
          >
            Select Department
          </InputLabel>
          <Select
            label="Department"
            required
            fullWidth
            labelId="department-label"
            id="department"
            onChange={handleChange}
            onBlur={handleBlur}
            name="department"
            value={values.department}
            error={errors.department && touched.department ? true : false}
            variant="standard"
          >
            {Object.keys(departments.departments).map((dep, i) => (
              <MenuItem key={i} value={dep}>
                {dep}
              </MenuItem>
            ))}
          </Select>
          <TextField
            margin="normal"
            required
            fullWidth
            id="description"
            label="Description"
            name="description"
            autoComplete="description"
            multiline
            minRows={4}
            maxRows={6}
            helperText={errors.description && touched.description ? errors.description : ""}
            value={values.description}
            error={errors.description && touched.description ? true : false}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            label="Amount"
            required
            fullWidth
            id="amount"
            onChange={handleChange}
            onBlur={handleBlur}
            name="amount"
            helperText={errors.amount && touched.amount ? errors.amount : ""}
            value={values.amount}
            error={errors.amount && touched.amount ? true : false}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            variant="standard"
          />
        </DialogContent>
        {!isSubmitting ? (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <CircularProgress color="secondary" />
          </DialogActions>
        )}
      </Form>
    </Box>
  );
};
