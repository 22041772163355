import { doc, getDoc, setDoc } from "firebase/firestore";
import { currentStaffYear } from "../helpers/FormatDate";
import { MenteesId } from "../models/Mentee";
import { PDPEmailForm } from "../models/PDPEmailForm";
import { PDPModel } from "../models/PDPModel";
import { User, UserID } from "../models/User";
import { sendPDPEmail } from "./email";
import { db } from "./firebase";
import { getAssignedHRRep, getMentorsAndMenteesIdCurrentYear } from "./mentees";
import { getUserById } from "./users";

const PDPs = "personalDevelopmentPlans";

export const MENTOR = "mentor";
export const HR = "HR";

const getPersonalDevelopmentPlanYear = async (year: number, mentee: User, mentor: User) => {
  const pdp = await getDoc(doc(db, PDPs, PDPs, year.toString(), mentee.id));
  if (pdp.exists()) {
    return {
      ...pdp.data(),
      goals: pdp.data().goals ? pdp.data().goals : [],
      additionalClarifications: (pdp.data().additionalClarifications ? pdp.data().additionalClarifications : []).map(
        (ac: any) => ({
          ...ac,
          submittedTime: ac.submittedTime.toDate(),
        })
      ),
      submittedTime: pdp.data().submittedTime ? pdp.data().submittedTime.toDate() : undefined,
      approvedByMentorTime: pdp.data().approvedByMentorTime ? pdp.data().approvedByMentorTime.toDate() : undefined,
      approvedByHRTime: pdp.data().approvedByHRTime ? pdp.data().approvedByHRTime.toDate() : undefined,
      mentee,
      mentor,
    } as PDPModel;
  }
  return {
    goals: [],
    additionalClarifications: [],
    mentee,
    mentor,
  } as PDPModel;
};

export const getPersonalDevelopmentPlanCurrentYear = async (mentee: User, mentor: User) =>
  getPersonalDevelopmentPlanYear(currentStaffYear, mentee, mentor);

export const userIsMentee = (pdp: PDPModel, user: User) => pdp.mentee.id === user.id;

export const userIsMentor = (pdp: PDPModel, user: User) => pdp.mentor.id === user.id;

const findMentorId = (menteesId: MenteesId, menteeId: UserID) => menteesId[menteeId];

export const getPersonalDevelopmentPlan = async (menteeId: UserID, user: User) => {
  const menteesId = await getMentorsAndMenteesIdCurrentYear();
  const mentorId = findMentorId(menteesId, menteeId);
  const assignedHRRep = await getAssignedHRRep();
  if (mentorId && assignedHRRep) {
    if (user.id === mentorId || user.id === menteeId || user.id === assignedHRRep.id) {
      const mentee = await getUserById(menteeId);
      const mentor = await getUserById(mentorId);
      return getPersonalDevelopmentPlanCurrentYear(mentee, mentor);
    }
  }
  return undefined;
};

export const updatePersonalDevelopmentPlan = async (pdp: PDPModel) => {
  Object.keys(pdp).forEach((key) => {
    if (pdp[key as keyof PDPModel] === undefined) {
      delete pdp[key as keyof PDPModel];
    }
  });
  const { mentor, mentee, ...pdpWithoutMentorAndMentee } = pdp;

  await setDoc(doc(db, PDPs, PDPs, currentStaffYear.toString(), pdp.mentee.id), { ...pdpWithoutMentorAndMentee });
};

const sendPDPSubmittedEmailToMentee = (pdp: PDPModel) => {
  const e: PDPEmailForm = {
    email: pdp.mentee.email,
    subject: "Your Personal Development Plan has been submitted for review",
    message:
      "Your Personal Development Plan for " +
      currentStaffYear +
      " has been submitted for review. Your mentor and HR have been notified. Please wait until they approve your plan.",
    relation: "Your",
    pdp: pdp,
  };
  sendPDPEmail(e);
};

const sendPDPSubmittedEmailToMentor = (pdp: PDPModel) => {
  const e: PDPEmailForm = {
    email: pdp.mentor.email,
    subject: "Please review your mentee's Personal Development Plan",
    message:
      "Your mentee's Personal Development Plan for " + currentStaffYear + " has been submitted. Please review it.",
    relation: "Your mentee (" + pdp.mentee.firstName + " " + pdp.mentee.lastName + ")'s",
    pdp: pdp,
  };
  sendPDPEmail(e);
};

const sendPDPSubmittedEmailToHRRep = (pdp: PDPModel, assignedHRRep: User) => {
  const e: PDPEmailForm = {
    email: assignedHRRep.email,
    subject: "Please review " + pdp.mentee.firstName + " " + pdp.mentee.lastName + "'s Personal Development Plan",
    message:
      pdp.mentee.firstName +
      " " +
      pdp.mentee.lastName +
      "'s Personal Development Plan for " +
      currentStaffYear +
      " has been submitted. Please review it.",
    relation: pdp.mentee.firstName + " " + pdp.mentee.lastName + "'s",
    pdp: pdp,
  };
  sendPDPEmail(e);
};

export const sendPDPSubmittedEmail = (pdp: PDPModel, assignedHRRep: User) => {
  sendPDPSubmittedEmailToMentee(pdp);
  sendPDPSubmittedEmailToMentor(pdp);
  sendPDPSubmittedEmailToHRRep(pdp, assignedHRRep);
};

const sendPDPApprovedEmailToMentee = (pdp: PDPModel, by: string) => {
  const pdpApproved = pdp.approvedByMentor && pdp.approvedByHR;
  const e: PDPEmailForm = {
    email: pdp.mentee.email,
    subject: "Your Personal Development Plan has been approved by " + (by === MENTOR ? "your mentor" : "HR"),
    message:
      "Your Personal Development Plan for " +
      currentStaffYear +
      " has been approved by " +
      (by === MENTOR ? "your mentor" : "HR") +
      ". " +
      (pdpApproved
        ? "Your plan has been fully approved!"
        : "Your plan still needs to be approved by " + (by === MENTOR ? "HR." : "your mentor.")),
    relation: "Your",
    pdp: pdp,
  };
  sendPDPEmail(e);
};

const sendPDPApprovedEmailToMentor = (pdp: PDPModel, by: string) => {
  const pdpApproved = pdp.approvedByMentor && pdp.approvedByHR;
  const e: PDPEmailForm = {
    email: pdp.mentor.email,
    subject:
      by === MENTOR
        ? "You have approved your mentee's Personal Development Plan"
        : "Your mentee's Personal Development Plan has been approved by HR",
    message:
      "Your mentee's Personal Development Plan for " +
      currentStaffYear +
      " has been approved by " +
      (by === MENTOR ? "you" : "HR") +
      ". " +
      (pdpApproved
        ? "The plan has been fully approved!"
        : "The plan still needs to be approved by " + (by === MENTOR ? "HR." : "you.")),
    relation: "Your mentee " + pdp.mentee.firstName + " " + pdp.mentee.lastName + "'s",
    pdp: pdp,
  };
  sendPDPEmail(e);
};

export const sendPDPApprovedEmail = (pdp: PDPModel, by: string) => {
  sendPDPApprovedEmailToMentee(pdp, by);
  sendPDPApprovedEmailToMentor(pdp, by);
};

const sendPDPDeclinedEmailToMentee = (pdp: PDPModel, by: string, reason: string) => {
  const e: PDPEmailForm = {
    email: pdp.mentee.email,
    subject: "Your Personal Development Plan has been declined by " + (by === MENTOR ? "your mentor" : "HR"),
    message:
      "Your Personal Development Plan for " +
      currentStaffYear +
      " has been declined by " +
      (by === MENTOR ? "your mentor" : "HR") +
      ". Please edit and resubmit your plan.",
    relation: "Your",
    pdp: pdp,
    reason: reason,
  };
  sendPDPEmail(e);
};

const sendPDPDeclinedEmailToMentor = (pdp: PDPModel, by: string, reason: string) => {
  const e: PDPEmailForm = {
    email: pdp.mentor.email,
    subject:
      by === MENTOR
        ? "You have declined your mentee's Personal Development Plan"
        : "Your mentee's Personal Development Plan has been declined by HR",
    message:
      "Your mentee's Personal Development Plan for " +
      currentStaffYear +
      " has been declined by " +
      (by === MENTOR ? "you" : "HR") +
      ". Your mentee needs to edit and resubmit their plan. Please guide them as they edit their plan.",
    relation: "Your mentee " + pdp.mentee.firstName + " " + pdp.mentee.lastName + "'s",
    pdp: pdp,
    reason: reason,
  };
  sendPDPEmail(e);
};

const sendPDPDeclinedEmailToHRRep = (pdp: PDPModel, by: string, reason: string, assignedHRRep: User) => {
  const e: PDPEmailForm = {
    email: assignedHRRep.email,
    subject:
      by === MENTOR
        ? pdp.mentee.firstName +
          " " +
          pdp.mentee.lastName +
          "'s Personal Development Plan has been declined by their mentor"
        : "Your have declined " + pdp.mentee.firstName + " " + pdp.mentee.lastName + "'s Personal Development Plan",
    message:
      pdp.mentee.firstName +
      " " +
      pdp.mentee.lastName +
      "'s Personal Development Plan for " +
      currentStaffYear +
      " has been declined by " +
      (by === MENTOR ? "their mentor" : "you") +
      ". " +
      pdp.mentee.firstName +
      " " +
      pdp.mentee.lastName +
      " needs to edit and resubmit their plan. Please wait for their resubmission.",
    relation: pdp.mentee.firstName + " " + pdp.mentee.lastName + "'s",
    pdp: pdp,
    reason: reason,
  };
  sendPDPEmail(e);
};

export const sendPDPDeclinedEmail = (pdp: PDPModel, by: string, reason: string, assignedHRRep: User) => {
  sendPDPDeclinedEmailToMentee(pdp, by, reason);
  sendPDPDeclinedEmailToMentor(pdp, by, reason);
  sendPDPDeclinedEmailToHRRep(pdp, by, reason, assignedHRRep);
};
