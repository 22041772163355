import { useState, useEffect, useContext } from "react";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import {
  Container,
  Snackbar,
  Grid,
  Link,
  Button,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import Alert from "@mui/material/Alert";
import { Path } from "../../helpers/Path";
import { DEPLOYMENT_ENVIRONMENT } from "../../helpers/Env";
import {
  deleteServiceHistoryBeforeYear,
  getServiceHistory,
  getUserById,
  maxFileSize,
  pastStaffYearsSinceYearJoinedUntilYear,
  saveServiceHistory,
  updateField,
} from "../../services/users";
import { PropertyComponent } from "./PropertyComponent";
import { universities } from "../../data/University";
import { microsoftProvider, storage } from "../../services/firebase";
import { Loading } from "../../components/Loading";
import { HeadOfDivision, Staff } from "../../models/Staff";
import { User } from "../../models/User";
import { ServiceHistory } from "../../models/ServiceHistory";
import { Error } from "../../components/Error";
import { atLeast, PastRoles } from "../../helpers/Role";
import { PropertySelectComponent } from "./PropertySelectComponent";
import {
  currentStaffYear,
  departmentsStartYear,
  divisionsStartYear,
  rolesStartYear,
  startYear,
  yearsSinceStartYear,
} from "../../helpers/FormatDate";
import { MessageState } from "../../models/MessageState";
import { DepartmentsContext, RolesContext, UserContext } from "../../components/PrivateRoute";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { getPastRoles } from "../../services/roles";
import { PastDepartments } from "../../models/Department";
import { getPastDepartments } from "../../services/departments";
import { DIRECTOR, HEAD_OF_DEPARTMENT, HEAD_OF_DIVISION, OUTSOURCE, STAFF } from "../../data/Roles";
import { PastDivisions } from "../../models/Division";
import { getPastDivisions } from "../../services/divisions";
import { linkWithRedirect } from "firebase/auth";
import { User as AuthUser } from "firebase/auth";

export interface EditState {
  firstName: boolean;
  lastName: boolean;
  localChurch: boolean;
  university: boolean;
  universityDegree: boolean;
  yearJoined: boolean;
  serviceHistory: boolean;
  currentYear: number;
}

export interface ProfileState {
  profileUser?: User;
  loading: boolean;
  messageState: MessageState;
  editState: EditState;
  serviceHistory?: ServiceHistory;
  pastRoles: PastRoles;
  pastDepartments: PastDepartments;
  pastDivisions: PastDivisions;
}
export const Profile = ({ setUser, currentUser }: { setUser: (user: User) => void; currentUser: AuthUser }) => {
  const user = useContext(UserContext);
  const roles = useContext(RolesContext);
  const departments = useContext(DepartmentsContext);
  const { user_id } = useParams() as { user_id: string };
  const [state, setState] = useState<ProfileState>({
    loading: true,
    messageState: {
      showMessage: false,
      message: "",
      severity: "success",
    },
    editState: {
      firstName: false,
      lastName: false,
      localChurch: false,
      university: false,
      universityDegree: false,
      yearJoined: false,
      serviceHistory: false,
      currentYear: Number(startYear),
    },
    pastRoles: {},
    pastDepartments: {},
    pastDivisions: {},
  });
  const theme = useTheme();
  const bottomMargin = {
    mb: theme.spacing(2),
  };

  const { profileUser, loading, messageState, serviceHistory, editState, pastRoles, pastDepartments, pastDivisions } =
    state;

  useEffect(() => {
    setState({ ...state, loading: true });
    const profileUserFetch = getUserById(user_id);
    const serviceHistoryFetch = getServiceHistory(user_id);
    const pastRolesFetch = getPastRoles();
    const pastDepartmentsFetch = getPastDepartments();
    const pastDivisionsFetch = getPastDivisions();

    Promise.all([profileUserFetch, serviceHistoryFetch, pastRolesFetch, pastDepartmentsFetch, pastDivisionsFetch]).then(
      ([profileUser, serviceHistory, pastRoles, pastDepartments, pastDivisions]) => {
        setState({
          ...state,
          profileUser,
          serviceHistory,
          editState: {
            ...editState,
            currentYear: Number(user.yearJoined),
          },
          pastRoles,
          pastDepartments,
          pastDivisions,
          loading: false,
        });
      }
    );
    // eslint-disable-next-line
  }, [user_id]);

  const handleMessageClose = () =>
    setState({
      ...state,
      messageState: { ...messageState, showMessage: false },
    });

  const handleChangeUser = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    if (user && event.target.name) {
      setUser({ ...user, [event.target.name]: event.target.value });
    }
  };

  const handleChangeYearJoined = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    if (user && event.target.name) {
      setState({
        ...state,
        editState: {
          ...editState,
          currentYear: Number(event.target.value),
        },
      });
      setUser({ ...user, yearJoined: event.target.value as string });
    }
  };

  const handleChangeEditState = (event: SelectChangeEvent<number>) => {
    if (user && event.target.name) {
      setState({
        ...state,
        editState: { ...editState, [event.target.name]: event.target.value },
      });
    }
  };

  const handleChangeServiceHistory = (event: SelectChangeEvent) => {
    if (user && serviceHistory && serviceHistory.history && event.target.name) {
      setState({
        ...state,
        serviceHistory: {
          ...serviceHistory,
          history: {
            ...serviceHistory.history,
            [editState.currentYear]: {
              ...serviceHistory.history[editState.currentYear],
              university:
                event.target.name === "role" &&
                (event.target.value === STAFF ||
                  event.target.value === OUTSOURCE ||
                  event.target.value === HEAD_OF_DEPARTMENT)
                  ? undefined
                  : serviceHistory.history[editState.currentYear]?.university,
              department:
                event.target.name === "role" &&
                atLeast(pastRoles[editState.currentYear], STAFF, event.target.value) &&
                event.target.value !== HEAD_OF_DIVISION
                  ? undefined
                  : (serviceHistory.history[editState.currentYear] as Staff)?.department,
              division:
                event.target.name === "role" && event.target.value !== HEAD_OF_DIVISION
                  ? undefined
                  : (serviceHistory.history[editState.currentYear] as HeadOfDivision)?.division,
              [event.target.name]: event.target.value,
            } as User,
          },
        },
      });
    }
  };

  const onUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (user === undefined || e.target === null || e.target.files === null) {
      return;
    }
    const file = e.target.files[0];
    if (file.size > maxFileSize) {
      setState({
        ...state,
        messageState: {
          showMessage: true,
          message: "Image exceeds file size limit (5MB)",
          severity: "error",
        },
      });
      return;
    }
    const fileURL = `profilePictures/${user.id}.${file.name.substring(file.name.lastIndexOf(".") + 1)}`;
    const storageRef = ref(storage, fileURL);
    uploadBytes(storageRef, file).then(() =>
      getDownloadURL(storageRef).then((url) =>
        updateField(user.id, "photoURL", url, "Photo")
          .then((response: string) => {
            setUser({ ...user, photoURL: url });
            setState({
              ...state,
              messageState: {
                showMessage: true,
                message: response,
                severity: "success",
              },
            });
          })
          .catch((error) => {
            setState({
              ...state,
              messageState: {
                showMessage: true,
                message: error.message,
                severity: "error",
              },
            });
          })
      )
    );
  };

  const alreadyLinkedWithMicrosoft = () =>
    currentUser.providerData.find((provider) => provider.providerId === "microsoft.com") ? true : false;

  const linkWithMicrosoft = () => linkWithRedirect(currentUser, microsoftProvider);

  const save = (name: string, value: string | undefined, label: string) => updateField(user.id, name, value, label);

  const saveYearJoined = (name: string, value: string | undefined, label: string) =>
    deleteServiceHistoryBeforeYear(value as string, user.id, serviceHistory).then((newServiceHistory) => {
      setState({ ...state, serviceHistory: newServiceHistory });
      return updateField(user.id, name, value, label);
    });

  return !loading ? (
    user.id === user_id ? (
      <>
        <Snackbar open={messageState.showMessage} onClose={handleMessageClose} autoHideDuration={6000}>
          <Alert elevation={6} variant="filled" severity={messageState.severity}>
            {messageState.message}
          </Alert>
        </Snackbar>
        <Container maxWidth="sm">
          {!alreadyLinkedWithMicrosoft() && (
            <Grid container justifyContent="center" item xs={12}>
              <Button variant="contained" onClick={() => linkWithMicrosoft()}>
                Link Account With Microsoft
              </Button>
            </Grid>
          )}
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Box
              component="input"
              accept="image/*"
              sx={{
                display: "none",
              }}
              id="contained-button-file"
              onChange={onUploadChange}
              type="file"
            />
            {user.photoURL && (
              <Grid xs={12} container item justifyContent="center">
                <Box
                  component="img"
                  alt="Profile"
                  src={user.photoURL}
                  sx={{
                    mb: theme.spacing(2),
                    width: theme.spacing(31),
                    height: theme.spacing(31),
                    overflow: "hidden",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </Grid>
            )}
            <Grid container justifyContent="center" xs={12} item>
              <Box component="label" htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span" size="small">
                  Change Profile Picture
                </Button>
              </Box>
            </Grid>
            <Grid xs={7} item>
              <Typography variant="body2" color="textSecondary">
                Email
              </Typography>
              <TextField
                sx={{
                  "&:disabled": {
                    color: theme.palette.common.black,
                  },
                }}
                fullWidth
                name="email"
                defaultValue={user.email}
                disabled
                variant="standard"
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <PropertyComponent
                label="First Name"
                name="firstName"
                handleChange={handleChangeUser}
                value={user.firstName}
                state={state}
                setState={setState}
                save={save}
              />
            </Grid>
            <Grid item xs={12}>
              <PropertyComponent
                label="Last Name"
                name="lastName"
                handleChange={handleChangeUser}
                value={user.lastName}
                state={state}
                setState={setState}
                save={save}
              />
            </Grid>
            <Grid item xs={12}>
              <PropertyComponent
                label="Local Church"
                name="localChurch"
                handleChange={handleChangeUser}
                value={user.localChurch}
                state={state}
                setState={setState}
                save={save}
              />
            </Grid>
            <Grid xs={12} item>
              <PropertySelectComponent
                label="University"
                name="university"
                handleChange={handleChangeUser}
                value={user.university}
                list={universities}
                state={state}
                setState={setState}
                save={save}
              />
            </Grid>
            <Grid xs={12} item>
              <PropertyComponent
                label="University Degree"
                name="universityDegree"
                handleChange={handleChangeUser}
                value={user.universityDegree}
                state={state}
                setState={setState}
                save={save}
              />
            </Grid>
            <Grid xs={12} item>
              <PropertyComponent
                label="Phone Number"
                name="phoneNumber"
                handleChange={handleChangeUser}
                value={user.phoneNumber}
                state={state}
                setState={setState}
                save={save}
              />
            </Grid>
            <Grid xs={12} item>
              <PropertySelectComponent
                label="Year Joined"
                name="yearJoined"
                handleChange={handleChangeYearJoined}
                value={user.yearJoined}
                list={yearsSinceStartYear().map((y) => String(y))}
                state={state}
                setState={setState}
                save={saveYearJoined}
              />
            </Grid>
            {user.yearJoined &&
              currentStaffYear !== Number(user.yearJoined) &&
              serviceHistory &&
              serviceHistory.history && (
                <Grid xs={12} item sx={bottomMargin}>
                  <Typography variant="body2" color="textSecondary">
                    Edit Service History
                  </Typography>
                  <Grid container item xs={12} alignItems="center" justifyContent="space-between">
                    <Grid xs={8} container spacing={2} item>
                      <Grid xs={3} item>
                        <Typography variant="body2" color="textSecondary">
                          Select Year
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            disabled={!editState.serviceHistory}
                            labelId="currentYear-label"
                            id="currentYear"
                            onChange={handleChangeEditState}
                            name="currentYear"
                            value={editState.currentYear}
                            variant="standard"
                          >
                            {pastStaffYearsSinceYearJoinedUntilYear(
                              Number(user.yearJoined),
                              serviceHistory.yearJoinedShed
                            ).map((v, i) => (
                              <MenuItem key={i} value={v}>
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid xs={4} item>
                        <Typography variant="body2" color="textSecondary">
                          Role
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            disabled={!editState.serviceHistory}
                            labelId="role-label"
                            id="role"
                            onChange={handleChangeServiceHistory}
                            name="role"
                            value={
                              serviceHistory.history && serviceHistory.history[editState.currentYear]?.role
                                ? serviceHistory.history[editState.currentYear]?.role
                                : ""
                            }
                            variant="standard"
                          >
                            {pastRoles[
                              editState.currentYear < rolesStartYear ? rolesStartYear - 1 : editState.currentYear
                            ]?.map((v, i) => (
                              <MenuItem key={i} value={v}>
                                {v}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {atLeast(
                        pastRoles[editState.currentYear < rolesStartYear ? rolesStartYear - 1 : editState.currentYear],
                        STAFF,
                        serviceHistory.history[editState.currentYear]?.role
                      ) &&
                        serviceHistory.history[editState.currentYear]?.role !== HEAD_OF_DIVISION &&
                        editState.currentYear >= departmentsStartYear && (
                          <Grid xs={5} item>
                            <FormControl fullWidth>
                              <Typography variant="body2" color="textSecondary">
                                Department
                              </Typography>
                              <Select
                                disabled={!editState.serviceHistory}
                                labelId="department-label"
                                id="department"
                                onChange={handleChangeServiceHistory}
                                name="department"
                                value={
                                  (serviceHistory.history[editState.currentYear] as Staff).department
                                    ? (serviceHistory.history[editState.currentYear] as Staff).department
                                    : ""
                                }
                                variant="standard"
                              >
                                {Object.keys(
                                  pastDepartments[
                                    editState.currentYear < departmentsStartYear
                                      ? departmentsStartYear - 1
                                      : editState.currentYear
                                  ]?.departments
                                ).map((v, i) => (
                                  <MenuItem key={i} value={v}>
                                    {v}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                      {serviceHistory.history[editState.currentYear]?.role !== HEAD_OF_DIVISION &&
                        editState.currentYear >= divisionsStartYear && (
                          <Grid xs={5} item>
                            <FormControl fullWidth>
                              <Typography variant="body2" color="textSecondary">
                                Division
                              </Typography>
                              <Select
                                disabled={!editState.serviceHistory}
                                labelId="division-label"
                                id="division"
                                onChange={handleChangeServiceHistory}
                                name="division"
                                value={
                                  (serviceHistory.history[editState.currentYear] as HeadOfDivision).division
                                    ? (serviceHistory.history[editState.currentYear] as HeadOfDivision).division
                                    : ""
                                }
                                variant="standard"
                              >
                                {Object.keys(
                                  pastDivisions[
                                    editState.currentYear < departmentsStartYear
                                      ? departmentsStartYear - 1
                                      : editState.currentYear
                                  ]
                                ).map((div, i) => (
                                  <MenuItem key={i} value={div}>
                                    {div}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                      {!atLeast(
                        pastRoles[editState.currentYear < rolesStartYear ? rolesStartYear - 1 : editState.currentYear],
                        STAFF,
                        serviceHistory.history[editState.currentYear]?.role
                      ) &&
                        serviceHistory.history[editState.currentYear]?.role !== undefined && (
                          <Grid xs={5} item>
                            <FormControl fullWidth>
                              <Typography variant="body2" color="textSecondary">
                                University
                              </Typography>
                              <Select
                                disabled={!editState.serviceHistory || editState.currentYear >= 2021}
                                labelId="university-label"
                                id="university"
                                onChange={handleChangeServiceHistory}
                                name="university"
                                value={
                                  serviceHistory.history[editState.currentYear]?.university
                                    ? serviceHistory.history[editState.currentYear]?.university
                                    : ""
                                }
                                variant="standard"
                              >
                                {universities.map((v, i) => (
                                  <MenuItem key={i} value={v}>
                                    {v}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                    </Grid>
                    {editState.serviceHistory ? (
                      <Grid item>
                        <Button
                          onClick={() =>
                            setState({
                              ...state,
                              editState: {
                                ...editState,
                                serviceHistory: false,
                              },
                            })
                          }
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            saveServiceHistory(user, pastRoles, serviceHistory)
                              .then((response: string) =>
                                setState({
                                  ...state,
                                  editState: {
                                    ...editState,
                                    serviceHistory: false,
                                  },
                                  messageState: {
                                    showMessage: true,
                                    message: response,
                                    severity: "success",
                                  },
                                })
                              )
                              .catch((error) =>
                                setState({
                                  ...state,
                                  messageState: {
                                    showMessage: true,
                                    message: error.message,
                                    severity: "error",
                                  },
                                })
                              );
                          }}
                        >
                          Save
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item>
                        <Button
                          onClick={() =>
                            setState({
                              ...state,
                              editState: {
                                ...editState,
                                serviceHistory: true,
                              },
                            })
                          }
                          disabled={editState.yearJoined}
                        >
                          Edit
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} direction="column" container justifyContent="center">
                    <Typography variant="body2" color="textSecondary">
                      Service History
                    </Typography>
                    {serviceHistory?.history &&
                      Object.entries(serviceHistory.history).map(
                        ([y, u], i) =>
                          u &&
                          user.yearJoined &&
                          y >= user.yearJoined && (
                            <Typography key={i} variant="body1">
                              {y}: {u?.role}
                              {!atLeast(pastRoles[y], STAFF, u?.role) && " - " + u?.university}
                              {atLeast(pastRoles[y], STAFF, u?.role) &&
                                u?.role !== HEAD_OF_DIVISION &&
                                (u as Staff)?.department &&
                                " - " + (u as Staff)?.department}
                              {u?.role === HEAD_OF_DIVISION && " - " + (u as HeadOfDivision)?.division}
                            </Typography>
                          )
                      )}
                  </Grid>
                </Grid>
              )}
            <Grid xs={7} item>
              <Typography variant="body2" color="textSecondary">
                Role
              </Typography>
              {DEPLOYMENT_ENVIRONMENT === "local" || DEPLOYMENT_ENVIRONMENT === "development" ? (
                <Select
                  labelId="role-select"
                  id="role"
                  onChange={(e) =>
                    updateField(user.id, "role", e.target.value, "Role").then(() =>
                      setUser({ ...user, role: e.target.value })
                    )
                  }
                  name="role"
                  value={user.role}
                  variant="standard"
                >
                  {roles.map((v, i) => (
                    <MenuItem key={i} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <TextField
                  sx={{
                    "&:disabled": {
                      color: theme.palette.common.black,
                    },
                  }}
                  fullWidth
                  name="role"
                  defaultValue={user.role}
                  disabled
                  variant="standard"
                />
              )}
            </Grid>
            {atLeast(roles, STAFF, user.role) && user.role !== HEAD_OF_DIVISION && (
              <Grid xs={7} item>
                <Typography variant="body2" color="textSecondary">
                  Department
                </Typography>

                {DEPLOYMENT_ENVIRONMENT === "local" || DEPLOYMENT_ENVIRONMENT === "development" ? (
                  <Select
                    labelId="department-select"
                    id="department"
                    onChange={(e) =>
                      updateField(user.id, "department", e.target.value, "Department").then(() =>
                        setUser({
                          ...user,
                          department: e.target.value,
                        } as User)
                      )
                    }
                    name="department"
                    value={(user as Staff).department}
                    variant="standard"
                  >
                    {Object.keys(departments.departments).map((v, i) => (
                      <MenuItem key={i} value={v}>
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <TextField
                    sx={{
                      "&:disabled": {
                        color: theme.palette.common.black,
                      },
                    }}
                    fullWidth
                    name="department"
                    defaultValue={(user as Staff).department}
                    disabled
                    variant="standard"
                  />
                )}
              </Grid>
            )}
            {user.role === HEAD_OF_DIVISION && (
              <Grid xs={7} item>
                <Typography variant="body2" color="textSecondary">
                  Division
                </Typography>
                <TextField
                  sx={{
                    "&:disabled": {
                      color: theme.palette.common.black,
                    },
                  }}
                  fullWidth
                  name="department"
                  defaultValue={(user as HeadOfDivision).division}
                  disabled
                  variant="standard"
                ></TextField>
              </Grid>
            )}
            <Grid container justifyContent="center" item xs={12}>
              <Link underline="hover" component={ReactRouterLink} to={Path["Reset Password"]}>
                Reset Password
              </Link>
            </Grid>
          </Grid>
        </Container>
      </>
    ) : profileUser ? (
      <>
        <Container maxWidth="sm">
          <Grid direction="column" container justifyContent="center" alignItems="center">
            {profileUser.photoURL && (
              <Grid container item justifyContent="center" sx={bottomMargin}>
                <Box
                  component="img"
                  alt="Profile"
                  src={profileUser.photoURL}
                  sx={{
                    mb: theme.spacing(2),
                    width: theme.spacing(31),
                    height: theme.spacing(31),
                    overflow: "hidden",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </Grid>
            )}
            <Grid item>
              <Typography variant="h4">
                {profileUser.firstName} {profileUser.lastName}
              </Typography>
            </Grid>
            <Grid item sx={bottomMargin}>
              <Typography color="textSecondary" variant="h6">
                {profileUser.role}
              </Typography>
            </Grid>
            <Grid item container sx={bottomMargin}>
              <Grid item xs={12} direction="column" container justifyContent="center" alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  Email
                </Typography>
                <Typography variant="body1">{profileUser.email}</Typography>
              </Grid>
            </Grid>
            <Grid item container sx={bottomMargin}>
              {profileUser.university && (
                <Grid item xs={12} direction="column" container justifyContent="center" alignItems="center">
                  <Typography variant="body2" color="textSecondary">
                    University
                  </Typography>
                  <Typography variant="body1">{profileUser.university}</Typography>
                </Grid>
              )}
            </Grid>

            <Grid item container sx={bottomMargin}>
              {profileUser.universityDegree && (
                <Grid item xs={12} direction="column" container justifyContent="center" alignItems="center">
                  <Typography variant="body2" color="textSecondary">
                    University Degree
                  </Typography>
                  <Typography variant="body1">{profileUser.universityDegree}</Typography>
                </Grid>
              )}
            </Grid>
            {profileUser.localChurch && (
              <Grid item sx={bottomMargin}>
                <Grid item xs={12} direction="column" container justifyContent="center" alignItems="center">
                  <Typography variant="body2" color="textSecondary">
                    Local Church
                  </Typography>
                  <Typography variant="body1">{profileUser.localChurch}</Typography>
                </Grid>
              </Grid>
            )}
            <Grid item sx={bottomMargin}>
              {(profileUser as Staff).department && (
                <Grid item xs={12} direction="column" container justifyContent="center" alignItems="center">
                  <Typography variant="body2" color="textSecondary">
                    Department
                  </Typography>
                  <Typography variant="body1">{(profileUser as Staff).department}</Typography>
                </Grid>
              )}
            </Grid>
            <Grid item sx={bottomMargin}>
              {(profileUser as HeadOfDivision).division && (
                <Grid item xs={12} direction="column" container justifyContent="center" alignItems="center">
                  <Typography variant="body2" color="textSecondary">
                    Division
                  </Typography>
                  <Typography variant="body1">{(profileUser as HeadOfDivision).division}</Typography>
                </Grid>
              )}
            </Grid>
            {profileUser.yearJoined && (
              <Grid item sx={bottomMargin}>
                <Grid item xs={12} direction="column" container justifyContent="center" alignItems="center">
                  <Typography variant="body2" color="textSecondary">
                    Year Joined
                  </Typography>
                  <Typography variant="body1">{profileUser.yearJoined}</Typography>
                </Grid>
              </Grid>
            )}
            <Grid item sx={bottomMargin}>
              <Grid item xs={12} direction="column" container justifyContent="center">
                <Typography variant="body2" align="center" color="textSecondary">
                  Service History
                </Typography>
                {serviceHistory?.history &&
                  Object.entries(serviceHistory.history).map(
                    ([y, u], i) =>
                      u && (
                        <Typography key={i} variant="body1">
                          {y}: {u?.role}
                          {!atLeast(pastRoles[y], STAFF, u?.role) && " - " + u?.university}
                          {atLeast(pastRoles[y], STAFF, u?.role) &&
                            !atLeast(pastRoles[y], HEAD_OF_DIVISION, u?.role) &&
                            u?.role !== DIRECTOR &&
                            u?.role !== HEAD_OF_DIVISION &&
                            (u as Staff)?.department &&
                            " - " + (u as Staff)?.department}
                          {u?.role === HEAD_OF_DIVISION && " - " + (u as HeadOfDivision)?.division}
                        </Typography>
                      )
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </>
    ) : (
      <Error />
    )
  ) : (
    <Loading />
  );
};
