import { collection, doc, getDoc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { db, functions } from "./firebase";
import { getCurrentYearAndTerm, getReviewQuestions } from "./review360";
import { httpsCallable } from "firebase/functions";
import { YearAndTerm } from "../models/Review360Model";
import { yearsSinceStartYear } from "../helpers/FormatDate";

export const hello = "hello";

export const backupQuestions = async () => {
  const questions = await getReviewQuestions(await getCurrentYearAndTerm());
  await setDoc(doc(db, "360reviews", "questions"), questions);
};

export const moveQuestionsToCurrentYearAndTerm = async () => {
  const questions = await getDoc(doc(db, "360reviews", "questions"));
  const currentYearAndTerm = await getCurrentYearAndTerm();
  await setDoc(
    doc(db, "360reviews", currentYearAndTerm.year.toString(), currentYearAndTerm.term, "questions"),
    questions.data()
  );
};

export const changeAllRequestsToRecipients = async () => {
  for (const year of ["2021", "2022", "2023"]) {
    const requests = await getDocs(collection(db, "requests", "requests", year));
    for (const request of requests.docs) {
      const data = request.data();
      const { accountName, accountNumber, receiptURLs, bsb, receiptAmount } = data;
      if (accountName && accountNumber && bsb && receiptAmount) {
        await updateDoc(doc(db, "requests", "requests", year, request.id), {
          recipients: [
            {
              accountName,
              accountNumber,
              receiptURLs: receiptURLs ? receiptURLs : [],
              bsb,
              receiptAmount,
            },
          ],
          totalReceiptAmount: receiptAmount,
        });
      }
    }
  }
};

export const addFirstLastNameToAllUsers = async () => {
  let dict: { [id: string]: { firstName: string; lastName: string } } = {};
  for (const year of yearsSinceStartYear()) {
    const users = await getDocs(collection(db, "users", "users", year.toString()));
    for (const user of users.docs) {
      if (dict[user.id]) {
        if (user.data().firstName) {
          dict[user.id].firstName = user.data().firstName;
        }
        if (user.data().lastName) {
          dict[user.id].lastName = user.data().lastName;
        }
      } else {
        dict[user.id] = { firstName: user.data().firstName, lastName: user.data().lastName };
      }
    }
  }
  for (const year of yearsSinceStartYear()) {
    for (const [id, user] of Object.entries(dict)) {
      try {
        await updateDoc(doc(db, "users", "users", year.toString(), id), { ...user });
        console.log("Successfully updated: " + user.firstName + " " + user.lastName + " in year: " + year);
      } catch (e) {}
    }
  }
};

export const calculateResultsAdmin = async (yat: YearAndTerm) => {
  await httpsCallable(functions, "calculateResults")(yat);
};

// export const changeQToT = async () => {
//   const reviewsData = await getReviewsData();
//   let yaq = reviewsData.startYearAndTerm;
//   while (yaq !== reviewsData.currentYearAndTerm) {
//     let term = "T0";
//     if (yaq.term === "Q1") {
//       term = "T1";
//     } else if (yaq.term === "Q2") {
//       term = "T2";
//     } else if (yaq.term === "Q4") {
//       term = "T3";
//     }
//     console.log(yaq);
//     console.log(term);
//     const info = await getDoc(doc(db, "360reviews", yaq.year.toString(), yaq.term, "info"));
//     await setDoc(doc(db, "360reviews", yaq.year.toString(), term, "info"), info.data());
//     const reviewQuestions = await getReviewQuestions(yaq);
//     await setDoc(doc(db, "360reviews", yaq.year.toString(), term, "questions"), reviewQuestions);

//     const reviewees = await getDocs(
//       collection(db, "360reviews", yaq.year.toString(), yaq.term, "info", "reviewees")
//     );
//     for (const document of reviewees.docs) {
//       await setDoc(doc(db, "360reviews", yaq.year.toString(), term, "info", "reviewees", document.id), document.data());
//       const mentors = await getDocs(
//         collection(db, "360reviews", yaq.year.toString(), yaq.term, "info", "reviewees", document.id, "Mentor")
//       );
//       for (const mentor of mentors.docs) {
//         await setDoc(
//           doc(db, "360reviews", yaq.year.toString(), term, "info", "reviewees", document.id, "Mentor", mentor.id),
//           mentor.data()
//         );
//       }
//       const peers = await getDocs(
//         collection(db, "360reviews", yaq.year.toString(), yaq.term, "info", "reviewees", document.id, "Peers")
//       );
//       for (const peer of peers.docs) {
//         await setDoc(
//           doc(db, "360reviews", yaq.year.toString(), term, "info", "reviewees", document.id, "Peers", peer.id),
//           peer.data()
//         );
//       }
//       const below = await getDocs(
//         collection(db, "360reviews", yaq.year.toString(), yaq.term, "info", "reviewees", document.id, "Below")
//       );
//       for (const b of below.docs) {
//         await setDoc(
//           doc(db, "360reviews", yaq.year.toString(), term, "info", "reviewees", document.id, "Below", b.id),
//           b.data()
//         );
//       }
//       const above = await getDocs(
//         collection(db, "360reviews", yaq.year.toString(), yaq.term, "info", "reviewees", document.id, "Above")
//       );
//       for (const a of above.docs) {
//         await setDoc(
//           doc(db, "360reviews", yaq.year.toString(), term, "info", "reviewees", document.id, "Above", a.id),
//           a.data()
//         );
//       }
//     }

//     yaq = getNextYearAndTerm(yaq);
//   }
// };
