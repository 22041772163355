import { Box, FormControlLabel, Grid, Radio, RadioGroup, TextField, useTheme } from "@mui/material";
import React from "react";
import { TypographyBold } from "../../../components/TypographyBold";
import { writtenComments } from "../../../data/Review360Data";
import {
  Review,
  reviewAnswerRange,
  ReviewAnswerRange,
  ReviewAnswers,
  ReviewQuestions,
  WrittenComments,
} from "../../../models/Review360Model";

export const Review360Form = ({
  updateAnswers,
  review,
  reviewQuestions,
  currentStep,
}: {
  updateAnswers: (answers: ReviewAnswers) => void;
  review: Review;
  reviewQuestions: ReviewQuestions;
  currentStep: number;
}) => {
  const currentAttribute = Object.keys(reviewQuestions).sort()[currentStep];
  const reviewAnswers = review.answers ? review.answers[currentAttribute] : undefined;

  const theme = useTheme();

  const rangeToMeaning = {
    1: "Strongly Disagree",
    2: "Disagree",
    3: "Tend to Disagree",
    4: "Neither Agree nor Disagree",
    5: "Tend to Agree",
    6: "Agree",
    7: "Strongly Agree",
  };

  return (
    <>
      {Object.entries(reviewQuestions[currentAttribute]).map(([attribute, questions], i) => (
        <Box key={i}>
          <TypographyBold
            sx={{
              mt: theme.spacing(2),
              fontStyle: "italic",
              textDecoration: "underline",
            }}
            variant="h4"
          >
            {attribute}
          </TypographyBold>
          {Object.entries(questions).map(([index, question], j) => (
            <Box key={j}>
              <TypographyBold sx={{ mt: theme.spacing(1) }}>
                {j + 1}. {question}
              </TypographyBold>
              <RadioGroup
                row
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  let answersTemp = review.answers;
                  if (!answersTemp) {
                    answersTemp = {};
                  }
                  if (!answersTemp[currentAttribute]) {
                    answersTemp[currentAttribute] = { writtenComments: {} };
                  }
                  if (!answersTemp[currentAttribute][attribute]) {
                    answersTemp[currentAttribute][attribute] = {};
                  }
                  answersTemp[currentAttribute][attribute][j] = Number(event.target.value) as ReviewAnswerRange;
                  updateAnswers(answersTemp);
                }}
                value={
                  reviewAnswers && reviewAnswers[attribute] && reviewAnswers[attribute][j]
                    ? reviewAnswers[attribute][j]
                    : 0
                }
              >
                <Grid container>
                  {reviewAnswerRange.map((range: ReviewAnswerRange, j) => (
                    <Grid item key={j} xs={12}>
                      <FormControlLabel
                        sx={{ marginLeft: 0, marginRight: 0 }}
                        label={rangeToMeaning[range]}
                        labelPlacement={"end"}
                        value={range}
                        control={<Radio />}
                      />
                    </Grid>
                  ))}
                </Grid>
              </RadioGroup>
            </Box>
          ))}
        </Box>
      ))}
      {review.reviewee.id !== review.reviewer.id && (
        <>
          <TypographyBold sx={{ mt: theme.spacing(2) }} variant="h6" gutterBottom>
            Based on your responses to these questions for {currentAttribute}, what should he/she start/stop/continue
            doing?
          </TypographyBold>
          <Grid container direction="column" spacing={2}>
            {writtenComments.map((c) => (
              <Grid key={c} item>
                <TextField
                  id={c}
                  required
                  fullWidth
                  multiline
                  label={c}
                  minRows={4}
                  maxRows={6}
                  value={
                    reviewAnswers &&
                    reviewAnswers.writtenComments &&
                    reviewAnswers.writtenComments[c as keyof WrittenComments]
                      ? reviewAnswers.writtenComments[c as keyof WrittenComments]
                      : ""
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    let answersTemp = review.answers;
                    if (!answersTemp) {
                      answersTemp = {};
                    }
                    if (!answersTemp[currentAttribute]) {
                      answersTemp[currentAttribute] = { writtenComments: {} };
                    }
                    if (answersTemp[currentAttribute].writtenComments) {
                      answersTemp[currentAttribute].writtenComments[c as keyof WrittenComments] = event.target.value;
                    }
                    updateAnswers(answersTemp);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};
