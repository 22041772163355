import { auth } from "./firebase";
import { allowedEmail } from "./allowedEmails";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
} from "firebase/auth";

export const signup = async (values: { email: string; password: string; confirmPassword: string }) => {
  if (allowedEmail(values.email)) {
    await createUserWithEmailAndPassword(auth, values.email, values.password);
  }
};

export const signin = async (email: string, password: string) => {
  if (allowedEmail(email)) {
    await signInWithEmailAndPassword(auth, email, password);
  }
};

export const logout = () =>
  new Promise((resolve, reject) => {
    auth
      .signOut()
      .then(() => {
        resolve("Succesfully logged out");
      })
      .catch((error) => {
        reject(error);
      });
  });

export const isAuthenticated = () => {
  return auth.onAuthStateChanged((user) => {
    return user ? true : false;
  });
};

export const resetPassword = (newPassword: string): Promise<string> =>
  new Promise((resolve, reject) => {
    auth.currentUser
      ? updatePassword(auth.currentUser, newPassword)
          .then(() => {
            resolve("Update successful");
          })
          .catch((error) => {
            reject(error);
          })
      : reject({ message: "You are not logged in" });
  });

export const sendPasswordResetEmailTo = (email: string): Promise<string> =>
  new Promise((resolve, reject) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        resolve("An email has been sent. Please check your inbox.");
      })
      .catch((error) => {
        reject(error);
      });
  });
