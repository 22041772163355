import {
  currentStaffYear,
  currentYear,
  departmentsStartYear,
  nextStaffYear,
  pastSOWYearsUntilYear,
  pastStaffYearsSinceYear,
  staffYearsSinceStartYear,
} from "../helpers/FormatDate";
import { atLeast, PastRoles } from "../helpers/Role";
import { Staff } from "../models/Staff";
import { User } from "../models/User";
import { ServiceHistory } from "../models/ServiceHistory";
import { ServiceHistoryEntry } from "../models/ServiceHistoryEntry";
import { db, auth, functions } from "./firebase";
import { getCurrentStaffYearRoles } from "./roles";
import { DepartmentInterface } from "../models/Department";
import { collection, deleteDoc, doc, documentId, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { DIRECTOR, STAFF } from "../data/Roles";
import { httpsCallable } from "firebase/functions";

export const maxFileSize = 5000000; // 5MB

const firestoreUser = (id: string, year: string) => doc(db, "users", "users", year, id);

export const getUser = (): Promise<User> =>
  new Promise((resolve, reject) => {
    let user = auth.currentUser;
    if (user !== null) {
      const userId = user.uid;
      const userEmail = user.email;
      getUserById(userId)
        .then((retrievedUser) => {
          resolve(retrievedUser);
        })
        .catch((error) => {
          if (error.message === "User: " + userId + " does not exist in the database") {
            httpsCallable(
              functions,
              "assignUserIDIfEmailExists"
            )({
              email: userEmail,
              uid: userId,
            })
              .then((result) => getUserById(userId).then((retrievedUser) => resolve(retrievedUser)))
              .catch((error) => reject(error));
          } else {
            reject(error);
          }
        });
    } else {
      reject({ message: "User is not found in authentication" });
    }
  });

export const getUserByIdYear = (id: string, year: string): Promise<User> =>
  new Promise((resolve, reject) => {
    getDoc(firestoreUser(id, year))
      .then((docRef) => {
        if (!docRef.exists()) {
          if (Number(year) === currentYear + 1) {
            getDoc(firestoreUser(id, currentYear.toString())).then((docRef2) => {
              if (!docRef2.exists()) {
                reject({ message: "User: " + id + " does not exist in the database" });
              } else {
                resolve({ id: id, ...docRef2.data() } as User);
              }
            });
          } else {
            reject({ message: "User: " + id + " does not exist in the database" });
          }
        } else {
          resolve({ id: id, ...docRef.data() } as User);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getUserById = (id: string): Promise<User> => getUserByIdYear(id, currentStaffYear.toString());

export const getUserByEmail = (email: string): Promise<User> =>
  new Promise((resolve, reject) => {
    getCurrentStaffYearRoles().then((roles) =>
      getDocs(query(collection(db, "users", "users", currentStaffYear.toString()), where("email", "==", email)))
        .then((querySnapshot) => {
          if (querySnapshot.size !== 1) {
            reject({ message: "Has to be exactly one HOD" });
          }
          querySnapshot.forEach((docRef) => {
            resolve({ id: docRef.id, ...docRef.data() } as User);
          });
        })
        .catch((error) => {
          reject(error);
        })
    );
  });

export const getHODByDepartment = (departments: DepartmentInterface, department: string): Staff =>
  departments.departments[department].head as Staff;

export const getFinanceHead = (departments: DepartmentInterface): Staff =>
  getHODByDepartment(departments, departments.FINANCE);

export const getDepartmentStaffListYear = async (department: string, year: string): Promise<Staff[]> => {
  const membersCollection = await getDocs(
    query(collection(db, "users", "users", year), where("department", "==", department))
  );

  let members: Staff[] = [];
  for (const doc of membersCollection.docs) {
    members.push({ id: doc.id, ...doc.data() } as Staff);
  }

  return members;
};

export const getDirector = async (year: string): Promise<Staff> => {
  const snapshot = await getDocs(
    query(collection(db, "users", "users", year), where("role", "in", ["Interim Director", DIRECTOR]))
  );
  if (snapshot.size !== 1) {
    throw new Error("Has to be exactly one Director");
  }
  return { id: snapshot.docs[0].id, ...snapshot.docs[0].data() } as Staff;
};

export const updateFieldYear = (
  id: string,
  name: string,
  value: string | undefined,
  label: string,
  year: string
): Promise<string> =>
  new Promise((resolve, reject) => {
    if (value === undefined) {
      resolve("Successfully updated " + label);
    }
    setDoc(
      firestoreUser(id, year),
      {
        [name]: value ? value.trim() : undefined,
      },
      { merge: true }
    )
      .then(() => {
        resolve("Successfully updated " + label);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateField = (id: string, name: string, value: string | undefined, label: string): Promise<string> =>
  updateFieldYear(id, name, value, label, currentStaffYear.toString());

export const getListOfUsers = (): Promise<User[]> =>
  new Promise((resolve, reject) => {
    getDocs(collection(db, "users", "users", currentStaffYear.toString()))
      .then((res) => {
        resolve(
          res.docs
            .filter((doc) => doc.id !== doc.data().email)
            .map((doc) => {
              return { id: doc.id, ...doc.data() } as User;
            })
        );
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getListOfUsersByStaffYear = (staffYear: number): Promise<User[]> =>
  new Promise((resolve, reject) => {
    getDocs(collection(db, "users", "users", staffYear.toString()))
      .then((res) => {
        resolve(
          res.docs.map((doc) => {
            return { id: doc.id, ...doc.data() } as User;
          })
        );
      })
      .catch((error) => {
        reject(error);
      });
  });

// also gets users who haven't signed in yet
export const getListOfAllUsers = (): Promise<User[]> =>
  new Promise((resolve, reject) => {
    getDocs(collection(db, "users", "users", currentStaffYear.toString()))
      .then((res) => {
        resolve(
          res.docs.map((doc) => {
            return { id: doc.id, ...doc.data() } as User;
          })
        );
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getListOfNewUsers = (): Promise<User[]> =>
  new Promise((resolve, reject) => {
    getDocs(collection(db, "users", "users", currentStaffYear.toString()))
      .then((res) => {
        resolve(
          res.docs
            .filter((doc) => doc.id === doc.data().email)
            .map((doc) => {
              return {
                id: doc.id,
                firstName: doc
                  .data()
                  .email.split(".")[0]
                  .charAt(0)
                  .toUpperCase()
                  .concat(doc.data().email.split(".")[0].slice(1)),
                lastName: doc
                  .data()
                  .email?.split(".")[1]
                  .split("@")[0]
                  .charAt(0)
                  .toUpperCase()
                  .concat(doc.data().email?.split(".")[1].split("@")[0].slice(1)),
                ...doc.data(),
              } as User;
            })
        );
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getListOfActiveUsersByStaffYear = async (year: number): Promise<User[]> => {
  return (await getListOfUsersByStaffYear(year)).filter((user) => user.email !== user.id);
};

export const getListOfActiveUsers = async (): Promise<User[]> => {
  return (await getListOfUsers()).filter((user) => user.email !== user.id);
};

export const getUsersByDepartment = async (department: string) =>
  (
    await getDocs(
      query(collection(db, "users", "users", currentStaffYear.toString()), where("department", "==", department))
    )
  ).docs.map((doc) => ({ id: doc.id, ...doc.data() } as User));

export const getListOfUsersByIds = (userIds: string[], year: string): Promise<User[]> =>
  new Promise((resolve, reject) => {
    if (!userIds || userIds.length === 0) {
      resolve([]);
    }
    getDocs(query(collection(db, "users", "users", year), where(documentId(), "in", userIds)))
      .then((res) => {
        resolve(
          res.docs
            .filter((doc) => doc.id !== doc.data().email)
            .map((doc) => {
              return { id: doc.id, ...doc.data() } as User;
            })
        );
      })
      .catch((error) => {
        reject(error);
      });
  });

const getServiceHistoryYear = (year: number, id: string): Promise<ServiceHistoryEntry> =>
  new Promise((resolve, reject) => {
    getDoc(doc(db, "users", "users", currentStaffYear.toString(), id, "serviceHistory", year.toString()))
      .then((user) => {
        if (user.exists()) {
          resolve({
            year: year,
            user: { id: user.id, ...user.data() } as User,
          });
        } else {
          resolve({ year: year, user: undefined });
        }
      })
      .catch((err) => resolve({ year: year, user: undefined }));
  });

const getServiceHistoryFromUserDataYear = async (year: number, id: string): Promise<ServiceHistoryEntry> =>
  new Promise((resolve, reject) =>
    getUserByIdYear(id, year.toString())
      .then((user) => resolve({ year, user }))
      .catch((error) => {
        reject(error);
      })
  );

export const getServiceHistory = (id: string): Promise<ServiceHistory> =>
  new Promise(async (resolve, reject) => {
    let y = currentStaffYear;
    let user = (await getServiceHistoryFromUserDataYear(y, id)).user;
    while (user) {
      --y;
      try {
        user = (await getServiceHistoryFromUserDataYear(y, id)).user;
      } catch {
        break;
      }
    }
    ++y;

    const pastYearUsersUntilYearPromise = pastSOWYearsUntilYear(y).map((y) => {
      return getServiceHistoryYear(y, id);
    });

    const pastYearUsersFromYearPromise = pastStaffYearsSinceYear(y).map((y) => {
      return getServiceHistoryFromUserDataYear(y, id);
    });

    Promise.all(pastYearUsersUntilYearPromise.concat(pastYearUsersFromYearPromise))
      .then((list) => {
        resolve({
          yearJoinedShed: y,
          history: list.reduce((a, v) => ({ ...a, [v.year]: v.user }), {}),
        });
      })
      .catch((error) => {
        reject(error);
      });
  });

export const saveServiceHistory = (
  currentUser: User,
  roles: PastRoles,
  serviceHistory: ServiceHistory
): Promise<string> =>
  new Promise(async (resolve, reject) => {
    Object.entries(serviceHistory.history).forEach(async ([year, user]) => {
      if (user && Number(year) < serviceHistory.yearJoinedShed) {
        if (atLeast(roles[year], STAFF, user.role)) {
          if (Number(year) >= departmentsStartYear) {
            await setDoc(
              doc(db, "users", "users", currentStaffYear.toString(), currentUser.id, "serviceHistory", year.toString()),
              {
                role: user.role,
                department: (user as Staff).department,
              }
            ).catch((error) => {
              reject(error);
            });
          } else {
            await setDoc(
              doc(db, "users", "users", currentStaffYear.toString(), currentUser.id, "serviceHistory", year.toString()),
              {
                role: user.role,
              }
            ).catch((error) => {
              reject(error);
            });
          }
        } else {
          await setDoc(
            doc(db, "users", "users", currentStaffYear.toString(), currentUser.id, "serviceHistory", year.toString()),
            {
              role: user.role,
              university: user.university,
            }
          ).catch((error) => {
            reject(error);
          });
        }
      }
    });
    resolve("Updated Service History");
  });

export const getNextStaffYearUsers = (): Promise<User[]> =>
  new Promise((resolve, reject) => {
    getDocs(collection(db, "users", "users", nextStaffYear.toString()))
      .then((users) => resolve(users.docs.map((u) => ({ id: u.id, ...u.data() } as User))))
      .catch((error) => reject(error));
  });

export const pastStaffYearsSinceYearJoinedUntilYear = (yearJoined: number, yearJoinedShed: number) =>
  staffYearsSinceStartYear().filter((year) => year >= yearJoined && year < yearJoinedShed);
export const deleteServiceHistoryBeforeYear = async (
  year: string,
  userID: string,
  serviceHistory: ServiceHistory | undefined
) => {
  const serviceHistoryDocs = await getDocs(
    collection(db, "users", "users", currentStaffYear.toString(), userID, "serviceHistory")
  );
  for (const d of serviceHistoryDocs.docs) {
    if (Number(d.id) < Number(year)) {
      await deleteDoc(doc(db, "users", "users", currentStaffYear.toString(), userID, "serviceHistory", d.id));
    }
  }
  return {
    ...serviceHistory,
    history: Object.fromEntries(
      Object.entries((serviceHistory as ServiceHistory).history).map(([key, val]) =>
        Number(key) >= Number(year) ? [key, val] : [key, undefined]
      )
    ),
  } as ServiceHistory;
};
