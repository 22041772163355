import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Form, FormikProps } from "formik";
import { HEAD_OF_DIVISION, STAFF } from "../../data/Roles";
import { universities } from "../../data/University";
import { atLeast, studentLeader } from "../../helpers/Role";
import { DepartmentInterface } from "../../models/Department";
import { Divisions } from "../../models/Division";
import { HeadOfDivision, Staff } from "../../models/Staff";

export const AddUsersForm = ({
  roles,
  departments,
  divisions,
  props,
  handleClose,
}: {
  roles: string[];
  departments: DepartmentInterface;
  divisions: Divisions;
  props: FormikProps<Staff & HeadOfDivision>;
  handleClose: () => void;
}) => {
  const theme = useTheme();

  const { values, errors, touched, handleBlur, handleChange, setFieldValue, isSubmitting } = props;

  return (
    <Box
      sx={{
        width: "100%", // Fix IE 11 issue.
        mt: theme.spacing(3),
      }}
    >
      <Form>
        <DialogTitle id="form-dialog-title">Add User</DialogTitle>
        <DialogContent>
          <Typography variant="body2">The email field will fill according to the first and last name.</Typography>
          <TextField
            label="First Name"
            required
            fullWidth
            id="firstname"
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              const e = event.target.value;
              setFieldValue(
                "email",
                e.toLowerCase().trim().replace(/\s+/g, "") +
                  "." +
                  (document.getElementById("lastname") as HTMLInputElement).value
                    .toLowerCase()
                    .trim()
                    .replace(/\s+/g, ""),
                true
              );
            }}
            onBlur={handleBlur}
            name="firstname"
            variant="standard"
          />
          <TextField
            label="Last Name"
            required
            fullWidth
            id="lastname"
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              const e = event.target.value;
              setFieldValue(
                "email",
                (document.getElementById("firstname") as HTMLInputElement).value
                  .toLowerCase()
                  .trim()
                  .replace(/\s+/g, "") +
                  "." +
                  e.toLowerCase().trim().replace(/\s+/g, ""),
                true
              );
            }}
            onBlur={handleBlur}
            name="lastname"
            variant="standard"
          />
          <TextField
            label="Email"
            required
            fullWidth
            id="email"
            onBlur={handleBlur}
            name="email"
            helperText={errors.email && touched.email ? errors.email : ""}
            value={values.email}
            error={errors.email && touched.email ? true : false}
            variant="standard"
            InputProps={{
              readOnly: true, // read only to prevent user from set editing email address
              disabled: true,
              endAdornment: <InputAdornment position="end">@sowaustralia.com</InputAdornment>,
            }}
          />
          <InputLabel id="role-label" required>
            Select Role
          </InputLabel>
          <Select
            label="Role"
            required
            fullWidth
            labelId="role-label"
            id="role"
            onChange={handleChange}
            onBlur={handleBlur}
            name="role"
            value={values.role}
            error={errors.role && touched.role ? true : false}
            variant="standard"
          >
            {roles.map((role, i) => (
              <MenuItem key={i} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
          {atLeast(roles, STAFF, values.role) && !atLeast(roles, HEAD_OF_DIVISION, values.role) && (
            <>
              <InputLabel id="department-label" required>
                Select Department
              </InputLabel>
              <Select
                label="Department"
                required
                fullWidth
                labelId="department-label"
                id="department"
                onChange={handleChange}
                onBlur={handleBlur}
                name="department"
                value={values.department}
                error={errors.department && touched.department ? true : false}
                variant="standard"
              >
                {Object.keys(departments.departments).map((dep, i) => (
                  <MenuItem key={i} value={dep}>
                    {dep}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          {values.role === HEAD_OF_DIVISION && (
            <>
              <InputLabel id="division-label" required>
                Select Division
              </InputLabel>
              <Select
                label="Division"
                required
                fullWidth
                labelId="division-label"
                id="division"
                onChange={handleChange}
                onBlur={handleBlur}
                name="division"
                value={values.division}
                error={errors.division && touched.division ? true : false}
                variant="standard"
              >
                {Object.keys(divisions).map((div, i) => (
                  <MenuItem key={i} value={div}>
                    {div}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          {studentLeader(values.role) && (
            <>
              <InputLabel id="university-label" required>
                Select University
              </InputLabel>
              <Select
                label="University"
                required
                fullWidth
                labelId="university-label"
                id="university"
                onChange={handleChange}
                onBlur={handleBlur}
                name="university"
                value={values.university}
                error={errors.university && touched.university ? true : false}
                variant="standard"
              >
                {universities.map((uni, i) => (
                  <MenuItem key={i} value={uni}>
                    {uni}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </DialogContent>
        {!isSubmitting ? (
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Add
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <CircularProgress color="secondary" />
          </DialogActions>
        )}
      </Form>
    </Box>
  );
};
