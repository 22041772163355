import * as yup from "yup";
import { Dialog } from "@mui/material";
import { PromotionRequestDeclined } from "../../models/PromotionRequestDeclined";
import { PromotionRequest } from "../../models/PromotionRequest";
import { Formik, FormikProps } from "formik";
import { PromotionRequestsDeclineForm } from "./PromotionRequestsDeclineForm";

const PromotionRequestsDeclineFormValidationSchema = yup.object().shape({
  reason: yup.string(),
});

export const PromotionRequestsDeclineConfirmationDialog = ({
  currRequest,
  dialogOpen,
  closeDialog,
  declineRequest,
}: {
  currRequest: PromotionRequest;
  dialogOpen: boolean;
  closeDialog: () => void;
  declineRequest: (request: PromotionRequestDeclined) => void;
}) => (
  <Dialog open={dialogOpen} onClose={closeDialog} aria-describedby="alert-dialog-description">
    <Formik
      initialValues={{
        ...currRequest,
        reason: "",
      }}
      onSubmit={(values: PromotionRequestDeclined) => declineRequest(values)}
      validationSchema={PromotionRequestsDeclineFormValidationSchema}
    >
      {(props: FormikProps<PromotionRequestDeclined>) => {
        return <PromotionRequestsDeclineForm props={props} handleClose={closeDialog} />;
      }}
    </Formik>
  </Dialog>
);
