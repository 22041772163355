import { Box, Step, Stepper, TableCell, TableRow, Typography } from "@mui/material";
import { formatDate, formatDateTime, formatTimeAMPM } from "../../helpers/FormatDate";
import { statusColours } from "../../services/eventForm";
import { EventStepLabel } from "./EventStep";
import { Staff } from "../../models/Staff";
import { TypographyBold } from "../../components/TypographyBold";
import { EventFormModel, SubFormStatus, SubFormType } from "../../models/EventFormModel";
import { DATA_AND_IT, EVENTS, RISK } from "../../data/Departments";
import { DepartmentInterface } from "../../models/Department";
import { HEAD_OF_DIVISION } from "../../data/Roles";
import { atLeast } from "../../helpers/Role";
import { useContext } from "react";
import { RolesContext } from "../../components/PrivateRoute";

export const EventTableRow = ({
  openEventFormDialog,
  eventForm,
  navigateToStageForm,
  year,
  user,
  departments,
}: {
  openEventFormDialog: (selectedEventForm: EventFormModel) => void;
  eventForm: EventFormModel;
  navigateToStageForm: (id: string, formType: string, year: string) => void;
  year: string;
  user: Staff;
  departments: DepartmentInterface;
}) => {
  const roles = useContext(RolesContext);
  const submitteeIsMe = eventForm.submittee.id === user.id;

  const submitteeSameDepartment = (eventForm.submittee as Staff).department === user.department;

  const departmentToSubFormType = {
    [departments.MARKETING]: SubFormType.MARKETING,
    [departments.FINANCE]: SubFormType.FINANCE,
    [RISK]: SubFormType.RISK,
    [DATA_AND_IT]: SubFormType.REGISTRATION,
  };

  const myType = departmentToSubFormType[user.department];

  const oneDayEvent = formatDate(eventForm.eventStartTime) === formatDate(eventForm.eventEndTime);

  return (
    <>
      {
        <TableRow hover role="checkbox" tabIndex={-1} key={eventForm.id}>
          <TableCell onClick={() => openEventFormDialog(eventForm)} sx={{ cursor: "pointer" }}>
            <TypographyBold variant="h6">{eventForm.name}</TypographyBold>
            <Box ml={2}>
              <TypographyBold variant="button">
                by {eventForm.submittee.firstName} {eventForm.submittee.lastName} (
                {(eventForm.submittee as Staff).department}) - Submitted: {formatDateTime(eventForm.submittedTime)}
              </TypographyBold>
              <Typography>
                on {formatDateTime(eventForm.eventStartTime)} -{" "}
                {oneDayEvent ? formatTimeAMPM(eventForm.eventEndTime) : formatDateTime(eventForm.eventEndTime)}
              </Typography>
              <Typography>at {eventForm.location}</Typography>
            </Box>
          </TableCell>
          <TableCell>
            <Box>
              <Stepper nonLinear>
                {(submitteeIsMe ||
                  submitteeSameDepartment ||
                  myType === SubFormType.MARKETING ||
                  atLeast(roles, HEAD_OF_DIVISION, user.role) ||
                  user.department === EVENTS ||
                  user.department === DATA_AND_IT) && (
                  <Step completed={eventForm.marketingStatus === SubFormStatus.APPROVED}>
                    <EventStepLabel
                      status={eventForm.marketingStatus}
                      isReviewer={!submitteeIsMe}
                      onClick={() => navigateToStageForm(eventForm.id, SubFormType.MARKETING, year)}
                    >
                      <Typography
                        sx={{ color: eventForm.marketingStatus ? statusColours[eventForm.marketingStatus] : "gray" }}
                      >
                        {SubFormType.MARKETING.charAt(0).toUpperCase() + SubFormType.MARKETING.slice(1)}
                      </Typography>
                    </EventStepLabel>
                  </Step>
                )}
                {(submitteeIsMe ||
                  submitteeSameDepartment ||
                  myType === SubFormType.FINANCE ||
                  atLeast(roles, HEAD_OF_DIVISION, user.role) ||
                  user.department === EVENTS ||
                  user.department === DATA_AND_IT) && (
                  <Step completed={eventForm.financeStatus === SubFormStatus.APPROVED}>
                    <EventStepLabel
                      status={eventForm.financeStatus}
                      isReviewer={!submitteeIsMe}
                      onClick={() => navigateToStageForm(eventForm.id, SubFormType.FINANCE, year)}
                    >
                      <Typography
                        sx={{ color: eventForm.financeStatus ? statusColours[eventForm.financeStatus] : "gray" }}
                      >
                        {SubFormType.FINANCE.charAt(0).toUpperCase() + SubFormType.FINANCE.slice(1)}
                      </Typography>
                    </EventStepLabel>
                  </Step>
                )}
                {(submitteeIsMe ||
                  submitteeSameDepartment ||
                  myType === SubFormType.RISK ||
                  atLeast(roles, HEAD_OF_DIVISION, user.role) ||
                  user.department === EVENTS ||
                  user.department === DATA_AND_IT) && (
                  <Step completed={eventForm.riskStatus === SubFormStatus.APPROVED}>
                    <EventStepLabel
                      status={eventForm.riskStatus}
                      isReviewer={!submitteeIsMe}
                      onClick={() => navigateToStageForm(eventForm.id, SubFormType.RISK, year)}
                    >
                      <Typography sx={{ color: eventForm.riskStatus ? statusColours[eventForm.riskStatus] : "gray" }}>
                        {SubFormType.RISK.charAt(0).toUpperCase() + SubFormType.RISK.slice(1)}
                      </Typography>
                    </EventStepLabel>
                  </Step>
                )}
                {(submitteeIsMe ||
                  submitteeSameDepartment ||
                  myType === SubFormType.REGISTRATION ||
                  atLeast(roles, HEAD_OF_DIVISION, user.role) ||
                  user.department === EVENTS ||
                  user.department === DATA_AND_IT) && (
                  <Step completed={eventForm.registrationStatus === SubFormStatus.APPROVED}>
                    <EventStepLabel
                      status={eventForm.registrationStatus}
                      isReviewer={!submitteeIsMe}
                      onClick={() => navigateToStageForm(eventForm.id, SubFormType.REGISTRATION, year)}
                    >
                      <Typography
                        sx={{
                          color: eventForm.registrationStatus ? statusColours[eventForm.registrationStatus] : "gray",
                        }}
                      >
                        {SubFormType.REGISTRATION.charAt(0).toUpperCase() + SubFormType.REGISTRATION.slice(1)}
                      </Typography>
                    </EventStepLabel>
                  </Step>
                )}
              </Stepper>
            </Box>
          </TableCell>
        </TableRow>
      }
    </>
  );
};
