import { useContext, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { MessageState } from "../../models/MessageState";
import { resetPassword } from "../../services/auth";
import { Path } from "../../helpers/Path";
import { Container, Typography, TextField, Grid, Snackbar, Link, Button } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Alert } from "@mui/material";
import { UserContext } from "../../components/PrivateRoute";

const resetPasswordFormValidationSchema = yup.object().shape({
  password: yup
    .string()
    .matches(/^(?=.*[A-Za-z])(?=.*[0-9!@#$%^&*()]).{7,20}\S$/)
    .required(
      "Please enter a valid password. One alpabetic, one special character or number, length between 8 to 20 characters"
    ),
  confirmPassword: yup
    .string()
    .required("Required")
    .test("password-match", "Password must match", function (value) {
      return this.parent.password === value;
    }),
});

export const ResetPassword = () => {
  const user = useContext(UserContext);
  const theme = useTheme();
  const [state, setState] = useState<MessageState>({
    showMessage: false,
    message: "",
    severity: "error",
  });

  const handleMessageClose = () => {
    setState({ ...state, showMessage: false });
  };

  const { showMessage, message, severity } = state;

  return (
    <>
      <Container component="main" maxWidth="sm">
        <div>
          <Snackbar open={showMessage} onClose={handleMessageClose} autoHideDuration={6000}>
            <Alert elevation={6} variant="filled" severity={severity}>
              {message}
            </Alert>
          </Snackbar>
          <Container maxWidth="md">
            <Typography variant="h4" align="center" gutterBottom>
              Reset Password
            </Typography>
            <Typography align="center" gutterBottom>
              You can reset your password here
            </Typography>
          </Container>
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            onSubmit={(values) => {
              resetPassword(values.password)
                .then((success) => {
                  setState({
                    severity: "success",
                    message: success,
                    showMessage: true,
                  });
                })
                .catch((error) => {
                  setState({
                    severity: "error",
                    message: error.message,
                    showMessage: true,
                  });
                });
            }}
            validationSchema={resetPasswordFormValidationSchema}
          >
            {(props) => {
              const { values, errors, touched, handleBlur, handleChange } = props;
              return (
                <Form>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    helperText={errors.password && touched.password ? errors.password : ""}
                    value={values.password}
                    error={errors.password && touched.password ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="current-password"
                    variant="standard"
                  />
                  <TextField
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    value={values.confirmPassword}
                    type="password"
                    helperText={
                      errors.confirmPassword && touched.confirmPassword
                        ? errors.confirmPassword
                        : "Re-enter password to confirm"
                    }
                    error={errors.confirmPassword && touched.confirmPassword ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="confirmPassword"
                    autoComplete="new-password"
                    variant="standard"
                  />
                  <Grid
                    container
                    sx={{
                      margin: theme.spacing(3, 0, 2),
                    }}
                  >
                    <Button fullWidth size="large" variant="contained" type="submit">
                      Reset Password
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
        <Grid container justifyContent="center">
          <Grid item>
            <Link
              sx={{
                textDecoration: "none",
                color: "black",
                fontSize: "large",
              }}
              underline="hover"
              component={ReactRouterLink}
              to={`${Path.Profile}/${user.id}`}
            >
              Go back to Profile
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
