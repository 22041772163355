import { Card, CardContent, Typography } from "@mui/material";
import { TypographyBold } from "../../components/TypographyBold";
import { HEAD_OF_DIVISION, STAFF } from "../../data/Roles";
import { atLeast } from "../../helpers/Role";
import { HeadOfDivision, Staff } from "../../models/Staff";
import { User } from "../../models/User";

export const ThisYearsUser = ({ u, roles }: { u: User; roles: string[] }) => (
  <Card>
    <CardContent>
      <TypographyBold gutterBottom>
        {u.firstName} {u.lastName}
      </TypographyBold>
      <Typography gutterBottom>Role: {u.role}</Typography>
      <Typography gutterBottom>
        {atLeast(roles, HEAD_OF_DIVISION, (u as Staff).role)
          ? `Division: ${(u as HeadOfDivision).division}`
          : atLeast(roles, STAFF, (u as Staff).role)
          ? `Department: ${(u as Staff).department}`
          : `University: ${u.university}`}
      </Typography>
    </CardContent>
  </Card>
);
