import { Button, CircularProgress, Container, Grid, TextField, Typography, useTheme } from "@mui/material";
import { signInAnonymously } from "firebase/auth";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Loading } from "../../../components/Loading";
import { MessageSnackbar } from "../../../components/MessageSnackbar";
import { TypographyBold } from "../../../components/TypographyBold";
import { InitReviewsData } from "../../../data/Review360Data";
import { Path } from "../../../helpers/Path";
import { InitMessageState, MessageState } from "../../../models/MessageState";
import { Review, ReviewsData, ReviewStatus } from "../../../models/Review360Model";
import { auth } from "../../../services/firebase";
import { getOtherWithEmail, getReviewsAsReviewer, getReviewsData } from "../../../services/review360";
import { Review360Card } from "./Review360Card";

interface Review360MemberInterface {
  loading: boolean;
  email: string;
  alert: MessageState;
  reviews: Review[];
  reviewsData: ReviewsData;
  loadingReviews: boolean;
}

export const Review360Member = () => {
  const [state, setState] = useState<Review360MemberInterface>({
    reviews: [],
    alert: InitMessageState,
    email: "",
    loadingReviews: false,
    reviewsData: InitReviewsData,
    loading: true,
  });

  const { reviews, email, loadingReviews, reviewsData, alert, loading } = state;

  const history = useHistory();
  const theme = useTheme();

  useEffect(() => {
    if (auth.currentUser) {
      if (!auth.currentUser.isAnonymous) {
        history.push(Path.Dashboard);
      }
    }
    setState({ ...state, loading: false });
    // eslint-disable-next-line
  }, []);

  const loadReviews = (email: string) => {
    setState({ ...state, loadingReviews: true });
    signInAnonymously(auth).then(() =>
      getReviewsData().then((reviewsData) => {
        if (reviewsData.reviewsInfo.status === ReviewStatus.IN_PROGRESS) {
          getReviewsAsReviewer(reviewsData, getOtherWithEmail(email)).then((reviews) => {
            if (reviews.length === 0) {
              auth.currentUser?.delete().then(() =>
                setState({
                  ...state,
                  alert: {
                    showMessage: true,
                    message: "Could not find user",
                    severity: "error",
                  },
                  loadingReviews: false,
                })
              );
            } else {
              setState({
                ...state,
                reviews,
                reviewsData,
                alert: {
                  showMessage: true,
                  message: "Loaded reviews",
                  severity: "success",
                },
                loadingReviews: false,
              });
            }
          });
        } else {
          setState({
            ...state,
            alert: {
              showMessage: true,
              message: "No reviews in progress",
              severity: "info",
            },
            loading: false,
          });
        }
      })
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <MessageSnackbar messageState={alert} setMessageState={(alert) => setState({ ...state, alert })} />
      <Container maxWidth="lg" sx={{ mt: theme.spacing(10) }}>
        <Typography>What is your email address?</Typography>
        <TextField
          id="member's email address"
          required
          fullWidth
          placeholder="Email"
          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            setState({ ...state, email: event.target.value })
          }
          variant="standard"
        />
        <Button variant="contained" disabled={!email} onClick={() => loadReviews(email)}>
          Load reviews
        </Button>
        {loadingReviews ? <CircularProgress /> : <></>}
        {reviews.length > 0 && reviews.every((review) => review.submitted) && (
          <TypographyBold variant="h4">All submitted!</TypographyBold>
        )}
        <Grid container spacing={2}>
          {reviews.map((r, i) => (
            <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
              <Review360Card reviewsData={reviewsData} review={r} email={email} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};
