import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  LinearProgressProps,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { Link as ReactRouterLink, useHistory, useParams } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { DepartmentsContext, UserContext } from "../../components/PrivateRoute";
import { TypographyBold } from "../../components/TypographyBold";
import { MISSIONS } from "../../data/Departments";
import { XeroAPI, tenantId } from "../../data/XeroAPI";
import { formatDate, formatDateComma } from "../../helpers/FormatDate";
import { Path } from "../../helpers/Path";
import { Staff } from "../../models/Staff";
import {
  BankTransactionLine,
  InitMissionsSupportData,
  InitMissionsSupportTeamData,
  MissionsSupportInfo,
  MissionsSupportTeam,
  XeroBankTransactionsMetaData,
  getMissionsSupportInfo,
  getMissionsSupportTeams,
} from "../../services/missionsSupport";
import { round2 } from "../../services/review360";
import { SetAuthenticated, get, getToken, isXeroAuthenticated } from "../../services/xero";

interface MissionsSponsorsInterface {
  loading: boolean;
  bankTransactions: BankTransactionLine[];
  generalTotalAmount: number;
  info: MissionsSupportInfo;
  teams: MissionsSupportTeam[];
}

export const MissionsSupport = () => {
  const { id } = useParams<{ id: string }>();

  const user = useContext(UserContext);
  const departments = useContext(DepartmentsContext);
  const [state, setState] = useState<MissionsSponsorsInterface>({
    loading: true,
    bankTransactions: [],
    generalTotalAmount: 0,
    info: InitMissionsSupportData,
    teams: [InitMissionsSupportTeamData],
  });

  const history = useHistory();

  const { loading, bankTransactions, generalTotalAmount, teams, info } = state;

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    if (!isXeroAuthenticated()) {
      getToken(
        "openid profile email accounting.transactions.read accounting.settings.read accounting.contacts.read accounting.reports.read offline_access"
      ).then((token) => {
        SetAuthenticated(token);
        getSponsors();
      });
    } else {
      getSponsors();
    }
    // eslint-disable-next-line
  }, [id]);

  const iAmGoingOnMissions = (teams: MissionsSupportTeam[]) =>
    teams.some((t) => t.members.map((m) => m.id).includes(user.id) || t.leader?.id === user.id);

  const myTeam = (teams: MissionsSupportTeam[]) =>
    teams.find((t) => t.members.map((m) => m.id).includes(user.id) || t.leader?.id === user.id);

  const iAmTheLeaderOfTheirTeam = (teams: MissionsSupportTeam[]) =>
    teams.find((t) => t.members.map((m) => m.id).includes(id))?.leader?.id === user.id;

  const iAmALeader = Boolean(teams.find((t) => t.leader?.id === user.id)).valueOf();
  const myTeamAsALeader = teams.find((t) => t.leader?.id === user.id);
  const getTransactions = (info: MissionsSupportInfo, teams: MissionsSupportTeam[]) =>
    Promise.all([
      get(XeroAPI.BankTransactions, tenantId, {
        where:
          'TYPE=="RECEIVE"' +
          "&&" +
          'BankAccount.AccountID==GUID("502947c7-7c08-48de-9aeb-0dad74ccb431")' +
          "&&" +
          `Date >= DateTime(${formatDateComma(info.supportStartDate)})` +
          "&&" +
          `Date <= DateTime(${formatDateComma(new Date(info.supportStartDate.getFullYear(), 11, 31))})` +
          "&&" +
          `LineItems[0].Description.Contains("${teams.flatMap((d) => d.members).find((m) => m.id === id)?.firstName} ${
            teams.flatMap((d) => d.members).find((m) => m.id === id)?.lastName
          }")`,
        page: 1,
      }),
      get(XeroAPI.BankTransactions, tenantId, {
        where:
          'TYPE=="RECEIVE"' +
          "&&" +
          'BankAccount.AccountID==GUID("502947c7-7c08-48de-9aeb-0dad74ccb431")' +
          "&&" +
          `Date >= DateTime(${formatDateComma(info.supportStartDate)})` +
          "&&" +
          `Date <= DateTime(${formatDateComma(new Date(info.supportStartDate.getFullYear(), 11, 31))})` +
          "&&" +
          `LineItems[0].Description.Contains("eneral")`,
        page: 1,
      }),
    ]);

  // if (id is me and I amGoingToMissions) or (id is other and They areGoingToMissions and I am a leader of their team or finance/missions)
  // xero
  // if (id is me and I am in finance/missions)
  // no xero stuff but continue
  // else
  // push to dashboard

  const getSponsors = () =>
    getMissionsSupportInfo().then((info) =>
      info.goal && info.goal !== 0
        ? getMissionsSupportTeams().then((teams) =>
            (id === user.id && iAmGoingOnMissions(teams)) ||
            (id !== user.id &&
              (iAmTheLeaderOfTheirTeam(teams) ||
                (user as Staff).department === departments.FINANCE ||
                (user as Staff).department === MISSIONS))
              ? getTransactions(info, teams).then(
                  ([bankTransactions, generalBankTransactions]: XeroBankTransactionsMetaData[]) =>
                    setState({
                      ...state,
                      bankTransactions: bankTransactions.BankTransactions.map((t) => ({
                        id: t.BankTransactionID,
                        date: formatDate(new Date(t.DateString)),
                        name: t.Contact.Name,
                        description: t.LineItems[0].Description,
                        amount: t.LineItems[0].LineAmount,
                      })),
                      generalTotalAmount: generalBankTransactions.BankTransactions.reduce(
                        (acc, el) => acc + el.LineItems[0].LineAmount,
                        0
                      ),
                      teams,
                      info,
                      loading: false,
                    })
                )
              : id === user.id &&
                ((user as Staff).department === departments.FINANCE || (user as Staff).department === MISSIONS)
              ? setState({
                  ...state,
                  teams,
                  info,
                  loading: false,
                })
              : history.push(Path.Dashboard)
          )
        : setState({
            ...state,
            info,
            loading: false,
          })
    );

  const bankTransactionColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      maxWidth: 30,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 100,
      maxWidth: 120,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 100,
      maxWidth: 150,
      renderCell: (params: GridRenderCellParams<number>) => <TypographyBold>${params.value}</TypographyBold>,
    },
  ];

  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const totalGoingToMissions = teams.flatMap((t) => t.members).length + teams.filter((t) => t.leader).length;

  const totalIndividualAmount = round2(bankTransactions.reduce((acc, el) => acc + el.amount, 0));
  const generalAmount = round2(generalTotalAmount / totalGoingToMissions);

  const theme = useTheme();

  return loading ? (
    <Loading />
  ) : (
    <Container maxWidth="lg">
      {iAmALeader && myTeamAsALeader && (
        <Box>
          <TypographyBold variant="h5" sx={{ mt: theme.spacing(2) }}>
            {myTeamAsALeader.country}
          </TypographyBold>
          <TypographyBold variant="h6">Your member's Support</TypographyBold>
          <Grid container direction="column" sx={{ mb: theme.spacing(4) }}>
            {myTeamAsALeader.members.map((member, i) => (
              <Grid item key={i}>
                <Link
                  sx={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "large",
                  }}
                  underline="hover"
                  component={ReactRouterLink}
                  to={`${Path["Missions Support"]}/${member.id}`}
                >
                  {member.firstName} {member.lastName}'s Missions Support
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {((user as Staff).department === departments.FINANCE || (user as Staff).department === MISSIONS) &&
        teams.length > 0 && (
          <Box>
            <TypographyBold variant="h6">All members' Support</TypographyBold>
            {teams.map((team, i) => (
              <Box key={i}>
                <TypographyBold variant="h5" sx={{ mt: theme.spacing(2) }}>
                  {team.country}
                </TypographyBold>
                {team.leader && (
                  <Link
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      fontSize: "large",
                    }}
                    underline="hover"
                    component={ReactRouterLink}
                    to={`${Path["Missions Support"]}/${team.leader?.id}`}
                  >
                    {team.leader?.firstName} {team.leader?.lastName}'s Missions Support
                  </Link>
                )}
                <Grid container direction="column" sx={{ mb: theme.spacing(4) }}>
                  {team.members.map((member, j) => (
                    <Grid item key={j}>
                      <Link
                        sx={{
                          textDecoration: "none",
                          color: "black",
                          fontSize: "large",
                        }}
                        underline="hover"
                        component={ReactRouterLink}
                        to={`${Path["Missions Support"]}/${member.id}`}
                      >
                        {member.firstName} {member.lastName}'s Missions Support
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}
          </Box>
        )}
      {(user as Staff).department === MISSIONS && (
        <Grid container justifyContent="flex-end">
          <Box pb={7}>
            <Button onClick={() => history.push(Path["Edit Missions Data"])} variant="contained" size="medium">
              Edit Missions Data
            </Button>
          </Box>
        </Grid>
      )}
      {iAmGoingOnMissions(teams) && (
        <>
          <TypographyBold>{myTeam(teams)?.country} Missions</TypographyBold>
          <DataGrid autoHeight rows={bankTransactions} columns={bankTransactionColumns} />
          <TypographyBold variant="h6">Current Support: ${totalIndividualAmount}</TypographyBold>
          <TypographyBold variant="h6">General: ${generalAmount}</TypographyBold>
          <Grid container justifyContent="space-between">
            <TypographyBold variant="h6">Total: ${round2(totalIndividualAmount + generalAmount)}</TypographyBold>
            <TypographyBold variant="h6">Goal: ${info.goal}</TypographyBold>
          </Grid>
          <LinearProgressWithLabel value={((totalIndividualAmount + generalAmount) / info.goal) * 100} />
        </>
      )}
    </Container>
  );
};
