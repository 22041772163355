import { useContext, useState, useEffect } from "react";
import {
  Alert,
  Button,
  Container,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { UserContext } from "../../components/PrivateRoute";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { InitMessageState, MessageState } from "../../models/MessageState";
import { submitFeedbackForm } from "../../services/feedbackForm";
import { FeedbackType } from "../../data/FeedbackType";
import { getDirector } from "../../services/users";
import { User } from "../../models/User";
import { currentStaffYear } from "../../helpers/FormatDate";

interface FeedbackInterface {
  messageState: MessageState;
  loading: boolean;
  director?: User;
}

const feedbackFormValidationSchema = yup.object().shape({
  type: yup.string().required("Choose your type"),
  content: yup.string().required("Enter your content"),
});

export const ContactUs = () => {
  const user = useContext(UserContext);
  const [state, setState] = useState<FeedbackInterface>({
    messageState: InitMessageState,
    loading: false,
  });

  useEffect(() => {
    setState({ ...state, loading: true });
    const directorFetch = getDirector(currentStaffYear.toString());

    Promise.all([directorFetch]).then(([director]) => {
      setState({
        ...state,
        director,
        loading: false,
      });
    });
    // eslint-disable-next-line
  }, []);

  const { messageState, director } = state;

  return (
    <Container maxWidth="sm">
      <Snackbar
        open={messageState.showMessage}
        onClose={() =>
          setState({
            ...state,
            messageState: { ...messageState, showMessage: false },
          })
        }
        autoHideDuration={6000}
      >
        <Alert elevation={6} variant="filled" severity={messageState.severity}>
          {messageState.message}
        </Alert>
      </Snackbar>
      <Typography variant="h5">General</Typography>
      <Typography gutterBottom>
        Director: <Link href={`mailto:${director?.email}`}>{director?.email}</Link>
      </Typography>
      <Typography variant="h5">Message us</Typography>
      <Formik
        initialValues={{
          type: "",
          content: "",
        }}
        onSubmit={(values, { resetForm }) => {
          submitFeedbackForm(values, user.id)
            .then(() =>
              setState({
                ...state,
                messageState: {
                  showMessage: true,
                  message: "Successfully submitted feedback",
                  severity: "success",
                },
              })
            )
            .catch((error) =>
              setState({
                ...state,
                messageState: {
                  showMessage: true,
                  message: error.message,
                  severity: "error",
                },
              })
            )
            .finally(() => resetForm());
        }}
        validationSchema={feedbackFormValidationSchema}
      >
        {(props) => {
          const { values, errors, touched, handleBlur, handleChange } = props;
          return (
            <Form>
              <InputLabel id="type-label" required>
                Select Type
              </InputLabel>
              <Select
                label="Type"
                required
                fullWidth
                labelId="type-label"
                id="type"
                onChange={handleChange}
                onBlur={handleBlur}
                name="type"
                value={values.type}
                error={errors.type && touched.type ? true : false}
                variant="standard"
              >
                {Object.values(FeedbackType).map((type, i) => (
                  <MenuItem key={i} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                margin="normal"
                required
                fullWidth
                id="content"
                label="Content"
                name="content"
                autoComplete="content"
                multiline
                minRows={4}
                maxRows={6}
                helperText={errors.content && touched.content ? errors.content : ""}
                value={values.content}
                error={errors.content && touched.content ? true : false}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Button fullWidth size="large" variant="contained" type="submit">
                Submit
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
