import * as yup from "yup";
import { Dialog } from "@mui/material";
import { Formik, FormikProps } from "formik";
import { Reimbursement, ReimbursementSubmit } from "../../models/Reimbursement";
import { Request } from "../../models/Request";
import { MessageState } from "../../models/MessageState";
import { ReimbursementForm } from "./ReimbursementForm";
import { submitReceipt } from "../../services/reimbursements";

const ReimbursementsFormValidationSchema = yup.object().shape({
  description: yup.string().required("Enter description"),
  totalReceiptAmount: yup
    .string()
    .required("Your total amount is not a valid input")
    .matches(/^[1-9]{1}[0-9]*(\.\d{1,2})?$/, "Must be a valid amount"),
  recipients: yup.array().of(
    yup.object().shape({
      receiptAmount: yup
        .string()
        .required("Please enter the actual amount")
        .matches(/^[1-9]{1}[0-9]*(\.\d{1,2})?$/, "Must be a valid amount"),
      files: yup.array().of(yup.string().required("Please upload a receipt")).required(),
      bsb: yup
        .string()
        .matches(/^[0-9]*$/, "Must be a valid bsb")
        .required("Please enter bsb"),
      accountNumber: yup
        .string()
        .matches(/^[0-9]*$/, "Must be a valid account number")
        .required("Please enter account number"),
      accountName: yup.string().required("Please enter account name"),
    })
  ),
});

export const ReimbursementsSubmit = ({
  currRequest,
  dialogOpen,
  closeDialog,
  handlePost,
}: {
  currRequest: Request;
  dialogOpen: boolean;
  closeDialog: () => void;
  handlePost: (m: MessageState, r: Reimbursement | null) => void;
}) =>
  currRequest && (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={dialogOpen}
      onClose={closeDialog}
      aria-describedby="alert-dialog-description"
    >
      <Formik
        initialValues={{
          ...currRequest,
          recipients: [
            {
              receiptAmount: "",
              accountName: "",
              bsb: "",
              accountNumber: "",
              receiptURLs: [],
              files: [],
            },
          ],
          totalReceiptAmount: "0",
          paid: false,
        }}
        onSubmit={(values: ReimbursementSubmit) =>
          submitReceipt(values)
            .then((r) =>
              handlePost(
                {
                  showMessage: true,
                  message: "Successfully submitted receipt/invoice(s)!",
                  severity: "success",
                },
                r
              )
            )
            .catch((error) => {
              handlePost(
                {
                  showMessage: true,
                  message: error.message,
                  severity: "error",
                },
                null
              );
            })
        }
        validationSchema={ReimbursementsFormValidationSchema}
      >
        {(props: FormikProps<ReimbursementSubmit>) => {
          return <ReimbursementForm props={props} handleClose={closeDialog} />;
        }}
      </Formik>
    </Dialog>
  );
