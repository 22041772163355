import { SyntheticEvent, useContext, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { ModuleListModel } from "../../models/ModuleListModel";
import { ModuleCard } from "./ModuleCard";
import { UserContext } from "../../components/PrivateRoute";
import { ModuleMonthsThemes, moduleMonthsThemes } from "../../services/modules";
import MuiAccordion from "@mui/material/Accordion";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import MuiAccordionSummary from "@mui/material/AccordionSummary";

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    borderRadius: 20,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
      borderLeft: "2px solid rgba(0, 0, 0, .125)",
      borderRight: "2px solid rgba(0, 0, 0, .125)",
      borderBottom: "2px solid rgba(0, 0, 0, .125)",
      borderRadius: 20,
      marginBottom: 30,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    border: "2px solid rgba(0, 0, 0, .125)",
    borderRadius: 20,
    backgroundColor: "white",
    "&$expanded": {
      minHeight: 40,
      borderRight: 0,
      borderLeft: 0,
      borderRadius: 0,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const ModulesList = ({ title, modules }: { title: string; modules: ModuleListModel[] }) => {
  const user = useContext(UserContext);
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = (_: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };
  return modules.length > 0 ? (
    <Grid container direction="column" xs={12}>
      <Grid item>
        <Accordion expanded={expanded} onChange={handleChange}>
          <AccordionSummary expandIcon={<ArrowDropDownCircleIcon color="primary" />} id="resources">
            <Typography align="center" gutterBottom variant="h4">
              {title} - {moduleMonthsThemes[title as keyof ModuleMonthsThemes]}
            </Typography>
          </AccordionSummary>
          {modules.map((m, i) => (
            <ModuleCard module={m} user={user} key={i} last={i === modules.length - 1} />
          ))}
        </Accordion>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};
