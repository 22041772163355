import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";

export const SubFormRequestForChangeDialog = ({
  dialogOpen,
  closeDialog,
  requestForChange,
}: {
  dialogOpen: boolean;
  closeDialog: () => void;
  requestForChange: (reason: string) => void;
}) => (
  <Dialog open={dialogOpen} onClose={closeDialog} aria-describedby="alert-dialog-description">
    <Formik
      initialValues={{
        reason: "",
      }}
      onSubmit={(values: { reason: string }) => requestForChange(values.reason)}
    >
      {(props: FormikProps<{ reason: string }>) => {
        const { values, errors, touched, handleBlur, handleChange, isSubmitting } = props;
        return (
          <Form>
            <DialogTitle id="form-dialog-title">Request for change</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Please submit a reason below. The submittee will be notified.
              </DialogContentText>
              <TextField
                id="reason"
                fullWidth
                required
                placeholder="Reason"
                multiline
                minRows={4}
                maxRows={6}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.reason && touched.reason ? errors.reason : ""}
                value={values.reason}
                error={errors.reason && touched.reason ? true : false}
                variant="standard"
              />
            </DialogContent>
            {!isSubmitting ? (
              <DialogActions>
                <Button onClick={closeDialog} color="primary">
                  Cancel
                </Button>
                <Button variant="contained" type="submit">
                  Request for change
                </Button>
              </DialogActions>
            ) : (
              <CircularProgress color="secondary" />
            )}
          </Form>
        );
      }}
    </Formik>
  </Dialog>
);
