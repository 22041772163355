import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { MarketingSubFormModel } from "../../../models/EventFormModel";

export const SubFormMarketing = ({
  canEdit,
  subForm,
  setSubFormProperty,
  registrationNumbers,
}: {
  canEdit: boolean;
  subForm: MarketingSubFormModel;
  setSubFormProperty: (property: keyof MarketingSubFormModel, value: any) => void;
  registrationNumbers: number;
}) => {
  const theme = useTheme();

  const formControlSx = {
    my: 2,
    display: "flex",
  };

  const SMALL_EVENT = 0;
  const MEDIUM_EVENT = 50;
  const BIG_EVENT = 100;

  const [eventSize, setEventSize] = useState("");

  useEffect(() => {
    if (registrationNumbers > SMALL_EVENT && registrationNumbers <= MEDIUM_EVENT) {
      setEventSize("SMALL");
    } else if (registrationNumbers > MEDIUM_EVENT && registrationNumbers < BIG_EVENT) {
      setEventSize("MEDIUM");
    } else if (registrationNumbers >= BIG_EVENT) {
      setEventSize("BIG");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Typography variant="h4" fontWeight="bold">
        Design
      </Typography>
      <FormControl sx={formControlSx}>
        <FormLabel sx={{ mb: theme.spacing(1) }}>
          What is the key message you would like to send out/for us to reflect?
        </FormLabel>
        <TextField
          size="small"
          disabled={!canEdit}
          label="Key Message"
          onChange={(e) => setSubFormProperty("keyMessage", e.target.value)}
          value={subForm.keyMessage ? subForm.keyMessage : ""}
          multiline
          minRows={4}
          maxRows={6}
          variant="outlined"
          fullWidth
          sx={{ mb: theme.spacing(1) }}
        />
      </FormControl>
      <FormControl sx={formControlSx}>
        <FormLabel sx={{ mb: theme.spacing(1) }}>
          Is there a theme/colour palette/visual preference that you would like us to work with? (Write n/a if you would
          like the design team to decide)
        </FormLabel>
        <TextField
          size="small"
          disabled={!canEdit}
          label="Theme"
          onChange={(e) => setSubFormProperty("theme", e.target.value)}
          value={subForm.theme ? subForm.theme : ""}
          multiline
          minRows={4}
          maxRows={6}
          variant="outlined"
          fullWidth
          sx={{ mb: theme.spacing(1) }}
        />
      </FormControl>
      <FormControl sx={formControlSx}>
        <Typography variant="h5" sx={{ mb: theme.spacing(1) }}>
          What would you like us to design?
        </Typography>
        <Box marginLeft={3}>
          <Typography color="textSecondary" variant="h6" sx={{ mb: theme.spacing(1) }}>
            Printed
          </Typography>
          <Box marginLeft={3}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="Brochures"
                disabled={!canEdit}
                checked={subForm.brochures}
                onChange={() => setSubFormProperty("brochures", !subForm.brochures)}
              />
              {subForm.brochures && (
                <>
                  <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                    ALLTEXT/COPY for designs are required - quantity - DATE each are required
                  </Typography>
                  <TextField
                    size="small"
                    disabled={!canEdit}
                    label="Brochures description/what to include"
                    onChange={(e) => setSubFormProperty("brochuresSpecifications", e.target.value)}
                    value={subForm.brochuresSpecifications ? subForm.brochuresSpecifications : ""}
                    multiline
                    minRows={4}
                    maxRows={6}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: theme.spacing(1) }}
                  />
                </>
              )}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={<Checkbox />}
                  disabled={!canEdit}
                  label="Flyers"
                  checked={subForm.flyers}
                  onChange={() => setSubFormProperty("flyers", !subForm.flyers)}
                />
                {eventSize === "BIG" && <Chip label="Recommended" color="info" size="small" />}
              </Box>
              {subForm.flyers && (
                <>
                  <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                    ALLTEXT/COPY for designs are required - quantity - DATE each are required
                  </Typography>
                  <TextField
                    size="small"
                    disabled={!canEdit}
                    label="Flyers description/what to include"
                    onChange={(e) => setSubFormProperty("flyersSpecifications", e.target.value)}
                    value={subForm.flyersSpecifications ? subForm.flyersSpecifications : ""}
                    multiline
                    minRows={4}
                    maxRows={6}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: theme.spacing(1) }}
                  />
                </>
              )}
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Invitations"
                checked={subForm.invitations}
                onChange={() => setSubFormProperty("invitations", !subForm.invitations)}
              />
              {subForm.invitations && (
                <>
                  <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                    ALLTEXT/COPY for designs are required - quantity - DATE each are required
                  </Typography>
                  <TextField
                    size="small"
                    disabled={!canEdit}
                    label="Invitations description/what to include"
                    onChange={(e) => setSubFormProperty("invitationsSpecifications", e.target.value)}
                    value={subForm.invitationsSpecifications ? subForm.invitationsSpecifications : ""}
                    multiline
                    minRows={4}
                    maxRows={6}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: theme.spacing(1) }}
                  />
                </>
              )}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={<Checkbox />}
                  disabled={!canEdit}
                  label="Signage"
                  checked={subForm.signage}
                  onChange={() => setSubFormProperty("signage", !subForm.signage)}
                />
                {(eventSize === "SMALL" || eventSize === "MEDIUM" || eventSize === "BIG") && (
                  <Chip label="Recommended" color="info" size="small" />
                )}
              </Box>
              {subForm.signage && (
                <>
                  <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                    ALLTEXT/COPY for designs are required - quantity - DATE each are required
                  </Typography>
                  <TextField
                    size="small"
                    disabled={!canEdit}
                    label="Signage description/what to include"
                    onChange={(e) => setSubFormProperty("signageSpecifications", e.target.value)}
                    value={subForm.signageSpecifications ? subForm.signageSpecifications : ""}
                    multiline
                    minRows={4}
                    maxRows={6}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: theme.spacing(1) }}
                  />
                </>
              )}
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Business Card"
                checked={subForm.businessCard}
                onChange={() => setSubFormProperty("businessCard", !subForm.businessCard)}
              />
              {subForm.businessCard && (
                <>
                  <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                    ALLTEXT/COPY for designs are required - quantity - DATE each are required
                  </Typography>
                  <TextField
                    size="small"
                    disabled={!canEdit}
                    label="Business Cards description/what to include"
                    onChange={(e) => setSubFormProperty("businessCardSpecifications", e.target.value)}
                    value={subForm.businessCardSpecifications ? subForm.businessCardSpecifications : ""}
                    multiline
                    minRows={4}
                    maxRows={6}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: theme.spacing(1) }}
                  />
                </>
              )}
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Pamphlet"
                checked={subForm.pamphlet}
                onChange={() => setSubFormProperty("pamphlet", !subForm.pamphlet)}
              />
              {subForm.pamphlet && (
                <>
                  <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                    ALLTEXT/COPY for designs are required - quantity - DATE each are required
                  </Typography>
                  <TextField
                    size="small"
                    disabled={!canEdit}
                    label="Pamphlets description/what to include"
                    onChange={(e) => setSubFormProperty("pamphletSpecifications", e.target.value)}
                    value={subForm.pamphletSpecifications ? subForm.pamphletSpecifications : ""}
                    multiline
                    minRows={4}
                    maxRows={6}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: theme.spacing(1) }}
                  />
                </>
              )}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={<Checkbox />}
                  disabled={!canEdit}
                  label="Poster"
                  checked={subForm.poster}
                  onChange={() => setSubFormProperty("poster", !subForm.poster)}
                />
                {eventSize === "BIG" && <Chip label="Recommended" color="info" size="small" />}
              </Box>
              {subForm.poster && (
                <>
                  <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                    ALLTEXT/COPY for designs are required - quantity - DATE each are required
                  </Typography>
                  <TextField
                    size="small"
                    disabled={!canEdit}
                    label="Posters description/what to include"
                    onChange={(e) => setSubFormProperty("posterSpecifications", e.target.value)}
                    value={subForm.posterSpecifications ? subForm.posterSpecifications : ""}
                    multiline
                    minRows={4}
                    maxRows={6}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: theme.spacing(1) }}
                  />
                </>
              )}
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Postcard"
                checked={subForm.postcard}
                onChange={() => setSubFormProperty("postcard", !subForm.postcard)}
              />
              {subForm.postcard && (
                <>
                  <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                    ALLTEXT/COPY for designs are required - quantity - DATE each are required
                  </Typography>
                  <TextField
                    size="small"
                    disabled={!canEdit}
                    label="Postcards description/what to include"
                    onChange={(e) => setSubFormProperty("postcardSpecifications", e.target.value)}
                    value={subForm.postcardSpecifications ? subForm.postcardSpecifications : ""}
                    multiline
                    minRows={4}
                    maxRows={6}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: theme.spacing(1) }}
                  />
                </>
              )}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={<Checkbox />}
                  disabled={!canEdit}
                  label="Merchandise"
                  checked={subForm.merchandise}
                  onChange={() => setSubFormProperty("merchandise", !subForm.merchandise)}
                />
                {eventSize === "BIG" && <Chip label="Recommended" color="info" size="small" />}
              </Box>
              {subForm.merchandise && (
                <>
                  <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                    ALLTEXT/COPY for designs are required - quantity - DATE each are required
                  </Typography>
                  <TextField
                    size="small"
                    disabled={!canEdit}
                    label="Merchandise description/what to include"
                    onChange={(e) => setSubFormProperty("merchandiseSpecifications", e.target.value)}
                    value={subForm.merchandiseSpecifications ? subForm.merchandiseSpecifications : ""}
                    multiline
                    minRows={4}
                    maxRows={6}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: theme.spacing(1) }}
                  />
                </>
              )}
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Other"
                checked={subForm.otherPrinted}
                onChange={() => setSubFormProperty("otherPrinted", !subForm.otherPrinted)}
              />
            </FormGroup>
            {subForm.otherPrinted && (
              <>
                <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                  Other Designs
                </Typography>
                <TextField
                  size="small"
                  disabled={!canEdit}
                  label="Other Specifications"
                  onChange={(e) => setSubFormProperty("otherPrintedSpecifications", e.target.value)}
                  value={subForm.otherPrintedSpecifications ? subForm.otherPrintedSpecifications : ""}
                  multiline
                  minRows={4}
                  maxRows={6}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: theme.spacing(1) }}
                />
              </>
            )}
          </Box>
        </Box>
        <Box marginLeft={3}>
          <Typography color="textSecondary" variant="h6" sx={{ mb: theme.spacing(1) }}>
            Digital
          </Typography>
          <Box marginLeft={3}>
            <FormGroup>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={<Checkbox />}
                  disabled={!canEdit}
                  label="Logo"
                  checked={subForm.logo}
                  onChange={() => setSubFormProperty("logo", !subForm.logo)}
                />
                {(eventSize === "SMALL" || eventSize === "MEDIUM" || eventSize === "BIG") && (
                  <Chip label="Recommended" color="info" size="small" />
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={<Checkbox />}
                  disabled={!canEdit}
                  label="Powerpoint Presentation"
                  checked={subForm.powerpoint}
                  onChange={() => setSubFormProperty("powerpoint", !subForm.powerpoint)}
                />
                {(eventSize === "SMALL" || eventSize === "MEDIUM" || eventSize === "BIG") && (
                  <Chip label="Recommended" color="info" size="small" />
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={<Checkbox />}
                  disabled={!canEdit}
                  label="Letterhead"
                  checked={subForm.letterhead}
                  onChange={() => setSubFormProperty("letterhead", !subForm.letterhead)}
                />
                {eventSize === "BIG" && <Chip label="Recommended" color="info" size="small" />}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={<Checkbox />}
                  disabled={!canEdit}
                  label="Facebook Event Banner"
                  checked={subForm.facebookEventBanner}
                  onChange={() => setSubFormProperty("facebookEventBanner", !subForm.facebookEventBanner)}
                />
                {(eventSize === "SMALL" || eventSize === "MEDIUM" || eventSize === "BIG") && (
                  <Chip label="Recommended" color="info" size="small" />
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={<Checkbox />}
                  disabled={!canEdit}
                  label="Instagram Post"
                  checked={subForm.instagramPost}
                  onChange={() => setSubFormProperty("instagramPost", !subForm.instagramPost)}
                />
                {(eventSize === "SMALL" || eventSize === "MEDIUM" || eventSize === "BIG") && (
                  <Chip label="Recommended" color="info" size="small" />
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={<Checkbox />}
                  disabled={!canEdit}
                  label="Website Banner"
                  checked={subForm.websiteBanner}
                  onChange={() => setSubFormProperty("websiteBanner", !subForm.websiteBanner)}
                />
                {(eventSize === "SMALL" || eventSize === "MEDIUM" || eventSize === "BIG") && (
                  <Chip label="Recommended" color="info" size="small" />
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={<Checkbox />}
                  disabled={!canEdit}
                  label="Promotion Video"
                  checked={subForm.promoVideo}
                  onChange={() => setSubFormProperty("promoVideo", !subForm.promoVideo)}
                />
                {(eventSize === "MEDIUM" || eventSize === "BIG") && (
                  <Chip label="Recommended" color="info" size="small" />
                )}
              </Box>
              {subForm.promoVideo && (
                <>
                  <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                    Ideas you have and would like us to consider and important info to include in the video e.g. event
                    dates
                  </Typography>
                  <TextField
                    size="small"
                    disabled={!canEdit}
                    label="Promo Video Specifications"
                    onChange={(e) => setSubFormProperty("promoVideoSpecifications", e.target.value)}
                    value={subForm.promoVideoSpecifications ? subForm.promoVideoSpecifications : ""}
                    multiline
                    minRows={4}
                    maxRows={6}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: theme.spacing(1) }}
                  />
                </>
              )}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={<Checkbox />}
                  disabled={!canEdit}
                  label="Recap Video"
                  checked={subForm.recapVideo}
                  onChange={() => setSubFormProperty("recapVideo", !subForm.recapVideo)}
                />
                {eventSize === "BIG" && <Chip label="Recommended" color="info" size="small" />}
              </Box>
              {subForm.recapVideo && (
                <>
                  <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                    Ideas you have and would like us to consider and important info to include in the video e.g. event
                    dates
                  </Typography>
                  <TextField
                    size="small"
                    disabled={!canEdit}
                    label="Recap Video Specifications"
                    onChange={(e) => setSubFormProperty("recapVideoSpecifications", e.target.value)}
                    value={subForm.recapVideoSpecifications ? subForm.recapVideoSpecifications : ""}
                    multiline
                    minRows={4}
                    maxRows={6}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: theme.spacing(1) }}
                  />
                </>
              )}
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Other"
                name="otherDigital"
                value={subForm.otherDigital}
                onChange={() => setSubFormProperty("otherDigital", !subForm.otherDigital)}
              />
              {subForm.otherDigital && (
                <>
                  <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                    Other Specifications
                  </Typography>
                  <TextField
                    size="small"
                    disabled={!canEdit}
                    label="Other Specifications"
                    onChange={(e) => setSubFormProperty("otherDigitalSpecifications", e.target.value)}
                    value={subForm.otherDigitalSpecifications ? subForm.otherDigitalSpecifications : ""}
                    multiline
                    minRows={4}
                    maxRows={6}
                    variant="outlined"
                    fullWidth
                    sx={{ mb: theme.spacing(1) }}
                  />
                </>
              )}
            </FormGroup>
          </Box>
        </Box>
      </FormControl>
      <FormControl sx={formControlSx}>
        <FormLabel>
          Would you like us to run the design by you before finalising? (true/false radio component)
        </FormLabel>
        <Box marginLeft={3}>
          <RadioGroup
            defaultValue="no"
            name="radio-buttons-group"
            onChange={(e) => setSubFormProperty("designNeedsApproval", e.target.value)}
          >
            <FormControlLabel value="Yes" disabled={!canEdit} control={<Radio />} label="Yes" />
            <FormControlLabel value="No" disabled={!canEdit} control={<Radio />} label="No" />
          </RadioGroup>
        </Box>
      </FormControl>
      <br />
      <Typography variant="h4" fontWeight="bold">
        Promotion
      </Typography>
      <FormControl sx={formControlSx}>
        <Typography variant="h5" sx={{ mb: theme.spacing(1) }}>
          What do you require on the day?
        </Typography>
        <Box marginLeft={3}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              disabled={!canEdit}
              label="Photography"
              checked={subForm.photography}
              onChange={() => setSubFormProperty("photography", !subForm.photography)}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Videography"
                checked={subForm.videography}
                onChange={() => setSubFormProperty("videography", !subForm.videography)}
              />
              {eventSize === "BIG" && <Chip label="Recommended" color="info" size="small" />}
            </Box>
            <FormControlLabel
              control={<Checkbox />}
              disabled={!canEdit}
              label="Instagram Stories"
              checked={subForm.instagram}
              onChange={() => setSubFormProperty("instagram", !subForm.instagram)}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Bible Verse"
                checked={subForm.bibleVerse}
                onChange={() => setSubFormProperty("bibleVerse", !subForm.bibleVerse)}
              />
              {(eventSize === "SMALL" || eventSize === "MEDIUM" || eventSize === "BIG") && (
                <Chip label="Recommended" color="info" size="small" />
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Theme/ Purpose"
                checked={subForm.themePurpose}
                onChange={() => setSubFormProperty("themePurpose", !subForm.themePurpose)}
              />
              {(eventSize === "SMALL" || eventSize === "MEDIUM" || eventSize === "BIG") && (
                <Chip label="Recommended" color="info" size="small" />
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Save The Date"
                checked={subForm.saveTheDate}
                onChange={() => setSubFormProperty("saveTheDate", !subForm.saveTheDate)}
              />
              {(eventSize === "SMALL" || eventSize === "MEDIUM" || eventSize === "BIG") && (
                <Chip label="Recommended" color="info" size="small" />
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Throwback Photos"
                checked={subForm.throwbackPhotos}
                onChange={() => setSubFormProperty("throwbackPhotos", !subForm.throwbackPhotos)}
              />
              {(eventSize === "MEDIUM" || eventSize === "BIG") && (
                <Chip label="Recommended" color="info" size="small" />
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Director's Address"
                checked={subForm.directorsAddress}
                onChange={() => setSubFormProperty("directorsAddress", !subForm.directorsAddress)}
              />
              {eventSize === "BIG" && <Chip label="Recommended" color="info" size="small" />}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Registration Countdown"
                checked={subForm.registrationCountdown}
                onChange={() => setSubFormProperty("registrationCountdown", !subForm.registrationCountdown)}
              />
              {(eventSize === "SMALL" || eventSize === "MEDIUM" || eventSize === "BIG") && (
                <Chip label="Recommended" color="info" size="small" />
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Prize/ Incentive"
                checked={subForm.prizeInventive}
                onChange={() => setSubFormProperty("prizeInventive", !subForm.prizeInventive)}
              />
              {eventSize === "BIG" && <Chip label="Recommended" color="info" size="small" />}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Sponsorships"
                checked={subForm.sponsorships}
                onChange={() => setSubFormProperty("sponsorships", !subForm.sponsorships)}
              />
              {eventSize === "BIG" && <Chip label="Recommended" color="info" size="small" />}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Rules/ FAQs"
                checked={subForm.rulesFaqs}
                onChange={() => setSubFormProperty("rulesFaqs", !subForm.rulesFaqs)}
              />
              {eventSize === "BIG" && <Chip label="Recommended" color="info" size="small" />}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Merchandise"
                checked={subForm.merchandisePromo}
                onChange={() => setSubFormProperty("merchandisePromo", !subForm.merchandisePromo)}
              />
              {eventSize === "BIG" && <Chip label="Recommended" color="info" size="small" />}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Paid Ads"
                checked={subForm.paidAds}
                onChange={() => setSubFormProperty("paidAds", !subForm.paidAds)}
              />
              {eventSize === "BIG" && <Chip label="Recommended" color="info" size="small" />}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Campus Promotion"
                checked={subForm.campusPromotion}
                onChange={() => setSubFormProperty("campusPromotion", !subForm.campusPromotion)}
              />
              {eventSize === "BIG" && <Chip label="Recommended" color="info" size="small" />}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Final Reminders/ Info"
                checked={subForm.finalRemindersInfo}
                onChange={() => setSubFormProperty("finalRemindersInfo", !subForm.finalRemindersInfo)}
              />
              {(eventSize === "MEDIUM" || eventSize === "BIG") && (
                <Chip label="Recommended" color="info" size="small" />
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={!canEdit}
                label="Event Photos (Post-event)"
                checked={subForm.eventPhotos}
                onChange={() => setSubFormProperty("eventPhotos", !subForm.eventPhotos)}
              />
              {(eventSize === "SMALL" || eventSize === "MEDIUM" || eventSize === "BIG") && (
                <Chip label="Recommended" color="info" size="small" />
              )}
            </Box>
            <FormControlLabel
              control={<Checkbox />}
              disabled={!canEdit}
              label="Other"
              checked={subForm.otherPromotion}
              onChange={() => setSubFormProperty("otherPromotion", !subForm.otherPromotion)}
            />
            {subForm.otherPromotion && (
              <>
                <Typography color="textSecondary" variant="body2" sx={{ mb: theme.spacing(1) }}>
                  Other Promotion Specifications
                </Typography>
                <TextField
                  size="small"
                  disabled={!canEdit}
                  label="Other Promotion Specifications"
                  onChange={(e) => setSubFormProperty("otherPromotionSpecifications", e.target.value)}
                  value={subForm.otherPromotionSpecifications ? subForm.otherPromotionSpecifications : ""}
                  multiline
                  minRows={4}
                  maxRows={6}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: theme.spacing(1) }}
                />
              </>
            )}
          </FormGroup>
        </Box>
      </FormControl>
      <FormControl sx={formControlSx}>
        <FormLabel sx={{ mb: theme.spacing(1) }}>When would you like promo to start?</FormLabel>
        <TextField
          size="small"
          disabled={!canEdit}
          onChange={(e) => setSubFormProperty("promoStart", e.target.value)}
          value={subForm.promoStart ? subForm.promoStart : ""}
          variant="outlined"
          type="date"
          sx={{ mb: theme.spacing(1), display: "block" }}
        />
      </FormControl>
      <FormControl sx={formControlSx}>
        <FormLabel sx={{ mb: theme.spacing(1) }}>
          Please provide all important information you want us to include in posts
        </FormLabel>
        <TextField
          size="small"
          disabled={!canEdit}
          label="For visual posts (What text to display on the post) or Captions"
          onChange={(e) => setSubFormProperty("importantPromotionInformation", e.target.value)}
          value={subForm.importantPromotionInformation ? subForm.importantPromotionInformation : ""}
          multiline
          minRows={4}
          maxRows={6}
          variant="outlined"
          fullWidth
          sx={{ mb: theme.spacing(1) }}
        />
      </FormControl>
      <FormControl sx={formControlSx}>
        <FormLabel sx={{ mb: theme.spacing(1) }}>
          Do you have any other information you would like to include?
        </FormLabel>
        <TextField
          size="small"
          disabled={!canEdit}
          label="Other information"
          onChange={(e) => setSubFormProperty("otherPromotionInformation", e.target.value)}
          value={subForm.otherPromotionInformation ? subForm.otherPromotionInformation : ""}
          multiline
          minRows={4}
          maxRows={6}
          variant="outlined"
          fullWidth
          sx={{ mb: theme.spacing(1) }}
        />
      </FormControl>
    </>
  );
};
