import {
  Box,
  Button,
  Collapse,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { InitRiskSubFormEntry, RiskSubFormEntry, RiskSubFormModel } from "../../../models/EventFormModel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../services/firebase";

export const SubFormRisk = ({
  canEdit,
  subForm,
  setSubFormProperty,
}: {
  canEdit: boolean;
  subForm: RiskSubFormModel;
  setSubFormProperty: (property: keyof RiskSubFormModel, value: any) => void;
}) => {
  const theme = useTheme();

  const { entries } = subForm;

  const setEntryValue = (key: keyof RiskSubFormEntry, value: any, i: number) =>
    setSubFormProperty("entries", [...entries.slice(0, i), { ...entries[i], [key]: value }, ...entries.slice(i + 1)]);

  const tableContainerSx: SxProps = {
    marginTop: 4,
    borderRadius: 1,
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [open, setOpen] = useState(-1);

  return (
    <>
      <Box>
        <Typography>Please refer to below document when filling out your risk assessment:</Typography>
        <Button
          onClick={() =>
            getDownloadURL(ref(storage, "riskSubForm/SOW General Risk Assessment Template.pdf")).then((r) =>
              window.open(r)
            )
          }
          variant="outlined"
          startIcon={<InfoIcon />}
          sx={{ mt: 2 }}
        >
          Click Here for Risk Assessment Instructions
        </Button>
      </Box>
      <Box>
        <TableContainer id="subFormRiskTable" component={Paper} sx={tableContainerSx}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderTopRightRadius: theme.spacing(2),
                  borderTopLeftRadius: theme.spacing(2),
                }}
              >
                <TableCell width="5%">
                  <Button
                    aria-describedby="popover"
                    variant="outlined"
                    color="secondary"
                    startIcon={<InfoIcon />}
                    onClick={handleClick}
                  >
                    Info
                  </Button>
                  <Popover
                    id="popoverInfo"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Typography sx={{ p: 2 }}>
                      Please read the above linked document for further details and examples on how to fill out your
                      risk assessment form.
                    </Typography>
                  </Popover>
                </TableCell>
                <TableCell scope="header">
                  <Typography color="secondary" variant="h6">
                    Risk Assessment
                  </Typography>
                </TableCell>
                <TableCell width="5%"></TableCell>
              </TableRow>
            </TableHead>
            {entries.map((entry, i) => (
              <TableBody key={i}>
                <TableRow>
                  <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(open === i ? -1 : i)}>
                      {open === i ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">{entry.task ? entry.task : "Fill task name"}</Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        entries.splice(i, 1);
                        setSubFormProperty("entries", entries);
                      }}
                      disabled={!canEdit}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={5} sx={{ paddingBottom: 0, paddingTop: 0, border: "0px" }}>
                    <Collapse in={open === i} timeout="auto" unmountOnExit>
                      <Typography variant="h6" gutterBottom component="div">
                        Task Details
                      </Typography>
                      <TextField
                        id="Task Name"
                        size="small"
                        disabled={!canEdit}
                        label="Task Name"
                        onChange={(e) => setEntryValue("task", e.target.value, i)}
                        value={entry.task}
                        variant="outlined"
                        fullWidth
                        sx={{ mb: theme.spacing(1) }}
                      />
                      <TextField
                        id="Hazards"
                        size="small"
                        disabled={!canEdit}
                        label="Hazards"
                        onChange={(e) => setEntryValue("hazards", e.target.value, i)}
                        value={entry.hazards}
                        multiline
                        minRows={4}
                        maxRows={6}
                        variant="outlined"
                        fullWidth
                        sx={{ mb: theme.spacing(1) }}
                      />
                      <TextField
                        id="Risk Identification"
                        size="small"
                        disabled={!canEdit}
                        label="Risk Identification"
                        onChange={(e) => setEntryValue("riskIdentification", e.target.value, i)}
                        value={entry.riskIdentification}
                        multiline
                        minRows={4}
                        maxRows={6}
                        variant="outlined"
                        fullWidth
                        sx={{ mb: theme.spacing(1) }}
                      />
                      <TextField
                        id="Proposed Control Measures"
                        size="small"
                        disabled={!canEdit}
                        label="Proposed Control Measures"
                        onChange={(e) => setEntryValue("proposedControlMeasures", e.target.value, i)}
                        value={entry.proposedControlMeasures}
                        multiline
                        minRows={4}
                        maxRows={6}
                        variant="outlined"
                        fullWidth
                        sx={{ mb: theme.spacing(1) }}
                      />
                      <InputLabel id="Residual Risk Level">Residual Risk Level</InputLabel>
                      <Select
                        id="Residual Risk Level"
                        disabled={!canEdit}
                        onChange={(e) => setEntryValue("residualRiskLevel", e.target.value, i)}
                        value={entry.residualRiskLevel}
                        variant="outlined"
                        fullWidth
                        sx={{ mb: theme.spacing(1) }}
                        label="Residual Risk Level"
                      >
                        <MenuItem value={"Extreme Risk"}>Extreme Risk</MenuItem>
                        <MenuItem value={"High Risk"}>High Risk</MenuItem>
                        <MenuItem value={"Medium Risk"}>Medium Risk</MenuItem>
                        <MenuItem value={"Low Risk"}>Low Risk</MenuItem>
                      </Select>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => setSubFormProperty("entries", entries.concat(InitRiskSubFormEntry))}
          disabled={!canEdit}
          sx={{ mt: 2, mb: 2 }}
        >
          Add Task
        </Button>
      </Box>
    </>
  );
};
