import * as yup from "yup";
import { Dialog } from "@mui/material";
import { Formik, FormikProps } from "formik";
import { ReimbursementPayForm } from "./ReimbursementPayForm";
import { Reimbursement } from "../../models/Reimbursement";

const ReimbursementPayFormValidationSchema = yup.object().shape({
  comment: yup.string(),
  paidAmount: yup
    .string()
    .required("Please enter the actual amount")
    .matches(/^[1-9]{1}[0-9]*(\.\d{1,2})?$/, "Must be a valid amount"),
});

export const ReimbursementPayConfirmationDialog = ({
  currReimbursement,
  dialogOpen,
  closeDialog,
  payReimbursement,
}: {
  currReimbursement: Reimbursement;
  dialogOpen: boolean;
  closeDialog: () => void;
  payReimbursement: (reimbursement: Reimbursement) => void;
}) => (
  <Dialog open={dialogOpen} onClose={closeDialog} aria-describedby="alert-dialog-description">
    <Formik
      initialValues={{
        ...currReimbursement,
        comment: "",
        paidAmount: "",
      }}
      onSubmit={(values: Reimbursement) => payReimbursement(values)}
      validationSchema={ReimbursementPayFormValidationSchema}
    >
      {(props: FormikProps<Reimbursement>) => {
        return <ReimbursementPayForm props={props} handleClose={closeDialog} />;
      }}
    </Formik>
  </Dialog>
);
