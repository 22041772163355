import { blue } from "@mui/material/colors";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  registerables as registerablesJS,
  Title,
  Tooltip,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { ColoursDepartments } from "../../../../data/colours";
import {
  CategoryAverageOfAll,
  ReviewAverageCategory,
  ReviewAverageGroup,
  reviewAverageGroups,
  ReviewResults,
  ReviewResultsOfAll,
  ReviewAverageAttribute,
  AttributeAverageOfAll,
} from "../../../../models/Review360Model";

export const Review360OverallComparison = ({
  results,
  resultsOfAll,
}: {
  results: ReviewResults;
  resultsOfAll: ReviewResultsOfAll;
}) => {
  ChartJS.register(...registerablesJS);
  ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

  const groupColours = {
    Self: ColoursDepartments.Missions_1 as string,
    Mentor: ColoursDepartments.SOW_2 as string,
    Others: ColoursDepartments.SOW_5 as string,
  };

  const options = (text: string) => ({
    scales: {
      y: {
        min: 0,
        max: 7,
      },
    },
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    spanGaps: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: text,
      },
    },
  });

  const getLabels = (averagesObject: ReviewAverageCategory | ReviewAverageAttribute) =>
    Object.keys(averagesObject).map((text) => text.split(" "));

  const data = (
    averagesObject: ReviewAverageCategory | ReviewAverageAttribute,
    averagesOfAllObject: CategoryAverageOfAll | AttributeAverageOfAll
  ) => ({
    labels: getLabels(averagesObject),
    datasets: [
      {
        type: "line" as const,
        label: "All Others",
        data: Object.values(averagesOfAllObject),
        backgroundColor: blue[800],
        borderColor: blue[800],
        pointStyle: "rect",
      },
      ...reviewAverageGroups.map((group) => ({
        type: "bar" as const,
        label: group,
        data: Object.values(averagesObject).map((averageGroup) => averageGroup[group as keyof ReviewAverageGroup]),
        backgroundColor: groupColours[group as keyof ReviewAverageGroup],
      })),
    ],
  });

  const categoriesLabels = Object.keys(results.averagesCategory);

  return (
    <>
      <Chart
        type="bar"
        options={options("Overall comparison")}
        data={data(results.averagesCategory, resultsOfAll.averagesCategoryOfAll)}
      />
      {categoriesLabels.map((category, i) => (
        <Chart
          type="bar"
          options={options(category)}
          data={data(results.averagesAttribute[category], resultsOfAll.averagesAttributeOfAll[category])}
          key={i}
        />
      ))}
    </>
  );
};
