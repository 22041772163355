import { Box, Button, Grid, MenuItem, Select, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useContext, useEffect, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { Loading } from "../../components/Loading";
import { UserContext } from "../../components/PrivateRoute";
import { DEPARTMENT_OVERSEER, EXECUTIVE, HEAD_OF_DEPARTMENT, PRESIDENT, STAFF, VICE_PRESIDENT } from "../../data/Roles";
import { University, UniversityAny, universities, universitiesWithoutAcu } from "../../data/University";
import { nextStaffYear, staffYearsSinceStartYear, staffYearsSinceStartYearAndNextYear } from "../../helpers/FormatDate";
import { atLeast } from "../../helpers/Role";
import { DepartmentInterface, Departments, InitDepartment } from "../../models/Department";
import { Divisions } from "../../models/Division";
import { Staff } from "../../models/Staff";
import { User } from "../../models/User";
import { getStaffYearDivisions } from "../../services/divisions";
import { getDirector, getListOfUsersByStaffYear } from "../../services/users";
import { OrganisationChartNode } from "./OrganisationChartNode";
import { OrganisationChartInterface } from "./OrganisationChartUtils";
import { useHistory, useParams } from "react-router-dom";
import { Path } from "../../helpers/Path";
import { getStaffYearDepartments } from "../../services/departments";
import { getStaffYearRoles } from "../../services/roles";

const initShowDepartment = (departments: DepartmentInterface, department?: string) => {
  const show = {} as { [department: string]: boolean };
  for (const dep of Object.keys(departments.departments)) {
    if (department === dep) {
      show[dep] = true;
    } else {
      show[dep] = false;
    }
  }
  return show;
};

const initShowCampus = (campuses: University[], campus?: string) => {
  const show = {} as { [campus: string]: boolean };
  for (const cam of campuses) {
    if (campus === cam) {
      show[cam] = true;
    } else {
      show[cam] = false;
    }
  }
  return show;
};

const initShowDivision = (divisions: Divisions, division?: string) => {
  const show = {} as { [div: string]: boolean };
  for (const div of Object.keys(divisions)) {
    if (division === div) {
      show[div] = true;
    } else {
      show[div] = false;
    }
  }
  return show;
};

const People = ({ roles, user, users, year }: { roles: string[]; user: User; users: User[]; year: number }) => (
  <TreeNode label={<OrganisationChartNode roles={roles} user={user} year={year} />}>
    {users.length > 0 ? <People roles={roles} user={users[0]} users={users.slice(1)} year={year} /> : null}
  </TreeNode>
);

export const OrganisationChart = () => {
  let { year } = useParams() as { year: string };
  const theme = useTheme();
  const history = useHistory();

  const user = useContext(UserContext);
  const [state, setState] = useState<OrganisationChartInterface>({
    users: [],
    roles: [],
    departments: InitDepartment,
    loading: true,
    showDivision: {},
    showDepartment: {},
    showCampus: {},
    divisions: {},
  });

  const { showDivision, showDepartment, showCampus, roles, departments, users, director, divisions, loading } = state;

  const noDivisions = (divisions: Divisions) => Object.keys(divisions).length === 0;
  const noDepartments = (departments: Departments) => Object.keys(departments).length === 0;
  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    if (!staffYearsSinceStartYearAndNextYear().includes(Number(year))) {
      history.push(`${Path.Error}/404 Not Found`);
    }
    const listOfUsersFetch = getListOfUsersByStaffYear(Number(year));
    const getDivisionsFetch = getStaffYearDivisions(year);
    const getRolesFetch = getStaffYearRoles(Number(year) < 2018 ? "2018" : year);
    const getDepartmentsFetch = getStaffYearDepartments(year) as Promise<DepartmentInterface>;
    const getDirectorFetch = getDirector(year);

    Promise.all([listOfUsersFetch, getRolesFetch, getDivisionsFetch, getDepartmentsFetch, getDirectorFetch]).then(
      ([users, roles, divisions, departments, director]) => {
        departments =
          Number(year) < 2022
            ? {
                ...departments,
                departments:
                  Number(year) > 2018
                    ? {
                        ...departments.departments,
                        Chaplaincy: { head: director, division: "", colour: "" },
                      }
                    : departments.departments,
              }
            : departments;
        setState({
          ...state,
          users,
          director,
          divisions,
          departments,
          roles,
          showDivision: noDivisions(divisions)
            ? { Departments: true }
            : (user as Staff).department
            ? initShowDivision(divisions, departments.departments[(user as Staff).department].division)
            : initShowDivision(divisions),
          showDepartment: noDepartments(departments.departments)
            ? { Staff: false, Chaplaincy: false }
            : (user as Staff).department
            ? initShowDepartment(departments, (user as Staff).department)
            : initShowDepartment(departments),
          showCampus: !atLeast(roles, STAFF, user.role)
            ? user.university
              ? initShowCampus(
                  Number(year) < 2021 || Number(year) > 2023 ? universitiesWithoutAcu : universities,
                  user.university
                )
              : initShowCampus(Number(year) < 2021 || Number(year) > 2023 ? universitiesWithoutAcu : universities)
            : initShowCampus(Number(year) < 2021 || Number(year) > 2023 ? universitiesWithoutAcu : universities),
          loading: false,
        });
      }
    );
    // eslint-disable-next-line
  }, []);

  const expandAll = () =>
    setState({
      ...state,
      showDivision: Object.fromEntries(Object.entries(showDivision).map(([name, _]) => [name, true])),
      showDepartment: Object.fromEntries(Object.entries(showDepartment).map(([name, _]) => [name, true])),
      showCampus: Object.fromEntries(Object.entries(showCampus).map(([name, _]) => [name, true])),
    });

  const reduceAll = () =>
    setState({
      ...state,
      showDivision: Object.fromEntries(Object.entries(showDivision).map(([name, _]) => [name, false])),
      showDepartment: Object.fromEntries(Object.entries(showDepartment).map(([name, _]) => [name, false])),
      showCampus: Object.fromEntries(Object.entries(showCampus).map(([name, _]) => [name, false])),
    });

  return loading ? (
    <Loading />
  ) : (
    <Box sx={{ margin: theme.spacing(10, 2, 20, 2) }}>
      <Grid container direction="row-reverse" spacing={2} sx={{ m: theme.spacing(2, 0, 4, 0) }}>
        <Button variant="contained" onClick={reduceAll}>
          REDUCE ALL
        </Button>
        <Button variant="contained" onClick={expandAll}>
          EXPAND ALL
        </Button>
        <Select
          placeholder="Year"
          labelId="year-label"
          id="year"
          onChange={(event) => {
            history.push(`${Path["Organisation Chart"]}/${event.target.value}`);
            window.location.reload();
          }}
          name="year"
          value={year}
          variant="outlined"
        >
          {staffYearsSinceStartYear().map((y, i) => (
            <MenuItem key={i} value={y}>
              {y}
            </MenuItem>
          ))}
          {atLeast(roles, HEAD_OF_DEPARTMENT, user.role) && <MenuItem value={nextStaffYear}>{nextStaffYear}</MenuItem>}
        </Select>
      </Grid>
      <Tree lineWidth={"2px"} lineHeight={"35px"} lineColor={grey[400]} lineBorderRadius={"15px"} label={"Campuses"}>
        {(Number(year) < 2021 || Number(year) > 2023 ? universitiesWithoutAcu : universities).map((uni, i) => (
          <TreeNode
            key={i}
            label={
              <OrganisationChartNode
                roles={roles}
                user={users.find((u) => u.university === uni && u.role === PRESIDENT) as User}
                text={UniversityAny[uni as any]}
                show={showCampus[uni]}
                toggle={() =>
                  showCampus[uni]
                    ? setState({
                        ...state,
                        showCampus: {
                          ...showCampus,
                          [uni]: false,
                        },
                      })
                    : setState({
                        ...state,
                        showCampus: {
                          ...showCampus,
                          [uni]: true,
                        },
                      })
                }
                year={Number(year)}
              />
            }
          >
            {showCampus[uni] ? (
              <People
                roles={roles}
                user={
                  users
                    .filter((u) => u.university === uni && !atLeast(roles, PRESIDENT, u.role))
                    .sort((u) => (u.role === EXECUTIVE ? -1 : 1))
                    .sort((u) => (u.role === VICE_PRESIDENT ? -1 : 1))[0]
                }
                users={users
                  .filter((u) => u.university === uni && !atLeast(roles, PRESIDENT, u.role))
                  .sort((u) => (u.role === EXECUTIVE ? -1 : 1))
                  .sort((u) => (u.role === VICE_PRESIDENT ? -1 : 1))
                  .slice(1)}
                year={Number(year)}
              />
            ) : null}
          </TreeNode>
        ))}
      </Tree>
      <Box marginTop={8}>
        <Tree lineWidth={"2px"} lineHeight={"35px"} lineColor={grey[400]} lineBorderRadius={"15px"} label={"Staff"}>
          <TreeNode
            key="director"
            label={
              noDepartments(departments.departments) ? null : (
                <OrganisationChartNode roles={roles} user={director} year={Number(year)} />
              )
            }
          >
            {Object.entries(noDivisions(divisions) && director ? { Departments: {} } : divisions)
              .sort(([divNameA, _], [divNameB, __]) => divNameA.localeCompare(divNameB))
              .map(([divName, division], i) => (
                <TreeNode
                  key={i}
                  label={
                    <OrganisationChartNode
                      roles={roles}
                      user={division.head}
                      text={divName}
                      show={showDivision[divName]}
                      toggle={() =>
                        showDivision[divName]
                          ? setState({
                              ...state,
                              showDivision: { ...showDivision, [divName]: false },
                            })
                          : setState({
                              ...state,
                              showDivision: { ...showDivision, [divName]: true },
                            })
                      }
                      year={Number(year)}
                    />
                  }
                >
                  {(noDivisions(divisions) || showDivision[divName]) &&
                    Object.entries(
                      noDepartments(departments.departments)
                        ? { Staff: { head: director }, Chaplaincy: { head: director } }
                        : departments.departments
                    )
                      .filter(([_, department]) =>
                        noDivisions(divisions) || noDepartments(departments.departments)
                          ? true
                          : department.division === divName
                      )
                      .sort(([depNameA, _], [depNameB, __]) => depNameA.localeCompare(depNameB))
                      .map(([depName, department], j) => (
                        <TreeNode
                          key={j}
                          label={
                            <OrganisationChartNode
                              roles={roles}
                              user={department.head}
                              text={depName}
                              show={showDepartment[depName]}
                              toggle={() =>
                                showDepartment[depName]
                                  ? setState({
                                      ...state,
                                      showDepartment: {
                                        ...showDepartment,
                                        [depName]: false,
                                      },
                                    })
                                  : setState({
                                      ...state,
                                      showDepartment: {
                                        ...showDepartment,
                                        [depName]: true,
                                      },
                                    })
                              }
                              year={Number(year)}
                            />
                          }
                        >
                          {showDepartment[depName] ? (
                            <People
                              roles={roles}
                              user={
                                depName === STAFF
                                  ? users.filter((user) => user.role === STAFF)[0]
                                  : users.filter((user) =>
                                      (user as Staff).department &&
                                      atLeast(roles, STAFF, user.role) &&
                                      !atLeast(roles, HEAD_OF_DEPARTMENT, user.role) &&
                                      !atLeast(roles, DEPARTMENT_OVERSEER, user.role)
                                        ? (user as Staff).department === depName
                                        : false
                                    )[0]
                              }
                              users={
                                depName === STAFF
                                  ? users.filter((user) => user.role === STAFF).slice(1)
                                  : users
                                      .filter((user) =>
                                        (user as Staff).department &&
                                        atLeast(roles, STAFF, user.role) &&
                                        !atLeast(roles, HEAD_OF_DEPARTMENT, user.role) &&
                                        !atLeast(roles, DEPARTMENT_OVERSEER, user.role)
                                          ? (user as Staff).department === depName
                                          : false
                                      )
                                      .slice(1)
                              }
                              year={Number(year)}
                            />
                          ) : null}
                        </TreeNode>
                      ))}
                </TreeNode>
              ))}
          </TreeNode>
        </Tree>
      </Box>
    </Box>
  );
};
