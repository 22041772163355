import ClearIcon from "@mui/icons-material/Clear";
import { Button, CircularProgress, Container, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { Form, Formik, FormikProps } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { Loading } from "../../components/Loading";
import { TypographyBold } from "../../components/TypographyBold";
import { InitPledgeGoals, PledgeGoal, PledgesGoals, getPledgeGoals, updatePledgeGoals } from "../../services/xero";

const PledgeGoalFormValidationSchema = yup.object().shape({
  date: yup
    .string()
    .matches(/^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{2}$/, "Enter a valid date")
    .required("Enter date"),
  goal: yup.number().min(0),
});

interface EditPledgesGoalsInterface {
  pledgesGoals: PledgesGoals;
  deletePledgesConfirmationDialog: boolean;
  pledgeToDelete: string;
  pledgeName: keyof PledgesGoals;
  loading: boolean;
}

export const EditPledgesGoals = () => {
  const [state, setState] = useState<EditPledgesGoalsInterface>({
    pledgesGoals: InitPledgeGoals,
    deletePledgesConfirmationDialog: false,
    pledgeToDelete: "",
    pledgeName: "alumniGoals",
    loading: true,
  });

  const { pledgesGoals, deletePledgesConfirmationDialog, pledgeToDelete, pledgeName, loading } = state;

  useEffect(() => {
    getPledgeGoals().then((pledgesGoals) =>
      setState({
        ...state,
        pledgesGoals,
        loading: false,
      })
    );

    // eslint-disable-next-line
  }, []);

  const theme = useTheme();

  const names = ["alumniGoals" as keyof PledgesGoals, "staffGoals" as keyof PledgesGoals];

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container maxWidth="lg">
        <ConfirmationModal
          title={"Delete Pledge Goal?"}
          content={"This will delete the pledge goal."}
          confirmText={"Yes"}
          cancelText={"No"}
          dialogOpen={deletePledgesConfirmationDialog}
          closeDialog={() => setState({ ...state, deletePledgesConfirmationDialog: false, pledgeToDelete: "" })}
          doAction={() => {
            delete pledgesGoals[pledgeName][pledgeToDelete];
            updatePledgeGoals(pledgesGoals).then((pledgesGoals) => {
              setState({ ...state, deletePledgesConfirmationDialog: false, pledgesGoals });
            });
          }}
        />
        {/* for each group (alumni, staff) */}
        {names.map((n, j) => (
          <Box key={j}>
            <Typography variant="h5">{n.toUpperCase()}</Typography>
            {Object.entries(pledgesGoals[n])
              .sort((a, b) => {
                let difference = Number(a[0].slice(-2)) - Number(b[0].slice(-2));
                if (difference !== 0) {
                  return difference;
                }
                return months.indexOf(a[0].slice(0, 2)) - months.indexOf(b[0].slice(0, 2));
              })
              // for each goal
              .map(([date, goal], k) => (
                <Box key={k}>
                  <TextField disabled id="date" size="small" required value={date} />
                  <TypographyBold display="inline">: </TypographyBold>
                  <TextField
                    disabled
                    id="goal"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    required
                    value={goal}
                  />
                  <IconButton
                    color="error"
                    aria-label="delete"
                    component="span"
                    onClick={() =>
                      setState({ ...state, deletePledgesConfirmationDialog: true, pledgeToDelete: date, pledgeName: n })
                    }
                  >
                    <ClearIcon />
                  </IconButton>
                </Box>
              ))}
            <Formik
              initialValues={{
                date: "",
                goal: 0,
              }}
              onSubmit={(values: PledgeGoal, { resetForm }) => {
                pledgesGoals[n][values.date] = values.goal;
                updatePledgeGoals(pledgesGoals).then((pledgesGoals) => {
                  setState({ ...state, pledgesGoals });
                  resetForm();
                });
              }}
              validationSchema={PledgeGoalFormValidationSchema}
            >
              {(props: FormikProps<PledgeGoal>) => {
                const { handleChange, handleBlur, errors, touched, isSubmitting, values } = props;
                return (
                  <Form>
                    <Typography sx={{ mt: theme.spacing(2) }}>Add goal</Typography>
                    <TextField
                      id="date"
                      helperText="e.g. Dec 23, Mar 24 (MMM yy)"
                      placeholder="Date"
                      size="small"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.date && touched.date ? true : false}
                      value={values.date}
                    />
                    <TypographyBold display="inline">: </TypographyBold>
                    <TextField
                      id="goal"
                      helperText="e.g. $1000, $1200"
                      size="small"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.goal && touched.goal ? true : false}
                      value={values.goal}
                    />
                    {!isSubmitting ? (
                      <Button type="submit" variant="contained">
                        Add
                      </Button>
                    ) : (
                      <CircularProgress color="primary" />
                    )}
                  </Form>
                );
              }}
            </Formik>
          </Box>
        ))}
      </Container>
    </>
  );
};
