import { FormikProps, Form } from "formik";
import {
  TextField,
  CircularProgress,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Typography,
  DialogContentText,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Reimbursement } from "../../models/Reimbursement";

export const ReimbursementPayForm = ({
  props,
  handleClose,
}: {
  props: FormikProps<Reimbursement>;
  handleClose: () => void;
}) => {
  const theme = useTheme();

  const { values, errors, touched, handleBlur, handleChange, isSubmitting } = props;

  return (
    <Box
      sx={{
        width: "100%", // Fix IE 11 issue.
        mt: theme.spacing(3),
      }}
    >
      <Form>
        <DialogTitle id="form-dialog-title">Reimbursement</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Only click PAY if you have sent the reimbursement to the account.
          </DialogContentText>
          <TextField
            label="Paid Amount"
            required
            fullWidth
            id="paidAmount"
            onChange={handleChange}
            onBlur={handleBlur}
            name="paidAmount"
            helperText={errors.paidAmount && touched.paidAmount ? errors.paidAmount : ""}
            value={values.paidAmount}
            error={errors.paidAmount && touched.paidAmount ? true : false}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            variant="standard"
          />
          <Typography variant="body2" color="textSecondary">
            NOTE: Budget Monitoring Officer will be notified if the paid amount is different to the requested amount
          </Typography>
          <TextField
            sx={{
              mt: theme.spacing(1),
            }}
            id="comment"
            fullWidth
            label="Comments"
            placeholder="Write any comments"
            multiline
            minRows={4}
            maxRows={6}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.comment && touched.comment ? errors.comment : ""}
            value={values.comment}
            error={errors.comment && touched.comment ? true : false}
            variant="standard"
          />
        </DialogContent>
        {!isSubmitting ? (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit">PAY</Button>
          </DialogActions>
        ) : (
          <CircularProgress color="secondary" />
        )}
      </Form>
    </Box>
  );
};
