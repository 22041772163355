import { FormikProps, Form, FormikErrors } from "formik";
import {
  TextField,
  CircularProgress,
  Button,
  Alert,
  AlertTitle,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { ReimbursementRecipientSubmit, ReimbursementSubmit } from "../../models/Reimbursement";

export const ReimbursementForm = ({
  props,
  handleClose,
}: {
  props: FormikProps<ReimbursementSubmit>;
  handleClose: () => void;
}) => {
  const theme = useTheme();
  const { values, errors, touched, handleBlur, handleChange, setFieldValue, isSubmitting } = props;

  return (
    <Box
      sx={{
        width: "100%", // Fix IE 11 issue.
        mt: theme.spacing(3),
      }}
    >
      <Form>
        <DialogTitle id="form-dialog-title">Submit Reimbursement</DialogTitle>
        <DialogContent>
          {values.recipients.map((recipient, i) => (
            <Box key={i}>
              <Typography variant="h6">Recipient #{i + 1}</Typography>
              <TextField
                label="Receipt/Invoice Amount"
                required
                fullWidth
                id={`receiptAmount-${i}`}
                name={`receiptAmount-${i}`}
                onBlur={handleBlur}
                onChange={(event) => {
                  setFieldValue(`recipients.${i}.receiptAmount`, event.target.value);
                  values.recipients[i].receiptAmount = event.target.value;
                  setFieldValue(
                    "totalReceiptAmount",
                    values.recipients.reduce((acc, rec) => Number(acc) + Number(rec.receiptAmount), 0)
                  );
                }}
                value={recipient.receiptAmount}
                error={Boolean((errors.recipients?.[i] as FormikErrors<ReimbursementRecipientSubmit>)?.receiptAmount)}
                helperText={(errors.recipients?.[i] as FormikErrors<ReimbursementRecipientSubmit>)?.receiptAmount}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                variant="standard"
              />
              <Typography variant="body2" color="textSecondary">
                NOTE: You can only be paid up to the requested amount. If you have already paid the recipient, please
                also provide evidence of the transaction as well as the original invoice.
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id={`accountName-${i}`}
                label="Account Name"
                name={`accountName-${i}`}
                onChange={(event) => {
                  setFieldValue(`recipients.${i}.accountName`, event.target.value);
                }}
                value={recipient.accountName}
                error={Boolean((errors.recipients?.[i] as FormikErrors<ReimbursementRecipientSubmit>)?.accountName)}
                helperText={(errors.recipients?.[i] as FormikErrors<ReimbursementRecipientSubmit>)?.accountName}
                onBlur={handleBlur}
                variant="standard"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id={`bsb-${i}`}
                label="BSB"
                name={`bsb-${i}`}
                onChange={(event) => {
                  setFieldValue(`recipients.${i}.bsb`, event.target.value);
                }}
                value={recipient.bsb}
                error={Boolean((errors.recipients?.[i] as FormikErrors<ReimbursementRecipientSubmit>)?.bsb)}
                helperText={(errors.recipients?.[i] as FormikErrors<ReimbursementRecipientSubmit>)?.bsb}
                onBlur={handleBlur}
                variant="standard"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id={`accountNumber-${i}`}
                label="Account Number"
                name={`accountNumber-${i}`}
                onChange={(event) => {
                  setFieldValue(`recipients.${i}.accountNumber`, event.target.value);
                }}
                value={recipient.accountNumber}
                error={Boolean((errors.recipients?.[i] as FormikErrors<ReimbursementRecipientSubmit>)?.accountNumber)}
                helperText={(errors.recipients?.[i] as FormikErrors<ReimbursementRecipientSubmit>)?.accountNumber}
                onBlur={handleBlur}
                variant="standard"
              />
              <Grid item xs={12} container direction="column" alignItems="center" justifyContent="center">
                <Alert severity="warning">
                  <AlertTitle>Must be readable</AlertTitle>
                </Alert>
                <Box component="label" htmlFor={`filePicker-${i}`}>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    size="small"
                    id="files"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    Upload Receipt/Invoice(s) *
                  </Button>
                </Box>
                {Boolean(values.recipients[i].files.length === 0) && (
                  <Typography color="error">Please upload at least one receipt</Typography>
                )}
                <Box
                  component="input"
                  id={`filePicker-${i}`}
                  style={{ visibility: "hidden" }}
                  type="file"
                  accept=".doc, .pdf, image/*"
                  name="files"
                  multiple
                  required
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    event.target &&
                    event.target.files &&
                    setFieldValue(`recipients.${i}.files`, Array.from(event.target.files))
                  }
                />
                <Grid item container alignItems="center" justifyContent="center">
                  {recipient.files.map((file, i) => (
                    <Box
                      component="img"
                      key={i}
                      alt={file.name}
                      src={URL.createObjectURL(file)}
                      sx={{
                        maxHeight: theme.spacing(19),
                        maxWidth: theme.spacing(19),
                      }}
                    />
                  ))}
                </Grid>
              </Grid>
            </Box>
          ))}
          <Grid item container sx={{ mt: theme.spacing(4) }} alignItems="center" justifyContent="center">
            <Button
              variant="contained"
              onClick={() =>
                setFieldValue("recipients", [
                  ...values.recipients,
                  {
                    receiptAmount: "",
                    accountName: "",
                    bsb: "",
                    accountNumber: "",
                    receiptURLs: [],
                    files: [],
                  },
                ])
              }
            >
              Add Recipient
            </Button>
          </Grid>
          {Number(values.amount) < Number(values.totalReceiptAmount) && (
            <Alert severity="warning">
              The total receipt amount exceeds the requested amount. You may only receive up to the requested amount.
            </Alert>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            disabled
            id="totalReceiptAmount"
            label="Total Receipt Amount"
            name="totalReceiptAmount"
            helperText={errors.totalReceiptAmount && touched.totalReceiptAmount ? errors.totalReceiptAmount : ""}
            value={values.totalReceiptAmount}
            error={errors.totalReceiptAmount && touched.totalReceiptAmount ? true : false}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
          />
        </DialogContent>
        {!isSubmitting ? (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <CircularProgress />
          </DialogActions>
        )}
      </Form>
    </Box>
  );
};
