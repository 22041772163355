import { Container, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Footer } from "../../components/Footer";
import { Loading } from "../../components/Loading";
import { UserContext } from "../../components/PrivateRoute";
import { getToken, isXeroAuthenticated, SetAuthenticated } from "../../services/xero";

interface BudgetsInterface {
  loading: boolean;
}

export const Budgets = () => {
  const user = useContext(UserContext);

  const [state, setState] = useState<BudgetsInterface>({
    loading: true,
  });

  const { loading } = state;

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    if (!isXeroAuthenticated()) {
      getToken(
        "openid profile email accounting.transactions.read accounting.settings.read accounting.contacts.read accounting.reports.read offline_access"
      ).then((token) => {
        SetAuthenticated(token);
        getBudgets();
      });
    } else {
      getBudgets();
    }

    // eslint-disable-next-line
  }, []);

  const getBudgets = () => {};
  // get(budgets, tenantId, body

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container maxWidth="lg">
        <Typography>My name is {user.firstName}</Typography>
      </Container>
      <Footer />
    </>
  );
};
