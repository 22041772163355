import { useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Path } from "../../helpers/Path";
import { Container, Typography, Grid, Snackbar, Link, useTheme, Box, Button } from "@mui/material";
import { Alert } from "@mui/material";
import { SignInForm } from "./SignInForm";
import { signInWithPopup } from "firebase/auth";
import { auth, microsoftProvider } from "../../services/firebase";

export const SignIn = () => {
  const theme = useTheme();
  const [state, setState] = useState({
    showMessage: false,
    error: "",
    activateNormalSignIn: false,
  });

  const handleMessageClose = () => {
    setState({ ...state, showMessage: false });
  };

  const { showMessage, error, activateNormalSignIn } = state;

  return (
    <>
      <Container component="main" maxWidth="sm">
        <Snackbar open={showMessage} onClose={handleMessageClose} autoHideDuration={6000}>
          <Alert elevation={6} variant="filled" severity="error">
            {error}
          </Alert>
        </Snackbar>
        <Typography variant="h4" align="center" gutterBottom>
          Log In
        </Typography>
        <Typography align="center" gutterBottom>
          Log into your SOW Account
        </Typography>
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            sx={{
              m: theme.spacing(2, 0, 6, 0),
            }}
            onClick={() => signInWithPopup(auth, microsoftProvider)}
          >
            Sign In with Microsoft
          </Button>
        </Grid>
        {activateNormalSignIn ? (
          <Box>
            <SignInForm setError={(message) => setState({ ...state, error: message, showMessage: true })} />
            <Grid container>
              <Grid item xs>
                <Link
                  sx={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "large",
                  }}
                  underline="hover"
                  component={ReactRouterLink}
                  to={Path["Forgot Password"]}
                >
                  Forgot password
                </Link>
              </Grid>
              <Grid item>
                <Link
                  sx={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "large",
                  }}
                  underline="hover"
                  component={ReactRouterLink}
                  to={Path.SignUp}
                >
                  Create an account
                </Link>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Link
            sx={{
              textDecoration: "none",
              fontSize: "large",
              cursor: "pointer",
            }}
            underline="hover"
            color="textSecondary"
            onClick={() => setState({ ...state, activateNormalSignIn: true })}
          >
            Sign In through email/password (not recommended)
          </Link>
        )}
      </Container>
    </>
  );
};
