import { EXECUTIVE, PRESIDENT, STUDENT_LEADER, VICE_PRESIDENT } from "../data/Roles";

export const atLeast = (roles: string[], role: string, roleIn?: string) => {
  return (
    roles.length > 0 &&
    roleIn !== undefined &&
    roles.includes(roleIn) &&
    roles.includes(role) &&
    roles.indexOf(role) >= roles.indexOf(roleIn)
  );
};

export const studentLeader = (role: string) =>
  role === PRESIDENT || role === VICE_PRESIDENT || role === EXECUTIVE || role === STUDENT_LEADER;

export interface PastRoles {
  [year: string]: string[];
}
