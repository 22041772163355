import { useContext } from "react";
import * as yup from "yup";
import { Dialog } from "@mui/material";
import { Formik, FormikProps } from "formik";
import { Staff } from "../../models/Staff";
import { PromotionRequest } from "../../models/PromotionRequest";
import { MessageState } from "../../models/MessageState";
import { PromotionRequestsForm } from "./PromotionRequestsForm";
import { RequestStatus } from "../../data/RequestStatus";
import { submitPromotionRequest } from "../../services/promotionRequests";
import { DepartmentsContext } from "../../components/PrivateRoute";

const PromotionRequestsFormValidationSchema = yup.object().shape({
  department: yup.string().required("Enter department"),
  details: yup.string().required("Enter details"),
  targetAudience: yup.string().required("Enter target audience"),
  targetAudienceOtherText: yup.string(),
  message: yup.string().required("Enter message"),
  theme: yup.string().required("Enter theme"),
  example: yup.string(),
  postWhen: yup.string().required("Enter when to post"),
  filesToInclude: yup.string(),
  askForApproval: yup.bool().required("Answer yes or no"),
  extraInformation: yup.string(),
});

export const PromotionRequestsSubmit = ({
  staff,
  dialogOpen,
  closeDialog,
  handlePost,
}: {
  staff: Staff;
  dialogOpen: boolean;
  closeDialog: () => void;
  handlePost: (m: MessageState, r: PromotionRequest | null) => void;
}) => {
  const departments = useContext(DepartmentsContext);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={dialogOpen}
      onClose={closeDialog}
      aria-describedby="alert-dialog-description"
    >
      <Formik
        initialValues={{
          submittedTime: new Date(),
          staff: staff,
          approvedByHOD: RequestStatus.PENDING,
          objective: {
            raiseAwareness: false,
            invitePeople: false,
            getPeopleInterested: false,
            hitRegistrations: false,
            other: false,
          },
          type: {
            graphics: false,
            photos: false,
            animatedGraphics: false,
            videos: false,
            instagramStories: false,
            other: false,
          },
          department: staff.department,
          details: "",
          theme: "",
          message: "",
          postWhen: "",
          targetAudience: "",
          askForApproval: false,
          completed: false,
        }}
        onSubmit={(values: PromotionRequest) => {
          submitPromotionRequest(values, departments)
            .then((dr) =>
              handlePost(
                {
                  showMessage: true,
                  message: "Successfully submitted promotion request! An email has been sent to the Promotion Team.",
                  severity: "success",
                },
                dr
              )
            )
            .catch((error) => {
              handlePost(
                {
                  showMessage: true,
                  message: error.message,
                  severity: "error",
                },
                null
              );
            });
        }}
        validationSchema={PromotionRequestsFormValidationSchema}
      >
        {(props: FormikProps<PromotionRequest>) => {
          return <PromotionRequestsForm props={props} handleClose={closeDialog} />;
        }}
      </Formik>
    </Dialog>
  );
};
