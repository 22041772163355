import { Link as ReactRouterLink } from "react-router-dom";
import { Typography, Grid, Paper, Link, TextField, Button, Divider, Accordion, AccordionSummary } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Staff } from "../../models/Staff";
import { PromotionRequestDeclined } from "../../models/PromotionRequestDeclined";
import { PromotionRequestCommentData } from "../../models/PromotionRequestCommentData";
import { PromotionRequest } from "../../models/PromotionRequest";
import { formatDateTime } from "../../helpers/FormatDate";
import { Path } from "../../helpers/Path";
import {
  promotionRequestApproved,
  promotionRequestDeclined,
  promotionRequestCompleted,
  promotionRequestStatus,
} from "../../services/promotionRequests";
import { MessageState } from "../../models/MessageState";
import { PromotionRequestCommentSection } from "./PromotionRequestCommentSection";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PromotionRequestsFormAnswers } from "./PromotionRequestsFormAnswers";
import { Status } from "../../helpers/Status";

export const PromotionRequestsComponent = ({
  promotionRequest,
  approve,
  decline,
  complete,
  hod,
  staff,
  handleCommentSubmit,
  handleCommentDelete,
  cancelPromotionRequest,
}: {
  promotionRequest: PromotionRequest;
  approve?: () => void;
  decline?: () => void;
  complete?: () => void;
  hod?: boolean;
  staff?: Staff | undefined;
  handleCommentSubmit?: (m: MessageState, c: PromotionRequestCommentData | null) => void;
  handleCommentDelete?: (m: MessageState, c: string) => void;
  cancelPromotionRequest?: () => void;
}) => {
  const theme = useTheme();

  return (
    <Accordion
      sx={{
        margin: theme.spacing(1, 1, 0, 1),
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="resources">
        <Typography>
          #{promotionRequest.id} by {promotionRequest.staff.firstName}
          {promotionRequestDeclined(promotionRequest) && " - DECLINED"}
          {promotionRequestCompleted(promotionRequest) && " - COMPLETED"}
        </Typography>
      </AccordionSummary>
      <Grid xs={12} item>
        <Paper
          elevation={3}
          sx={{
            padding: theme.spacing(2),
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6" display="inline">
                #{promotionRequest.id}
              </Typography>
              <Typography display="inline">
                {" "}
                by {promotionRequest.staff.firstName} {promotionRequest.staff.lastName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Department: {promotionRequest.department}
              </Typography>
            </Grid>
            <Grid item>
              {!promotionRequestDeclined(promotionRequest) && cancelPromotionRequest && (
                <Grid
                  container
                  item
                  justifyItems="flex-end"
                  sx={{
                    mb: theme.spacing(1),
                  }}
                >
                  <Link component="button" color="error" onClick={cancelPromotionRequest}>
                    CANCEL REQUEST
                  </Link>
                </Grid>
              )}
              <Grid container item justifyItems="flex-end">
                <Typography variant="caption" color="textSecondary">
                  Submitted {formatDateTime(promotionRequest.submittedTime)}
                </Typography>
              </Grid>
              {promotionRequestApproved(promotionRequest) && promotionRequest.approvedTime && (
                <Grid container item justifyItems="flex-end">
                  <Typography variant="caption" color="textSecondary">
                    Approved {formatDateTime(promotionRequest.approvedTime)}
                  </Typography>
                </Grid>
              )}
              {promotionRequestDeclined(promotionRequest) && promotionRequest.declinedTime && (
                <Grid container item justifyItems="flex-end">
                  <Typography variant="caption" color="textSecondary">
                    Declined {formatDateTime(promotionRequest.declinedTime)}
                  </Typography>
                </Grid>
              )}
              {promotionRequestCompleted(promotionRequest) && promotionRequest.completedTime && (
                <Grid container item justifyItems="flex-end">
                  <Typography variant="caption" color="textSecondary">
                    Done {formatDateTime(promotionRequest.completedTime)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <PromotionRequestsFormAnswers promotionRequest={promotionRequest} />
          <Grid container item xs={12}>
            <Grid xs={6} item direction="column" container justifyItems="center" alignItems="center">
              {hod && approve && decline ? (
                <Grid item>
                  <Button variant="outlined" sx={Status(theme).APPROVED} onClick={approve} size="small">
                    Approve
                  </Button>
                  <Button variant="outlined" sx={Status(theme).DECLINED} onClick={decline} size="small">
                    Decline
                  </Button>
                </Grid>
              ) : (
                <>
                  <Typography variant="body2" color="textSecondary">
                    Approval Status
                  </Typography>
                  <Typography sx={Status(theme)[promotionRequestStatus(promotionRequest)]}>
                    {promotionRequestStatus(promotionRequest)}
                  </Typography>
                </>
              )}
            </Grid>
            {promotionRequestApproved(promotionRequest) && (
              <Grid xs={6} item direction="column" container justifyItems="center" alignItems="center">
                <Typography variant="body2" color="textSecondary">
                  Status
                </Typography>
                {hod && !promotionRequestCompleted(promotionRequest) && (
                  <Grid item>
                    <Button variant="outlined" sx={Status(theme).APPROVED} onClick={complete}>
                      COMPLETE
                    </Button>
                  </Grid>
                )}
                {promotionRequestCompleted(promotionRequest) && (
                  <Typography sx={Status(theme).APPROVED}>COMPLETED</Typography>
                )}
                {!hod && !promotionRequestCompleted(promotionRequest) && (
                  <Typography sx={Status(theme).PENDING}>PENDING</Typography>
                )}
              </Grid>
            )}
          </Grid>
          {(promotionRequest as PromotionRequestDeclined).reason && (
            <Grid
              container
              item
              xs={12}
              sx={{
                mt: theme.spacing(2),
              }}
            >
              <TextField
                disabled
                fullWidth
                label="Declined Reason"
                sx={{
                  "&:disabled": {
                    color: theme.palette.common.black,
                  },
                }}
                value={(promotionRequest as PromotionRequestDeclined).reason}
                variant="outlined"
              />
            </Grid>
          )}
          <Divider
            sx={{
              mt: theme.spacing(1),
            }}
          />
          <Grid container direction="column" justifyItems="center" alignItems="center">
            {promotionRequest.hod && (
              <Link
                sx={Status(theme)[promotionRequest.approvedByHOD]}
                component={ReactRouterLink}
                underline="hover"
                to={`${Path.Profile}/${promotionRequest.hod.id}`}
              >
                HOD ({promotionRequest.hod.firstName}): {promotionRequest.approvedByHOD}
              </Link>
            )}
          </Grid>
          <PromotionRequestCommentSection
            promotionRequest={promotionRequest}
            staff={staff}
            handleSubmit={handleCommentSubmit}
            handleDelete={
              !promotionRequestDeclined(promotionRequest) && !promotionRequest.completed
                ? handleCommentDelete
                : undefined
            }
          />
        </Paper>
      </Grid>
    </Accordion>
  );
};
