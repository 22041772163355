import { SyntheticEvent, useState } from "react";
import { Link as ReactRouterLink, useHistory } from "react-router-dom";
import { Box, Button, Card, CardActions, CardContent, Grid, Link, Paper, Typography } from "@mui/material";
import { Path } from "../../../helpers/Path";
import withStyles from "@mui/styles/withStyles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Resource } from "../../../models/Resource";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../services/firebase";
import { useTheme } from "@mui/styles";

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
      borderLeft: "2px solid rgba(0, 0, 0, .125)",
      borderRight: "2px solid rgba(0, 0, 0, .125)",
      borderBottom: "2px solid rgba(0, 0, 0, .125)",
      borderRadius: 20,
      marginBottom: 30,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    border: "2px solid rgba(0, 0, 0, .125)",
    marginBottom: 10,
    minHeight: 40,
    borderRadius: 20,
    "&$expanded": {
      minHeight: 40,
      borderRight: 0,
      borderLeft: 0,
      borderRadius: 0,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const ResourcesCard = ({ resource, noCardActions }: { resource: Resource; noCardActions?: boolean }) => {
  const theme = useTheme();
  return (
    <Paper elevation={8}>
      <Card>
        <CardContent
          sx={{
            minHeight: 756,
            padding: theme.spacing(3, 3, 1, 3),
          }}
        >
          {resource.folders?.map((r, i) => (
            <FolderComponent key={i} resource={r} noCardActions={noCardActions} />
          ))}
        </CardContent>
        {!noCardActions && (
          <CardActions
            sx={{
              padding: theme.spacing(1, 2, 2, 1),
            }}
          >
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Link component={ReactRouterLink} underline="hover" to={Path.Resources}>
                <Grid container alignItems="center" justifyContent="center">
                  <Typography variant="body2">Go to resources</Typography>
                  <ArrowForwardIcon />
                </Grid>
              </Link>
            </Grid>
          </CardActions>
        )}
      </Card>
    </Paper>
  );
};

const FolderComponent = ({ resource, noCardActions }: { resource: Resource; noCardActions?: boolean }) => {
  const theme = useTheme();
  const history = useHistory();
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = (_: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };
  return (
    <Box
      onClick={() => !noCardActions && history.push(Path.Resources)}
      sx={{ marginBottom: expanded ? theme.spacing(3) : theme.spacing(0) }}
    >
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary expandIcon={<ArrowDropDownCircleIcon color="primary" />} id="resources">
          <Typography>{resource.folderName}</Typography>
        </AccordionSummary>
        <Grid
          direction="column"
          container
          spacing={1}
          sx={{
            padding: theme.spacing(0, 2, 0, 2),
            marginBottom: theme.spacing(1),
          }}
        >
          {resource.items.map((file, j) => (
            <Grid item key={j}>
              <Link
                component="button"
                align="left"
                variant="body2"
                color="textPrimary"
                onClick={() => getDownloadURL(ref(storage, file.fullPath)).then((r) => window.open(r))}
              >
                {file.name}
              </Link>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            margin: theme.spacing(0, 1, 0, 1),
          }}
        >
          {resource.folders?.map((r, i) => (
            <FolderComponent key={i} resource={r} />
          ))}
        </Box>
        <Grid container justifyContent="center">
          <Button
            onClick={() => setExpanded(false)}
            size="small"
            sx={{ bottom: theme.spacing(-2) }}
            variant="contained"
          >
            Collapse
          </Button>
        </Grid>
      </Accordion>
    </Box>
  );
};
