import { ReviewAverageGroup, YearAndTerm } from "../../../models/Review360Model";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  registerables as registerablesJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { blue } from "@mui/material/colors";
import { ColoursDepartments } from "../../../data/colours";

export const Review360Progression = ({
  pastYearAveragesOfAll,
  pastYearAndTerms,
  pastYearAverages,
}: {
  pastYearAveragesOfAll: number[];
  pastYearAndTerms: YearAndTerm[];
  pastYearAverages: ReviewAverageGroup[];
}) => {
  ChartJS.register(...registerablesJS);
  ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

  const yearAndTermToString = (yearAndTerm: YearAndTerm): string => `${yearAndTerm.year} ${yearAndTerm.term}`;

  const labels = pastYearAndTerms.map((pastYearAndTerm) => yearAndTermToString(pastYearAndTerm));

  const groupColours = {
    Self: ColoursDepartments.Missions_1 as string,
    Mentor: ColoursDepartments.SOW_2 as string,
    Others: ColoursDepartments.SOW_5 as string,
  };

  const options = {
    scales: {
      y: {
        min: 0,
        max: 7,
      },
    },
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    spanGaps: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Your Progression",
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        type: "line" as const,
        label: "All Others",
        data: pastYearAveragesOfAll,
        backgroundColor: blue[800],
        borderColor: blue[800],
        pointStyle: "rect",
      },
      {
        label: "Self",
        data: pastYearAverages.map((pastYearAverage) => pastYearAverage.Self),
        backgroundColor: groupColours.Self,
      },
      {
        label: "Mentor",
        data: pastYearAverages.map((pastYearAverage) => pastYearAverage.Mentor),
        backgroundColor: groupColours.Mentor,
      },
      {
        label: "Others",
        data: pastYearAverages.map((pastYearAverage) => pastYearAverage.Others),
        backgroundColor: groupColours.Others,
      },
    ],
  };

  return <Chart type="bar" options={options} data={data} />;
};
