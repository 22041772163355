import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { User, onAuthStateChanged } from "firebase/auth";
import { useLayoutEffect, useState } from "react";
import CacheBuster from "react-cache-buster";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import packageJson from "../package.json";
import { Error } from "./components/Error";
import { Loading } from "./components/Loading";
import { PrivateRoute } from "./components/PrivateRoute";
import { PurePublicRoute } from "./components/PurePublicRoute";
import {
  DATA_AND_IT,
  EVENTS,
  FINANCE,
  HUMAN_RESOURCES,
  MARKETING,
  MISSIONS,
  PEOPLE_AND_CULTURE,
  TRAINING_AND_DEVELOPMENT,
} from "./data/Departments";
import { STAFF } from "./data/Roles";
import { DEPLOYMENT_ENVIRONMENT } from "./helpers/Env";
import { Path } from "./helpers/Path";
import { SOWTheme } from "./helpers/Theme";
import { Review360HR } from "./pages/360-reviews/360-review-hr/Review360HR";
import { Review360 } from "./pages/360-reviews/360-review/Review360";
import { Review360Member } from "./pages/360-reviews/360-review/Review360Member";
import { Review360Review } from "./pages/360-reviews/360-review/Review360Review";
import { Admin } from "./pages/Admin";
import { PageNotFound } from "./pages/PageNotFound";
import { Announcements } from "./pages/announcements/Announcements";
import { Attendance } from "./pages/attendance/Attendance";
import { ForgotPassword } from "./pages/authentication/ForgotPassword";
import { SignIn } from "./pages/authentication/SignIn";
import { SignUp } from "./pages/authentication/SignUp";
import { Budgets } from "./pages/budgets/Budgets";
import { Calendar } from "./pages/calendar/Calendar";
import { ContactUs } from "./pages/contact-us/ContactUs";
import { ViewFeedback } from "./pages/contact-us/ViewFeedback";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { DesignRequests } from "./pages/design-requests/DesignRequests";
import { PastYearsDesignRequests } from "./pages/design-requests/PastYearsDesignRequests";
import { EventForm } from "./pages/event-form/EventForm";
import { PastYearsEvents } from "./pages/event-form/PastYearsEvents";
import { SetRegistrationReviewer } from "./pages/event-form/SetRegistrationReviewer";
import { SubForm } from "./pages/event-form/sub-forms/SubForm";
import { Metrics } from "./pages/metrics/Metrics";
import { EditMissionsData } from "./pages/missions-support/EditMissionsData";
import { MissionsSupport } from "./pages/missions-support/MissionsSupport";
import { EditModule } from "./pages/modules/EditModule";
import { EditModules } from "./pages/modules/EditModules";
import { Module } from "./pages/modules/Module";
import { ModuleAnswers } from "./pages/modules/ModuleAnswers";
import { Modules } from "./pages/modules/Modules";
import { OrganisationChart } from "./pages/organisation-chart/OrganisationChart";
import { UpdateUsers } from "./pages/organisation-rearrangement/UpdateUsers";
import { UpdateUsersNextYear } from "./pages/organisation-rearrangement/UpdateUsersNextYear";
import { OrganisationStructure } from "./pages/organisation-structure/OrganisationStructure";
import { OrganisationStructureNextYear } from "./pages/organisation-structure/OrganisationStructureNextYear";
import { PersonalDevelopmentPlan } from "./pages/personal-development-plan/PersonalDevelopmentPlan";
import { EditPledgesGoals } from "./pages/pledges/EditPledgesGoals";
import { Pledges } from "./pages/pledges/Pledges";
import { Profile } from "./pages/profile/Profile";
import { ResetPassword } from "./pages/profile/ResetPassword";
import { PastYearsPromotionRequests } from "./pages/promotion-requests/PastYearsPromotionRequests";
import { PromotionRequests } from "./pages/promotion-requests/PromotionRequests";
import { PastYearsRequests } from "./pages/request-form/PastYearsRequests";
import { Requests } from "./pages/request-form/Requests";
import { SetBudgetManager } from "./pages/request-form/set-budget-manager/SetBudgetManager";
import { Resources } from "./pages/resources/Resources";
import { UpdateMentors } from "./pages/update-mentors/UpdateMentors";
import { auth } from "./services/firebase";
import "./styles.css";
import { EventFormDownload } from "./pages/event-form/EventFormDownload";
import { AllModules } from "./pages/modules/AllModules";

function App() {
  const [state, setState] = useState<{
    currentUser: User | null;
    loading: boolean;
  }>({ currentUser: null, loading: true });

  useLayoutEffect(() => {
    onAuthStateChanged(auth, (currentUser) => setState({ currentUser, loading: false }));
    // eslint-disable-next-line
  }, []);

  const { currentUser, loading } = state;

  return (
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled={DEPLOYMENT_ENVIRONMENT === "production" || DEPLOYMENT_ENVIRONMENT === "development"} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
    >
      {loading ? (
        <Loading />
      ) : (
        <StyledEngineProvider injectFirst>
          <CssBaseline />
          <ThemeProvider theme={SOWTheme}>
            <Router>
              <Switch>
                <PurePublicRoute path={Path.SignUp} currentUser={currentUser} component={SignUp} />
                <PurePublicRoute path={Path.SignIn} currentUser={currentUser} component={SignIn} />
                <PurePublicRoute path={Path["Forgot Password"]} currentUser={currentUser} component={ForgotPassword} />
                <PrivateRoute exact path={Path.Dashboard} currentUser={currentUser} component={Dashboard} />
                <PrivateRoute path={Path.Resources} currentUser={currentUser} component={Resources} />
                <PrivateRoute path={`${Path.Profile}/:user_id`} currentUser={currentUser} component={Profile} />
                <PrivateRoute path={Path["Reset Password"]} currentUser={currentUser} component={ResetPassword} />
                <PrivateRoute path={Path.Calendar} currentUser={currentUser} component={Calendar} />
                <PrivateRoute
                  path={`${Path["Organisation Chart"]}/:year`}
                  currentUser={currentUser}
                  component={OrganisationChart}
                />
                <PrivateRoute
                  path={Path["Update Users"]}
                  currentUser={currentUser}
                  component={UpdateUsers}
                  atLeastRole={STAFF}
                  departments={[PEOPLE_AND_CULTURE, HUMAN_RESOURCES]}
                />
                <PrivateRoute
                  path={Path["Update Users for Next Year"]}
                  currentUser={currentUser}
                  component={UpdateUsersNextYear}
                  atLeastRole={STAFF}
                  departments={[PEOPLE_AND_CULTURE, HUMAN_RESOURCES]}
                />
                <PrivateRoute
                  path={Path["Organisation Structure"]}
                  currentUser={currentUser}
                  component={OrganisationStructure}
                  atLeastRole={STAFF}
                  departments={[PEOPLE_AND_CULTURE, HUMAN_RESOURCES]}
                />
                <PrivateRoute
                  path={Path["Organisation Structure for Next Year"]}
                  currentUser={currentUser}
                  component={OrganisationStructureNextYear}
                  atLeastRole={STAFF}
                  departments={[PEOPLE_AND_CULTURE, HUMAN_RESOURCES]}
                />
                <PrivateRoute
                  path={Path["Reimbursement Requests"]}
                  currentUser={currentUser}
                  component={Requests}
                  atLeastRole={STAFF}
                />
                <PrivateRoute path={Path.Metrics} currentUser={currentUser} component={Metrics} />
                <PrivateRoute
                  path={Path["Set Budget Manager"]}
                  currentUser={currentUser}
                  component={SetBudgetManager}
                  atLeastRole={STAFF}
                  departments={[FINANCE]}
                />
                <PrivateRoute
                  path={Path["Set Registration Reviewer"]}
                  currentUser={currentUser}
                  component={SetRegistrationReviewer}
                  atLeastRole={STAFF}
                  departments={[DATA_AND_IT]}
                />
                <PrivateRoute
                  path={Path["Edit Pledges Goals"]}
                  currentUser={currentUser}
                  component={EditPledgesGoals}
                  atLeastRole={STAFF}
                  departments={[FINANCE]}
                />
                <PrivateRoute
                  path={Path.Admin}
                  currentUser={currentUser}
                  component={Admin}
                  atLeastRole={STAFF}
                  departments={DATA_AND_IT}
                />
                <PrivateRoute
                  path={Path["Staff and Alumni Pledges"]}
                  currentUser={currentUser}
                  component={Pledges}
                  atLeastRole={STAFF}
                />
                <PrivateRoute path={Path["Contact Us"]} currentUser={currentUser} component={ContactUs} />
                <PrivateRoute
                  path={Path["View Feedback"]}
                  currentUser={currentUser}
                  component={ViewFeedback}
                  atLeastRole={STAFF}
                />
                <PrivateRoute path={Path.Modules} currentUser={currentUser} component={Modules} />
                <PrivateRoute
                  path={`${Path["Module Answers"]}/:year/:month`}
                  currentUser={currentUser}
                  component={ModuleAnswers}
                  departments={[TRAINING_AND_DEVELOPMENT, HUMAN_RESOURCES]}
                  atLeastRole={STAFF}
                />
                <PrivateRoute path={`${Path.Module}/:id/:userid`} currentUser={currentUser} component={Module} />
                <PrivateRoute
                  path={Path["Edit Modules"]}
                  currentUser={currentUser}
                  component={EditModules}
                  atLeastRole={STAFF}
                  departments={[TRAINING_AND_DEVELOPMENT, HUMAN_RESOURCES]}
                />
                <PrivateRoute
                  path={Path["All Modules"]}
                  currentUser={currentUser}
                  component={AllModules}
                  atLeastRole={STAFF}
                  departments={[TRAINING_AND_DEVELOPMENT, HUMAN_RESOURCES]}
                />
                <PrivateRoute
                  path={`${Path["Edit Module"]}/:id`}
                  currentUser={currentUser}
                  component={EditModule}
                  atLeastRole={STAFF}
                />
                <PrivateRoute
                  path={Path["Design Requests"]}
                  currentUser={currentUser}
                  component={DesignRequests}
                  atLeastRole={STAFF}
                />
                <PrivateRoute
                  path={`${Path["Past Year's Design Requests"]}/:id`}
                  currentUser={currentUser}
                  component={PastYearsDesignRequests}
                  atLeastRole={STAFF}
                  departments={[MARKETING]}
                />
                <PrivateRoute
                  path={Path["Promotion Requests"]}
                  currentUser={currentUser}
                  component={PromotionRequests}
                  atLeastRole={STAFF}
                />
                <PrivateRoute
                  path={`${Path["Past Year's Promotion Requests"]}/:id`}
                  currentUser={currentUser}
                  component={PastYearsPromotionRequests}
                  atLeastRole={STAFF}
                  departments={[MARKETING]}
                />
                <PrivateRoute
                  path={`${Path["Past Year's Requests"]}/:id`}
                  currentUser={currentUser}
                  component={PastYearsRequests}
                  atLeastRole={STAFF}
                  departments={[FINANCE]}
                />
                <PrivateRoute
                  path={`${Path["Past Year's Events"]}/:id`}
                  currentUser={currentUser}
                  component={PastYearsEvents}
                  atLeastRole={STAFF}
                  departments={[EVENTS]}
                />
                <PrivateRoute path={Path.Announcements} currentUser={currentUser} component={Announcements} />
                <PrivateRoute path={Path.Attendance} currentUser={currentUser} component={Attendance} />
                <PrivateRoute path={Path["360 Reviews"]} currentUser={currentUser} component={Review360} />
                <PrivateRoute
                  path={Path["360 Reviews Setup"]}
                  currentUser={currentUser}
                  component={Review360HR}
                  atLeastRole={STAFF}
                  departments={[TRAINING_AND_DEVELOPMENT, HUMAN_RESOURCES]}
                />
                <PrivateRoute
                  path={Path["Budgets"]}
                  currentUser={currentUser}
                  component={Budgets}
                  atLeastRole={STAFF}
                />
                <PrivateRoute
                  path={`${Path["Personal Development Plan"]}/:id`}
                  currentUser={currentUser}
                  component={PersonalDevelopmentPlan}
                />
                <PrivateRoute
                  path={Path["Update Mentees and Mentors"]}
                  currentUser={currentUser}
                  component={UpdateMentors}
                  atLeastRole={STAFF}
                />
                <PrivateRoute
                  path={`${Path["Missions Support"]}/:id`}
                  currentUser={currentUser}
                  component={MissionsSupport}
                />
                <PrivateRoute
                  path={Path["Edit Missions Data"]}
                  currentUser={currentUser}
                  component={EditMissionsData}
                  atLeastRole={STAFF}
                  departments={[MISSIONS]}
                />
                <PrivateRoute
                  path={Path["Event Forms"]}
                  currentUser={currentUser}
                  component={EventForm}
                  atLeastRole={STAFF}
                />
                <PrivateRoute
                  path={`${Path["Event Form Download"]}/:year/:id`}
                  currentUser={currentUser}
                  component={EventFormDownload}
                  atLeastRole={STAFF}
                />
                <PrivateRoute
                  path={`${Path["Event Sub Form"]}/:year/:type/:id`}
                  currentUser={currentUser}
                  component={SubForm}
                  atLeastRole={STAFF}
                />
                <Route path={`${Path["360 Review Form"]}/:id/:email?`} component={Review360Review} />
                <Route path={Path["360 Reviews - Member"]} component={Review360Member} />
                <Route path={`${Path.Error}/:message`} component={Error} />
                <PrivateRoute currentUser={currentUser} component={PageNotFound} />
              </Switch>
            </Router>
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </CacheBuster>
  );
}

export default App;
