import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useContext } from "react";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { DepartmentsContext } from "../../components/PrivateRoute";
import { HEAD_OF_DEPARTMENT } from "../../data/Roles";
import { PromotionRequest } from "../../models/PromotionRequest";
import { PromotionRequestDeclined } from "../../models/PromotionRequestDeclined";
import {
  approvePromotionRequest,
  completePromotionRequest,
  declinePromotionRequest,
} from "../../services/promotionRequests";
import { PromotionRequestsInterface } from "./PromotionRequests";
import { PromotionRequestsComponent } from "./PromotionRequestsComponent";
import { PromotionRequestsDeclineConfirmationDialog } from "./PromotionRequestsDeclineConfirmationDialog";

export const PromotionRequestsList = ({
  name,
  requestsToApprove,
  requestsApproved,
  requestsCompletedOrDeclined,
  state,
  setState,
}: {
  name: string;
  requestsToApprove: PromotionRequest[];
  requestsApproved: PromotionRequest[];
  requestsCompletedOrDeclined: PromotionRequest[];
  state: PromotionRequestsInterface;
  setState: (state: PromotionRequestsInterface) => void;
}) => {
  const theme = useTheme();
  const departments = useContext(DepartmentsContext);

  const { declineDialogOpen, completeDialogOpen, currRequest, staff } = state;

  const approveRequest = (requestApproved: PromotionRequest) =>
    approvePromotionRequest(requestApproved)
      .then((approvedRequest) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: "Successfully approved request!",
            severity: "success",
          },
          [`requestsToApprove${name}`]: requestsToApprove.filter((obj) => obj.id !== approvedRequest.id),
          [`requestsApproved${name}`]: [...requestsApproved, approvedRequest],
          currRequest: undefined,
        })
      )
      .catch((error) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: error.message,
            severity: "error",
          },
        })
      );

  const declineRequest = (requestDeclined: PromotionRequestDeclined) =>
    declinePromotionRequest(requestDeclined)
      .then((declinedRequest) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message:
              "Successfully declined. An email has been sent to " +
              declinedRequest.staff.firstName +
              " notifying that you have declined the request.",
            severity: "success",
          },
          declineDialogOpen: false,
          [`requestsToApprove${name}`]: requestsToApprove.filter((obj) => obj.id !== declinedRequest.id),
          [`requestsCompletedOrDeclined${name}`]: [...requestsCompletedOrDeclined, declinedRequest],
          currRequest: undefined,
        })
      )
      .catch((error) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: error.message,
            severity: "error",
          },
          declineDialogOpen: false,
        })
      );

  const complete = () =>
    completePromotionRequest(currRequest as PromotionRequest)
      .then((paidRequest) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: "Completed! An email has been sent to the user",
            severity: "success",
          },
          completeDialogOpen: false,
          [`requestsApproved${name}`]: requestsApproved.filter((obj) => obj.id !== paidRequest.id),
          [`requestsCompletedOrDeclined${name}`]: [...requestsCompletedOrDeclined, paidRequest],
          currRequest: undefined,
        })
      )
      .catch((error) =>
        setState({
          ...state,
          alert: {
            showMessage: true,
            message: error.message,
            severity: "error",
          },
          completeDialogOpen: false,
        })
      );

  return (
    <>
      <ConfirmationModal
        title={"Complete Promotion Request"}
        content={"This will complete the request."}
        confirmText={"Complete"}
        cancelText={"Cancel"}
        dialogOpen={completeDialogOpen}
        closeDialog={() => setState({ ...state, completeDialogOpen: false })}
        doAction={complete}
      />
      <PromotionRequestsDeclineConfirmationDialog
        currRequest={currRequest as PromotionRequest}
        dialogOpen={declineDialogOpen}
        closeDialog={() => setState({ ...state, declineDialogOpen: false })}
        declineRequest={declineRequest}
      />
      <Typography
        variant="h6"
        sx={{
          mt: theme.spacing(2),
        }}
      >
        Promotion Requests
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={12} md={4}>
          <Typography>Promotion Requests to Approve</Typography>
          {requestsToApprove?.map((dr, i) => (
            <PromotionRequestsComponent
              key={i}
              hod={staff?.department === departments.MARKETING && staff?.role === HEAD_OF_DEPARTMENT}
              promotionRequest={dr}
              approve={() => approveRequest(dr)}
              decline={() =>
                setState({
                  ...state,
                  declineDialogOpen: true,
                  currRequest: dr,
                })
              }
              staff={staff}
              handleCommentSubmit={(alertState, comment) =>
                setState({
                  ...state,
                  alert: alertState,
                  [`requestsToApprove${name}`]: requestsToApprove.map((r) => {
                    if (r.id === dr.id && comment) {
                      return {
                        ...r,
                        commentSection: [...(r.commentSection ?? []), comment],
                      };
                    }
                    return r;
                  }),
                })
              }
              handleCommentDelete={(alertState, comment_id) =>
                setState({
                  ...state,
                  alert: alertState,
                  [`requestsToApprove${name}`]: requestsToApprove.map((r) => {
                    if (r.id === dr.id) {
                      return {
                        ...r,
                        commentSection: (r.commentSection ?? []).filter((c) => c.id !== comment_id),
                      };
                    }
                    return r;
                  }),
                })
              }
            />
          ))}
        </Grid>
        <Grid item sm={12} md={4}>
          <Typography>Requests Approved</Typography>
          {requestsApproved?.map((dr, i) => {
            return (
              <PromotionRequestsComponent
                key={i}
                promotionRequest={dr}
                complete={() =>
                  setState({
                    ...state,
                    completeDialogOpen: true,
                    currRequest: dr,
                  })
                }
                staff={staff}
                hod={staff?.department === departments.MARKETING && staff?.role === HEAD_OF_DEPARTMENT}
                handleCommentSubmit={(alertState, comment) =>
                  setState({
                    ...state,
                    alert: alertState,
                    [`requestsApproved${name}`]: requestsApproved.map((r) => {
                      if (r.id === dr.id && comment) {
                        return {
                          ...r,
                          commentSection: [...(r.commentSection ?? []), comment],
                        };
                      }
                      return r;
                    }),
                  })
                }
                handleCommentDelete={(alertState, comment_id) =>
                  setState({
                    ...state,
                    alert: alertState,
                    [`requestsApproved${name}`]: requestsApproved.map((r) => {
                      if (r.id === dr.id) {
                        return {
                          ...r,
                          commentSection: (r.commentSection ?? []).filter((c) => c.id !== comment_id),
                        };
                      }
                      return r;
                    }),
                  })
                }
              />
            );
          })}
        </Grid>
        <Grid item sm={12} md={4}>
          <Typography gutterBottom>Requests Completed or Declined</Typography>
          <Grid container>
            {requestsCompletedOrDeclined?.map((dr, i) => (
              <PromotionRequestsComponent key={i} promotionRequest={dr} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
