import { FormikProps, Form } from "formik";
import {
  TextField,
  CircularProgress,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { DesignRequestDeclined } from "../../models/DesignRequestDeclined";

export const DesignRequestsDeclineForm = ({
  props,
  handleClose,
}: {
  props: FormikProps<DesignRequestDeclined>;
  handleClose: () => void;
}) => {
  const theme = useTheme();
  const { values, errors, touched, handleBlur, handleChange, isSubmitting } = props;
  return (
    <Box
      sx={{
        width: "100%", // Fix IE 11 issue.
        mt: theme.spacing(3),
      }}
    >
      <Form>
        <DialogTitle id="form-dialog-title">Decline Design Request</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please submit a reason below. The requestee will be notified.
          </DialogContentText>
          <TextField
            id="reason"
            fullWidth
            required
            placeholder="Reason"
            multiline
            minRows={4}
            maxRows={6}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.reason && touched.reason ? errors.reason : ""}
            value={values.reason}
            error={errors.reason && touched.reason ? true : false}
          />
        </DialogContent>
        {!isSubmitting ? (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit">Decline</Button>
          </DialogActions>
        ) : (
          <CircularProgress color="secondary" />
        )}
      </Form>
    </Box>
  );
};
