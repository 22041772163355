import { Website } from "../models/Website";

export const christianPsychologistsData: Website[] = [
  {
    link: "https://milliganhealth.com.au/robynmilligan/",
    name: "Dr. Robyn Milligan",
  },
  {
    link: "https://www.rayofhope.com.au/",
    name: "Ray of Hope Clinic",
  },
  {
    link: "https://www.theresiliencecentre.com.au/",
    name: "The Resilience Centre",
  },
  {
    link: "http://www.delhiroadclinic.com.au/clinicians/dr-linda-nguy/",
    name: "Dr Linda Nguy",
  },
  {
    link: "https://www.effectiveliving.com.au/",
    name: "Effective Living",
  },
  {
    link: "https://thetalbotcentre.com.au/",
    name: "The Talbot Centre",
  },
];
