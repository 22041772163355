import { AlertColor } from "@mui/lab/Alert";

export interface MessageState {
  showMessage: boolean;
  message: string;
  severity: AlertColor;
}

export const InitMessageState: MessageState = {
  showMessage: false,
  message: "",
  severity: "success",
};
