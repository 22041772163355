export enum DesignRequestProductType {
  brochure = "Brochure",
  flyer = "Flyer",
  booklet = "Booklet",
  facebookbanner = "Facebook banner",
  infographic = "Infographic",
  invitation = "Invitation",
  logo = "Logo (eg. for SOW Camp)",
  merchandise = "Merchandise",
  poster = "Poster",
  powerpointPresentation = "Powerpoint presentation",
  other = "Other",
}
