import { Website } from "../models/Website";

export const websitesData: Website[] = [
  {
    link: "https://www.ligonier.org/",
    name: "Ligonier",
  },
  {
    link: "https://christcollege.edu.au/",
    name: "Christ College",
  },
  {
    link: "https://www.monergism.com/",
    name: "Monergism",
  },
  {
    link: "https://rts.edu/",
    name: "Reformed Theological Seminary",
  },
  {
    link: "https://www.covenantseminary.edu/",
    name: "Covenant Seminary",
  },
  {
    link: "https://www.acl.org.au/",
    name: "ACL - Faith and Politics",
  },
  {
    link: "https://biologos.org/",
    name: "BioLogos - Faith and Science",
  },
  {
    link: "https://truthxchange.com/",
    name: "truthxchange - Faith and Society",
  },
];
