import { useContext, useEffect, useState } from "react";
import { Path } from "../../helpers/Path";
import { Loading } from "../../components/Loading";
import { useParams, Link as ReactRouterLink, useHistory } from "react-router-dom";
import { Staff } from "../../models/Staff";
import { PastYearsDesignRequestsComponent } from "./PastYearsDesignRequestsComponent";
import { Container, Link, Typography } from "@mui/material";
import { DepartmentsContext, UserContext } from "../../components/PrivateRoute";
import { DesignRequest } from "../../models/DesignRequest";
import { getYearDesignRequests } from "../../services/designRequests";

interface PastYearsRequestsInterface {
  loading: boolean;
  staff?: Staff;
  pastDesignRequests: DesignRequest[];
}

export const PastYearsDesignRequests = () => {
  const user = useContext(UserContext) as Staff;
  const departments = useContext(DepartmentsContext);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [state, setState] = useState<PastYearsRequestsInterface>({
    loading: true,
    pastDesignRequests: [],
  });

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });

    const pastDesignRequestsFetch = getYearDesignRequests(id);

    Promise.all([pastDesignRequestsFetch]).then(([pastDesignRequests]) => {
      if (user.department !== departments.MARKETING) {
        history.push(Path["Design Requests"]);
      } else {
        setState({
          ...state,
          pastDesignRequests: pastDesignRequests,
          loading: false,
        });
      }
    });

    // eslint-disable-next-line
  }, [id]);

  const { loading, pastDesignRequests } = state;

  return loading ? (
    <Loading />
  ) : (
    <>
      <Container maxWidth="md">
        <Link to={Path["Design Requests"]} component={ReactRouterLink}>
          back
        </Link>
        <Typography align="center">Design Requests History - Year: {id}</Typography>
        {pastDesignRequests.map((dr, i) => (
          <PastYearsDesignRequestsComponent key={i} designRequest={dr} staff={user} />
        ))}
      </Container>
    </>
  );
};
