import { Grid } from "@mui/material";
import { DesignRequest } from "../../models/DesignRequest";
import { DesignRequestsInterface } from "./DesignRequests";
import { DesignRequestsComponent } from "./DesignRequestsComponent";
import { designRequestCompleted, designRequestDeclined } from "../../services/designRequests";

export const DesignRequestsMyList = ({
  name,
  requests,
  state,
  setState,
}: {
  name: string;
  requests: DesignRequest[];
  state: DesignRequestsInterface;
  setState: (state: DesignRequestsInterface) => void;
}) => {
  const { myRequests, staff } = state;

  return (
    <Grid container>
      {requests?.map((dr, i) => {
        return designRequestDeclined(dr) || designRequestCompleted(dr) ? (
          <DesignRequestsComponent key={i} staff={staff} designRequest={dr} />
        ) : (
          <DesignRequestsComponent
            key={i}
            staff={staff}
            handleCommentSubmit={(alertState, comment) =>
              setState({
                ...state,
                alert: alertState,
                myRequests: myRequests.map((r) => {
                  if (r.id === dr.id && comment) {
                    return {
                      ...r,
                      commentSection: [...(r.commentSection ?? []), comment],
                    };
                  }
                  return r;
                }),
              })
            }
            handleCommentDelete={(alertState, comment_id) =>
              setState({
                ...state,
                alert: alertState,
                myRequests: myRequests.map((r) => {
                  if (r.id === dr.id) {
                    return {
                      ...r,
                      commentSection: (r.commentSection ?? []).filter((c) => c.id !== comment_id),
                    };
                  }
                  return r;
                }),
              })
            }
            designRequest={dr}
            cancelDesignRequest={() =>
              setState({
                ...state,
                cancelRequestDialogOpen: true,
                currRequest: dr,
                myRequestsName: name,
                myRequestsRequests: requests,
              })
            }
          />
        );
      })}
    </Grid>
  );
};
