import { doc, getDoc, setDoc } from "firebase/firestore";
import { currentStaffYear } from "../helpers/FormatDate";
import { InitMentorsHR, MenteeMentorPair, MenteesId, MentorsHR } from "../models/Mentee";
import { User, UserID } from "../models/User";
import { db } from "./firebase";
import { getUserById, getUserByIdYear } from "./users";

export const MENTEES = "mentees";

const findMenteeIds = (mentorsAndMenteesId: MenteesId, mentorId: UserID) =>
  Object.entries(mentorsAndMenteesId)
    .filter(([_, mentor]) => mentor === mentorId)
    .map(([mentee, _]) => mentee);

const getMenteesIdYear = async (year: number) => {
  const mentees = await getDoc(doc(db, MENTEES, year.toString()));
  if (mentees.exists()) {
    return mentees.data().mentees as MenteesId;
  }
  return {};
};

export const getMentorsAndMenteesIdCurrentYear = async () => getMenteesIdYear(currentStaffYear);

export const getMentees = async (mentorId: UserID) => {
  const mentorsAndMenteesId = await getMentorsAndMenteesIdCurrentYear();
  const mentees: User[] = [];
  for (const menteeId of findMenteeIds(mentorsAndMenteesId, mentorId)) {
    mentees.push(await getUserById(menteeId));
  }
  return mentees;
};

export const getMenteesAsHR = async () => {
  const mentorsAndMenteesId = await getMentorsAndMenteesIdCurrentYear();
  const mentees: User[] = [];
  for (const menteeId of Object.keys(mentorsAndMenteesId)) {
    mentees.push(await getUserById(menteeId));
  }
  return mentees;
};

export const getMentorsAndMentees = async (mentorsAndMenteesId: MenteesId) => {
  let mentorsAndMentees: MenteeMentorPair[] = [];
  for (const [menteeId, mentorId] of Object.entries(mentorsAndMenteesId)) {
    mentorsAndMentees.push({
      mentee: await getUserById(menteeId),
      mentor: await getUserById(mentorId),
    });
  }
  return mentorsAndMentees;
};

const updateMentorsAndMenteesYear = async (mentorsAndMentees: MenteeMentorPair[], year: number) =>
  setDoc(
    doc(db, MENTEES, year.toString()),
    {
      mentees: mentorsAndMentees.reduce(
        (a, v) => ({
          ...a,
          [v.mentee.id]: v.mentor.id,
        }),
        {}
      ),
    },
    { merge: true }
  );

export const updateMentorsAndMentees = async (mentorsAndMentees: MenteeMentorPair[]) =>
  updateMentorsAndMenteesYear(mentorsAndMentees, currentStaffYear);

export const getAssignedHRRepYear = async (year: number) => {
  const mentees = await getDoc(doc(db, MENTEES, year.toString()));
  if (mentees.exists()) {
    const assignedHRRepId = mentees.data().assignedHRRep;
    if (assignedHRRepId) {
      return await getUserByIdYear(assignedHRRepId, year.toString());
    }
  }
  return undefined;
};

export const getAssignedHRRep = async () => getAssignedHRRepYear(currentStaffYear);

export const updateAssignedHRRepYear = async (hrRep: User, year: number) =>
  setDoc(doc(db, MENTEES, year.toString()), { assignedHRRep: hrRep.id }, { merge: true });

export const updateAssignedHRRep = async (hrRep: User) => updateAssignedHRRepYear(hrRep, currentStaffYear);

const getMentorsHRYear = async (year: number) => {
  const mentees = await getDoc(doc(db, MENTEES, year.toString()));
  if (mentees.exists()) {
    const assignedHRRepId = mentees.data().assignedHRRep;
    return {
      assignedHRRep: assignedHRRepId ? await getUserByIdYear(assignedHRRepId, year.toString()) : undefined,
      mentorsAndMentees: mentees.data().mentees ? await getMentorsAndMentees(mentees.data().mentees) : [],
    } as MentorsHR;
  }
  return InitMentorsHR;
};

export const getMentorsHR = async () => getMentorsHRYear(currentStaffYear);

const updateMentorsHRYear = async (mentorsHR: MentorsHR, year: number) =>
  setDoc(doc(db, MENTEES, year.toString()), {
    assignedHRRep: mentorsHR.assignedHRRep ? mentorsHR.assignedHRRep.id : "",
    mentees: mentorsHR.mentorsAndMentees.reduce(
      (a, v) => ({
        ...a,
        [v.mentee.id]: v.mentor.id,
      }),
      {}
    ),
  });

export const updateMentorsHR = async (mentorsHR: MentorsHR) => updateMentorsHRYear(mentorsHR, currentStaffYear);
