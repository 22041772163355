import { Grid, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { ColoursCampuses } from "../data/colours";
import { STAFF } from "../data/Roles";

export const LegendComponent = ({ colour, text }: { colour: string; text: String }) => (
  <Grid xs={1.7} item container justifyContent="center" alignItems="center">
    <FiberManualRecordIcon
      style={{
        color: colour,
      }}
    />
    <Typography>{text}</Typography>
  </Grid>
);

export const Legend = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={1}>
      <LegendComponent colour={ColoursCampuses.ALL} text={"All"} />
      <LegendComponent colour={ColoursCampuses.USYD} text={"USYD"} />
      <LegendComponent colour={ColoursCampuses.UNSW} text={"UNSW"} />
      <LegendComponent colour={ColoursCampuses.MACQ} text={"MACQ"} />
      <LegendComponent colour={ColoursCampuses.UTS} text={"UTS"} />
      <LegendComponent colour={ColoursCampuses.ACU} text={"ACU"} />
      <LegendComponent colour={ColoursCampuses.STAFF} text={STAFF} />
    </Grid>
  );
};
