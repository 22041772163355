import { useTheme } from "@mui/styles";
import { Button, Container, Grid } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Footer } from "../../components/Footer";
import { Loading } from "../../components/Loading";
import { Path } from "../../helpers/Path";
import { atLeast } from "../../helpers/Role";
import { RequestFormMenu } from "./RequestFormMenu";
import { getCalendarThisMonthWithReocurringEvents, getRolesResource } from "../../services/calendar";
import { getRecentAnnouncement } from "../../services/announcements";
import { getNModulesToComplete } from "../../services/modules";
import { CalendarInterface } from "../../components/CalendarComponent";
import { Announcement } from "../../models/Announcement";
import { ModulesCard } from "./DashboardCards/ModulesCard";
import { AnnouncementCard } from "./DashboardCards/AnnouncementCard";
import { DailyVerseCard } from "./DashboardCards/DailyVerseCard";
import { ResourcesCard } from "./DashboardCards/ResourcesCard";
import { CalendarCard } from "./DashboardCards/CalendarCard";
import { InitResource, Resource } from "../../models/Resource";
import { getResourcesFoldersOnly } from "../../services/storage";
import { SearchBar } from "./SearchBar";
import { RolesContext, UserContext } from "../../components/PrivateRoute";
import { STAFF } from "../../data/Roles";

interface DashboardInterface {
  loading: boolean;
  requestFormMenu: null | HTMLElement;
  calendarComponent: CalendarInterface;
  announcement: Announcement;
  nModulesToComplete?: number;
  resource: Resource;
  roles: string[];
}

export const Dashboard = () => {
  const history = useHistory();
  const theme = useTheme();
  const user = useContext(UserContext);
  const roles = useContext(RolesContext);
  const [state, setState] = useState<DashboardInterface>({
    loading: true,
    requestFormMenu: null,
    calendarComponent: {
      loading: true,
    },
    announcement: { title: "", content: "", time: new Date(), user: user, tag: "" },
    resource: InitResource,
    roles: [],
  });

  const { loading, requestFormMenu, calendarComponent, announcement, nModulesToComplete, resource } = state;

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });

    const calendarFetch = getCalendarThisMonthWithReocurringEvents(user);
    const announcementFetch = getRecentAnnouncement();
    const nModulesToCompleteFetch = getNModulesToComplete(user);
    const resourcesFetch = getResourcesFoldersOnly();

    Promise.all([calendarFetch, announcementFetch, nModulesToCompleteFetch, resourcesFetch])
      .then(([calendar, announcement, nModulesToComplete, resource]) =>
        setState({
          ...state,
          calendarComponent: {
            ...calendarComponent,
            data: calendar,
            rolesResource: getRolesResource(user.role, roles),
            loading: false,
          },
          announcement: announcement,
          nModulesToComplete,
          resource: resource,
          loading: false,
        })
      )
      .catch((e) => {
        console.log(e);
        history.push(`${Path.Error}/Internal Error`);
      });

    // eslint-disable-next-line
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <Grid
        sx={{
          paddingRight: theme.spacing(4),
        }}
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <SearchBar />
        </Grid>
        <Grid item>
          {atLeast(roles, STAFF, user.role) && (
            <RequestFormMenu
              open={requestFormMenu}
              handleClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                setState({ ...state, requestFormMenu: event.currentTarget })
              }
              handleClose={() => setState({ ...state, requestFormMenu: null })}
            />
          )}
        </Grid>
        <Grid item>
          <Button size="large" variant="contained" onClick={() => window.open("https://www.sow.org.au" as string)}>
            Main Website
          </Button>
        </Grid>
      </Grid>
      <Container maxWidth="lg">
        <Grid
          sx={{
            mt: theme.spacing(3),
          }}
          container
          spacing={5}
          justifyContent="center"
        >
          <Grid item xs={12} md={6} lg={4}>
            <ModulesCard nModulesToComplete={nModulesToComplete} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AnnouncementCard announcement={announcement} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DailyVerseCard />
          </Grid>
        </Grid>
        <Grid
          sx={{
            mt: theme.spacing(1),
          }}
          container
          spacing={5}
          justifyContent="space-between"
        >
          <Grid item xs={12} lg={8}>
            <CalendarCard
              calendarComponent={calendarComponent}
              setState={(calendarComponent) =>
                setState({
                  ...state,
                  calendarComponent: calendarComponent,
                })
              }
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <ResourcesCard resource={resource} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
