export enum Path {
  Dashboard = "/",
  SignIn = "/sign-in",
  SignUp = "/sign-up",
  "Forgot Password" = "/forgot-password",
  "360 Reviews - Member" = "/360-reviews-member",

  // Signed In
  "Reset Password" = "/reset-password",
  Announcements = "/announcements",
  Calendar = "/calendar",
  Resources = "/resources",
  Profile = "/profile",
  "Organisation Chart" = "/organisation-chart",
  Modules = "/modules",
  Module = "/module",
  "Contact Us" = "/contact-us",
  "360 Reviews" = "/360-reviews",
  "360 Review Form" = "/360-review-form",
  "Personal Development Plan" = "/personal-development-plan",

  // HODs
  Metrics = "/metrics",

  // Staff
  "Reimbursement Requests" = "/reimbursement-requests",
  "Design Requests" = "/design-requests",
  "Promotion Requests" = "/promotion-requests",
  "Staff and Alumni Pledges" = "/staff-and-alumni-pledges",
  "View Feedback" = "/view-feedback",
  "Missions Support" = "/missions-support",
  "Edit Missions Data" = "/edit-missions-data",
  "Budgets" = "/budgets",
  "Event Forms" = "/event-form",
  "Event Form Download" = "/event-form-download",
  "Event Sub Form" = "/event-sub-form",

  // R&D
  "All Modules" = "/view-modules",
  "Edit Modules" = "/edit-modules",
  "Edit Module" = "/edit-module",
  "Module Answers" = "/module-answers",

  // Finance
  "Set Budget Manager" = "/set-budget-manager",
  "Edit Pledges Goals" = "/edit-pledges-goals",
  "Past Year's Requests" = "/past-years-finance-requests",

  // Marketing
  "Past Year's Design Requests" = "/past-years-design-requests",
  "Past Year's Promotion Requests" = "/past-years-promotion-requests",

  // HR
  "Update Users" = "/organisation-rearrangement",
  "Update Users for Next Year" = "/organisation-rearrangement-next-year",
  "Organisation Structure" = "/organisation-structure",
  "Organisation Structure for Next Year" = "/organisation-structure-next-year",
  "360 Reviews Setup" = "/360-reviews-setup",
  "Update Mentees and Mentors" = "/update-mentees-and-mentors",

  // Events
  "Past Year's Events" = "/past-years-events",

  // IT
  "Set Registration Reviewer" = "/set-registration-reviewer",

  // Other
  Error = "/error",
  Admin = "/admin",
  Attendance = "/attendance",
}
