import { useEffect, useState } from "react";
import { Container, Grid, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import { Path } from "../../helpers/Path";
import { getReviewerModules, ModuleMonthsThemes, moduleMonthsThemes } from "../../services/modules";
import { Loading } from "../../components/Loading";
import { ReviewerModuleModel } from "../../models/ModuleModel";

export const ModuleAnswers = () => {
  let { year, month } = useParams() as { year: string; month: string };
  const [state, setState] = useState<{
    modules: ReviewerModuleModel[];
    loading: boolean;
  }>({
    loading: true,
    modules: [],
  });

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    getReviewerModules(year, month).then((modules) => setState({ ...state, modules, loading: false }));
    // eslint-disable-next-line
  }, []);

  const { modules, loading } = state;

  let modules1 = modules.filter((m) => m.order === 1);
  let modules2 = modules.filter((m) => m.order === 2);

  const theme = useTheme();
  return !loading ? (
    <>
      <Container maxWidth="lg">
        <Grid
          container
          direction="column"
          sx={{
            mb: theme.spacing(2),
          }}
        >
          <Typography variant="h4">
            {month} - {moduleMonthsThemes[month as keyof ModuleMonthsThemes]}
          </Typography>
          <Typography variant="h5">Week #1</Typography>
          {modules1.map((m, j) => (
            <Grid item key={j}>
              <Typography variant="h6">{m.title}</Typography>
              <Typography variant="h6" sx={{ color: "green" }}>
                Completed
              </Typography>
              {m.usersWhoCompleted.map((u, k) => (
                <Grid item key={k}>
                  <Link
                    sx={{ color: "green" }}
                    key={k}
                    to={Path.Module + "/" + m.id + "/" + u.id}
                    component={ReactRouterLink}
                  >
                    {u.firstName} {u.lastName}
                  </Link>
                </Grid>
              ))}
              <Typography variant="h6" sx={{ color: "red" }}>
                Not Completed
              </Typography>
              {m.usersWhoDidntComplete.map((u, k) => (
                <Grid item key={k}>
                  <Typography sx={{ color: "red" }}>
                    {u.firstName} {u.lastName}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          ))}
          <Typography variant="h5">Week #2</Typography>
          {modules2.map((m, j) => (
            <Grid item key={j}>
              <Typography variant="h6">{m.title}</Typography>
              <Typography variant="h6" sx={{ color: "green" }}>
                Completed
              </Typography>
              {m.usersWhoCompleted.map((u, k) => (
                <Grid item key={k}>
                  <Link
                    sx={{ color: "green" }}
                    key={k}
                    to={Path.Module + "/" + m.id + "/" + u.id}
                    component={ReactRouterLink}
                  >
                    {u.firstName} {u.lastName}
                  </Link>
                </Grid>
              ))}
              <Typography variant="h6" sx={{ color: "red" }}>
                Not Completed
              </Typography>
              {m.usersWhoDidntComplete.map((u, k) => (
                <Grid item key={k}>
                  <Typography sx={{ color: "red" }}>
                    {u.firstName} {u.lastName}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  ) : (
    <Loading />
  );
};
