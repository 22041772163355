import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Loading } from "../../../components/Loading";
import { UserContext } from "../../../components/PrivateRoute";
import { TypographyBold } from "../../../components/TypographyBold";
import {
  Term,
  Review,
  ReviewAverageGroup,
  ReviewHR,
  ReviewQuestions,
  ReviewResults,
  ReviewResultsOfAll,
  ReviewsData,
  ReviewStatus,
  YearAndTerm,
} from "../../../models/Review360Model";
import {
  finaliseReviewers,
  getMyReview,
  getPastYearAndTerms,
  getPastYearAndTermsForMentees,
  getPastYearAverages,
  getPastYearAveragesOfAll,
  getReviewHR,
  getReviewQuestions,
  getReviewResults,
  getReviewResultsOfAll,
  getReviewsAsReviewer,
  getReviewsData,
} from "../../../services/review360";
import { Review360Card } from "./Review360Card";
import { Review360Results } from "./360-review-results/Review360Results";
import { User } from "../../../models/User";
import { Review360Progression } from "./Review360Progression";
import { formatDateTime } from "../../../helpers/FormatDate";
import { Review360HRReviewee } from "../360-review-hr/Review360HRReviewee";
import { getListOfUsers } from "../../../services/users";
import { Review360HRConfirmationDialog } from "../360-review-hr/Review360HRConfirmationDialog";
import { LegendComponent } from "../../../components/Legend";
import { ColoursCampuses } from "../../../data/colours";

interface Review360Interface {
  loading: boolean;
  myReview?: Review;
  pastYearAverages: ReviewAverageGroup[];
  reviewsData?: ReviewsData;
  reviewResults?: ReviewResults;
  reviewResultsOfAll?: ReviewResultsOfAll;
  reviewResultsQuestions: ReviewQuestions;
  reviewQuestions: ReviewQuestions;
  showReviewQuestions: boolean;
  pastYearAndTerms: YearAndTerm[];
  selectedYearAndTerm?: YearAndTerm;
  pastYearAveragesOfAll: number[];
  pastYearAndTermsForMentees: {
    mentee: User;
    pastYearAndTerms: YearAndTerm[];
    selectedYearAndTerm?: YearAndTerm;
  }[];
  reviews: Review[];
  reviewHR?: ReviewHR;
  users: User[];
  reviewersFinalisedDialog: boolean;
}

export const Review360 = () => {
  const user = useContext(UserContext);
  const theme = useTheme();

  const [state, setState] = useState<Review360Interface>({
    pastYearAverages: [],
    reviews: [],
    reviewResultsQuestions: {},
    showReviewQuestions: false,
    reviewQuestions: {},
    pastYearAndTerms: [],
    pastYearAveragesOfAll: [],
    pastYearAndTermsForMentees: [],
    users: [],
    reviewersFinalisedDialog: false,
    loading: true,
  });

  const {
    myReview,
    reviews,
    reviewsData,
    reviewResults,
    reviewResultsOfAll,
    reviewQuestions,
    showReviewQuestions,
    reviewResultsQuestions,
    pastYearAndTerms,
    pastYearAndTermsForMentees,
    pastYearAverages,
    pastYearAveragesOfAll,
    selectedYearAndTerm,
    reviewHR,
    users,
    reviewersFinalisedDialog,
    loading,
  } = state;

  useEffect(() => {
    getReviewsData().then((reviewsData) => {
      const getPastYearAndTermsFetch = getPastYearAndTerms(reviewsData, user);
      const getPastYearAveragesFetch = getPastYearAverages(reviewsData, user);
      const getPastYearAveragesOfAllFetch = getPastYearAveragesOfAll(reviewsData, user);
      const getPastYearAndTermsForMenteesFetch = getPastYearAndTermsForMentees(reviewsData, user);
      const getReviewQuestionsFetch = getReviewQuestions(reviewsData.currentYearAndTerm);

      const getReviewHRFetch = getReviewHR(reviewsData.currentYearAndTerm, user.id);
      const getUsersFetch = getListOfUsers();

      const getMyReviewFetch = getMyReview(reviewsData, user);
      const getReviewsFetch = getReviewsAsReviewer(reviewsData, user);
      Promise.all([
        getPastYearAndTermsFetch,
        getPastYearAndTermsForMenteesFetch,
        getPastYearAveragesFetch,
        getPastYearAveragesOfAllFetch,
        getReviewQuestionsFetch,
        reviewsData.reviewsInfo.status === ReviewStatus.IN_PROGRESS ? getMyReviewFetch : Promise.resolve(undefined),
        reviewsData.reviewsInfo.status === ReviewStatus.IN_PROGRESS ? getReviewsFetch : Promise.resolve([]),
        reviewsData.reviewsInfo.status === ReviewStatus.SETTING_UP ||
        reviewsData.reviewsInfo.status === ReviewStatus.IN_PROGRESS
          ? getReviewHRFetch
          : Promise.resolve(undefined),
        getUsersFetch,
      ]).then(
        ([
          pastYearAndTerms,
          pastYearAndTermsForMentees,
          pastYearAverages,
          pastYearAveragesOfAll,
          reviewQuestions,
          myReview,
          reviews,
          reviewHR,
          users,
        ]) =>
          setState({
            ...state,
            pastYearAndTerms,
            pastYearAndTermsForMentees,
            myReview,
            pastYearAverages,
            pastYearAveragesOfAll,
            reviews,
            reviewsData,
            reviewQuestions,
            reviewHR,
            users,
            loading: false,
          })
      );
    });

    // eslint-disable-next-line
  }, []);

  const getReviewResultsYearAndTerm = (yearAndTerm: YearAndTerm, user: User) => {
    const getReviewResultsFetch = getReviewResults(yearAndTerm, user);
    const getReviewResultsOfAllFetch = getReviewResultsOfAll(yearAndTerm);
    const getReviewResultsQuestions = getReviewQuestions(yearAndTerm);

    for (const pastYearAndTermsForMentee of pastYearAndTermsForMentees) {
      pastYearAndTermsForMentee.selectedYearAndTerm = undefined;
    }
    Promise.all([getReviewResultsFetch, getReviewResultsOfAllFetch, getReviewResultsQuestions]).then(
      ([reviewResults, reviewResultsOfAll, reviewResultsQuestions]) =>
        setState({
          ...state,
          reviewResults,
          reviewResultsOfAll,
          reviewResultsQuestions,
          selectedYearAndTerm: yearAndTerm,
          pastYearAndTermsForMentees,
        })
    );
  };

  const getReviewResultsYearAndTermMentee = (yearAndTerm: YearAndTerm, mentee: User, index: number) => {
    const getReviewResultsFetch = getReviewResults(yearAndTerm, mentee);
    const getReviewResultsOfAllFetch = getReviewResultsOfAll(yearAndTerm);
    const getReviewResultsQuestions = getReviewQuestions(yearAndTerm);

    for (const pastYearAndTermsForMentee of pastYearAndTermsForMentees) {
      pastYearAndTermsForMentee.selectedYearAndTerm = undefined;
    }

    pastYearAndTermsForMentees[index].selectedYearAndTerm = yearAndTerm;

    Promise.all([getReviewResultsFetch, getReviewResultsOfAllFetch, getReviewResultsQuestions]).then(
      ([reviewResults, reviewResultsOfAll, reviewResultsQuestions]) =>
        setState({
          ...state,
          reviewResults,
          reviewResultsOfAll,
          reviewResultsQuestions,
          selectedYearAndTerm: undefined,
          pastYearAndTermsForMentees,
        })
    );
  };

  const yearAndTermToString = (yearAndTerm: YearAndTerm): string => `${yearAndTerm.year} ${yearAndTerm.term}`;

  const convertYearAndTermBack = (yearAndTermString: string): YearAndTerm => ({
    year: Number(yearAndTermString.split(" ")[0]),
    term: yearAndTermString.split(" ")[1] as Term,
  });

  return loading ? (
    <Loading />
  ) : (
    <Container maxWidth="lg">
      {reviewHR && reviewsData?.reviewsInfo.status === ReviewStatus.IN_PROGRESS && (
        <Grid container>
          <LegendComponent colour={ColoursCampuses.USYD} text={"Not Submitted"} />
          <LegendComponent colour={ColoursCampuses.UNSW} text={"Submitted"} />
        </Grid>
      )}
      {reviewHR &&
        (reviewsData?.reviewsInfo.status === ReviewStatus.SETTING_UP ||
          reviewsData?.reviewsInfo.status === ReviewStatus.IN_PROGRESS) && (
          <Review360HRReviewee
            i={0}
            reviewsData={reviewsData}
            cannotRemove={reviewHR.reviewee.reviewersFinalised}
            fullyDisabled={reviewHR.reviewee.reviewersFinalised}
            currentYearAndTerm={reviewsData.currentYearAndTerm}
            review={reviewHR}
            reviewsHR={[reviewHR]}
            setReviewsHR={(reviewsHR: ReviewHR[]) =>
              setState({
                ...state,
                reviewHR: reviewsHR[0],
              })
            }
            users={users}
            revieweeDeleteButton={false}
            fromUser={true}
          />
        )}
      {reviewHR &&
        reviewsData?.reviewsInfo.status === ReviewStatus.SETTING_UP &&
        (reviewHR?.reviewee.reviewersFinalised ? (
          <Typography>You have finalised your reviewers. Waiting for HR to commence reviews...</Typography>
        ) : (
          <>
            <Button variant="contained" onClick={() => setState({ ...state, reviewersFinalisedDialog: true })}>
              Finalise Reviewers
            </Button>
            <Review360HRConfirmationDialog
              heading={"Finalise reviewers?"}
              content={
                "This will let the HR team know that you have finalised your reviewers and that your reviews are ready to commence"
              }
              action={"Finalise"}
              dialogOpen={reviewersFinalisedDialog}
              closeDialog={() => setState({ ...state, reviewersFinalisedDialog: false })}
              doAction={() => finaliseReviewers(reviewHR).then((reviewHR) => setState({ ...state, reviewHR }))}
            />
          </>
        ))}
      {myReview && reviewsData && reviewsData.reviewsInfo.cutOffDate && (
        <>
          <Alert variant="filled" severity="info">
            <TypographyBold>Due Date: {formatDateTime(reviewsData.reviewsInfo.cutOffDate)}</TypographyBold>
          </Alert>
          <Typography variant="h3">My review</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Review360Card reviewsData={reviewsData} review={myReview} />
            </Grid>
          </Grid>
        </>
      )}
      {reviewsData && reviews.length > 0 && (
        <>
          <Typography variant="h3">Review others</Typography>
          {reviews.length > 0 && reviews.every((review) => review.submitted) && (
            <TypographyBold variant="h4">All submitted!</TypographyBold>
          )}
          <Grid container spacing={2}>
            {reviewsData &&
              reviews.map((r, i) => (
                <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                  <Review360Card reviewsData={reviewsData} review={r} />
                </Grid>
              ))}
          </Grid>
        </>
      )}
      {pastYearAndTerms.length > 0 && (
        <>
          <Typography variant="h3">Results</Typography>
          {pastYearAndTerms.length > 0 && pastYearAveragesOfAll.length > 0 && pastYearAverages.length > 0 && (
            <Review360Progression
              pastYearAndTerms={pastYearAndTerms.slice().reverse()}
              pastYearAveragesOfAll={pastYearAveragesOfAll.slice().reverse()}
              pastYearAverages={pastYearAverages.slice().reverse()}
            />
          )}
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <TypographyBold>Select Year and Term</TypographyBold>
            </Grid>
            <Grid item>
              <Select
                required
                fullWidth
                placeholder="Year and Term"
                labelId="yearAndTerm-label"
                id="yearAndTerm"
                onChange={(event) =>
                  event.target.value
                    ? getReviewResultsYearAndTerm(convertYearAndTermBack(event.target.value), user)
                    : null
                }
                name="yearAndTerm"
                value={selectedYearAndTerm ? yearAndTermToString(selectedYearAndTerm) : "Select"}
                variant="outlined"
              >
                <MenuItem disabled value="Select">
                  None
                </MenuItem>
                {pastYearAndTerms.map((pastYearAndTerm, i) => (
                  <MenuItem key={i} value={yearAndTermToString(pastYearAndTerm)}>
                    {yearAndTermToString(pastYearAndTerm)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </>
      )}
      {pastYearAndTermsForMentees.length > 0 && <Typography variant="h3">Your Mentees' Results</Typography>}
      {pastYearAndTermsForMentees.map((v, i) => (
        <Box key={i}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <TypographyBold>
                {v.mentee.firstName} {v.mentee.lastName}:
              </TypographyBold>
            </Grid>
            <Grid item>
              <Select
                required
                fullWidth
                placeholder="Year and Term"
                labelId="yearAndTerm-label"
                id={`yearAndTerm-${i}`}
                onChange={(event) =>
                  event.target.value
                    ? getReviewResultsYearAndTermMentee(convertYearAndTermBack(event.target.value), v.mentee, i)
                    : null
                }
                name="yearAndTerm"
                value={v.selectedYearAndTerm ? yearAndTermToString(v.selectedYearAndTerm) : "Select"}
                variant="outlined"
              >
                <MenuItem disabled value="Select">
                  None
                </MenuItem>
                {v.pastYearAndTerms.map((pastYearAndTerm, i) => (
                  <MenuItem key={i} value={yearAndTermToString(pastYearAndTerm)}>
                    {yearAndTermToString(pastYearAndTerm)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Box>
      ))}
      {reviewResults && reviewResultsOfAll && reviewResultsQuestions && (
        <Review360Results
          results={reviewResults}
          resultsOfAll={reviewResultsOfAll}
          questions={reviewResultsQuestions}
        />
      )}
      <Box sx={{ mb: theme.spacing(5) }} />
      <Button onClick={() => setState({ ...state, showReviewQuestions: !showReviewQuestions })} variant="contained">
        Questions
      </Button>
      <Box sx={{ mb: theme.spacing(2) }} />
      {reviewQuestions &&
        showReviewQuestions &&
        Object.entries(reviewQuestions).map(([category, attributes], i) => (
          <Card key={i} sx={{ marginBottom: theme.spacing(2) }}>
            <CardHeader title={<Typography variant="h3">{category}</Typography>} />
            <CardContent>
              {Object.entries(attributes).map(([attribute, questions], j) => (
                <Box key={j}>
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      textDecoration: "underline",
                    }}
                    variant="h4"
                  >
                    {attribute}
                  </Typography>
                  {Object.entries(questions).map(([index, question], k) => (
                    <Typography key={k} variant="h5">
                      {question}
                    </Typography>
                  ))}
                </Box>
              ))}
            </CardContent>
          </Card>
        ))}
      <Box sx={{ mb: theme.spacing(20) }} />
    </Container>
  );
};
