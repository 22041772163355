import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Loading } from "../../../components/Loading";
import { NavBarIconButtonLink } from "../../../components/NavBarIconButton";
import { TypographyBold } from "../../../components/TypographyBold";
import { Path } from "../../../helpers/Path";
import { Review, ReviewQuestions, ReviewStatus } from "../../../models/Review360Model";
import {
  getCurrentStep,
  getReview,
  getReviewQuestions,
  getReviewsData,
  readyToSubmit,
  submitReview,
  updateReview,
} from "../../../services/review360";
import { Review360Form } from "./Review360Form";
import { auth } from "../../../services/firebase";
import { Review360HelpDialog } from "./Review360HelpDialog";

export interface Review360ReviewInterface {
  loading: boolean;
  review?: Review;
  reviewQuestions?: ReviewQuestions;
  openHelpDialog: boolean;
  currentStep: number;
}

export const Review360Review = () => {
  const { id, email } = useParams<{ id: string; email: string }>();
  const history = useHistory();
  const theme = useTheme();

  const [state, setState] = useState<Review360ReviewInterface>({
    loading: true,
    openHelpDialog: false,
    currentStep: 0,
  });

  const [updating, setUpdating] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    setState({ ...state, loading: true });
    if (!auth.currentUser) {
      history.push(Path["360 Reviews - Member"]);
    }

    getReviewsData().then((reviewsData) => {
      if (reviewsData.reviewsInfo.status === ReviewStatus.IN_PROGRESS) {
        const getReviewFetch = getReview(reviewsData, id, email);
        const getReviewQuestionsFetch = getReviewQuestions(reviewsData.currentYearAndTerm);

        Promise.all([getReviewFetch, getReviewQuestionsFetch]).then(([review, reviewQuestions]) => {
          if (review === undefined) {
            history.push(!auth.currentUser?.isAnonymous ? Path["360 Reviews"] : Path["360 Reviews - Member"]);
          } else {
            setState({
              ...state,
              review,
              reviewQuestions,
              currentStep: getCurrentStep(review.answers, reviewQuestions),
              loading: false,
            });
          }
        });
      } else {
        history.push(!auth.currentUser?.isAnonymous ? Path["360 Reviews"] : Path["360 Reviews - Member"]);
      }
    });
    // eslint-disable-next-line
  }, []);

  const { review, reviewQuestions, currentStep, openHelpDialog, loading } = state;

  const renderCounter = useRef(0);

  useEffect(() => {
    if (review) {
      if (renderCounter.current > 1) {
        const delayDebounceFn = setTimeout(() => {
          updateReview(review).then(() => {
            setUpdating(false);
            setShowMessage(true);
          });
        }, 1000);
        setUpdating(true);
        setShowMessage(true);
        return () => clearTimeout(delayDebounceFn);
      }
      ++renderCounter.current;
    }
  }, [review]);

  const categories = reviewQuestions ? Object.keys(reviewQuestions).sort() : [];
  const categoriesCount = reviewQuestions ? Object.keys(reviewQuestions).length : 0;

  const finalStep = categoriesCount === currentStep + 1;

  const handleNext = () => {
    window.scrollTo({ top: 0, left: 0 });
    setState({ ...state, currentStep: currentStep + 1 });
  };

  const handleBack = () => {
    window.scrollTo({ top: 0, left: 0 });
    setState({ ...state, currentStep: currentStep - 1 });
  };

  const handleSubmit = () =>
    submitReview(review).then(() => {
      auth.currentUser?.isAnonymous
        ? auth.currentUser?.delete().then(() => history.push(Path["360 Reviews - Member"]))
        : history.push(Path["360 Reviews"]);
    });

  return loading ? (
    <Loading />
  ) : (
    <>
      <NavBarIconButtonLink
        to={!auth.currentUser?.isAnonymous ? Path["360 Reviews"] : Path["360 Reviews - Member"]}
        sx={{
          mt: theme.spacing(2),
          ml: theme.spacing(2),
        }}
      >
        <ArrowBackIcon color="primary" />
      </NavBarIconButtonLink>
      <Container maxWidth="lg">
        <Grid container justifyContent="space-between">
          <Typography variant="h5">
            {review?.reviewee.id === review?.reviewer.id ? "Your Review" : review?.reviewee.firstName + "'s Review"}
          </Typography>
          {review?.reviewerGroup && <Typography>Your group: {review.reviewerGroup}</Typography>}
          <Review360HelpDialog
            dialogOpen={openHelpDialog}
            closeDialog={() => setState({ ...state, openHelpDialog: false })}
          />
          <Button variant="contained" onClick={() => setState({ ...state, openHelpDialog: true })}>
            Help
          </Button>
        </Grid>
        <Grid container justifyContent="space-between">
          <TypographyBold variant="h3">{reviewQuestions ? categories[currentStep] : ""}</TypographyBold>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={showMessage}
            onClose={() => setShowMessage(false)}
            autoHideDuration={3000}
          >
            <Alert severity={updating ? "warning" : "success"} elevation={6} variant="filled">
              {updating ? "Saving..." : "Saved!"}
            </Alert>
          </Snackbar>
        </Grid>
        {review && reviewQuestions && (
          <Review360Form
            updateAnswers={(answers) => setState({ ...state, review: { ...review, answers } })}
            review={review}
            reviewQuestions={reviewQuestions}
            currentStep={currentStep}
          />
        )}
      </Container>
      <Box sx={{ pb: theme.spacing(20) }} />
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          backdropFilter: "blur(5px)",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          width: "100%",
          borderRadius: 0,
        }}
      >
        <Grid container direction="column">
          {!updating ? (
            <Grid
              container
              spacing={1}
              alignItems="center"
              item
              sx={{
                mb: theme.spacing(1),
              }}
            >
              <Grid item>
                <Button disabled={currentStep === 0} onClick={handleBack}>
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={
                    !(
                      (finalStep &&
                        readyToSubmit(review?.answers, reviewQuestions, review?.reviewee.id === review?.reviewer.id)) ||
                      !finalStep
                    )
                  }
                  variant="contained"
                  color="primary"
                  onClick={finalStep ? handleSubmit : handleNext}
                >
                  {finalStep ? "Submit" : "Next"}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <CircularProgress sx={{ ml: theme.spacing(6) }} />
            </Grid>
          )}
          <Grid item>
            <Stepper activeStep={currentStep} alternativeLabel>
              {reviewQuestions &&
                categories.map((category, i) => (
                  <Step key={i}>
                    <StepLabel>
                      <Typography>{category}</Typography>
                    </StepLabel>
                  </Step>
                ))}
            </Stepper>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
