export const ALUMNI = "Alumni";
export const DIRECTOR = "Director";
export const HEAD_OF_DIVISION = "Head of Division";
export const HEAD_OF_DEPARTMENT = "Head of Department";
export const SENIOR_CHAPLAIN = "Senior Chaplain";
export const JUNIOR_CHAPLAIN = "Junior Chaplain";
export const CAMPUS_CHAPLAIN = "Campus Chaplain";
export const INTERN_CHAPLAIN = "Intern Chaplain";
export const STAFF = "Staff";
export const DEPARTMENT_OVERSEER = "Department Overseer";
export const OUTSOURCE = "Outsource";
export const PRESIDENT = "President";
export const VICE_PRESIDENT = "Vice President";
export const EXECUTIVE = "Executive";
export const STUDENT_LEADER = "Student Leader";
