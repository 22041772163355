export enum XeroAPI {
  getToken = "getToken",
  getConnections = "getConnections",
  Accounts = "Accounts",
  Invoices = "Invoices",
  ProfitAndLoss = "Reports/ProfitAndLoss",
  BankTransactions = "BankTransactions",
  Contacts = "Contacts",
}

export const tenantId = "f4d7d470-b070-419c-8ef2-de54a4ac7f81";
